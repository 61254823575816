import React from 'react';
import { ContainerMeuPlano } from './styles';
import { CardMeuPlano } from './components/CardMeuPlano';
import { CurrentPlan } from './components/CurrentPlan';
import { ChangePaymentMethod } from './components/ChangePaymentMethod';
import { AvailablePlans } from './components/AvailablePlans';
import { Invoices } from './components/Invoices';
import { ChangeBillingDetails } from './components/ChangeBillingDetails';
import { useMeuPlano } from './hooks/useMeuPlano';
import { CancelSubscription } from './components/CancelSubscription';

export const MeuPlanoContent: React.FC = () => {
  const { screen } = useMeuPlano();

  return (
    <ContainerMeuPlano>
      {screen === 'HOME' && (
        <>
          <section className="meu-plano-content-top">
            <CardMeuPlano
              screenOpen="GERENCIAR-ASSINATURA"
              content={<CurrentPlan />}
            />
            <CardMeuPlano
              screenOpen="MUDAR-FORMA-PAGAMENTO"
              content={<ChangePaymentMethod />}
            />
          </section>
          <section className="meu-plano-content-bottom">
            <CardMeuPlano
              screenOpen="PLANOS-DISPONIVEIS"
              content={<AvailablePlans />}
            />
            <CardMeuPlano screenOpen="FATURAS" content={<Invoices />} />
            <CardMeuPlano
              screenOpen="MUDAR-DADOS-COBRANCA"
              content={<ChangeBillingDetails />}
            />
          </section>
        </>
      )}
      {screen === 'GERENCIAR-ASSINATURA' && <CurrentPlan />}
      {screen === 'MUDAR-FORMA-PAGAMENTO' && <ChangePaymentMethod />}
      {(screen === 'PLANOS-DISPONIVEIS' ||
        screen === 'PLANOS-DISPONIVEIS-DADOS') && <AvailablePlans />}
      {screen === 'FATURAS' && <Invoices />}
      {screen === 'MUDAR-DADOS-COBRANCA' && <ChangeBillingDetails />}
      {screen === 'CANCELAR-ASSINATURA' && <CancelSubscription />}
    </ContainerMeuPlano>
  );
};
