import React from 'react';
import { DataGrid, GridColumns } from '@material-ui/data-grid';
import Tooltip from '@atlaskit/tooltip';
import { CenterTableContent, CutomizedTooltip, TableContainer } from './styles';

import { Loja } from '../../protocols';
import Checkbox from '@atlaskit/checkbox';
import { Radio } from '@atlaskit/radio';

import { useUsuario } from '../../UsuarioContext';

export const Table: React.FC = () => {
  const { formUsuario, handleChangeFormUsuario, lojas, handleLojas, setValue } =
    useUsuario();

  const columns: GridColumns = [
    {
      field: 'id',
      headerName: 'Id',
      hide: true,
      width: 100,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'acesso',
      headerName: 'Acesso?',
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        return (
          <CenterTableContent>
            {!row.selectable ? (
              <Tooltip
                component={CutomizedTooltip}
                position="top"
                content={`Seu usuário não possui permissão para editar a loja ${row.cod_loja}`}
              >
                <Checkbox
                  isChecked={row.flg_acesso}
                  isDisabled={!row.selectable}
                  onChange={() => handleAcesso(row)}
                />
              </Tooltip>
            ) : (
              <Checkbox
                isChecked={row.flg_acesso}
                isDisabled={!row.selectable}
                onChange={() => handleAcesso(row)}
              />
            )}
          </CenterTableContent>
        );
      },
    },
    {
      field: 'flg_padrao',
      headerName: 'Padrão?',
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        return (
          <CenterTableContent>
            {!row.selectable ? (
              <Tooltip
                component={CutomizedTooltip}
                position="top"
                content={`Seu usuário não possui permissão para editar a loja ${row.cod_loja}`}
              >
                <Radio
                  value={row.cod_loja}
                  isDisabled={!row.selectable}
                  name="flg_matriz"
                  isChecked={row.flg_padrao}
                  onChange={handleChangeLojaPadrao}
                />
              </Tooltip>
            ) : (
              <Radio
                value={row.cod_loja}
                isDisabled={!row.selectable}
                name="flg_matriz"
                isChecked={row.flg_padrao}
                onChange={handleChangeLojaPadrao}
              />
            )}
          </CenterTableContent>
        );
      },
    },
    {
      field: 'loja',
      headerName: 'Loja',
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'flg_lib_financeiro',
      headerName: 'Lib. Financeiro?',
      headerAlign: 'center',
      flex: 1,
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        return (
          <CenterTableContent>
            {!row.selectable ? (
              <Tooltip
                component={CutomizedTooltip}
                position="top"
                content={`Seu usuário não possui permissão para editar a loja ${row.cod_loja}`}
              >
                <Checkbox
                  isChecked={row.flg_lib_financeiro}
                  isDisabled={!row.selectable}
                  onChange={() => handleLiberador(row)}
                />
              </Tooltip>
            ) : (
              <Checkbox
                isChecked={row.flg_lib_financeiro}
                isDisabled={!row.selectable}
                onChange={() => handleLiberador(row)}
              />
            )}
          </CenterTableContent>
        );
      },
    },
    {
      field: 'flg_vendedor',
      headerName: 'Vendedor?',
      headerAlign: 'center',
      flex: 1,
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        return (
          <CenterTableContent>
            {!row.selectable ? (
              <Tooltip
                component={CutomizedTooltip}
                position="top"
                content={`Seu usuário não possui permissão para editar a loja ${row.cod_loja}`}
              >
                <Checkbox
                  isChecked={row.flg_vendedor}
                  isDisabled={!row.selectable}
                  onChange={() => handleVendedor(row)}
                />
              </Tooltip>
            ) : (
              <Checkbox
                isChecked={row.flg_vendedor}
                isDisabled={!row.selectable}
                onChange={() => handleVendedor(row)}
              />
            )}
          </CenterTableContent>
        );
      },
    },
  ];

  const handleChangeLojaPadrao = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cod_loja = Number(e.target.value);
    const newLojas = lojas.map((loja: Loja) => {
      if (loja.cod_loja === cod_loja) {
        loja.flg_padrao = true;
        loja.flg_acesso = true;
      } else {
        loja.flg_padrao = false;
      }
      return loja;
    });
    handleSelectedLojas(newLojas);
    handleLojas(newLojas);
  };

  const handleAcesso = (row: any) => {
    const newLojas = lojas.map((loja: Loja) => {
      if (loja.cod_loja === row.cod_loja) {
        if (row.flg_acesso) {
          loja.flg_acesso = false;
          loja.flg_padrao = false;
        } else {
          loja.flg_acesso = true;
          if (formUsuario.lojas.lojas.length === 0) {
            loja.flg_padrao = true;
          }
        }
      }
      return loja;
    });
    handleLojas(newLojas);
    handleSelectedLojas(newLojas);
  };

  const handleLiberador = (row: any) => {
    const newLojas = lojas.map((loja: Loja) => {
      loja.cod_loja === row.cod_loja &&
        (loja.flg_lib_financeiro = !row.flg_lib_financeiro);
      return loja;
    });
    handleLojas(newLojas);
    handleSelectedLojas(newLojas);
  };

  const handleVendedor = (row: any) => {
    const newLojas = lojas.map((loja: Loja) => {
      loja.cod_loja === row.cod_loja && (loja.flg_vendedor = !row.flg_vendedor);
      return loja;
    });
    handleLojas(newLojas);
    handleSelectedLojas(newLojas);
  };

  const handleSelectedLojas = (newLojas: Loja[]) => {
    const selecteds = newLojas.filter((loja: Loja) => {
      loja.flg_lib_financeiro;
      return loja.flg_acesso;
    });

    const selectedsPa = newLojas.filter((loja: Loja) => {
      return loja.flg_padrao;
    });

    if (selectedsPa.length === 0 && selecteds.length > 0) {
      selecteds[0].flg_padrao = true;
    }
    setValue('lojas', selecteds);

    handleChangeFormUsuario({
      ...formUsuario,
      lojas: {
        ...formUsuario.lojas,
        lojas: selecteds,
      },
    });
  };

  return (
    <TableContainer>
      <DataGrid
        rowsPerPageOptions={[5]}
        rows={lojas}
        columns={columns}
        pageSize={5}
        disableColumnSelector
        disableSelectionOnClick
        localeText={{
          noRowsLabel: 'Nenhum registro encontrado...',
          columnMenuLabel: 'Menu',
          columnMenuFilter: 'Filtrar',
          columnMenuHideColumn: 'Esconder',
          columnMenuUnsort: 'Não ordenar',
          columnMenuSortAsc: 'Ordernar ASC',
          columnMenuSortDesc: 'Ordernar DESC',
          columnMenuShowColumns: 'Mostrar columnas',
        }}
      />
    </TableContainer>
  );
};
