import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  InputAsyncSelect,
  InputDate,
  InputMoney,
} from '~/components/NovosInputs';

import { formatCurrencyAsText, transformAsCurrency } from '~/utils/functions';
import Separator from '~/components/Separator';

import { toast } from 'react-toastify';

import { insertOrUpdate } from '~/utils/masterDetail';
import { nanoid } from 'nanoid';
import { ButtonItens } from '~/pages/NFOutrasOperacoes/screens/Nfe/styles';
import { FaEraser, FaSync } from 'react-icons/fa';
import { usePedidoVenda } from '~/pages/PedidoVenda/PedidoVendaContext';
import { LojaContext } from '~/context/loja';
import { CustomButtonNovo } from '~/components/Buttons/CustomButtonNovo';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { differenceInDays, isBefore, parseISO } from 'date-fns';

export const FormParcelas: React.FC = () => {
  const { codLoja } = useContext(LojaContext);
  const [numParcela, setNumParcela] = useState(1);

  const {
    formFinanceiro: {
      register,
      handleSubmit,
      control,
      reset,
      setValue,
      clearErrors,
      setError,
      formState: { errors },
    },
    formPedidoVenda: { getValues: getValuesPedidoVenda },
    isFormFinanceiroEditing,
    setIsFormFinanceiroEditing,
    isDisabledPedido,
    parcelasRows,
    setParcelasRows,
    setMasterDetail,
    masterDetail,
    handleRecalculaFinanceiro,
    valorTotal,
  } = usePedidoVenda();

  useEffect(() => {
    const valorPedido = transformAsCurrency(valorTotal || 0);
    if (parcelasRows.length !== 0) {
      const totalParcelas = parcelasRows.reduce(
        (acc, parcela) => acc + parcela.val_parcela,
        0,
      );
      const resultado = valorPedido - totalParcelas;
      setValue(
        'val_financeiro',
        formatCurrencyAsText(resultado > 0 ? resultado : 0),
      );
    } else {
      setValue('val_financeiro', formatCurrencyAsText(valorPedido));
    }
  }, [parcelasRows, valorTotal]);

  const calcularDiasEntreDatas = useCallback(
    (dtaVencimento: string, dtaEmissao: string): number => {
      if (!dtaVencimento || !dtaEmissao) return 0;

      const dataVencimento = parseISO(dtaVencimento);
      const dataEmissao = parseISO(dtaEmissao);

      return differenceInDays(dataVencimento, dataEmissao);
    },
    [],
  );

  const validarDatas = useCallback(
    (dtaVencimento: string, dtaEmissao: string): boolean => {
      if (!dtaVencimento || !dtaEmissao) return false;

      const dataVencimento = parseISO(dtaVencimento);
      const dataEmissao = parseISO(dtaEmissao);

      return !isBefore(dataVencimento, dataEmissao);
    },
    [],
  );

  const handleAddParcela = handleSubmit(
    async (data: any) => {
      const dta_emissao = getValuesPedidoVenda('dta_emissao');

      if (!validarDatas(data.dta_vencimento, dta_emissao)) {
        setError('dta_vencimento', { type: 'required' }, { shouldFocus: true });
        toast.warning(
          'A data de vencimento não pode ser anterior à data de emissão.',
        );
        return;
      }

      if (data.val_financeiro === '' || data.val_financeiro === '0,00') {
        setError('val_financeiro', { type: 'required' });
        return;
      }

      const exists = parcelasRows.filter(
        (parcela) =>
          parcela.num_condicao === data.num_condicao &&
          parcela.cod_finalizadora === data.finalizadora.value &&
          parcela.uuid !== isFormFinanceiroEditing.uuid,
      );
      if (exists.length > 0) {
        return toast.warning('Já existe uma condição com essas informações.');
      }

      const formatedParcela: any = {
        uuid: isFormFinanceiroEditing.isEdit
          ? isFormFinanceiroEditing.uuid
          : nanoid(),
        id: isFormFinanceiroEditing.isEdit
          ? isFormFinanceiroEditing.uuid
          : nanoid(),
        num_condicao: calcularDiasEntreDatas(data.dta_vencimento, dta_emissao),
        cod_condicao: 2,
        des_condicao: 'DD - DIAS DA DATA',
        dta_vencimento: data.dta_vencimento,
        val_parcela: transformAsCurrency(data.val_financeiro),
        cod_finalizadora: data.finalizadora.value,
        des_finalizadora: data.finalizadora.label,
        num_registro: numParcela,
      };
      setNumParcela(numParcela + 1);
      const parcelasDetail: any[] = await insertOrUpdate(
        'financeiro',
        formatedParcela,
        masterDetail,
        setMasterDetail,
      );

      setParcelasRows(parcelasDetail);
      reset({
        num_condicao: 30,
        finalizadora: '',
        dta_vencimento: '',
        val_financeiro: '',
      });
    },
    (err) => {
      console.log(err);
    },
  );

  return (
    <>
      <Row>
        <Col md={10} sm={12}>
          <Separator labelText="Financeiro" sidePadding="0 0" />
        </Col>
        <Col md={2} sm={12}>
          <ButtonItens
            type="button"
            onClick={() => handleRecalculaFinanceiro()}
            disabled={isDisabledPedido}
          >
            <FaSync color="#28a745" />
            <span>(Re) Calcular</span>
          </ButtonItens>
        </Col>
      </Row>
      <Row className="d-flex align-items-center position-relative">
        <Col sm={12} md={4}>
          <InputAsyncSelect
            label="Finalizadora"
            maxLength={50}
            placeholder="Selecione..."
            name="finalizadora"
            register={register}
            isError={!!errors.finalizadora}
            control={control}
            disabled={isDisabledPedido}
            listWidth="300px"
            changeSelected={(selected) => {
              clearErrors('finalizadora');
              setValue('finalizadora', selected);
            }}
            api={{
              route: '/finalizadora',
              method: 'get',
              bodyParams: {
                codLoja,
              },
              fields: ['cod_finalizadora', 'des_finalizadora'],
              dependsOf: ['codLoja'],
              messageForDependsOf:
                'É necessário selecionar a loja na capa da NF',
              searchBeforeFilter: true,
            }}
          />
        </Col>

        <Col sm={12} md={2}>
          <InputDate
            register={register}
            label="Vencimento"
            name="dta_vencimento"
            control={control}
            disabled={isDisabledPedido}
            isError={!!errors.dta_vencimento}
          />
        </Col>
        <Col sm={12} md={2}>
          <InputMoney
            label="Valor"
            placeholder="0,00"
            min={0}
            decimals={2}
            name="val_financeiro"
            register={register}
            disabled={isDisabledPedido}
            isError={!!errors.val_financeiro}
          />
        </Col>
        <Col
          md={2}
          sm={12}
          style={{ display: 'flex', height: '70px', alignItems: 'flex-end' }}
        >
          <CustomButtonNovo
            onClick={async () => {
              await handleAddParcela();
              setTimeout(() => {
                setIsFormFinanceiroEditing({ isEdit: false, uuid: undefined });
              }, 300);
            }}
            label="Adicionar"
            icon={IoMdAddCircleOutline}
            disabled={isDisabledPedido}
            variant="confirm"
          />
        </Col>
        <Col
          md={2}
          sm={12}
          style={{ display: 'flex', height: '70px', alignItems: 'flex-end' }}
        >
          <CustomButtonNovo
            onClick={() => {
              reset({
                num_condicao: 30,
                finalizadora: '',
                dta_vencimento: '',
                val_financeiro: '',
              });
              setIsFormFinanceiroEditing({ isEdit: false, uuid: undefined });
            }}
            label="Limpar"
            icon={FaEraser}
            disabled={isDisabledPedido}
            variant="clear"
          />
        </Col>
      </Row>
    </>
  );
};
