/* eslint-disable prettier/prettier */
import {
  DataGrid,
  GridCellParams,
  GridColumns,
  GridSelectionModel,
} from '@material-ui/data-grid';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaArrowAltCircleDown, FaArrowAltCircleUp } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import DefaultLoader from '~/components/DefaultLoader';
import FooterDefault from '~/components/FooterDefault';
import Loja from '~/components/Loja';
import { BuscaProduto } from '~/components/NovosInputs';
import Separator from '~/components/Separator';
import api from '~/services/api';

import {
  Button,
  ButtonCancel,
  ButtonRow,
  Container,
  ContainerProduto,
  SeparatorLine,
} from './styles';
import { IProduto } from './types';
import { Spinner } from 'react-bootstrap';
import { createDateWithoutTimezone } from '~/utils/createDateWithoutTimezone ';
import moment from 'moment';
import { Tooltip } from '@material-ui/core';

const MySwal = withReactContent(Swal);

const Mix: React.FC = () => {
  const [produtosInativos, setProdutosInativos] = useState<IProduto[]>([]);
  const [produtosAtivos, setProdutosAtivos] = useState<IProduto[]>([]);
  const [clearCampoProduto, setClearCampoProduto] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [isDisableSearch, setIsDisableSearch] = useState<boolean>(false);
  const [lojas, setLojas] = useState<number | number[]>();
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [selectionModelAtivos, setSelectionModelAtivos] =
    useState<GridSelectionModel>([]);

  // Estados de condição para carregamento dos botões de Ativar/Inativar
  const [loadingAtivacao, setLoadingAtivacao] = useState(false);
  const [loadingInativacao, setLoadingInativacao] = useState(false);

  const [pageAtivos, setPageAtivos] = useState<number>(0);
  const [pageInativos, setPageInativos] = useState<number>(0);

  const colunasDentroDoMix: GridColumns = [
    {
      field: 'id',
      hide: true,
    },
    {
      field: 'cod_loja',
      headerName: 'Loja',
      width: 110,
      align: 'center',
    },
    {
      field: 'cod_produto',
      headerName: 'Código',
      width: 120,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'des_reduzida',
      headerName: 'Descrição',
      width: 300,
      headerClassName: 'super-app-theme--header',
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        const descricaoCompleta = row.des_produto;
        return (
          <Tooltip title={descricaoCompleta}>
            <span>{row.des_reduzida}</span>
          </Tooltip>
        );
      },
    },
    {
      // field: 'cod_barra_principal',
      field: 'cod_gtin_principal',
      headerName: 'Código de Barras',
      width: 180,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'qtd_est_atual',
      headerName: 'Estoque',
      width: 110,
      type: 'number',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'cod_associado',
      headerName: 'Associado',
      width: 180,
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header',
    },

    {
      field: '',
      headerName: 'Ação',
      sortable: false,
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      // // disableClickEventBubbling: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        const inativarProduto = () => {
          handleAlteraStatusProduto(row, 'INATIVAR');
        };
        return (
          <>
            <ButtonRow type="button" onClick={inativarProduto}>
              <FaArrowAltCircleDown size={20} style={{ color: '#e63c3c' }} />
            </ButtonRow>
          </>
        );
      },
    },
  ];
  const colunasForaDoMix: GridColumns = [
    {
      field: 'id',
      hide: true,
    },
    {
      field: 'cod_loja',
      headerName: 'Loja',
      width: 110,
      align: 'center',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
    },
    {
      field: 'cod_produto',
      headerName: 'Código',
      width: 120,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'des_reduzida',
      headerName: 'Descrição',
      width: 300,
      headerClassName: 'super-app-theme--header',
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        const descricaoCompleta = row.des_produto;
        return (
          <Tooltip title={descricaoCompleta}>
            <span>{row.des_reduzida}</span>
          </Tooltip>
        );
      },
    },
    {
      // field: 'cod_barra_principal',
      field: 'cod_gtin_principal',
      headerName: 'Código de Barras',
      width: 180,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'dta_inativacao',
      headerName: 'Data de Inativação',
      width: 290,
      align: 'center',
      headerClassName: 'super-app-theme--header',
    },
    {
      field: '',
      headerName: 'Ação',
      sortable: false,
      flex: 1,
      minWidth: 100,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      // // disableClickEventBubbling: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        const ativarProduto = () => {
          handleAlteraStatusProduto(row, 'ATIVAR');
        };
        return (
          <>
            <ButtonRow type="button" onClick={ativarProduto}>
              <FaArrowAltCircleUp size={20} style={{ color: '#1b7e1b' }} />
            </ButtonRow>
          </>
        );
      },
    },
  ];

  const handleValidarInativacao = useCallback(async (produtos: IProduto[]) => {
    const messageformat = [];
    let ocorrecia = '';
    let inativar = true;
    // eslint-disable-next-line no-restricted-syntax
    for await (const ItemProduto of produtos) {
      if (ItemProduto.qtd_est_troca !== 0 || ItemProduto.qtd_est_atual !== 0) {
        ocorrecia = 'Produto com estoque';
        inativar = false;
      }

      if (
        ItemProduto.cod_associado !== '' &&
        ItemProduto.cod_associado !== null
      ) {
        if (ocorrecia !== '') {
          ocorrecia += ` e Produto associado`;
        } else {
          ocorrecia = 'Produto associado';
        }
      }
      messageformat.push({
        des_produto: ItemProduto.des_produto,
        loja: ItemProduto.cod_loja,
        plu: ItemProduto.cod_produto,
        PossuiCodAssociado: !!(
          ItemProduto.cod_associado !== '' && ItemProduto.cod_associado !== null
        ),
        ocorrecia,
        inativar,
        cod_associado: ItemProduto.cod_associado,
      });
      ocorrecia = '';
      inativar = true;
    }
    return messageformat;
  }, []);

  const {
    register,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onSubmit',
  });

  const handleSelecionarProduto = useCallback(
    (produtos: IProduto[]) => {
      if (produtos.length === 0) {
        toast.warn('Nenhum produto encontrado com o critério informado.');
        setValue('busca_produto', {
          value: undefined,
          label: ''
        })
      }
      const Ativo = [];
      const Inativo = [];
      const idsVistos: Record<string, boolean> = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const produto of produtos) {
        const id = `${produto.cod_loja}${produto.cod_produto}`;
        if (!idsVistos[id]) {
          idsVistos[id] = true;

          if (produto.flg_inativo) {
            produto.dta_inativacao = produto.dta_inativacao
              ? moment(createDateWithoutTimezone(produto.dta_inativacao)).format(
              'DD/MM/YYYY',
            )
              : '';
            Inativo.push({
              id,
              ...produto,
            });
          } else {
            Ativo.push({
              id,
              ...produto,
            });
          }
        }
      }

      produtos.length > 0 && (setIsDisableSearch(true))
      setProdutosInativos(Inativo);
      setProdutosAtivos(Ativo);
    },
    [produtosInativos],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleProdutos = useCallback(
    (produto: IProduto, operacao: string) => {
      let Inativos: IProduto[] = produtosInativos;
      let Ativos: IProduto[] = produtosAtivos;
      const produtoAtual = produto;
      produtoAtual.flg_inativo = operacao !== 'ATIVAR';

      if (operacao === 'ATIVAR') {
        const InativosItens = Inativos.filter(
          (item) => item.id !== produtoAtual.id,
        );
        Inativos = InativosItens;
        produtoAtual.dta_inativacao = '';
        Ativos = [...Ativos, produtoAtual];
      } else {
        const AtivosItens = Ativos.filter(
          (item) => item.id !== produtoAtual.id,
        );
        Ativos = AtivosItens;

        produtoAtual.dta_inativacao = moment(new Date(), 'DD/MM/YYYY').format(
          'DD/MM/YYYY',
        );
        produtoAtual.cod_associado = null;
        Inativos = [...Inativos, produtoAtual];
      }
      setProdutosInativos(Inativos);
      setProdutosAtivos(Ativos);
    },
    [produtosInativos, produtosAtivos],
  );

  function CreateTable(produtos: any): boolean {
    let resultModal: any = false;
    const TableHeader =
      '<tr><th>Loja</th><th>Descrição</th><th>PLU</th><th>Ocorrência</th></tr>';
    const TableBody = produtos.map((item: any): string => {
      const spaco = '&nbsp;&nbsp;';
      return `<tr><td>${item.loja}${spaco}</td><td align="left">${item.des_produto}${spaco}</td><td>${item.plu}${spaco}</td><td>${item.ocorrecia}</td></tr>`;
    });

    const Table = `
            <div style='max-height: 200px;white-space: nowrap; border: solid 1px #dcdcdc; overflow: auto'>
              <table style='width:100%;min-width: 500px'>
                <thead>${TableHeader}</thead>
                <tbody>${TableBody.join(' ')}</tbody>
              </table>
            </div>
            <p style='text-align: left; padding: 20px'>
              Regularize a situação para prosseguir
            </p>
            `;
    if (produtos.length > 0) {
      resultModal = MySwal.fire({
        icon: 'warning',
        width: 800,
        title: 'Os produtos listados não foram retirados do MIX',
        html: String(Table),
      }).then((result) => {
        if (result.isConfirmed) {
          return true;
        }
        return false;
      });
    }
    return resultModal;
  }

  function CreateTableAssociados(produtos: any): boolean {
    let resultModal: any = false;
    const TableHeader =
      '<tr><th>Loja</th><th>Descrição</th><th>PLU</th><th>Associado</th><th>Ocorrência</th></tr>';
    const TableBody = produtos.map((item: any): string => {
      const spaco = '&nbsp;&nbsp;';
      return `<tr><td>${item.loja}${spaco}</td><td align="left">${item.des_produto}${spaco}</td><td>${item.plu}${spaco}</td><td>${item.cod_associado}</td><td>${item.ocorrecia}</td></tr>`;
    });

    const Table = `
            <div style='max-height: 200px;white-space: nowrap; border: solid 1px #dcdcdc; overflow: auto'>
              <table style='width:100%;min-width: 500px'>
                <thead>${TableHeader}</thead>
                <tbody>${TableBody.join(' ')}</tbody>
              </table>
            </div>
            <p style='text-align: left; padding: 20px'>
              Necessário confirmação para prosseguir
            </p>
            `;
    if (produtos.length > 0) {
      resultModal = MySwal.fire({
        icon: 'warning',
        width: 800,
        title: 'Os produtos listados são associados!',
        html: String(Table),
        showCancelButton: true,
        confirmButtonColor: '#8850BF',
        cancelButtonColor: '#DE350B',
        confirmButtonText: 'Confirmar e desvincular associados',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          return true;
        }
        return false;
      });
    }
    return resultModal;
  }
  const handleAlteraStatusProduto = useCallback(
    async (produto: IProduto, action: string) => {
      const { cod_produto, cod_loja } = produto;
      const validacoes = await handleValidarInativacao([produto]);
      if (action === 'INATIVAR') {
        const possuiCodeSimiar = validacoes.filter(
          (item) => item.PossuiCodAssociado === true,
        );

        if (possuiCodeSimiar.length > 0) {
          const resultaItensAssociados = await CreateTableAssociados(
            possuiCodeSimiar,
          );

          if (!resultaItensAssociados) return;
        }
      }

      if (action === 'ATIVAR') {
        const { data } = await api.put(`/mix/${cod_produto}`, {
          cod_loja,
          action,
        });
        if (data.success) {
          toast.success(data.message);
          handleProdutos(produto, 'ATIVAR');
        }
      }
      if (action === 'INATIVAR') {
        const produtoQueNaoPodeSerInativado = validacoes.filter(
          (item) => item.inativar === false,
        );
        await CreateTable(produtoQueNaoPodeSerInativado);
        if (produtoQueNaoPodeSerInativado.length !== 0) return;

        const { data } = await api.put(`/mix/${cod_produto}`, {
          cod_loja,
          action,
        });
        if (data.success) {
          toast.success(data.message);
          handleProdutos(produto, 'INATIVAR');
        }
      }
      setLoader(false);
    },
    [handleValidarInativacao, handleProdutos],
  );

  const handleAssociadosValidation = async (validacoes: any[]) => {
    const possuiCodeSimiar = validacoes.filter(
      (item) => item.PossuiCodAssociado,
    );
    if (possuiCodeSimiar.length > 0) {
      const resultaItensAssociados = await CreateTableAssociados(
        possuiCodeSimiar,
      );
      if (!resultaItensAssociados) return false;
    }
    return true;
  };

  const processInativacao = async (
    produtos: any[],
    validacoes: any[],
    action: string,
  ) => {
    const Ativos: any[] = [];
    const Inativos: any[] = [];
    const produtosQueNaoPodemSerInativados = validacoes.filter(
      (item) => !item.inativar,
    );
    setSelectionModelAtivos([]);
    await CreateTable(produtosQueNaoPodemSerInativados);

    // eslint-disable-next-line no-restricted-syntax
    for (const produto of produtos) {
      const Existem = produtosQueNaoPodemSerInativados.find(
        (item) =>
          item.plu === produto.cod_produto && item.loja === produto.cod_loja,
      );

      if (!Existem) {
        produto.dta_inativacao = !produto.dta_inativacao
          ? moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY')
          : produto.dta_inativacao;

        produto.cod_associado = null;
        Inativos.push(produto);
      } else {
        produto.dta_inativacao = 'null';
      }
    }

    const novosAtivos = produtosAtivos.filter(
      (item) => !Inativos.find((inativo) => inativo.id === item.id),
    );

    if (Inativos.length > 0) {
      const { data } = await api.put('/mix', {
        produtos: Inativos,
        action,
      });
      if (data.success) {
        toast.success(data.message);
        setProdutosInativos((prev) => [...Inativos, ...prev]);
        setProdutosAtivos(novosAtivos);
      }
    }
  };

  const processAtivacao = async (produtos: any[], action: string) => {
    const Ativos: any[] = produtosAtivos;
    const Inativos: any[] = produtos;

    const novosInativos = produtosInativos.filter(
      (item) => !Inativos.find((inativo) => inativo.id === item.id),
    );

    const { data } = await api.put('/mix', {
      produtos: Inativos,
      action,
    });
    if (data.success) {
      toast.success(data.message);
      setProdutosAtivos([...Inativos, ...Ativos]);
      setProdutosInativos(novosInativos);
    }
  };

  const handleAlteraStatusProdutosPorLote = useCallback(
    async (action: string) => {
      const Ativos = produtosAtivos;
      const Inativos = produtosInativos;
      const SelectionModel = selectionModel;
      const SelectionModelAtivos = selectionModelAtivos;
      const selecionadosAtivos = Ativos.filter((item) => {
        return SelectionModelAtivos.find((id) => item.id === id);
      });
      const selecionadosInativos = Inativos.filter((item) =>
        SelectionModel.find((id) => item.id === id),
      );
      const validacoes = await handleValidarInativacao(Ativos);
      const validacoesSelecionados = await handleValidarInativacao(
        selecionadosAtivos,
      );

      if (
        action === 'INATIVAR' &&
        !(await handleAssociadosValidation(validacoes))
      )
        return;
      if (
        action === 'INATIVAR-SELECIONADOS' &&
        !(await handleAssociadosValidation(validacoesSelecionados))
      )
        return;

      try {
        if (action === 'INATIVAR') {
          setLoadingInativacao(true);
          await processInativacao(Ativos, validacoes, action);
          setLoadingInativacao(false);
        } else if (action === 'INATIVAR-SELECIONADOS') {
          setLoadingInativacao(true);
          await processInativacao(
            selecionadosAtivos,
            validacoesSelecionados,
            'INATIVAR',
          );
          setLoadingInativacao(false);
        } else if (action === 'ATIVAR') {
          setLoadingAtivacao(true);
          await processAtivacao(Inativos, action);
          setLoadingAtivacao(false);
        } else if (action === 'ATIVAR-SELECIONADOS') {
          setLoadingAtivacao(true);
          await processAtivacao(selecionadosInativos, 'ATIVAR');
          setLoadingAtivacao(false);
        }
      } finally {
        setLoader(false);
      }
    },
    [
      handleValidarInativacao,
      handleProdutos,
      produtosAtivos,
      produtosInativos,
      selectionModel,
      selectionModelAtivos,
    ],
  );

  if (loader) {
    return <DefaultLoader />;
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const handleClear = useCallback(() => {
    setIsDisableSearch(false);
    setProdutosAtivos([]);
    setProdutosInativos([]);
    reset();
    setClearCampoProduto(true);
    setTimeout(() => {
      setClearCampoProduto(false);
    }, 200);
    setPageAtivos(0);
    setPageInativos(0);
  }, [isDisableSearch, produtosAtivos, produtosInativos, reset]);

  return (
    <Container>
      <h3>Mix de Produtos</h3>
      <SeparatorLine />
      <div className="row">
        {/* Loja */}
        <div className="col-md-12">
          <Loja isMulti onChange={(val) => setLojas(val)} disabled={isDisableSearch}/>
        </div>
        {/* Buscar produtos */}
        <ContainerProduto>
          <div style={{ width: '100%' }}>
            <BuscaProduto
              label="Buscar por"
              placeholder="Selecione..."
              name="busca_produto"
              register={register}
              isError={!!errors.busca_produto}
              disabled={isDisableSearch}
              control={control}
              buscaNasLojas={lojas ?? [1]}
              buscaItensInativos
              customOptions={{
                hideBuscarPor: true,
                buscarPor: ['Produto', 'Fornecedor', 'Check-List'],
                showSelecionarItensContendo: true,
                buscaPadrao: 'Produto',
              }}
              getProduct={(selected: any) => {
                handleSelecionarProduto(selected);
              }}
              style={{ width: '100%' }}
              setValue={setValue}
              clearCampoBusca={clearCampoProduto}
            />
          </div>
          <div style={{ marginTop: '1.875rem' }}>
            <ButtonCancel
              type="button"
              disabled={!isDisableSearch}
              onClick={() => handleClear()}
            >
              Cancelar
            </ButtonCancel>
          </div>
        </ContainerProduto>
      </div>
      <Separator labelText="Dentro do Mix" />
      <div className="row">
        <div
          className="col-sm-12 col-md-12 dataTable"
          style={{ minHeight: 200, width: '100%', marginTop: '0px' }}
        >
          <DataGrid
            style={{
              height: '380px',
            }}
            rows={produtosAtivos}
            columns={colunasDentroDoMix}
            pageSize={5}
            disableColumnMenu
            disableSelectionOnClick
            localeText={{
              noRowsLabel: 'Nenhum registro encontrado...',
              columnMenuLabel: 'Menu',
              columnMenuFilter: 'Filtrar',
              columnMenuHideColumn: 'Esconder',
              columnMenuUnsort: 'Não ordenar',
              columnMenuSortAsc: 'Ordernar ASC',
              columnMenuSortDesc: 'Ordernar DESC',
              columnMenuShowColumns: 'Mostrar columnas',
              footerRowSelected: (count) =>
                count !== 1
                  ? `${count.toLocaleString()} Produtos selecionados`
                  : `${count.toLocaleString()} Produto selecionado`,
            }}
            checkboxSelection
            page={pageAtivos}
            onPageChange={(newPage) => setPageAtivos(newPage)}
            onSelectionModelChange={(newSelectionModel) => {
              const validSelections = newSelectionModel.filter((id) =>
                produtosAtivos.some((produto) => produto.id === id)
              );
              setSelectionModelAtivos(validSelections);

            }}
            selectionModel={selectionModelAtivos}
          />
          {produtosAtivos.length > 0 ? (
            selectionModelAtivos.length > 0 ? (
              <div className="col-12" style={{ width: '100%' }}>
                <Button
                  color="#bf5050"
                  type="button"
                  disabled={loadingInativacao}
                  onClick={() =>
                    handleAlteraStatusProdutosPorLote('INATIVAR-SELECIONADOS')
                  }
                >
                  {loadingInativacao ? (
                    <>
                      <Spinner
                        animation="border"
                        variant="light"
                        size="sm"
                        style={{ marginBottom: 4 }}
                      />
                      {' Inativando selecionados'}
                    </>
                  ) : (
                    <>Inativar selecionados </>
                  )}
                </Button>
              </div>
            ) : (
              <div className="col-12" style={{ width: '100%' }}>
                <Button
                  color="#bf5050"
                  type="button"
                  disabled={loadingInativacao}
                  onClick={() => handleAlteraStatusProdutosPorLote('INATIVAR')}
                >
                  {loadingInativacao ? (
                    <>
                      <Spinner
                        animation="border"
                        variant="light"
                        size="sm"
                        style={{ marginBottom: 4 }}
                      />
                      {' Inativando todos'}
                    </>
                  ) : (
                    <>Inativar todos</>
                  )}
                </Button>
              </div>
            )
          ) : (
            ''
          )}
        </div>
      </div>
      <Separator labelText="Fora do Mix" />
      <div
        className="col-sm-12 col-md-12 dataTable"
        style={{
          minHeight: 200,
          width: '100%',
          marginTop: '0px',
          marginBottom: '10px',
        }}
      >
        <DataGrid
          style={{
            height: '380px',
          }}
          rows={produtosInativos}
          columns={colunasForaDoMix}
          pageSize={5}
          disableColumnMenu
          checkboxSelection
          disableSelectionOnClick
          localeText={{
            noRowsLabel: 'Nenhum registro encontrado...',
            columnMenuLabel: 'Menu',
            columnMenuFilter: 'Filtrar',
            columnMenuHideColumn: 'Esconder',
            columnMenuUnsort: 'Não ordenar',
            columnMenuSortAsc: 'Ordernar ASC',
            columnMenuSortDesc: 'Ordernar DESC',
            columnMenuShowColumns: 'Mostrar columnas',
            footerTotalRows: 'Selecionados',
            footerRowSelected: (count) =>
              count !== 1
                ? `${count.toLocaleString()} Produtos selecionados`
                : `${count.toLocaleString()} Produto selecionado`,
          }}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          page={pageInativos}
          onPageChange={(newPage) => setPageInativos(newPage)}
          selectionModel={selectionModel}
        />
        {produtosInativos.length > 0 ? (
          selectionModel.length > 0 ? (
            <div className="col-12" style={{ width: '100%' }}>
              <Button
                color="#50bf94"
                type="button"
                disabled={loadingAtivacao}
                onClick={() =>
                  handleAlteraStatusProdutosPorLote('ATIVAR-SELECIONADOS')
                }
              >
                {loadingAtivacao ? (
                  <>
                    <Spinner
                      animation="border"
                      variant="light"
                      size="sm"
                      style={{ marginBottom: 4 }}
                    />
                    {' Ativando selecionados'}
                  </>
                ) : (
                  <>Ativar selecionados</>
                )}
              </Button>
            </div>
          ) : (
            <div className="col-12" style={{ width: '100%' }}>
              <Button
                color="#50bf94"
                type="button"
                disabled={loadingAtivacao}
                onClick={() => handleAlteraStatusProdutosPorLote('ATIVAR')}
              >
                {loadingAtivacao ? (
                  <>
                    <Spinner
                      animation="border"
                      variant="light"
                      size="sm"
                      style={{ marginBottom: 4 }}
                    />
                    {' Ativando todos'}
                  </>
                ) : (
                  <>Ativar todos</>
                )}
              </Button>
            </div>
          )
        ) : (
          ''
        )}
      </div>
      <SeparatorLine />
      <br />
      <FooterDefault codTela={32} />
    </Container>
  );
};

export default Mix;
