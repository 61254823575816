import api from '~/services/api';
import { ResponseFormatted } from '../../types';
import {
  BuscaDadosImprimirProps,
  ResponseDuplicarPedidoProps,
} from '../../types/services';

const indexImprimir = async (
  cod_seq_pedido: number,
): Promise<ResponseFormatted<BuscaDadosImprimirProps>> => {
  const { data } = await api.get<ResponseFormatted<BuscaDadosImprimirProps>>(
    `/pdv-online/consulta-venda/imprimir/${cod_seq_pedido}`,
  );
  return data;
};
const indexDuplicarPedido = async (
  num_pedido: number,
  cod_loja: string | number,
): Promise<ResponseFormatted<ResponseDuplicarPedidoProps[]>> => {
  const { data } = await api.get<
    ResponseFormatted<ResponseDuplicarPedidoProps[]>
  >(`/pdv-online/consulta-venda/duplicar-pedido/${num_pedido}/${cod_loja}`);
  return data;
};
const cancelarPedido = async (
  cod_loja: string | number,
  num_pdv: string | number,
  cod_seq_pedido: number,
): Promise<ResponseFormatted<number>> => {
  const { data } = await api.put<ResponseFormatted<number>>(
    '/pdv-online/consulta-venda/cancelar-pedido',
    { cod_loja, num_pdv, cod_seq_pedido },
  );
  return data;
};
const indexEnviarEmail = async (
  num_pedido: string | number,
  cod_loja: string | number,
): Promise<ResponseFormatted<string>> => {
  const { data } = await api.get<ResponseFormatted<string>>(
    `/pdv-online/consulta-venda/enviar-email/${num_pedido}/${cod_loja}`,
  );
  return data;
};
const storeEnviarEmail = async (
  cod_loja: string | number,
  email_to: string,
  subject: string,
  cupom: FormData,
): Promise<{ success: boolean; message: string }> => {
  const formData = new FormData();
  formData.append('email_to', email_to);
  formData.append('subject', subject);

  cupom.forEach((value, key) => {
    formData.append(key, value);
  });

  const { data } = await api.post<{ success: boolean; message: string }>(
    `/pdv-online/consulta-venda/enviar-email/${cod_loja}`,
    formData,
    { headers: { 'Content-Type': 'multipart/form-data' } },
  );
  return data;
};
const indexVerificaSerie = async (
  cod_loja: string | number,
  num_pdv: string | number,
): Promise<{ success: boolean; message: string }> => {
  const { data } = await api.get<{ success: boolean; message: string }>(
    `/pdv-online/transmitir-nfce/verifica-serie/${cod_loja}/${num_pdv}`,
  );
  return data;
};

export const consultarVendasService = {
  indexImprimir,
  indexDuplicarPedido,
  cancelarPedido,
  indexEnviarEmail,
  storeEnviarEmail,
  indexVerificaSerie,
};
