import { useCallback, useState } from 'react';
import api from '~/services/api';

interface ILoja {
  des_loja: any;
  des_uf: string;
  cod_loja: number;
  des_fantasia: string;
  num_cnpj: string;
  des_cidade: string;
  cst_pis_cof_entrada: string;
  cst_pis_cof_saida: string;
  cst_pis_cof_saida_aliq_zero: string;
  cst_pis_cof_ent_aliq_zero: string;
  cst_pis_cof_saida_monof: string;
  cst_pis_cof_ent_monof: string;
  cst_pis_cof_saida_subst: string;
  cst_pis_cof_ent_subst: string;
  cst_pis_cof_saida_imune: string;
  cst_pis_cof_ent_imune: string;
  cst_pis_cof_saida_susp: string;
  cst_pis_cof_ent_susp: string;
  tipo_regime: number;
  flg_st_bc_pis_cof: boolean;
  flg_ipi_bc_pis_cof: boolean;
  tipo_integra_frente: number;
  tipo_totalizador_etq_bal: number;
  qtd_dig_cod_bal: number;
  flg_sem_dig_verif_bal: boolean;
  regra_empresa: {
    tipo_margem: number;
    tipo_custo: number;
    per_tol_custo: number;
    per_bloq_custo: number;
    per_tol_venda: number;
    per_bloq_venda: number;
  };
  parametro_nfe: {
    tipo_ambiente: number;
  };
}

export interface IUseLoja {
  codLoja: number;
  loja: ILoja;
  handleLoja: (value: ILoja) => void;
  changeLoja: (cod_loja: number) => void;
}

export default function useLoja(): IUseLoja {
  const [codLoja, setCodLoja] = useState<number>(0);
  const [loja, setLoja] = useState<ILoja>({} as ILoja);

  // useEffect(() => {
  //   (async () => {
  //     if (codLoja > 0) {
  //       api.get(`/lojas/${codLoja}`).then((res) => {
  //         const { data, success } = res.data;
  //         if (success) {
  //           setLoja(data);
  //           console.log(data);
  //         }
  //       });
  //     }
  //   })();
  // }, [codLoja]);

  const handleLoja = (value: ILoja) => {
    setLoja(value);
  };

  const getLojaInfo = useCallback((cod_loja: number) => {
    if (cod_loja > 0) {
      api.get(`/lojas/${cod_loja}`).then((res) => {
        const { data, success } = res.data;
        if (success) {
          setLoja(data);
        }
      });
    }
  }, []);

  const changeLoja = useCallback(
    (cod_loja: number) => {
      setCodLoja(cod_loja);
      getLojaInfo(cod_loja);
    },
    [getLojaInfo],
  );

  return {
    codLoja,
    loja,
    handleLoja,
    changeLoja,
  };
}
