import { Box } from '@material-ui/core';
import { DataGrid, GridColDef, GridColumns } from '@material-ui/data-grid';
import React, { useCallback, useState } from 'react';
import { usePdvOnline } from '~/pages/PdvOnline/hooks/usePdvOnline';
import { ButtonAction, ContainerButtons } from './styles';
import { consultarVendasService } from '~/pages/PdvOnline/services/consulta-venda';
import {
  ConsultaVendaProps,
  PedidoProps,
} from '~/pages/PdvOnline/types/context';
import ImprimirCupomVenda from '~/utils/classes/ImpressaoRecibo/PdvOnline/ImprimirCupomVenda';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { nanoid } from 'nanoid';
import { ProdutoTableProps } from '~/pages/PdvOnline/types';
import { moneyFormat } from '~/utils/functions';
import { calcSubTotalProduto } from '~/pages/PdvOnline/components/TabProdutos/utils/calcSubTotalProduto';
import { useFocusTabProduto } from '~/pages/PdvOnline/hooks/useFocusTabProduto';
import { gerarCupomCaixa } from '../../utils/gerarCupomCaixa';
import { aberturaPdv } from '~/pages/PdvOnline/services/abertura-pdv';
import useAuth from '~/hooks/useAuth';
import { pedidoService } from '~/pages/PdvOnline/services/pedido';
import { getButtonActions } from '../../utils/buttonsActions';

const MySwal = withReactContent(Swal);

export const TableVendas: React.FC = () => {
  const {
    consultaVendas,
    onCloseModal,
    initialStatePdv,
    handleAddProdutoTable,
    handlePedido,
    onOpenModal,
    onBuscarVendas,
    filterParamConsultaVenda,
    handlePdfDanfe,
    handleLoadingNFCe,
  } = usePdvOnline();
  const { handleInputFocus } = useFocusTabProduto();
  const { user } = useAuth();
  const [disableAct, setDisableAct] = useState(false);

  const createColumn = useCallback(
    (
      field: string,
      headerName: string,
      options: Partial<GridColDef> = {},
    ): GridColDef => ({
      field,
      headerName,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      ...options,
    }),
    [],
  );

  const renderActionCell = (params: { row: any }) => {
    const { row } = params;
    const rowItem = row as ConsultaVendaProps;

    const handleImprimir = async () => {
      const { success, data } = await consultarVendasService.indexImprimir(
        rowItem.cod_seq_pedido,
      );

      if (success) {
        const reciboGerado = new ImprimirCupomVenda(data).createCupomVenda();
        gerarCupomCaixa(reciboGerado, 'open');
      }
    };

    const handleDuplicar = async () => {
      if (!initialStatePdv) return;

      const { isConfirmed } = await MySwal.fire({
        title: 'Duplicação de Pedido.',
        html: `
          <span>
            <p>Será iniciada uma nova venda com os mesmos itens e valores.</p>
            <p>Deseja realmente duplicar?</p>
          </span>
        `,
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#0065FF',
        cancelButtonColor: '#DE350B',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      });

      if (!isConfirmed) return;

      const { success, data, message } =
        await consultarVendasService.indexDuplicarPedido(
          rowItem.num_pedido,
          initialStatePdv.cod_loja,
        );

      if (success) {
        const pedidos: PedidoProps[] = [];
        const produtoTables: ProdutoTableProps[] = [];

        data.forEach((p) => {
          const id = nanoid();
          const {
            cod_gtin,
            cod_produto,
            val_preco,
            val_desconto = 0,
            qtd_pedido,
            des_produto,
            des_unidade,
            flg_ipv,
          } = p;

          const pedido: PedidoProps = {
            id,
            cod_gtin,
            cod_produto,
            val_preco,
            val_desconto,
            qtd_pedido,
          };

          const val_total = moneyFormat(
            calcSubTotalProduto(
              String(qtd_pedido),
              String(val_preco),
              val_desconto,
              flg_ipv,
            ).toString(),
          );

          const produtotable: ProdutoTableProps = {
            id,
            cod_produto,
            des_produto,
            des_unidade_venda: des_unidade,
            qtd_produto: qtd_pedido,
            val_preco: moneyFormat(String(val_preco)),
            val_desconto: `${moneyFormat(val_desconto.toString())}%`,
            val_total,
          };

          pedidos.push(pedido);
          produtoTables.push(produtotable);
        });

        handleAddProdutoTable(produtoTables);
        handlePedido(pedidos);
        onCloseModal();
        handleInputFocus('leitor');

        toast.success(message);
      }
    };

    const handleEnviar = async () => {
      const { success, data } = await consultarVendasService.indexImprimir(
        rowItem.cod_seq_pedido,
      );

      if (success) {
        const reciboGerado = new ImprimirCupomVenda(data).createCupomVenda();
        const cupom = gerarCupomCaixa(
          reciboGerado,
          'send',
          'comprovante_venda',
        );

        const comprovante = rowItem.tipo_status === 0 ? cupom : {};

        if (comprovante instanceof FormData) {
          onOpenModal('enviarEmailComprovanteVenda', {
            enviarEmailComprovanteVenda: {
              num_pedido: rowItem.num_pedido,
              formData: comprovante,
              returnTo: 'buscarVendas',
            },
          });
        }
      }
    };

    const handleCancelar = async () => {
      if (!initialStatePdv) return;

      if (rowItem.tipo_status_cupom_chave === 0) {
        toast.warning(
          'Pedido já transmitido, use a tela de Cancelamento de Nota Fiscal!',
        );
        return;
      }

      const { data, message } = await consultarVendasService.cancelarPedido(
        initialStatePdv.cod_loja,
        rowItem.num_pdv,
        rowItem.cod_seq_pedido,
      );

      if (data === 1) {
        toast.success(message);
        return;
      }

      if (data === 0) {
        await onBuscarVendas(true, filterParamConsultaVenda);
        toast.success(message);
      } else {
        await MySwal.fire({
          title: message,
          text: 'Vá até a tela de “CANCELAMENTO DE NOTA FISCAL“ e execute o cancelamento.',
          icon: 'warning',
        });
      }
    };

    const handleTransmitir = async () => {
      try {
        if (!initialStatePdv || rowItem.tipo_status !== 0) return;

        if (!user) {
          toast.warning(
            'Não foi possível encontrar usuário, tente novamente ou entre em contato com o suporte',
          );
          return;
        }

        const hasCertificate = await aberturaPdv.getCertificado(
          initialStatePdv.cod_loja,
        );

        const findTelaParametrizcaoNfe = user.controleAcessoTela.some(
          (c) => c.cod_controle === -2 && c.cod_tela === 41,
        );

        if (!findTelaParametrizcaoNfe) {
          toast.warning(
            'Usuário sem acesso à tela de Parametrização de NFe, contate o administrador do sistema.',
          );
          return;
        }

        if (!hasCertificate) {
          await MySwal.fire({
            icon: 'warning',
            html: `
              <span>
                <p>
                  Para transmitir NFC-e primeiramente é preciso configurar o certificado
                  digital da loja!
                </p>
              </span>
            `,
          });

          return;
        }

        const { success, message } =
          await consultarVendasService.indexVerificaSerie(
            initialStatePdv.cod_loja,
            initialStatePdv.num_pdv,
          );

        if (!success) {
          await MySwal.fire({
            icon: 'warning',
            title: message,
            html: `
              <span>
                <p>1º Vá até o cadastro de PDV.</p>
                <p>2º Vincule uma série da espécie NFCe.</p>
                <p>3º Volte aqui e tente novamente.</p>
              </span>
            `,
          });
          return;
        }

        setDisableAct(true);
        handleLoadingNFCe(true, false);
        const res = await pedidoService.transmitirNFCe(
          Number(initialStatePdv.cod_loja),
          Number(rowItem.cod_seq_pedido),
        );

        if (res.success) {
          handleLoadingNFCe(false, true);
          const danfe = await pedidoService.readDanfe(
            Number(initialStatePdv.cod_loja),
            Number(rowItem.cod_seq_pedido),
          );

          if (danfe.success) {
            handleLoadingNFCe(false, false);
            handlePdfDanfe(danfe.data);

            const byteArray = new Uint8Array(danfe.data.data);
            const blob = new Blob([byteArray], { type: 'application/pdf' });

            const fileURL = URL.createObjectURL(blob);
            const pdfWindow = window.open();
            if (pdfWindow) pdfWindow.location.href = fileURL;
          }

          toast.success(res.message);
          await onBuscarVendas(true, filterParamConsultaVenda);
        }
      } finally {
        setDisableAct(false);
        handleLoadingNFCe(false, false);
      }
    };

    const handleImprimirDanfeNFCe = async () => {
      try {
        if (!initialStatePdv) return;

        setDisableAct(true);
        handleLoadingNFCe(false, true);

        const danfe = await pedidoService.readDanfe(
          Number(initialStatePdv.cod_loja),
          Number(rowItem.cod_seq_pedido),
        );

        if (danfe.success) {
          handlePdfDanfe(danfe.data);

          const byteArray = new Uint8Array(danfe.data.data);
          const blob = new Blob([byteArray], { type: 'application/pdf' });

          const fileURL = URL.createObjectURL(blob);
          const pdfWindow = window.open();
          if (pdfWindow) pdfWindow.location.href = fileURL;
        }
      } finally {
        setDisableAct(false);
        handleLoadingNFCe(false, false);
      }
    };

    const actionHandlers: { [key: string]: () => Promise<void> } = {
      Imprimir: handleImprimir,
      Duplicar: handleDuplicar,
      Enviar: handleEnviar,
      Cancelar: handleCancelar,
      Transmitir: handleTransmitir,
      ImprimirDanfeNFCe: handleImprimirDanfeNFCe,
    };

    return (
      <ContainerButtons>
        {getButtonActions(rowItem.tipo_status_cupom_chave ?? -1).map(
          ({ icon: Icon, action, ...rest }) => (
            <ButtonAction
              type="button"
              key={rest.ariaLabel}
              aria-label={rest.ariaLabel}
              title={
                (rowItem.tipo_status_cupom_chave ?? -1) === 0
                  ? rest.ariaLabel
                  : undefined
              }
              disabled={disableAct}
              onClick={async () => {
                const handleClick = actionHandlers[action];
                if (handleClick) await handleClick();
              }}
            >
              <Icon size={20} color={rest.color} />
            </ButtonAction>
          ),
        )}
      </ContainerButtons>
    );
  };

  const columns: GridColumns = [
    createColumn('id', 'Id', {
      hide: true,
      width: 100,
      disableColumnMenu: true,
    }),
    createColumn('cod_seq_pedido', 'Cod Sequence Pedido', {
      hide: true,
      width: 100,
      disableColumnMenu: true,
    }),
    createColumn('tipo_status', 'Tipo Status', {
      hide: true,
      width: 100,
      disableColumnMenu: true,
    }),
    createColumn('hra_pedido', 'Horário', {
      headerAlign: 'center',
      align: 'center',
    }),
    createColumn('nome_pessoa', 'Cliente', {
      headerAlign: 'left',
      align: 'left',
      flex: 1,
    }),
    createColumn('val_pedido', 'Valor', {
      headerAlign: 'right',
      align: 'right',
    }),
    createColumn('num_pedido', 'Cupom', {
      headerAlign: 'center',
      align: 'center',
    }),
    createColumn('des_situacao', 'Situação', {
      headerAlign: 'center',
      align: 'center',
      width: 130,
    }),
    createColumn('', 'Ações', {
      cellClassName: 'super-app-theme--cell',
      headerClassName: 'super-app-theme--header',
      disableColumnMenu: true,
      width: 150,
      renderCell: renderActionCell,
    }),
  ];

  return (
    <Box sx={{ height: 230, width: 790 }}>
      <DataGrid
        rows={consultaVendas}
        columns={columns}
        disableColumnFilter
        disableColumnSelector
        disableSelectionOnClick
        disableColumnMenu
        hideFooterPagination
        hideFooterRowCount
        hideFooterSelectedRowCount
        hideFooter
        localeText={{
          noRowsLabel: 'Nenhuma venda encontrada',
          columnMenuLabel: 'Menu',
          columnMenuFilter: 'Filtrar',
          columnMenuHideColumn: 'Esconder',
          columnMenuUnsort: 'Não ordenar',
          columnMenuSortAsc: 'Ordernar ASC',
          columnMenuSortDesc: 'Ordernar DESC',
          columnMenuShowColumns: 'Mostrar colunas',
        }}
      />
    </Box>
  );
};
