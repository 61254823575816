import React, { useCallback, useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridRowParams } from '@material-ui/data-grid';

import Spinner from '@atlaskit/spinner';
import { toast } from 'react-toastify';
import Search from '~/components/Search';

import {
  ButtonReturnCards,
  ButtonRow,
  Container,
  ContainerOrder,
} from './styles';
import FormDefault from '~/components/FormDefault';
import { InputSelect, InputText } from '~/components/NovosInputs';
import { Col, Row } from 'react-bootstrap';
import Tooltip from '@atlaskit/tooltip';
import Separator from '~/components/Separator';
import { LibsIconsContainer } from './components/LibsIconsContainer';
import { IconContainer } from './components/IconContainer';
import { CustomButtonNovo } from '~/components/Buttons/CustomButtonNovo';
import { IoMdRemoveCircle } from 'react-icons/io';
import { useTrilha } from './TrilhaContext';
import OrderTela from './components/OrderTela';
import TrilhaApi from './services';
import { dataFormProps } from './types';
import { MdSort } from 'react-icons/md';
import {
  addUpdate,
  deleteItens,
  insertOrUpdate,
  MasterDetailProps,
} from '~/utils/masterDetail';
import { libIcons } from '../CadastroDeTela/data/LibIcons';
import { icons } from '../CadastroDeTela/data/Icons';
import { useQueryClient } from 'react-query';

export interface selectProps {
  label: string;
  value: number;
}

export const TrilhaContent: React.FC = () => {
  const queryClient = useQueryClient();

  const [loader, setLoader] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);
  const [telasRow, setTelasRow] = useState<any[]>([]);
  const [openOrderModal, setOpenOrderModal] = useState<boolean>(false);
  const [openOrderModalSearch, setOpenOrderModalSearch] =
    useState<boolean>(false);
  const [rowTrilhas, setRowTrilhas] = useState<any[]>([]);
  const [loadModalOrderTrilha, setLoadModalOrderTrilha] =
    useState<boolean>(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const masterDetailDefault = [
    {
      obj_name: 'trilha_tela',
      pk_fields: ['cod_seq_trilha_tela'],
      itens: {
        insert: [],
        update: [],
        delete: [],
      },
    },
  ];

  const [masterDetail, setMasterDetail] =
    useState<MasterDetailProps[]>(masterDetailDefault);

  const {
    register,
    control,
    errors,
    setValue,
    getTela,
    reset,
    handleSubmit,
    getValues,
    optionsTelas,
    setError,
    clearErrors,
  } = useTrilha();

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 90, hide: true },
    {
      field: 'ordem',
      headerName: 'Ordem',
      width: 150,
      editable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'des_campo',
      headerName: 'Tela',
      width: 500,
      editable: false,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'des_modulo',
      headerName: 'Modulo',
      width: 300,
      editable: false,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'acao',
      headerName: 'Ação',
      width: 200,
      headerClassName: 'hideRightSeparator',
      headerAlign: 'center',
      align: 'center',
      editable: false,

      renderCell: (params: { row: any }) => {
        const onDelete = () => {
          const { row } = params;
          handleRemoveTela(row.id);
        };

        return (
          <>
            <ButtonRow type="button" onClick={onDelete}>
              <IoMdRemoveCircle size={20} style={{ color: '#e63c3c' }} />
            </ButtonRow>
          </>
        );
      },
    },
  ];

  const resetFormData = useCallback(() => {
    reset();
    setTelasRow([]);
    setValue('des_trilha', '');
    setValue('lib_icon', { value: undefined, label: '' });
    setValue('des_icon', { value: undefined, label: '' });
    setMasterDetail(JSON.parse(JSON.stringify(masterDetailDefault)));
  }, [reset, masterDetailDefault, setValue]);
  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;

    const { cod_trilha, des_icon, des_trilha, lib_icon } = row;

    const telasTrilha = await TrilhaApi.getTelasTrilha(cod_trilha);

    setValue('cod_trilha', cod_trilha);
    setValue('des_trilha', des_trilha);
    setValue(
      'lib_icon',
      libIcons.find((libIcon) => libIcon.value === lib_icon),
    );

    const findLibIcons = libIcons.find((libIcon) => libIcon.value === lib_icon);

    if (findLibIcons) {
      const libOption = icons.find((icon) => icon.lib === findLibIcons.value);

      if (libOption) {
        const findLibTrilha = libOption.values.find(
          (icon) => icon.value === des_icon,
        );
        setValue('des_icon', findLibTrilha);
      }
    }

    if (telasTrilha.success) {
      if (telasTrilha.data.message !== 'Nenhum registro encontrado.') {
        const options = telasTrilha.data.data.map((tela: any) => {
          return {
            uuid: tela.cod_tela,
            id: tela.cod_tela,
            cod_seq_trilha_tela: tela.cod_seq_trilha_tela,
            ordem: tela.num_ordem_menu,
            des_campo: tela.des_tela,
            des_modulo: tela.des_modulo.toUpperCase(),
            cod_campo: tela.cod_tela,
          };
        });

        await addUpdate('trilha_tela', options, masterDetail, setMasterDetail);
        setTelasRow(options);
      }
    }

    setUpdate(true);
    setLoader(false);
    setShowSearch(false);
  };

  const onSave = handleSubmit(async (dataForm: any) => {
    if (telasRow.length === 0) {
      return toast.warning('Tela deve ser adicionada para criar a trilha');
    }

    const masterDetailSelect = masterDetail.find(
      (master) => master.obj_name === 'trilha_tela',
    );

    const prepareData: dataFormProps = {
      des_trilha: dataForm.des_trilha.trim(),
      num_ordem_menu: dataForm.num_ordem_menu,
      lib_icon: dataForm.lib_icon.value,
      des_icon: dataForm.des_icon.value,
      telas_trilha_master_detail: masterDetailSelect
        ? masterDetailSelect.itens
        : undefined,
    };

    if (isUpdate) {
      const { success, message } = await TrilhaApi.putTrilha(
        dataForm.cod_trilha,
        prepareData,
      );
      if (success) {
        queryClient.setQueryData(`infoTrilha_menu`, null);
        resetFormData();
        setUpdate(false);
        setShowSearch(true);
        return toast.success(message);
      }
      toast.warning(message);
      setLoader(false);
      return;
    }

    const { success, message } = await TrilhaApi.postTrilha(prepareData);
    if (success) {
      queryClient.setQueryData(`infoTrilha_menu`, null);
      toast.success(message);
      resetFormData();
      setShowSearch(false);
    }
  });

  const handleDelete = async () => {
    const { cod_trilha } = getValues();
    const res = await TrilhaApi.deleteTelaTrilhas(cod_trilha);
    const { success, message } = res;
    if (!success) throw new Error(message);
    resetFormData();
    setShowSearch(true);
    toast.success(message);
  };

  useEffect(() => {
    getTela();
  }, []);

  const onNew = () => {
    resetFormData();
    setUpdate(false);
  };

  const handleRemoveTela = async (uuid: string) => {
    const arrayTelasRow: any[] = await deleteItens(
      'trilha_tela',
      uuid,
      masterDetail,
      setMasterDetail,
    );

    arrayTelasRow.sort((a, b) => a.ordem - b.ordem);

    arrayTelasRow.forEach(async (tela, index) => {
      tela.ordem = tela.ordem === index + 1 ? tela.ordem : index + 1;
      await insertOrUpdate('trilha_tela', tela, masterDetail, setMasterDetail);
    });

    const masterArray = masterDetail.find(
      (master) => master.obj_name === 'trilha_tela',
    );

    if (masterArray) {
      const { insert, update } = masterArray.itens;

      const insertAndUpdate: any = insert.concat(update);
      insertAndUpdate.sort((a: any, b: any) => a.ordem - b.ordem);
      setTelasRow(insertAndUpdate);
    }
  };

  const handleAdicionarTela = useCallback(async () => {
    const selectTela = getValues('select_tela');

    if (!selectTela) {
      return setError('select_tela', { type: 'required' });
    }

    const hasTela = telasRow.find((telaRow) => telaRow.id === selectTela.value);

    if (hasTela) {
      return toast.warning('Tela já inclusa');
    }

    const telaRelacao = {
      uuid: selectTela.value,
      id: selectTela.value,
      ordem: telasRow.length + 1,
      des_campo: selectTela.label,
      des_modulo: selectTela.des_modulo.toUpperCase(),
      cod_campo: selectTela.value,
      cod_seq_trilha_tela: undefined,
    };

    const arrayTelasRow: any[] = await insertOrUpdate(
      'trilha_tela',
      telaRelacao,
      masterDetail,
      setMasterDetail,
    );

    arrayTelasRow.sort((a, b) => a.ordem - b.ordem);

    const reordenarTelas = arrayTelasRow.map((tela, index) => ({
      ...tela,
      ordem: tela.ordem === index + 1 ? tela.ordem : index + 1,
    }));

    setTelasRow(reordenarTelas);
    setValue('select_tela', undefined);
  }, [getValues, telasRow, setValue, masterDetail, setError]);

  const handleOrder = (rowNewOrder: any) => {
    const telaNewOrderArray: any[] = [];
    rowNewOrder.forEach(async (newOrder: any) => {
      const findOrderTelaTrilha = telasRow.find(
        (tela: any) => tela.id === newOrder.cod_campo,
      );
      if (findOrderTelaTrilha) {
        findOrderTelaTrilha.ordem = newOrder.order;
        telaNewOrderArray.push(findOrderTelaTrilha);
        await insertOrUpdate(
          'trilha_tela',
          findOrderTelaTrilha,
          masterDetail,
          setMasterDetail,
        );
      }
    });

    setTelasRow(telaNewOrderArray);
    setOpenOrderModal(false);
  };

  if (loader) {
    return (
      <Container>
        <div className="w-100 h-100 d-flex justify-conten-center align-items-center">
          <Spinner />
        </div>
      </Container>
    );
  }

  const handleGetTrilhas = async () => {
    setLoadModalOrderTrilha(true);
    try {
      const { data } = await TrilhaApi.getTrilhas();

      const teste = data.map((dt: any) => ({
        ordem: dt.num_ordem_menu,
        des_campo: dt.des_trilha,
        cod_campo: dt.cod_trilha,
      }));

      setRowTrilhas(teste);
      setLoadModalOrderTrilha(false);
    } catch (error) {
      setLoadModalOrderTrilha(false);
    }
  };
  const handleUpdateTrilha = async (new_order: any) => {
    const { success, message } = await TrilhaApi.updateTrilhas(new_order);

    if (success) {
      queryClient.setQueryData(`infoTrilha_menu`, null);
      setOpenOrderModalSearch(false);
      toast.success(message);
      return;
    }
    toast.warning(message);
  };

  const ButtonOrdenarTrilhas = () => {
    return (
      <ContainerOrder>
        <Tooltip position="right" content="CTRL + ALT + T">
          <ButtonReturnCards
            onClick={() => {
              handleGetTrilhas();
              setOpenOrderModalSearch(true);
            }}
            className="ordenar"
          >
            <MdSort />
            Ordenar Trilhas
          </ButtonReturnCards>
        </Tooltip>
      </ContainerOrder>
    );
  };

  return (
    <Container>
      {showSearch && (
        <>
          <Search
            newData={() => setShowSearch(false)}
            onRowClick={onRowClick}
            customButtons={<ButtonOrdenarTrilhas />}
            codTela={303}
          />
          <OrderTela
            openOrderModal={openOrderModalSearch}
            setOpenOrderModal={setOpenOrderModalSearch}
            optionsBase={rowTrilhas}
            handleSaveOrder={(val: any) => {
              handleUpdateTrilha(val);
            }}
            loadModal={loadModalOrderTrilha}
            label="Ordenação das Trilhas"
          />
        </>
      )}

      {!showSearch && (
        <>
          <FormDefault
            codTela={303}
            title="Trilha"
            onSave={async () => {
              await onSave();
            }}
            codigoRegistro={[
              {
                value: getValues('cod_trilha'),
                des_campo: 'Código',
              },
            ]}
            onCancel={() => {
              resetFormData();
              setShowSearch(true);
            }}
            isUpdate={isUpdate}
            onNew={() => onNew()}
            onDelete={handleDelete}
            onClearFields={() => resetFormData()}
            onReturnSearch={() => {
              setShowSearch(true);
              setUpdate(false);
              resetFormData();
            }}
            showSwitch={false}
          >
            <Row>
              <Col sm={12} md={8}>
                <InputText
                  register={register}
                  label="Descrição"
                  placeholder="Informe a Descrição"
                  control={control}
                  isError={!!errors.des_trilha}
                  name="des_trilha"
                  minLength={1}
                  caseInput="upper"
                  maxLength={100}
                />
              </Col>
              <Col sm={12} md={2}>
                <LibsIconsContainer />
              </Col>
              <Col sm={12} md={2}>
                <IconContainer />
              </Col>
            </Row>
            <br />
            <Separator
              labelText="Relação das telas vinculadas: "
              marginTop="0"
            />
            <Row>
              <Col sm={12} md={9}>
                <InputSelect
                  options={optionsTelas}
                  placeholder="Selecione uma tela"
                  name="select_tela"
                  register={register}
                  isError={!!errors.select_tela}
                  control={control}
                  changeSelected={(selected: selectProps) => {
                    clearErrors('select_tela');
                    setValue('select_tela', selected);
                  }}
                />
              </Col>
              <Col
                sm={12}
                md={3}
                style={{
                  marginTop: '10px',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <CustomButtonNovo
                  variant="confirm"
                  label="Adicionar"
                  onClick={() => {
                    handleAdicionarTela();
                  }}
                />
                <CustomButtonNovo
                  onClick={() => {
                    setOpenOrderModal(true);
                  }}
                  style={{ marginLeft: '10px' }}
                  label="Ordenar"
                />
              </Col>
            </Row>
            <br />
            <OrderTela
              openOrderModal={openOrderModal}
              setOpenOrderModal={setOpenOrderModal}
              optionsBase={telasRow}
              handleSaveOrder={(val: any) => {
                handleOrder(val);
              }}
            />
            <Row style={{ minHeight: '500px' }}>
              <Col sm={12} md={12} className="dataTable">
                <DataGrid
                  className="myDataGrid"
                  rows={telasRow}
                  disableColumnMenu
                  autoHeight
                  disableColumnFilter
                  disableColumnSelector
                  hideFooterSelectedRowCount
                  columns={columns}
                  localeText={{
                    noRowsLabel: 'Nenhum registro encontrado...',
                    columnMenuLabel: 'Menu',
                    columnMenuFilter: 'Filtrar',
                    columnMenuHideColumn: 'Esconder',
                    columnMenuUnsort: 'Não ordenar',
                    columnMenuSortAsc: 'Ordernar ASC',
                    columnMenuSortDesc: 'Ordernar DESC',
                    columnMenuShowColumns: 'Mostrar columnas',
                  }}
                />
              </Col>
            </Row>
          </FormDefault>
        </>
      )}
    </Container>
  );
};
