import React, { useEffect, useState } from 'react';
import {
  CircleAccordion,
  ContainerAccordion,
  ContentAccordion,
  HeaderAccordion,
  LabelAccordion,
  MethodContainerAccordion,
} from './styles';
import { useMeuPlano } from '../../hooks/useMeuPlano';
import {
  ComponentPaymentProps,
  MethodFaturamento,
  ScreenPaymentsProps,
} from '../../types/context';

interface PaymentMethod {
  id: string;
  label: string;
  Component: React.FC<ComponentPaymentProps>;
}

interface PaymentAccordionProps {
  methods: PaymentMethod[];
  screen: ScreenPaymentsProps;
}

const PaymentAccordion: React.FC<PaymentAccordionProps> = ({
  methods,
  screen,
}) => {
  const [resetFlag, setResetFlag] = useState(false);
  const { handleMethodFaturamento, selectedMethod, setSelectedMethod } =
    useMeuPlano();

  const toggleMethod = (id: string) => {
    if (selectedMethod === id) {
      setSelectedMethod(null);
      setResetFlag(true);
      handleMethodFaturamento(null);
      setTimeout(() => setResetFlag(false), 0);
    } else {
      handleMethodFaturamento(id as MethodFaturamento);
      setSelectedMethod(id);
      setResetFlag(false);
    }
  };

  return (
    <ContainerAccordion>
      {methods.map((method) => (
        <MethodContainerAccordion key={method.id}>
          <HeaderAccordion onClick={() => toggleMethod(method.id)}>
            <CircleAccordion selected={selectedMethod === method.id} />
            <LabelAccordion>{method.label}</LabelAccordion>
          </HeaderAccordion>
          <ContentAccordion isOpen={selectedMethod === method.id}>
            {selectedMethod === method.id && (
              <method.Component reset={resetFlag} screen={screen} />
            )}
          </ContentAccordion>
        </MethodContainerAccordion>
      ))}
    </ContainerAccordion>
  );
};

export default PaymentAccordion;
