import { format } from 'date-fns';
import { IoRocketSharp } from 'react-icons/io5';
import { WiTime9 } from 'react-icons/wi';
import { FaRegFaceSmile } from 'react-icons/fa6';
import { GoAlertFill } from 'react-icons/go';
import { AccountStatus } from '../types/AccountStatus';

export const useStatusMap = (
  planLabel: string,
  dtaInicio: string,
): Record<AccountStatus, any> => {
  return {
    TRIAL: {
      icon: IoRocketSharp,
      label: 'Contrate um plano que traga potencial ao seu negócio.',
      iconColor: '#009E11',
    },
    'ENTRARA-EM-VIGOR': {
      icon: WiTime9,
      label: `Novo plano (${planLabel}), entrará em vigor no dia ${format(
        new Date(dtaInicio || new Date()),
        'dd/MM/yyyy',
      )}.`,
      iconColor: '#3082E8',
    },
    'AGUARDANDO-CONFIRMACAO-PAGAMENTO': {
      icon: GoAlertFill,
      label: `Novo plano (${planLabel}), aguardando confirmação do pagamento.`,
      iconColor: '#FF9900',
    },
    'FALHA-PROCESSAMENTO-PAGAMENTO': {
      icon: GoAlertFill,
      label: 'Não foi possível processar seu pagamento.',
      iconColor: '#CF2A28',
    },
    'CONTINUAR-USANDO-SISTEMA': {
      icon: FaRegFaceSmile,
      label: 'Me arrependi e desejo continuar usando o sistema.',
      iconColor: '#17A133',
      link: 'link',
    },
    NENHUM: {
      icon: IoRocketSharp,
      label: 'Nenhum status definido.',
      iconColor: '#A9A9A9',
    },
  };
};
