import { GridRowParams } from '@material-ui/data-grid';
import React, { useContext, useState } from 'react';
import Swal from 'sweetalert2';

import withReactContent from 'sweetalert2-react-content';
import { toast } from 'react-toastify';
import Search from '~/components/Search';

import { Container } from './styles';
import api from '~/services/api';
import FormDefault from '~/components/FormDefault';

import { InputPercent, InputTextArea } from '~/components/NovosInputs';
import ToggleDefault from '~/components/ToggleDefault';
import { Col, Row } from 'react-bootstrap';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './validations';
import { InputMaskCest } from '~/components/NovosInputs/InputMaskCest';
import { formatCurrencyAsText, transformAsCurrency } from '~/utils/functions';
import { getUserData } from '~/services/user';
import { loadingContext } from '~/context/loading';

const Cest: React.FC = () => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { setLoading } = useContext(loadingContext);

  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);
  const [flgPublico, setFlgPublico] = useState(false);
  const { flg_superadmin } = getUserData();

  const MySwal = withReactContent(Swal);

  const formBlank = {
    cod_cest: undefined,
    num_cest: '',
    per_iva: null,
    des_cest: '',
    flgPublico: false,
  };

  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;
    const { cod_cest, num_cest, per_iva, des_cest, flg_publico } = row;

    reset({
      cod_cest,
      num_cest,
      per_iva_old: formatCurrencyAsText(per_iva),
      per_iva: formatCurrencyAsText(per_iva),
      des_cest,
      flgPublico: flg_publico,
    });

    setFlgPublico(flg_publico);
    setUpdate(true);
    setShowSearch(false);
  };

  const resetFormData = () => {
    reset(formBlank);
    setFlgPublico(false);
    setUpdate(false);
  };

  // Trata os campos, pois como há mascaras, o correto é subir sem mascara e no devido formato

  const serializeData = (data: any) => {
    data.num_cest = data.num_cest.replace(/\D/g, '');
    data.per_iva = transformAsCurrency(data.per_iva);
    // verifica se houve alteração de iva para que dê a mensagem
    if (isUpdate) {
      data.per_iva_old = transformAsCurrency(data.per_iva_old);
    }
    return data;
  };

  const onSubmit = handleSubmit(async (data) => {
    data = serializeData(data);
    if (isUpdate) {
      if (data.per_iva !== data.per_iva_old) {
        MySwal.fire({
          title: ``,
          html: `O novo % de IVA informado será atualizado em todos NCMs (Produtos) vinculados a este CEST. <br/> Deseja continuar?`,
          showCancelButton: true,
          confirmButtonColor: '#07289e',
          cancelButtonColor: '#ff7b7b',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        }).then(async (result) => {
          if (result.isConfirmed) {
            setLoading(false);
            const res = await api.put(`/cest/${data.cod_cest}`, {
              ...data,
              att_ncm: true,
            });

            const { success, message } = res.data;

            if (!success) {
              return toast.warning(message);
            }

            if (success) {
              toast.success(message);
              resetFormData();
              setShowSearch(true);
            }
          }
        });
        return;
      }

      const res = await api.put(`/cest/${data.cod_cest}`, {
        ...data,
        att_ncm: false,
      });

      const { success, message } = res.data;

      if (!success) {
        return toast.warning(message);
      }

      if (success) {
        resetFormData();
        setShowSearch(true);
        return toast.success(message);
      }
    }
    const res = await api.post('/cest', data);

    const { success, message } = res.data;

    if (!success) {
      return toast.warning(message);
    }

    resetFormData();
    return toast.success(message);
  });

  const handleDeleteCest = async () => {
    const values = getValues();
    const { cod_cest } = values;
    const res = await api.delete(`/cest/${cod_cest}`);
    const { success, message } = res.data;
    if (!success) {
      return toast.warning(message);
    }
    toast.success(message);
    resetFormData();
    setShowSearch(true);
  };

  const onNewData = () => {
    resetFormData();
    setShowSearch(false);
  };

  return (
    <Container>
      {showSearch && (
        <Search codTela={16} newData={onNewData} onRowClick={onRowClick} />
      )}

      {!showSearch && (
        <FormDefault
          codTela={16}
          title="Cadastro de CEST"
          codigoRegistro={[
            {
              des_campo: 'Código',
              value: getValues('cod_cest'),
            },
          ]}
          onSave={async () => {
            await onSubmit();
          }}
          onCancel={() => setShowSearch(true)}
          isUpdate={isUpdate}
          onNew={resetFormData}
          onDelete={handleDeleteCest}
          onClearFields={resetFormData}
          onReturnSearch={() => {
            resetFormData();
            setShowSearch(true);
          }}
        >
          <Row>
            <Col sm={12} md={2}>
              <InputMaskCest
                register={register}
                label="CEST"
                name="num_cest"
                isError={!!errors.num_cest}
                control={control}
              />
            </Col>
            <Col sm={12} md={2}>
              <InputPercent
                label="Percentual IVA"
                max={100}
                min={0}
                placeholder="0,00"
                name="per_iva"
                register={register}
                control={control}
                isError={!!errors.per_iva}
                showIcon
              />
            </Col>
            {flg_superadmin && (
              <Col sm={12} md={2}>
                <ToggleDefault
                  labelText="Público?"
                  setChecked={flgPublico}
                  onSwitch={() => {
                    setValue('flgPublico', !flgPublico);
                    setFlgPublico(!flgPublico);
                  }}
                />
              </Col>
            )}
          </Row>
          <Row>
            <Col sm={12} md={12} className="inputUpperCase">
              <InputTextArea
                maxLength={500}
                label="Descrição"
                placeholder="Informe a Descrição"
                name="des_cest"
                register={register}
                isError={!!errors.des_cest}
                control={control}
              />
            </Col>
          </Row>
        </FormDefault>
      )}
    </Container>
  );
};

export default Cest;
