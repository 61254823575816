import React, { useCallback, useState } from 'react';
import { DataGrid, GridColumns } from '@material-ui/data-grid';
import { usePedidoVenda } from '~/pages/PedidoVenda/PedidoVendaContext';
import { ButtonRow, SubTotal, TableContainer } from './styles';
import { GoPencil } from 'react-icons/go';
import { FaTrash } from 'react-icons/fa';
import { Col, Row } from 'react-bootstrap';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { deleteItens } from '~/utils/masterDetail';
import { Tooltip } from '@material-ui/core';

export const TableItens: React.FC = () => {
  const MySwal = withReactContent(Swal);
  const {
    formProduto: {
      register,
      formState: { errors },
      control,
      setValue,
      getValues,
      clearErrors,
      reset,
    },
    produtosRows,
    isUpdate,
    isDisabledPedido,
    setIsUpdate,
    setIsFormProdutoEditing,
    handleDeleteProduto,
    valorTotal,
    valorTotalIsNegative,
  } = usePedidoVenda();

  const columns: GridColumns = [
    {
      field: 'num_item',
      headerName: 'Seq',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'cod_produto',
      headerName: 'Cod. PLU',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'des_produto',
      headerName: 'Descrição',
      width: 150,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'qtd_embalagem',
      headerName: 'Emb.',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'des_unidade',
      headerName: 'Unid.',
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: 'qtd_pedido',
      headerName: 'Qtd.',
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'val_pedido',
      headerName: 'Valor',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'val_desconto',
      headerName: 'Val. Desc.',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'acrescimo',
      headerName: 'Val. Acresc.',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'val_total',
      headerName: 'Total',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
  ];

  const columnsWithActions: GridColumns = [
    {
      field: '',
      headerName: 'Ações',
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        return (
          <>
            <ButtonRow
              type="button"
              onClick={() => onEditItem(row)}
              disabled={isDisabledPedido}
              title="Editar"
            >
              <GoPencil size={20} style={{ color: '#72ab90' }} />
            </ButtonRow>
            <ButtonRow
              type="button"
              onClick={() => handleDeleteProduto(row)}
              disabled={isDisabledPedido}
              title="Remover"
            >
              <FaTrash size={20} style={{ color: '#e63c3c' }} />
            </ButtonRow>
          </>
        );
      },
    },
  ];

  const onEditItem = (row: any) => {
    setIsFormProdutoEditing({ uuid: row.uuid, isEdit: true });
    setValue(
      'produtos',
      row.produtos
        ? row.produtos
        : { label: row.des_produto, value: row.cod_produto },
    );
    setValue('qtd_pedido', row.qtd_pedido);
    setValue('val_pedido', row.val_pedido);
    setValue('val_desconto', row.val_desconto);
    setValue('qtd_embalagem', row.qtd_embalagem);
    setValue('des_unidade', row.des_unidade);
    setValue('acrescimo', row.acrescimo);
  };

  const handleClearForm = () => {
    reset();
  };

  const renderTooltipColumns = (params: { row: any; value: any }) => {
    const { row, value } = params;
    return (
      <Tooltip
        title={`Seq: ${row.num_item} - ${row.des_produto} - Código do Produto: ${row.cod_produto}`}
        placement="bottom"
        id={row.id}
      >
        <div style={{ width: '100%', height: '100%' }}>{value}</div>
      </Tooltip>
    );
  };

  const columnsWithTooltips = columns.map((col) => ({
    ...col,
    renderCell: renderTooltipColumns,
  }));

  const allColumns = [...columnsWithTooltips, ...columnsWithActions];

  return (
    <>
      <Row>
        <Col sm={12} md={12}>
          <TableContainer>
            <DataGrid
              rowsPerPageOptions={[5]}
              rows={produtosRows}
              columns={allColumns}
              pageSize={5}
              disableColumnSelector
              disableColumnFilter
              disableSelectionOnClick
              localeText={{
                noRowsLabel: 'Nenhum registro encontrado...',
                columnMenuLabel: 'Menu',
                columnMenuFilter: 'Filtrar',
                columnMenuHideColumn: 'Esconder',
                columnMenuUnsort: 'Não ordenar',
                columnMenuSortAsc: 'Ordernar ASC',
                columnMenuSortDesc: 'Ordernar DESC',
                columnMenuShowColumns: 'Mostrar columnas',
              }}
            />
          </TableContainer>
        </Col>
        <SubTotal>
          <Col
            className={`sub-total ${valorTotalIsNegative ? 'negative' : ''}`}
          >
            {' '}
            <span
              title={`${
                valorTotalIsNegative ? 'SubTotal deve ser positivo' : ''
              }`}
            >
              Total Itens: R${valorTotal}
            </span>
          </Col>
        </SubTotal>
      </Row>
    </>
  );
};
