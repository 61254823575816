import * as yup from 'yup';

const creditCardSchema = yup.object().shape({
  number: yup.string().required('O número do cartão é obrigatório.'),
  expiry: yup.string().required('A data de validade é obrigatória.'),
  cvc: yup.string().required('O código de segurança (ccv) é obrigatório.'),
  name: yup.string().required('O nome do titular do cartão é obrigatório.'),

  // Campos adicionados quando a tela é 'MUDAR-FORMA-PAGAMENTO'
  des_nome: yup.string().when('tela', {
    is: 'MUDAR-FORMA-PAGAMENTO',
    then: yup
      .string()
      .required('O nome é obrigatório para mudar a forma de pagamento.'),
  }),
  des_email: yup.string().when('tela', {
    is: 'MUDAR-FORMA-PAGAMENTO',
    then: yup
      .string()
      .email('Email inválido')
      .required('O e-mail é obrigatório para mudar a forma de pagamento.'),
  }),
  num_cpf_cnpj: yup.string().when('tela', {
    is: 'MUDAR-FORMA-PAGAMENTO',
    then: yup
      .string()
      .required('O CPF/CNPJ é obrigatório para mudar a forma de pagamento.'),
  }),
  num_cep: yup.string().when('tela', {
    is: 'MUDAR-FORMA-PAGAMENTO',
    then: yup
      .string()
      .required('O CEP é obrigatório para mudar a forma de pagamento.'),
  }),
  num_endereco: yup.string().when('tela', {
    is: 'MUDAR-FORMA-PAGAMENTO',
    then: yup
      .string()
      .required(
        'O número do endereço é obrigatório para mudar a forma de pagamento.',
      ),
  }),
  des_complemento: yup.string().nullable(),
  num_telefone: yup.string().when('tela', {
    is: 'MUDAR-FORMA-PAGAMENTO',
    then: yup
      .string()
      .required('O telefone é obrigatório para mudar a forma de pagamento.'),
  }),
  num_celular: yup.string().when('tela', {
    is: 'MUDAR-FORMA-PAGAMENTO',
    then: yup
      .string()
      .required('O celular é obrigatório para mudar a forma de pagamento.'),
  }),
});

export default creditCardSchema;
