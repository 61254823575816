import React, { ChangeEvent, useState } from 'react';
import { GridRowParams } from '@material-ui/data-grid';

import Spinner from '@atlaskit/spinner';
import { toast } from 'react-toastify';
import Search from '~/components/Search';

import { Container } from './styles';
import FormDefault from '~/components/FormDefault';
import api from '~/services/api';
import ToggleDefault from '~/components/ToggleDefault';
import { getUserData } from '~/services/user';
import { InputNumber2, InputText } from '~/components/NovosInputs';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './Validations';
import defaultData from './defaultData/defaultData.json';

const Cfop: React.FC = () => {
  const {
    register,
    control,
    reset,
    getValues,
    setValue,
    clearErrors,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });
  const [loader, setLoader] = useState<boolean>(false);
  const [flgPublico, setFlgPublico] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);
  const { flg_superadmin } = getUserData();

  /**
   * Reseta form para criação de novo registro
   */
  const resetFormData = () => {
    reset(defaultData);
    setFlgPublico(false);
    setUpdate(false);
  };

  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;
    const { cfop, des_cfop, flg_publico } = row;
    setValue('cod_cfop', cfop);
    setValue('des_cfop', des_cfop);
    setFlgPublico(flg_publico);
    setUpdate(true);
    setLoader(false);
    setShowSearch(false);
  };

  const onSubmit = handleSubmit(async (dataFields) => {
    try {
      if (isUpdate) {
        const { data } = await api.put(`/cfop/${dataFields.cod_cfop}`, {
          des_cfop: dataFields.des_cfop,
          flg_publico: flgPublico,
        });
        if (data.success) {
          toast.success(data.message);
          resetFormData();
          setShowSearch(true);
          return;
        }
      }

      const { data } = await api.post('/cfop', {
        des_cfop: dataFields.des_cfop,
        cod_cfop: dataFields.cod_cfop,
        flg_publico: flgPublico,
      });
      if (data.success) {
        toast.success(data.message);
        resetFormData();
        setShowSearch(false);
      } else {
        toast.warning(data.message);
      }
    } finally {
      setLoader(false);
    }
  });

  const handleDelete = async () => {
    const codCfop = getValues('cod_cfop');
    const res = await api.delete(`/cfop/${codCfop}`);
    const { success, message } = res.data;
    if (!success) throw new Error(message);
    resetFormData();
    setShowSearch(true);
    toast.success(message);
  };

  const onNew = () => {
    resetFormData();
    setUpdate(false);
  };

  const handleKeyDown = (event: any) => {
    if ([69, 109, 107, 194, 110].includes(event.keyCode)) {
      event.preventDefault();
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const { clipboardData } = event;
    const pastedText = clipboardData.getData('text');
    if (/[a-zA-Z]/.test(pastedText)) {
      const numericText = pastedText.replace(/[^0-9]/g, '');
      setValue('cod_cfop', numericText);
      event.preventDefault();
    }
  };

  if (loader) {
    return (
      <Container>
        <div className="w-100 h-100 d-flex justify-conten-center align-items-center">
          <Spinner />
        </div>
      </Container>
    );
  }
  return (
    <Container>
      {showSearch && (
        <Search
          newData={() => setShowSearch(false)}
          onRowClick={onRowClick}
          codTela={92}
        />
      )}

      {!showSearch && (
        <FormDefault
          codTela={92}
          title="Cadastro de CFOP"
          codigoRegistro={[
            {
              des_campo: 'Código',
              value: watch('cod_cfop'),
            },
          ]}
          onSave={async () => {
            await onSubmit();
          }}
          onCancel={() => {
            resetFormData();
            setShowSearch(true);
          }}
          isUpdate={isUpdate}
          onNew={() => onNew()}
          onDelete={handleDelete}
          onClearFields={() => resetFormData()}
          onReturnSearch={() => {
            setShowSearch(true);
            setUpdate(false);
            resetFormData();
          }}
          showSwitch={false}
        >
          <div className="row">
            {/* CFOP */}
            <div className="col-sm-12 col-md-2">
              <InputNumber2
                name="cod_cfop"
                label="CFOP"
                register={register}
                isError={!!errors.cod_cfop}
                maxLength={4}
                max={9999}
                placeholder="0000"
                control={control}
                disabled={isUpdate}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  clearErrors('cod_cfop');
                  if (event.target.value.length >= 4) {
                    event.target.value = event.target.value.slice(0, 4);
                  }
                  setValue('cod_cfop', event.target.value);
                }}
                onPaste={handlePaste}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div className={flg_superadmin ? 'col-md-9' : 'col-md-10'}>
              <InputText
                register={register}
                label="Descrição"
                placeholder="Informe a Descrição"
                control={control}
                isError={!!errors.des_cfop}
                name="des_cfop"
                minLength={1}
                caseInput="upper"
                maxLength={500}
              />
            </div>
            {flg_superadmin && (
              <div className="col-sm-12 col-md-1">
                <ToggleDefault
                  labelText="Público?"
                  setChecked={flgPublico}
                  onSwitch={() => {
                    setFlgPublico(!flgPublico);
                  }}
                />
              </div>
            )}
          </div>
        </FormDefault>
      )}
    </Container>
  );
};

export default Cfop;
