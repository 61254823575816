import React, { useCallback, useEffect, useState } from 'react';
import { GridRowParams } from '@material-ui/data-grid';

import Spinner from '@atlaskit/spinner';
import { toast } from 'react-toastify';
import TextArea from '@atlaskit/textarea';
import Search from '~/components/Search';
import { schema } from './validations';

import { Container } from './styles';
import FormDefault from '~/components/FormDefault';
import api from '~/services/api';
import ToggleDefault from '~/components/ToggleDefault';
import InputText from '~/components/Inputs/InputText';
import { FormReceita, formReceitaBlank } from './protocols/receita';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

const CadastroReceita: React.FC = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);

  const [formReceita, setFormReceita] = useState<FormReceita>(formReceitaBlank);
  const [init, setInit] = useState(false);

  const {
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    setInit(false);
  }, [formReceita]);

  const handlePasteWeight = useCallback(
    (detalhamento) => {
      if (detalhamento.length > 1990) {
        setFormReceita({
          ...formReceita,
          des_formula: detalhamento.substring(0, 1990),
        });
        setValue('des_formula', detalhamento);
        return;
      }
      setFormReceita({
        ...formReceita,
        des_formula: detalhamento,
      });
      setValue('des_formula', detalhamento);
    },
    [setValue, formReceita],
  );

  const onRowClick = async (param: GridRowParams) => {
    setLoader(true);
    const { row } = param;

    const { cod_receita, des_receita, flg_gluten, des_formula } = row;

    setFormReceita({
      ...formReceita,
      cod_receita: {
        value: cod_receita,
        isInvalid: false,
        isRequired: true,
      },
      des_receita: {
        value: des_receita,
        isInvalid: false,
        isRequired: true,
      },
      flg_gluten: {
        value: flg_gluten,
        isInvalid: false,
        isRequired: true,
      },
      des_formula: {
        value: des_formula,
        isInvalid: false,
        isRequired: true,
      },
    });

    setValue('des_receita', des_receita);
    setValue('flg_gluten', flg_gluten);
    setValue('des_formula', des_formula);

    setUpdate(true);
    setShowSearch(false);
    setLoader(false);
  };

  const resetFormReceita = () => {
    setUpdate(false);
    setFormReceita(formReceitaBlank);
    reset();
  };

  const onCancel = () => {
    resetFormReceita();
    setShowSearch(true);
  };

  const onNew = () => {
    resetFormReceita();
    setUpdate(false);
  };

  const onDelete = async () => {
    const { data } = await api.delete(
      `/receita/${formReceita?.cod_receita.value}`,
    );

    if (data.success) {
      resetFormReceita();
      setShowSearch(true);
      return toast.success(data.message);
    }
  };

  const onSave = handleSubmit(async (dataForm1) => {
    if (!dataForm1.des_formula) {
      return toast.warning('Há informações pendentes.');
    }

    try {
      const dataForm = {
        des_receita: dataForm1.des_receita.trim(),
        flg_gluten: dataForm1.flg_gluten || false,
        des_formula: dataForm1.des_formula,
      };

      if (isUpdate) {
        const { data } = await api.put(
          `/receita/${formReceita.cod_receita.value}`,
          dataForm,
        );

        if (data.success) {
          toast.success(data.message);
          resetFormReceita();
          setShowSearch(true);
        }
        return;
      }
      const { data } = await api.post('/receita', dataForm);

      if (data.success) {
        toast.success(data.message);
        resetFormReceita();
      }
    } finally {
      setLoader(false);
    }
  });

  if (loader) {
    return (
      <Container>
        <div className="w-100 h-100 d-flex justify-conten-center align-items-center">
          <Spinner />
        </div>
      </Container>
    );
  }

  return (
    <Container>
      {showSearch && (
        <Search
          newData={() => setShowSearch(false)}
          onRowClick={onRowClick}
          codTela={36}
        />
      )}

      {!showSearch && (
        <FormDefault
          codTela={36}
          title="Cadastro de Receita"
          onCancel={onCancel}
          codigoRegistro={[
            {
              value: formReceita.cod_receita.value,
              des_campo: 'Código',
            },
          ]}
          onClearFields={resetFormReceita}
          onDelete={onDelete}
          onNew={onNew}
          onReturnSearch={onCancel}
          onSave={async () => {
            await onSave();
          }}
          isUpdate={isUpdate}
        >
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <InputText
                label="Descrição"
                placeholder="Informe a Descrição da Receita"
                value={formReceita.des_receita.value}
                toUpperCase
                isRequired
                setInvalid={!!errors.des_receita}
                iniInicializado={!!errors.des_receita}
                onChange={(value) => {
                  setValue('des_receita', value.trimStart());
                  setFormReceita({
                    ...formReceita,
                    des_receita: {
                      ...formReceita.des_receita,
                      value: value.trimStart(),
                      isInvalid: value.trim() === '',
                    },
                  });
                }}
              />
            </div>

            <div
              className="col-sm-12 col-md-2"
              style={{ textAlign: 'center', padding: '10px' }}
            >
              <ToggleDefault
                labelText="Contém Glúten?"
                setChecked={formReceita.flg_gluten.value === true}
                onSwitch={() => {
                  setFormReceita({
                    ...formReceita,
                    flg_gluten: {
                      ...formReceita.flg_gluten,
                      value: !formReceita.flg_gluten.value,
                    },
                  });
                  setValue('flg_gluten', !formReceita.flg_gluten.value);
                }}
              />
            </div>

            <div className="col-12" style={{ marginTop: '20px' }}>
              <label
                htmlFor="input-lojas"
                className="sc-iqAclL lflqij"
                style={{
                  color: '#6B778C',
                }}
              >
                Fórmula da Receita
              </label>
              <TextArea
                value={formReceita.des_formula.value}
                onChange={(e) => {
                  setValue('des_formula', e.target.value);
                  setFormReceita({
                    ...formReceita,
                    des_formula: {
                      ...formReceita.des_formula,
                      value: e.target.value,
                    },
                  });
                }}
                placeholder="Informe o Detalhamento da Receita"
                minimumRows={6}
                maxHeight="400px"
                maxLength={1990}
                onPaste={(e: any) =>
                  handlePasteWeight(e.clipboardData.getData('Text'))
                }
              />
            </div>
          </div>
        </FormDefault>
      )}
    </Container>
  );
};

export default CadastroReceita;
