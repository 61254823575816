import React from 'react';
import { Col, Row } from 'react-bootstrap';
import InputNumber from '~/components/Inputs/InputNumber';
import InputPercent from '~/components/Inputs/InputPercent';
import InputSelect from '~/components/Inputs/InputSelect';

import Separator from '~/components/Separator';
import ToggleDefault from '~/components/ToggleDefault';

import { useRegraLoja } from '~/pages/RegraDeLoja/RegraLojaContext';

import {
  tipoPisCofinsEntrada,
  tipoPisCofinsSaida,
  ISelect,
  tipoRegimePisCofins,
} from '../../types';

const PisCofins = () => {
  const {
    formRegraLoja,
    changeFormRegraLoja,
    formTela: { setValue },
  } = useRegraLoja();

  return (
    <>
      <div className="row mt-3">
        <div className="col-sm-12 col-md-3">
          <InputSelect
            label="Regime de Tributação (EFD)"
            placeholder="Selecione o tipo de Tributo"
            name="tipo_regime_pis_cofins"
            options={tipoRegimePisCofins}
            isRequired={formRegraLoja.tipo_regime_pis_cofins.isRequired}
            setInvalid={formRegraLoja.tipo_regime_pis_cofins.isInvalid}
            onChange={(value: any, isInvalid = true) => {
              setValue('tipo_regime_pis_cofins', value);
              changeFormRegraLoja({
                ...formRegraLoja,
                tipo_regime_pis_cofins: {
                  ...formRegraLoja.tipo_regime_pis_cofins,
                  value,
                  isInvalid: value.value === undefined,
                },
              });
            }}
            value={
              tipoRegimePisCofins.find((item: ISelect) => {
                return (
                  Number(item.value) ===
                  Number(formRegraLoja.tipo_regime_pis_cofins.value.value)
                );
              }) ?? {
                value: 0,
                label: 'Não cumulativo',
              }
            }
          />
        </div>
        <div className="col-sm-12 col-md-3">
          <ToggleDefault
            labelText="Considerar o ICMS-ST na BC Crédito?"
            setChecked={formRegraLoja.flg_st_bc_pis_cof.value}
            onSwitch={() => {
              setValue(
                'flg_st_bc_pis_cof',
                !formRegraLoja.flg_st_bc_pis_cof.value,
              );
              changeFormRegraLoja({
                ...formRegraLoja,
                flg_st_bc_pis_cof: {
                  ...formRegraLoja.flg_st_bc_pis_cof,
                  value: !formRegraLoja.flg_st_bc_pis_cof.value,
                },
              });
            }}
          />
        </div>
        <div className="col-sm-12 col-md-3">
          <ToggleDefault
            labelText="Considerar o IPI na BC Crédito?"
            setChecked={formRegraLoja.flg_ipi_bc_pis_cof.value}
            onSwitch={() => {
              setValue(
                'flg_ipi_bc_pis_cof',
                !formRegraLoja.flg_ipi_bc_pis_cof.value,
              );
              changeFormRegraLoja({
                ...formRegraLoja,
                flg_ipi_bc_pis_cof: {
                  ...formRegraLoja.flg_ipi_bc_pis_cof,
                  value: !formRegraLoja.flg_ipi_bc_pis_cof.value,
                },
              });
            }}
          />
        </div>
        <div className="col-sm-12 col-md-3">
          <ToggleDefault
            labelText="Excluir ICMS do BC/COFINS nas Entradas de NF's?"
            setChecked={formRegraLoja.flg_exc_icms_bc_pis_ent.value}
            onSwitch={() => {
              setValue(
                'flg_exc_icms_bc_pis_ent',
                !formRegraLoja.flg_exc_icms_bc_pis_ent.value,
              );
              changeFormRegraLoja({
                ...formRegraLoja,
                flg_exc_icms_bc_pis_ent: {
                  ...formRegraLoja.flg_exc_icms_bc_pis_ent,
                  value: !formRegraLoja.flg_exc_icms_bc_pis_ent.value,
                },
              });
            }}
          />
        </div>
        <Col sm="12">
          <Separator labelText="Incidentes" />
        </Col>
        <Col md="2">
          <div className="">
            <InputPercent
              label="% PIS"
              value={formRegraLoja.val_pis.value}
              min={0}
              max={100}
              casas=".01"
              placeholder=""
              isNull
              isUndefined
              isEmpty
              isRequired={formRegraLoja.val_pis.isRequired}
              setInvalid={formRegraLoja.val_pis.isInvalid}
              onChange={(value: number, isInvalid = true) => {
                setValue('val_pis', Number(value.toString().replace('-', '')));
                changeFormRegraLoja({
                  ...formRegraLoja,
                  val_pis: {
                    ...formRegraLoja.val_pis,
                    value: Number(value.toString().replace('-', '')),
                  },
                });
              }}
            />
          </div>
        </Col>

        <div className="col-sm-6 col-md-2">
          <InputPercent
            label="% COFINS"
            value={formRegraLoja.val_cofins.value}
            min={0}
            max={100}
            casas=".01"
            placeholder=""
            isNull
            isUndefined
            isEmpty
            isRequired={formRegraLoja.val_cofins.isRequired}
            setInvalid={formRegraLoja.val_cofins.isInvalid}
            onChange={(value: number, isInvalid = true) => {
              setValue('val_cofins', Number(value.toString().replace('-', '')));
              changeFormRegraLoja({
                ...formRegraLoja,
                val_cofins: {
                  ...formRegraLoja.val_cofins,
                  value: Number(value.toString().replace('-', '')),
                },
              });
            }}
          />
        </div>

        <div className="col-sm-6 col-md-2">
          <InputNumber
            label="Código DARF PIS"
            value={formRegraLoja.cod_darf_pis.value}
            placeholder=""
            isNull
            isUndefined
            isRequired={formRegraLoja.cod_darf_pis.isRequired}
            setInvalid={formRegraLoja.cod_darf_pis.isInvalid}
            onChange={(value: number) => {
              if (String(value).length <= 9) {
                setValue(
                  'val_cofins',
                  Number(value.toString().replace('-', '')),
                );
                changeFormRegraLoja({
                  ...formRegraLoja,
                  cod_darf_pis: {
                    ...formRegraLoja.cod_darf_pis,
                    value: Number(value.toString().replace('-', '')),
                  },
                });
              }
            }}
          />
        </div>

        <div className="col-sm-6 col-md-2">
          <InputNumber
            label="Código DARF COFINS"
            value={formRegraLoja.cod_darf_cofins.value}
            placeholder=""
            min={0}
            maxLength={9}
            isNull
            isUndefined
            isRequired={formRegraLoja.cod_darf_cofins.isRequired}
            setInvalid={formRegraLoja.cod_darf_cofins.isInvalid}
            onChange={(value: number) => {
              if (String(value).length <= 9) {
                setValue(
                  'cod_darf_cofins',
                  Number(value.toString().replace('-', '')),
                );
                changeFormRegraLoja({
                  ...formRegraLoja,
                  cod_darf_cofins: {
                    ...formRegraLoja.cod_darf_cofins,
                    value: Number(value.toString().replace('-', '')),
                  },
                });
              }
            }}
          />
        </div>

        <div className="col-sm-12 col-md-6">
          <InputSelect
            options={tipoPisCofinsEntrada}
            label="CST Entrada"
            value={
              tipoPisCofinsEntrada.find((item: ISelect) => {
                return (
                  Number(item.value) ===
                  Number(formRegraLoja.cst_pis_cof_entrada.value.value)
                );
              }) ?? {
                value: '50',
                label:
                  '50 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Tributada no Mercado Interno',
              }
            }
            isRequired={formRegraLoja.cst_pis_cof_entrada.isRequired}
            setInvalid={formRegraLoja.cst_pis_cof_entrada.isInvalid}
            onChange={(value: any, isInvalid = true) => {
              setValue('cst_pis_cof_entrada', value);
              changeFormRegraLoja({
                ...formRegraLoja,
                cst_pis_cof_entrada: {
                  ...formRegraLoja.cst_pis_cof_entrada,
                  value,
                  isInvalid: value.value === undefined,
                },
              });
            }}
          />
        </div>

        <div className="col-sm-12 col-md-6">
          <InputSelect
            options={tipoPisCofinsSaida}
            label="CST Saída"
            value={
              tipoPisCofinsSaida.find((item: ISelect) => {
                return (
                  Number(item.value) ===
                  Number(formRegraLoja.cst_pis_cof_saida.value.value)
                );
              }) ?? {
                value: '01',
                label: '01 - Operação Tributável com Alíquota Básica',
              }
            }
            isRequired={formRegraLoja.cst_pis_cof_saida.isRequired}
            setInvalid={formRegraLoja.cst_pis_cof_saida.isInvalid}
            onChange={(value: any, isInvalid = true) => {
              setValue('cst_pis_cof_saida', value);
              changeFormRegraLoja({
                ...formRegraLoja,
                cst_pis_cof_saida: {
                  ...formRegraLoja.cst_pis_cof_saida,
                  value,
                  isInvalid: value.value === undefined,
                },
              });
            }}
          />
        </div>
      </div>

      <div className="row">
        <Col sm="12">
          <Separator labelText="Não Incidentes" />
        </Col>
        <Col md="6">
          <div className="">
            <InputSelect
              options={tipoPisCofinsEntrada}
              label="CST Entrada Aliquota Zero"
              value={
                tipoPisCofinsEntrada.find((item: ISelect) => {
                  return (
                    Number(item.value) ===
                    Number(formRegraLoja.cst_pis_cof_ent_aliq_zero.value.value)
                  );
                }) ?? {
                  value: '73',
                  label: '73 - Operação de Aquisição a Alíquota Zero',
                }
              }
              isRequired={formRegraLoja.cst_pis_cof_ent_aliq_zero.isRequired}
              setInvalid={formRegraLoja.cst_pis_cof_ent_aliq_zero.isInvalid}
              onChange={(value: any, isInvalid = true) => {
                setValue('cst_pis_cof_ent_aliq_zero', value);
                changeFormRegraLoja({
                  ...formRegraLoja,
                  cst_pis_cof_ent_aliq_zero: {
                    ...formRegraLoja.cst_pis_cof_ent_aliq_zero,
                    value,
                    isInvalid: value.value === undefined,
                  },
                });
              }}
            />
          </div>
        </Col>
        <div className="col-sm-12 col-md-6">
          <InputSelect
            options={tipoPisCofinsSaida}
            label="CST Saída Aliquota Zero"
            value={
              tipoPisCofinsSaida.find((item: ISelect) => {
                return (
                  Number(item.value) ===
                  Number(formRegraLoja.cst_pis_cof_saida_aliq_zero.value.value)
                );
              }) ?? {
                value: '06',
                label: '06 - Operação Tributável a Alíquota Zero',
              }
            }
            isRequired={formRegraLoja.cst_pis_cof_saida_aliq_zero.isRequired}
            setInvalid={formRegraLoja.cst_pis_cof_saida_aliq_zero.isInvalid}
            onChange={(value: any, isInvalid = true) => {
              setValue('cst_pis_cof_saida_aliq_zero', value);
              changeFormRegraLoja({
                ...formRegraLoja,
                cst_pis_cof_saida_aliq_zero: {
                  ...formRegraLoja.cst_pis_cof_saida_aliq_zero,
                  value,
                  isInvalid: value.value === undefined,
                },
              });
            }}
          />
        </div>

        <div className="col-sm-12 col-md-6">
          <InputSelect
            options={tipoPisCofinsEntrada}
            label="CST Entrada Monofásico"
            value={
              tipoPisCofinsEntrada.find((item: ISelect) => {
                return (
                  Number(item.value) ===
                  Number(formRegraLoja.cst_pis_cof_ent_monof.value.value)
                );
              }) ?? {
                value: '70',
                label: '70 - Operação de Aquisição sem Direito a Crédito',
              }
            }
            isRequired={formRegraLoja.cst_pis_cof_ent_monof.isRequired}
            setInvalid={formRegraLoja.cst_pis_cof_ent_monof.isInvalid}
            onChange={(value: any, isInvalid = true) => {
              setValue('cst_pis_cof_ent_monof', value);
              changeFormRegraLoja({
                ...formRegraLoja,
                cst_pis_cof_ent_monof: {
                  ...formRegraLoja.cst_pis_cof_ent_monof,
                  value,
                  isInvalid: value.value === undefined,
                },
              });
            }}
          />
        </div>
        <div className="col-sm-12 col-md-6">
          <InputSelect
            options={tipoPisCofinsSaida}
            label="CST Saída Monofásico"
            value={
              tipoPisCofinsSaida.find((item: ISelect) => {
                return (
                  Number(item.value) ===
                  Number(formRegraLoja.cst_pis_cof_saida_monof.value.value)
                );
              }) ?? {
                value: '04',
                label:
                  '04 - Operação Tributável Monofásica - Revenda a Alíquota Zero',
              }
            }
            isRequired={formRegraLoja.cst_pis_cof_saida_monof.isRequired}
            setInvalid={formRegraLoja.cst_pis_cof_saida_monof.isInvalid}
            onChange={(value: any, isInvalid = true) => {
              setValue('cst_pis_cof_saida_monof', value);
              changeFormRegraLoja({
                ...formRegraLoja,
                cst_pis_cof_saida_monof: {
                  ...formRegraLoja.cst_pis_cof_saida_monof,
                  value,
                  isInvalid: value.value === undefined,
                },
              });
            }}
          />
        </div>

        <div className="col-sm-12 col-md-6">
          <InputSelect
            options={tipoPisCofinsEntrada}
            label="CST Entrada Substituição"
            value={
              tipoPisCofinsEntrada.find((item: ISelect) => {
                return (
                  Number(item.value) ===
                  Number(formRegraLoja.cst_pis_cof_ent_subst.value.value)
                );
              }) ?? {
                value: '75',
                label: '75 - Operação de Aquisição por Substituição Tributária',
              }
            }
            isRequired={formRegraLoja.cst_pis_cof_ent_subst.isRequired}
            setInvalid={formRegraLoja.cst_pis_cof_ent_subst.isInvalid}
            onChange={(value: any, isInvalid = true) => {
              setValue('cst_pis_cof_ent_subst', value);
              changeFormRegraLoja({
                ...formRegraLoja,
                cst_pis_cof_ent_subst: {
                  ...formRegraLoja.cst_pis_cof_ent_subst,
                  value,
                  isInvalid: value.value === undefined,
                },
              });
            }}
          />
        </div>
        <div className="col-sm-12 col-md-6">
          <InputSelect
            options={tipoPisCofinsSaida}
            label="CST Saída Substituição"
            value={
              tipoPisCofinsSaida.find((item: ISelect) => {
                return (
                  Number(item.value) ===
                  Number(formRegraLoja.cst_pis_cof_saida_subst.value.value)
                );
              }) ?? {
                value: '05',
                label: '05 - Operação Tributável por Substituição Tributária',
              }
            }
            isRequired={formRegraLoja.cst_pis_cof_saida_subst.isRequired}
            setInvalid={formRegraLoja.cst_pis_cof_saida_subst.isInvalid}
            onChange={(value: any, isInvalid = true) => {
              setValue('cst_pis_cof_saida_subst', value);
              changeFormRegraLoja({
                ...formRegraLoja,
                cst_pis_cof_saida_subst: {
                  ...formRegraLoja.cst_pis_cof_saida_subst,
                  value,
                  isInvalid: value.value === undefined,
                },
              });
            }}
          />
        </div>

        <div className="col-sm-12 col-md-6">
          <InputSelect
            options={tipoPisCofinsEntrada}
            label="CST Entrada Imune"
            value={
              tipoPisCofinsEntrada.find((item: ISelect) => {
                return (
                  Number(item.value) ===
                  Number(formRegraLoja.cst_pis_cof_ent_imune.value.value)
                );
              }) ?? {
                value: '74',
                label:
                  '74 - Operação de Aquisição sem Incidência da Contribuição',
              }
            }
            isRequired={formRegraLoja.cst_pis_cof_ent_imune.isRequired}
            setInvalid={formRegraLoja.cst_pis_cof_ent_imune.isInvalid}
            onChange={(value: any, isInvalid = true) => {
              setValue('cst_pis_cof_ent_imune', value);
              changeFormRegraLoja({
                ...formRegraLoja,
                cst_pis_cof_ent_imune: {
                  ...formRegraLoja.cst_pis_cof_ent_imune,
                  value,
                  isInvalid: value.value === undefined,
                },
              });
            }}
          />
        </div>
        <div className="col-sm-12 col-md-6">
          <InputSelect
            options={tipoPisCofinsSaida}
            label="CST Saída Imune"
            value={
              tipoPisCofinsSaida.find((item: ISelect) => {
                return (
                  Number(item.value) ===
                  Number(formRegraLoja.cst_pis_cof_saida_imune.value.value)
                );
              }) ?? {
                value: '08',
                label: '08 - Operação sem Incidência da Contribuição',
              }
            }
            isRequired={formRegraLoja.cst_pis_cof_saida_imune.isRequired}
            setInvalid={formRegraLoja.cst_pis_cof_saida_imune.isInvalid}
            onChange={(value: any, isInvalid = true) => {
              setValue('cst_pis_cof_saida_imune', value);
              changeFormRegraLoja({
                ...formRegraLoja,
                cst_pis_cof_saida_imune: {
                  ...formRegraLoja.cst_pis_cof_saida_imune,
                  value,
                  isInvalid: value.value === undefined,
                },
              });
            }}
          />
        </div>

        <div className="col-sm-12 col-md-6">
          <InputSelect
            options={tipoPisCofinsEntrada}
            label="CST Entrada Suspensão"
            value={
              tipoPisCofinsEntrada.find((item: ISelect) => {
                return (
                  Number(item.value) ===
                  Number(formRegraLoja.cst_pis_cof_ent_susp.value.value)
                );
              }) ?? {
                value: '72',
                label: '72 - Operação de Aquisição com Suspensão',
              }
            }
            isRequired={formRegraLoja.cst_pis_cof_ent_susp.isRequired}
            setInvalid={formRegraLoja.cst_pis_cof_ent_susp.isInvalid}
            onChange={(value: any, isInvalid = true) => {
              setValue('cst_pis_cof_ent_susp', value);
              changeFormRegraLoja({
                ...formRegraLoja,
                cst_pis_cof_ent_susp: {
                  ...formRegraLoja.cst_pis_cof_ent_susp,
                  value,
                  isInvalid: value.value === undefined,
                },
              });
            }}
          />
        </div>
        <div className="col-sm-12 col-md-6">
          <InputSelect
            options={tipoPisCofinsSaida}
            label="CST Saída Suspensão"
            value={
              tipoPisCofinsSaida.find((item: ISelect) => {
                return (
                  Number(item.value) ===
                  Number(formRegraLoja.cst_pis_cof_saida_susp.value.value)
                );
              }) ?? {
                value: '09',
                label: '09 - Operação com Suspensão da Contribuição',
              }
            }
            isRequired={formRegraLoja.cst_pis_cof_saida_susp.isRequired}
            setInvalid={formRegraLoja.cst_pis_cof_saida_susp.isInvalid}
            onChange={(value: any, isInvalid = true) => {
              setValue('cst_pis_cof_saida_susp', value);
              changeFormRegraLoja({
                ...formRegraLoja,
                cst_pis_cof_saida_susp: {
                  ...formRegraLoja.cst_pis_cof_saida_susp,
                  value,
                  isInvalid: value.value === undefined,
                },
              });
            }}
          />
        </div>
      </div>
    </>
  );
};

export default PisCofins;
