import Button from '@atlaskit/button';
import { CircularProgress } from '@material-ui/core';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import {
  InputCidade,
  InputDate,
  InputMaskCep,
  InputText,
} from '~/components/NovosInputs';
import { usePedidoVenda } from '../../PedidoVendaContext';
import { Col, Row } from 'react-bootstrap';
import { ButtonSearchCep, ButtonSearchCepContainer } from './styles';
import axios from 'axios';
import { ICEP } from '~/components/Pessoa/types';
import { toast } from 'react-toastify';
import { CidadeParams } from '../../types';

export const Entrega: React.FC = () => {
  const {
    formPedidoVenda: {
      register,
      formState: { errors },
      control,
      setValue,
      getValues,
      clearErrors,
      watch,
    },
    isUpdate,
    isDisabledPedido,
    setIsUpdate,
    cidade,
    setCidade,
  } = usePedidoVenda();
  const watchCep = watch('num_cep');
  const [loaderCep, setLoaderCep] = useState(false);

  /**
   * FUNÇÕES
   */

  const searchCep = useCallback(async () => {
    setLoaderCep(true);
    let cep = getValues('num_cep');
    if (!cep) {
      toast.warning('CEP inválido');
      setLoaderCep(false);
      return;
    }
    cep = cep.replace(/[^\d]/g, '');
    if (cep === '') {
      toast.warning('CEP inválido');
      setLoaderCep(false);
      return;
    }
    try {
      const data: ICEP = await axios.get(
        `https://brasilapi.com.br/api/cep/v2/${cep}`,
      );
      if (data.data && !data.message) {
        setValue(
          'des_logradouro',
          data.data.street && data.data.street.toUpperCase(),
        );
        setValue(
          'des_bairro',
          data.data.neighborhood && data.data.neighborhood.toUpperCase(),
        );
        setCidade({
          cidade: data.data.city && data.data.city.toUpperCase(),
          uf: data.data.state,
        });
        setValue('latitude', data.data.location.coordinates.latitude);
        setValue('longitude', data.data.location.coordinates.longitude);
        setLoaderCep(false);
        return;
      }
      if (data.message) {
        setLoaderCep(false);
        return;
      }
    } catch (error: any) {
      setLoaderCep(false);
      toast.warn('CEP não encontrado');
      return;
    }
    setLoaderCep(false);
  }, [getValues, setValue, watchCep]);

  return (
    <>
      <Row>
        <Col sm={12} md={2}>
          <ButtonSearchCepContainer>
            <InputMaskCep
              label="CEP"
              name="num_cep"
              isError={!!errors.num_cep}
              control={control}
            />
            <ButtonSearchCep
              aria-label="buscar cep"
              type="button"
              onClick={searchCep}
              disabled={isDisabledPedido}
            >
              {!loaderCep && <AiOutlineSearch size={20} color="#fff" />}
              {loaderCep && (
                <CircularProgress style={{ color: '#fff' }} size={15} />
              )}
            </ButtonSearchCep>
          </ButtonSearchCepContainer>
        </Col>
        <Col sm={12} md={5}>
          <InputText
            maxLength={1000}
            label="Logradouro"
            placeholder="Informe o Logradouro"
            disabled={isDisabledPedido}
            name="des_logradouro"
            register={register}
            control={control}
            isError={!!errors.des_logradouro}
          />
        </Col>
        <Col sm={12} md={2}>
          <InputText
            maxLength={1000}
            label="Número"
            placeholder="Informe o Número"
            disabled={isDisabledPedido}
            name="num_endereco"
            register={register}
            control={control}
            isError={!!errors.num_endereco}
          />
        </Col>
        <Col sm={12} md={3}>
          <InputText
            maxLength={1000}
            label="Bairro"
            placeholder="Informe o Bairro"
            disabled={isDisabledPedido}
            name="des_bairro"
            register={register}
            control={control}
            isError={!!errors.des_bairro}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={7}>
          <InputText
            maxLength={1000}
            label="Complemento"
            placeholder="Informe o Complemento"
            disabled={isDisabledPedido}
            name="des_complemento"
            register={register}
            control={control}
            isError={!!errors.des_complemento}
          />
        </Col>
        <Col sm={12} md={3}>
          <InputCidade
            label="Cidade"
            maxLength={29}
            placeholder="Selecione a Cidade"
            name="cidades"
            register={register}
            isError={!!errors.cidades}
            control={control}
            disabled={isDisabledPedido}
            setValue={setValue}
            clearCampo={false}
            cidadeABuscar={cidade}
            changeSelected={(selected) => {
              setValue('cidades', selected);
            }}
          />
        </Col>
        <Col sm={12} md={2}>
          <InputDate
            register={register}
            control={control}
            name="dta_entrega"
            label="Entrega"
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const { value } = event?.target;
              clearErrors('dta_entrega');
              setValue('dta_entrega', value);
            }}
            disabled={isDisabledPedido}
            isError={!!errors.dta_entrega}
          />
        </Col>
      </Row>
    </>
  );
};
