import { GridRowParams } from '@material-ui/data-grid';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import DefaultLoader from '~/components/DefaultLoader';
import FormDefault from '~/components/FormDefault';
import InputSelect from '~/components/Inputs/InputSelect';
import InputText from '~/components/Inputs/InputText';
import Loja from '~/components/Loja';
import Search from '~/components/Search';
import api from '~/services/api';

import { Container } from './styles';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './Validations';

interface IFormData {
  cod_feriado: {
    value: string | undefined;
    isInvalid: boolean;
    isRequired: boolean;
  };
  des_feriado: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
  tipo_feriado: {
    value: { label: string | undefined; value: number | undefined } | undefined;
    isInvalid: boolean;
    isRequired: boolean;
  };
  num_dia: {
    value: { label: string | undefined; value: number | undefined } | undefined;
    isInvalid: boolean;
    isRequired: boolean;
  };
  num_mes: {
    value: { label: string | undefined; value: number | undefined };
    isInvalid: boolean;
    isRequired: boolean;
  };
  num_ano: {
    value: { label: string | undefined; value: number | undefined } | undefined;
    isInvalid: boolean;
    isRequired: boolean;
  };
}

const meses = [
  {
    label: 'Janeiro',
    value: 1,
  },
  {
    label: 'Fevereiro',
    value: 2,
  },
  {
    label: 'Março',
    value: 3,
  },
  {
    label: 'Abril',
    value: 4,
  },
  {
    label: 'Maio',
    value: 5,
  },
  {
    label: 'Junho',
    value: 6,
  },
  {
    label: 'Julho',
    value: 7,
  },
  {
    label: 'Agosto',
    value: 8,
  },
  {
    label: 'Setembro',
    value: 9,
  },
  {
    label: 'Outubro',
    value: 10,
  },
  {
    label: 'Novembro',
    value: 11,
  },
  {
    label: 'Dezembro',
    value: 12,
  },
];
const tipoDeFeriado = [
  {
    label: 'Fixo',
    value: 0,
  },
  {
    label: 'Variável',
    value: 1,
  },
];

const Feriados: React.FC = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState(false);
  const [showSearch, setShowSearch] = useState(true);
  const [invalidLoja, setInvalidLoja] = useState(false);
  const [lojas, setLojas] = useState<number | number[]>();
  const [dias, setDias] = useState([
    { label: `${new Date().getDate()}`, value: new Date().getDate() },
  ]);
  const [anos, setAnos] = useState([
    { label: `${new Date().getFullYear()}`, value: new Date().getFullYear() },
  ]);

  const {
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  const [formData, setFormData] = useState<IFormData>({
    cod_feriado: {
      value: undefined,
      isInvalid: false,
      isRequired: false,
    },
    des_feriado: {
      value: '',
      isInvalid: true,
      isRequired: true,
    },
    tipo_feriado: {
      value: undefined,
      isInvalid: true,
      isRequired: true,
    },
    num_dia: {
      value: { label: `${new Date().getDate()}`, value: new Date().getDate() },
      isInvalid: false,
      isRequired: true,
    },
    num_mes: {
      value: {
        label: `${new Date()
          .toLocaleDateString('pt-BR', { month: 'long' })
          .charAt(0)
          .toUpperCase()}${new Date()
          .toLocaleDateString('pt-BR', { month: 'long' })
          .slice(1)}`,
        value: new Date().getMonth() + 1,
      },
      isInvalid: false,
      isRequired: true,
    },
    num_ano: {
      value: {
        label: `${new Date().getFullYear()}`,
        value: new Date().getFullYear(),
      },
      isInvalid: false,
      isRequired: false,
    },
  });
  const [formDisabled, setFormDisabled] = useState({
    num_ano: false,
  });

  useEffect(() => {
    setValue('num_mes', {
      label: `${new Date()
        .toLocaleDateString('pt-BR', { month: 'long' })
        .charAt(0)
        .toUpperCase()}${new Date()
        .toLocaleDateString('pt-BR', { month: 'long' })
        .slice(1)}`,
      value: new Date().getMonth() + 1,
    });

    setValue('num_ano', {
      label: `${new Date().getFullYear()}`,
      value: new Date().getFullYear(),
    });

    setValue('num_dia', {
      label: `${new Date().getDate()}`,
      value: new Date().getDate(),
    });
    const currentYear = new Date();
    const years = [
      {
        label: `${currentYear.getFullYear()}`,
        value: currentYear.getFullYear(),
      },
    ];
    for (let i = 1; i <= 10; i++) {
      years.push({
        label: `${currentYear.getFullYear() + i}`,
        value: currentYear.getFullYear() + i,
      });
    }
    setAnos(years);
  }, []);

  useEffect(() => {
    const mes = formData.num_mes.value.value;
    const ano = formData.num_ano.value?.value;
    const ultimoDiaMes = new Date(Number(ano), Number(mes), 0).getDate();

    const days = [];

    for (let i = 1; i <= ultimoDiaMes; i++) {
      days.push({
        label: `${i}`,
        value: i,
      });

      if (i === ultimoDiaMes && ultimoDiaMes !== 29 && mes === 2) {
        days.push({
          label: '29',
          value: 29,
        });
      }
      setDias(days);
    }
  }, [formData.num_mes.value.value, formData.num_ano.value?.value]);

  const onRowClick = async (param: GridRowParams) => {
    setLoader(true);
    const { row } = param;

    const newlojas: number[] = [];
    if (row.cod_loja) {
      for (let i = 0; i < row.cod_loja.split(',').length; i++) {
        const codLoja = row.cod_loja.split(',');
        newlojas.push(Number(codLoja[i]));
      }
      setLojas(newlojas);
    }
    const {
      cod_feriado,
      des_feriado,
      num_ano,
      num_dia,
      num_mes,
      tipo_feriado,
    } = row;

    const mesAtual = meses.find((mes) => mes.value === num_mes);
    setFormData({
      cod_feriado: {
        value: cod_feriado,
        isInvalid: false,
        isRequired: false,
      },
      des_feriado: {
        value: des_feriado,
        isInvalid: !des_feriado,
        isRequired: true,
      },
      tipo_feriado: {
        value:
          tipo_feriado === 0
            ? { label: 'Fixo', value: 0 }
            : { label: 'Variável', value: 1 },
        isInvalid: false,
        isRequired: true,
      },
      num_dia: {
        value: { label: `${num_dia}`, value: num_dia },
        isInvalid: !num_dia,
        isRequired: true,
      },
      num_mes: {
        value: {
          label: `${mesAtual?.label}`,
          value: num_mes,
        },
        isInvalid: !num_dia,
        isRequired: true,
      },
      num_ano: {
        value: {
          label: `${num_ano}`,
          value: num_ano,
        },
        isInvalid: !num_ano,
        isRequired: true,
      },
    });
    setFormDisabled({
      ...formDisabled,
      num_ano: tipo_feriado === 0,
    });

    setValue('des_feriado', des_feriado);
    setValue(
      'tipo_feriado',
      tipo_feriado === 0
        ? { label: 'Fixo', value: 0 }
        : { label: 'Variável', value: 1 },
    );
    setValue('num_dia', { label: `${num_dia}`, value: num_dia });
    setValue('num_mes', {
      label: `${mesAtual?.label}`,
      value: num_mes,
    });
    setValue('num_ano', {
      label: `${num_ano}`,
      value: num_ano,
    });

    setUpdate(true);
    setShowSearch(false);
    setLoader(false);
  };

  const newData = () => {
    setUpdate(false);
    setShowSearch(false);
    resetFormData();
  };

  const resetFormData = useCallback(() => {
    reset();
    setValue('num_mes', {
      label: `${new Date()
        .toLocaleDateString('pt-BR', { month: 'long' })
        .charAt(0)
        .toUpperCase()}${new Date()
        .toLocaleDateString('pt-BR', { month: 'long' })
        .slice(1)}`,
      value: new Date().getMonth() + 1,
    });
    setValue('num_dia', {
      label: `${new Date().getDate()}`,
      value: new Date().getDate(),
    });

    setValue('num_ano', {
      label: `${new Date().getFullYear()}`,
      value: new Date().getFullYear(),
    });
    setFormData({
      cod_feriado: {
        value: undefined,
        isInvalid: false,
        isRequired: false,
      },
      des_feriado: {
        value: '',
        isInvalid: true,
        isRequired: true,
      },
      tipo_feriado: {
        value: undefined,
        isInvalid: true,
        isRequired: true,
      },
      num_dia: {
        value: {
          label: `${new Date().getDate()}`,
          value: new Date().getDate(),
        },
        isInvalid: false,
        isRequired: true,
      },
      num_mes: {
        value: {
          label: `${new Date()
            .toLocaleDateString('pt-BR', { month: 'long' })
            .charAt(0)
            .toUpperCase()}${new Date()
            .toLocaleDateString('pt-BR', { month: 'long' })
            .slice(1)}`,
          value: new Date().getMonth() + 1,
        },
        isInvalid: false,
        isRequired: true,
      },
      num_ano: {
        value: {
          label: `${new Date().getFullYear()}`,
          value: new Date().getFullYear(),
        },
        isInvalid: false,
        isRequired: true,
      },
    });
    setUpdate(false);
    setInvalidLoja(false);
  }, [reset, setValue]);

  const handleTipo = (
    val: { label: string; value: number },
    isInvalid: boolean,
  ) => {
    const ano = formData.num_ano.value?.value;
    const mes = formData.num_mes.value?.value;
    const dia = formData.num_dia.value?.value || 1;
    const ultimoDiaMes = new Date(
      Number(val?.value === 0 ? new Date().getFullYear() : ano),
      Number(mes),
      0,
    ).getDate();
    setValue('tipo_feriado', val);
    setValue('num_dia', {
      label: dia > ultimoDiaMes ? ultimoDiaMes.toString() : dia.toString(),
      value: dia > ultimoDiaMes ? ultimoDiaMes : dia,
    });
    setValue(
      'num_ano',
      val?.value === 0
        ? {
            label: `${new Date().getFullYear()}`,
            value: new Date().getFullYear(),
          }
        : formData.num_ano.value,
    );

    setFormData({
      ...formData,
      tipo_feriado: {
        ...formData.tipo_feriado,
        value: val,
        isInvalid,
      },
      num_dia: {
        ...formData.num_dia,
        value: {
          label: dia > ultimoDiaMes ? ultimoDiaMes.toString() : dia.toString(),
          value: dia > ultimoDiaMes ? ultimoDiaMes : dia,
        },
      },
      num_ano: {
        ...formData.num_ano,
        value:
          val?.value === 0
            ? {
                label: `${new Date().getFullYear()}`,
                value: new Date().getFullYear(),
              }
            : formData.num_ano.value,
        isInvalid,
      },
    });
    setFormDisabled({
      ...formDisabled,
      num_ano: val.value === 0,
    });
  };
  const handleDay = (
    val: { label: string; value: number },
    isInvalid: boolean,
  ) => {
    setFormData({
      ...formData,
      num_dia: {
        ...formData.num_dia,
        value: val,
        isInvalid,
      },
    });
    setValue('num_dia', val);
  };
  const handleMonth = (
    val: { label: string; value: number },
    isInvalid: boolean,
  ) => {
    const ano = formData.num_ano.value?.value;
    const dia = formData.num_dia.value?.value || 1;
    const ultimoDiaMes = new Date(Number(ano), Number(val.value), 0).getDate();
    setFormData({
      ...formData,
      num_mes: {
        ...formData.num_mes,
        value: val,
        isInvalid,
      },
      num_dia: {
        ...formData.num_dia,
        value: {
          label: dia > ultimoDiaMes ? ultimoDiaMes.toString() : dia.toString(),
          value: dia > ultimoDiaMes ? ultimoDiaMes : dia,
        },
      },
    });

    setValue('num_mes', val);
    setValue('num_dia', {
      label: dia > ultimoDiaMes ? ultimoDiaMes.toString() : dia.toString(),
      value: dia > ultimoDiaMes ? ultimoDiaMes : dia,
    });
  };
  const handleYear = (
    val: { label: string; value: number },
    isInvalid: boolean,
  ) => {
    const tipo = formData.tipo_feriado.value?.value;
    const mes = formData.num_mes.value?.value;
    const dia = formData.num_dia.value?.value || 1;
    const ultimoDiaMes = new Date(
      Number(tipo === 0 ? new Date().getFullYear() : val?.value),
      Number(mes),
      0,
    ).getDate();
    setFormData({
      ...formData,
      num_ano: {
        ...formData.num_ano,
        value: val,
        isInvalid,
      },
      num_dia: {
        ...formData.num_dia,
        value: {
          label: dia > ultimoDiaMes ? ultimoDiaMes.toString() : dia.toString(),
          value: dia > ultimoDiaMes ? ultimoDiaMes : dia,
        },
      },
    });
    setValue('num_dia', {
      label: dia > ultimoDiaMes ? ultimoDiaMes.toString() : dia.toString(),
      value: dia > ultimoDiaMes ? ultimoDiaMes : dia,
    });
    setValue('num_ano', val);
  };
  const handleDescription = (val: string, isInvalid: boolean) => {
    setFormData({
      ...formData,
      des_feriado: {
        ...formData.des_feriado,
        value: val,
        isInvalid,
      },
    });

    setValue('des_feriado', val);
  };

  const onSave = handleSubmit(async (dataForm) => {
    try {
      if (isUpdate) {
        const { data } = await api.put(
          `/feriado/${formData.cod_feriado.value}`,
          {
            tipo_feriado: dataForm.tipo_feriado.value,
            num_dia: dataForm.num_dia.value,
            num_mes: dataForm.num_mes.value,
            num_ano: formDisabled
              ? new Date().getFullYear()
              : dataForm.num_ano.value,
            des_feriado: dataForm.des_feriado,
            lojas: dataForm.lojas,
          },
        );
        if (data.success) {
          toast.success(data.message);
          setUpdate(false);
          resetFormData();
          setShowSearch(true);
          return;
        }

        toast.error(data.message);
        return;
      }

      const { data } = await api.post('/feriado', {
        tipo_feriado: dataForm.tipo_feriado.value,
        num_dia: dataForm.num_dia.value,
        num_mes: dataForm.num_mes.value,
        num_ano: dataForm.num_ano.value,
        des_feriado: dataForm.des_feriado,
        lojas: dataForm.lojas,
      });
      if (data.success) {
        toast.success(data.message);
        resetFormData();
        // setShowSearch(true);
      }
    } finally {
      setLoader(false);
    }
  });

  const handleCancel = useCallback(() => {
    resetFormData();
    setShowSearch(true);
  }, [resetFormData]);

  const handleDelete = useCallback(async () => {
    const { data } = await api.delete(`/feriado/${formData.cod_feriado.value}`);
    if (data.success) {
      resetFormData();
      setShowSearch(true);
      toast.success('Feriado excluído com sucesso');
    }
  }, [formData.cod_feriado.value, resetFormData]);

  if (loader) {
    return <DefaultLoader />;
  }

  return (
    <Container>
      {showSearch && (
        <Search codTela={49} newData={newData} onRowClick={onRowClick} />
      )}
      {!showSearch && (
        <FormDefault
          codTela={49}
          title="Cadastro de Feriado"
          codigoRegistro={[
            { value: formData.cod_feriado.value, des_campo: 'Código' },
          ]}
          onSave={async () => {
            await onSave();
          }}
          onCancel={handleCancel}
          onNew={newData}
          onDelete={handleDelete}
          onClearFields={resetFormData}
          isUpdate={isUpdate}
          onReturnSearch={() => setShowSearch(true)}
        >
          <div className="row">
            <div className="row mb-2">
              <div className="col-md-12 mb-2">
                <Loja
                  isMulti
                  onChange={(val) => {
                    setValue('lojas', val);
                    setLojas(val);
                  }}
                  selectedLoja={lojas}
                  IsInvalid={invalidLoja}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-4">
                <InputSelect
                  options={tipoDeFeriado}
                  label="Tipo"
                  value={formData.tipo_feriado.value}
                  isRequired
                  setInvalid={!!errors.tipo_feriado}
                  noOptionsMessage="Nenhum registro encontrado."
                  iniInicializado={!!errors.tipo_feriado}
                  placeholder="Selecione o tipo de feriado"
                  onChange={(newValue: any, isInvalid = true) => {
                    handleTipo(newValue, isInvalid);
                  }}
                />
              </div>
              <div className="col-sm-12 col-md-2">
                <InputSelect
                  options={dias}
                  label="Dia"
                  value={formData.num_dia.value}
                  isRequired
                  setInvalid={!!errors.num_dia}
                  noOptionsMessage="Nenhum registro encontrado."
                  iniInicializado={!!errors.num_dia}
                  placeholder="Selecione o dia..."
                  onChange={(newValue: any, isInvalid = true) => {
                    handleDay(newValue, isInvalid);
                  }}
                />
              </div>
              <div className="col-sm-12 col-md-2">
                <InputSelect
                  options={meses}
                  label="Mês"
                  value={formData.num_mes.value}
                  isRequired
                  setInvalid={!!errors.num_mes}
                  noOptionsMessage="Nenhum registro encontrado."
                  iniInicializado={!!errors.num_mes}
                  placeholder="Selecione o mês..."
                  onChange={(newValue: any, isInvalid = true) => {
                    handleMonth(newValue, isInvalid);
                  }}
                />
              </div>
              <>
                {formData?.tipo_feriado.value?.value === 1 && (
                  <div className="col-sm-12 col-md-2">
                    <InputSelect
                      options={anos}
                      label="Ano"
                      value={formData.num_ano.value}
                      isRequired
                      setInvalid={!!errors.num_ano}
                      noOptionsMessage="Nenhum registro encontrado."
                      iniInicializado={!!errors.num_ano}
                      placeholder="Selecione o ano..."
                      isDisabled={formDisabled.num_ano}
                      onChange={(newValue: any, isInvalid = true) => {
                        handleYear(newValue, isInvalid);
                      }}
                    />
                  </div>
                )}
              </>
              <div className="col-sm-12 col-md-12">
                <InputText
                  label="Descrição"
                  value={formData.des_feriado.value}
                  maxLength={50}
                  placeholder="Informe a descrição do feriado"
                  isEmpty
                  isNull
                  isUndefined
                  isRequired
                  setInvalid={!!errors.des_feriado}
                  iniInicializado={!!errors.des_feriado}
                  onChange={(newValue: string, isInvalid = true) => {
                    handleDescription(
                      newValue.trimStart().toUpperCase(),
                      isInvalid,
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </FormDefault>
      )}
    </Container>
  );
};

export default Feriados;
