import React, { useState, useEffect } from 'react';
import { GridRowParams } from '@material-ui/data-grid';
import Search from '~/components/Search';
import { Table, Form } from './components';
import FormDefault from '~/components/FormDefault';
import { Container } from './styles';
import api from '~/services/api';
import { tipoFinalizadoras, tipoOperacao } from './data';
import {
  FormFinalizadora,
  FormFinalizadoraLoja,
  Finalizadora,
  FinalizadoraLoja,
} from './protocols';
import { useFinalizadora, useFormContext } from './FinalizadoraContext';
import { toast } from 'react-toastify';

const FinalizadoraContent: React.FC = () => {
  const [showSearch, setShowSearch] = useState(true);
  const [isUpdate, setIsUpdate] = useState(false);
  const {
    handleClearForm,
    handleClearFormFinalizadoraLoja,
    formFinalizadora,
    onChangeFormFinalizadora,
    onChangeInit,
    handleInitLoja,
    handleResetFormLoja,
    getContasAll,
    getLojas,
    setFlagConfTestAuto,
    flagConfTesAuto,
  } = useFinalizadora();

  const { handleSubmit, setValue, setError, setValueFinalizadora, getValues } =
    useFormContext();

  useEffect(() => {
    handleResetFormLoja();
    getContasAll();
  }, []);

  const getFinalizadoraLojas = async (
    cod_finalizadora: number,
  ): Promise<any> => {
    const res = await api.get(`/finalizadora/${cod_finalizadora}`);
    const { data } = res.data;
    return data;
  };

  const setDataToForm = (data: Finalizadora) => {
    tipoFinalizadoras.filter((item: any) => {
      if (data.tipo_finalizadora === item.value) {
        data.tipo_finalizadora = item;
        return false;
      }
      return false;
    });
    tipoOperacao.filter((item: any) => {
      if (data.tipo_operacao_cartao === item.value) {
        data.tipo_operacao_cartao = item;
        return false;
      }
      return false;
    });
    const lojas: FormFinalizadoraLoja[] = data.lojas.map(
      (loja: FinalizadoraLoja) => {
        return {
          cod_loja: {
            value: loja.cod_loja,
            isInvalid: false,
            isRequired: true,
          },
          des_loja: {
            value: loja.des_loja,
            isInvalid: false,
            isRequired: false,
          },
          des_tecla: {
            value: loja.des_tecla,
            isInvalid: false,
            isRequired: true,
          },
          num_condicao: {
            value: loja.num_condicao,
            isInvalid: false,
            isRequired: true,
          },
          cod_condicao: {
            value: { label: '', value: loja.cod_condicao },
            isInvalid: false,
            isRequired: true,
          },
          cod_cc: {
            value: { label: '', value: loja.cod_cc },
            isInvalid: false,
            isRequired: true,
          },
          cod_categoria: {
            value: { label: '', value: loja.cod_categoria },
            isInvalid: false,
            isRequired: true,
          },
          flg_inativa: {
            value: loja.flg_inativa,
            isInvalid: false,
            isRequired: true,
          },
        };
      },
    );

    setFlagConfTestAuto(data.flg_conf_tes_auto);
    setValueFinalizadora('cod_finalizadora', data.cod_finalizadora);
    setValue('cod_finalizadora', data.cod_finalizadora);
    setValue('des_finalizadora', data.des_finalizadora);
    setValue('tipo_finalizadora', data.tipo_finalizadora);
    setValue('tipo_operacao_cartao', data.tipo_operacao_cartao);

    const finalizadora: FormFinalizadora = {
      cod_finalizadora: {
        value: data.cod_finalizadora,
        isRequired: false,
        isInvalid: false,
      },
      des_finalizadora: {
        value: data.des_finalizadora,
        isRequired: true,
        isInvalid: false,
      },
      flg_pdv: {
        value: data.flg_pdv,
        isRequired: false,
        isInvalid: false,
      },
      tipo_finalizadora: {
        value: { label: '', value: data.tipo_finalizadora },
        isRequired: true,
        isInvalid: false,
      },
      lojas: {
        lojas,
        isInvalid: false,
        isRequired: false,
      },
    };

    onChangeFormFinalizadora(finalizadora);
  };

  const onRowClick = async (param: GridRowParams) => {
    const { cod_finalizadora } = param.row;
    const data = await getFinalizadoraLojas(cod_finalizadora);

    setDataToForm(data);
    setIsUpdate(true);
    setShowSearch(false);
  };

  const onCancel = (): void => {
    handleClearForm();
    handleClearFormFinalizadoraLoja();
    setShowSearch(true);
  };

  const onNew = (): void => {
    handleClearForm();
    handleClearFormFinalizadoraLoja();
    setValue('tipo_operacao_cartao', {
      value: -1,
      label: 'Nenhum',
    });
    setIsUpdate(false);
    setShowSearch(false);
    onChangeInit(false);
    handleResetFormLoja();
  };

  const onReturnSearch = () => {
    handleClearForm();
    handleClearFormFinalizadoraLoja();
    setIsUpdate(false);
    setShowSearch(true);
    handleResetFormLoja();
  };

  const onSave = handleSubmit(async (data: any) => {
    onChangeInit(false);
    handleInitLoja(false);

    if (formFinalizadora.lojas.lojas.length < 1) {
      toast.warning('Condição da loja não informada');
      return;
    }
    const lojas = formFinalizadora.lojas.lojas.map(
      (finalizadoraLoja: FormFinalizadoraLoja) => {
        return {
          cod_loja: finalizadoraLoja.cod_loja?.value,
          des_tecla: finalizadoraLoja.des_tecla.value?.trim(),
          cod_condicao: finalizadoraLoja.cod_condicao?.value?.value,
          num_condicao: finalizadoraLoja.num_condicao?.value,
          cod_cc: finalizadoraLoja.cod_cc.value?.value,
          cod_categoria: finalizadoraLoja.cod_categoria?.value?.value,
          flg_inativa: finalizadoraLoja.flg_inativa?.value,
        };
      },
    );

    const upsert = {
      cod_finalizadora: formFinalizadora.cod_finalizadora?.value,
      des_finalizadora: data.des_finalizadora?.trim(),
      flg_pdv: data.flg_pdv,
      tipo_finalizadora: data.tipo_finalizadora?.value,
      tipo_operacao_cartao: data.tipo_operacao_cartao?.value,
      flg_conf_tes_auto: flagConfTesAuto,
      lojas,
    };
    for (let i = 0; i < lojas.length; i++) {
      if (lojas[i].des_tecla === undefined) {
        toast.warning(`Informe uma Tecla para a loja na posição ${i + 1}.`);
        return;
      }
    }

    if (
      (data.tipo_finalizadora.value === 1 &&
        data.tipo_operacao_cartao?.value === -1) ||
      (data.tipo_finalizadora.value === 1 &&
        data.tipo_operacao_cartao === -1) ||
      data.tipo_operacao_cartao === undefined
    ) {
      setError('tipo_operacao_cartao', { type: 'required' });
      return toast.warning(
        'Tipo de Operação do cartão deve ser informado (Crédito/Débito).',
      );
    }

    if (!isUpdate) {
      const res = await api.post('/finalizadora', upsert);
      const { message } = res.data;

      handleClearForm();
      handleClearFormFinalizadoraLoja();
      setValue('tipo_finalizadora', '');

      return toast.success(message);
    }

    const res = await api.put(
      `/finalizadora/${upsert.cod_finalizadora}`,
      upsert,
    );
    const { message } = res.data;

    onReturnSearch();
    return toast.success(message);
  });

  const onDelete = async () => {
    const codFinalizadora = formFinalizadora.cod_finalizadora.value;
    const res = await api.delete(`/finalizadora/${codFinalizadora}`);
    const { message } = res.data;

    onReturnSearch();

    return toast.success(message);
  };

  return (
    <Container>
      {showSearch && (
        <Search
          codTela={39}
          newData={() => {
            getLojas();
            setShowSearch(false);
          }}
          onRowClick={onRowClick}
        />
      )}
      {!showSearch && (
        <FormDefault
          codTela={39}
          title="Cadastro de Finalizadora"
          codigoRegistro={[
            {
              des_campo: 'Código',
              value: getValues('cod_finalizadora'),
            },
          ]}
          onSave={async () => {
            await onSave();
          }}
          isUpdate={isUpdate}
          onCancel={() => onCancel()}
          onNew={() => {
            onNew();
            setValue('tipo_finalizadora', '');
          }}
          isDelete={false}
          onDelete={() => onDelete()}
          onClearFields={() => {
            handleClearForm();
            handleClearFormFinalizadoraLoja();
            setValue('tipo_finalizadora', '');
          }}
          onReturnSearch={() => onReturnSearch()}
          showSwitch={false}
        >
          <Form />
          <Table />
        </FormDefault>
      )}
    </Container>
  );
};

export default FinalizadoraContent;
