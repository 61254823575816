import React, { useEffect } from 'react';
import { ButtonBackHome } from './components/ButtonBackHome';
import { ButtonFullScreen } from './components/ButtonFullScreen';
import { Footer } from './components/Footer';
import { ModalAbertura } from './components/Modal/ModalAbertura';
import { ModalBuscarVendas } from './components/Modal/ModalBuscarVendas';
import { ModalCadastroCliente } from './components/Modal/ModalCadastroCliente';
// import { ModalCertificado } from './components/Modal/ModalCertificado';
import { ModalFunctions } from './components/Modal/ModalFunctions';
import { ModalOperador } from './components/Modal/ModalOperador';
import { ModalPedidoRegistrado } from './components/Modal/ModalPedidoRegistrado';
import { ModalReforco } from './components/Modal/ModalReforco';
import { ModalSangria } from './components/Modal/ModalSangria';
import { Tab } from './components/Tab';
import { TabFinalizacao } from './components/TabFinalizacao';
import { TabProdutos } from './components/TabProdutos';
import { TableHeader } from './components/TableHeader';
import { Tabs } from './components/Tabs';
import { usePdvOnline } from './hooks/usePdvOnline';
import { MainContainer, MainContent, MainHeader, Spacer } from './styles';
import { tabKeysMappings } from './utils/tabKeysMappings';
import { ModalEnviarEmailComprovanteVenda } from './components/Modal/ModalEnviarEmailComprovanteVenda';
import { ModalLeituraDeCaixa } from './components/Modal/ModalLeituraDeCaixa';
import { ModalImportarPedido } from './components/Modal/ModalImportarPedido';

const PdvOnlineContent: React.FC = () => {
  const { isPdvOpen, handleActiveTab, mainContentRef, isModalOpen } =
    usePdvOnline();

  useEffect(() => {
    const handleTab = (event: KeyboardEvent) => {
      const tabSelector = tabKeysMappings[event.key];

      if (event.altKey && tabSelector) {
        const tabElement: HTMLButtonElement | null =
          document.querySelector(tabSelector);

        if (tabElement) {
          tabElement.click();
          handleActiveTab(event.key === '1' ? 'PRODUTOS' : 'FINALIZACAO');
        }
      }
    };

    document.addEventListener('keydown', handleTab);
    return () => document.removeEventListener('keydown', handleTab);
  }, [handleActiveTab]);

  const openingPdv = !isPdvOpen && isModalOpen.isOpen;

  return (
    <MainContainer>
      <MainHeader openingPdv={openingPdv}>
        <ButtonFullScreen openingPdv={openingPdv} />
        <ButtonBackHome openingPdv={openingPdv} />
      </MainHeader>

      <ModalOperador />
      {/* <ModalCertificado /> */}
      <ModalAbertura />
      <ModalFunctions />
      <ModalCadastroCliente />
      <ModalReforco />
      <ModalSangria />
      <ModalPedidoRegistrado />
      <ModalBuscarVendas />
      <ModalEnviarEmailComprovanteVenda />
      <ModalLeituraDeCaixa />
      <ModalImportarPedido />

      <MainContent ref={mainContentRef}>
        <h1>PDV ONLINE</h1>

        {isPdvOpen && (
          <section>
            <TableHeader />

            <div style={{ marginTop: '0.9375rem' }}>
              <Tabs initialTab={0} bgColor="#f2f2f2">
                <Tab label="Produtos (Alt + 1)">
                  <TabProdutos />
                </Tab>
                <Tab label="Finalização (Alt + 2)">
                  <TabFinalizacao />
                </Tab>
              </Tabs>
            </div>
          </section>
        )}
      </MainContent>
      <Spacer />
      <Footer />
    </MainContainer>
  );
};

export default PdvOnlineContent;
