import React, { useCallback, useEffect, useState } from 'react';
import { GridRowParams } from '@material-ui/data-grid';

import Spinner from '@atlaskit/spinner';
import { toast } from 'react-toastify';
import Search from '~/components/Search';

import { Container } from './styles';
import FormDefault from '~/components/FormDefault';
import api from '~/services/api';
import { ICondicao } from './types';
import InputText from '~/components/Inputs/InputText';
import { useForm } from 'react-hook-form';
import { schema } from './Validations';
import { yupResolver } from '@hookform/resolvers/yup';

const CadastroDeCondicao: React.FC = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const [iniInicializado, setInitInicializado] = useState(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);
  const [formData, setFormData] = useState<ICondicao>({
    cod_condicao: {
      value: '',
      isInvalid: false,
      isRequired: false,
    },
    des_condicao: {
      value: '',
      isInvalid: true,
      isRequired: true,
    },
    des_definicao: {
      value: '',
      isInvalid: true,
      isRequired: true,
    },
  });

  const {
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  /**
   * Reseta form para criação de novo registro
   */
  const resetFormData = useCallback(() => {
    reset();
    setFormData({
      ...formData,
      des_condicao: {
        value: '',
        isInvalid: true,
        isRequired: true,
      },
      des_definicao: {
        value: '',
        isInvalid: true,
        isRequired: true,
      },
    });

    setInitInicializado(false);
  }, [formData, reset]);

  useEffect(() => {
    if (iniInicializado) setInitInicializado(false);
  }, [formData]);

  const handleChangeDescricao = useCallback(
    (val: string) => {
      setFormData({
        ...formData,
        des_condicao: {
          ...formData.des_condicao,
          value: val.trimStart(),
          isInvalid: val === '',
        },
      });

      setValue('des_condicao', val.trimStart());
    },
    [formData, setValue],
  );

  const handleChangeDefinicao = useCallback(
    (val: string, isInvalid: boolean) => {
      if (String(val).length <= 20) {
        setFormData({
          ...formData,
          des_definicao: {
            ...formData.des_definicao,
            value: val.trim(),
            isInvalid,
          },
        });

        setValue('des_definicao', val.trimStart());
      }
    },
    [formData, setValue],
  );
  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;

    const { cod_condicao, des_condicao, des_definicao } = row;
    setValue('des_condicao', des_condicao);
    setValue('des_definicao', des_definicao);

    setUpdate(true);
    setFormData({
      ...formData,
      cod_condicao: {
        value: cod_condicao,
        isInvalid: false,
        isRequired: false,
      },
      des_condicao: {
        value: des_condicao,
        isInvalid: false,
        isRequired: false,
      },
      des_definicao: {
        value: des_definicao,
        isInvalid: false,
        isRequired: false,
      },
    });

    setLoader(false);
    setShowSearch(false);
  };

  const onSave = handleSubmit(async (dataForm: any) => {
    if (isUpdate) {
      const { data } = await api.put(
        `/cadastro-de-condicao/${formData.cod_condicao.value}`,
        {
          des_definicao: dataForm.des_definicao,
          des_condicao: dataForm.des_condicao.trim(),
        },
      );
      if (data.success) {
        toast.success(data.message);
        resetFormData();
        setUpdate(false);
        setShowSearch(true);
        return;
      }
    }
    const { data } = await api.post('/cadastro-de-condicao', {
      des_definicao: dataForm.des_definicao,
      des_condicao: dataForm.des_condicao.trim(),
    });
    if (data.success) {
      toast.success(data.message);
      resetFormData();
      setShowSearch(false);
    }
  });

  const handleDelete = async () => {
    try {
      const res = await api.delete(
        `/cadastro-de-condicao/${formData.cod_condicao.value}`,
      );
      const { success, message } = res.data;
      if (!success) throw new Error(message);
      resetFormData();
      setUpdate(false);
      setShowSearch(true);
      toast.success(message);
    } catch (e: any) {
      if (e.status === 409) {
        return toast.warning(e.data.message);
      }
      toast.error(e.message);
    }
  };

  const onNew = () => {
    resetFormData();
    setUpdate(false);
  };

  if (loader) {
    return (
      <Container>
        <div className="w-100 h-100 d-flex justify-conten-center align-items-center">
          <Spinner />
        </div>
      </Container>
    );
  }

  return (
    <Container>
      {showSearch && (
        <Search
          newData={() => setShowSearch(false)}
          onRowClick={onRowClick}
          codTela={186}
        />
      )}

      {!showSearch && (
        <FormDefault
          codTela={186}
          title="Cadastro de Condição"
          codigoRegistro={[
            {
              des_campo: 'Código',
              value: formData.cod_condicao.value,
            },
          ]}
          onSave={async () => {
            await onSave();
          }}
          onCancel={() => {
            resetFormData();
            setShowSearch(true);
          }}
          isUpdate={isUpdate}
          onNew={() => onNew()}
          onDelete={handleDelete}
          onClearFields={() => resetFormData()}
          onReturnSearch={() => {
            setShowSearch(true);
            setUpdate(false);
            resetFormData();
          }}
          showSwitch={false}
        >
          <div className="row">
            <div className="col-sm-12 col-md-2">
              <InputText
                label="Definição"
                value={formData.des_definicao.value}
                maxLength={100}
                placeholder="Informe a Definição"
                toUpperCase
                isRequired
                setInvalid={!!errors.des_definicao}
                iniInicializado={!!errors.des_definicao}
                onChange={(newValue: string, isInvalid = true) => {
                  handleChangeDefinicao(newValue, isInvalid);
                  if (newValue === '') {
                    setFormData({
                      ...formData,
                      des_definicao: {
                        value: '',
                        isInvalid: true,
                        isRequired: true,
                      },
                    });
                  }
                }}
              />
            </div>
            <div className="col-sm-12 col-md-10">
              <InputText
                label="Descrição"
                value={formData.des_condicao.value}
                maxLength={100}
                placeholder="Informe a Descrição"
                toUpperCase
                isRequired
                setInvalid={!!errors.des_condicao}
                iniInicializado={!!errors.des_condicao}
                onChange={(newValue: string, isInvalid = true) => {
                  handleChangeDescricao(newValue);
                  if (newValue === '') {
                    setFormData({
                      ...formData,
                      des_condicao: {
                        value: '',
                        isInvalid: true,
                        isRequired: true,
                      },
                    });
                  }
                }}
              />
            </div>
          </div>
        </FormDefault>
      )}
    </Container>
  );
};

export default CadastroDeCondicao;
