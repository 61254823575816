import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import {
  BuscaProduto,
  InputMoney,
  InputNumber,
} from '~/components/NovosInputs';
import Separator from '~/components/Separator';
import { LojaContext } from '~/context/loja';
import { usePedidoVenda } from '~/pages/PedidoVenda/PedidoVendaContext';
import { GreenItemContainer } from '../styles';
import { ClearButton, ConfirmButton } from '~/components/Buttons';
import { Container, EntradaItensContainer, Subtotal } from './styles';
import { moneyFormat } from '~/utils/functions';

export const FormItens: React.FC = () => {
  const { codLoja } = useContext(LojaContext);
  const {
    formProduto: {
      register,
      handleSubmit,
      control,
      setValue,
      getValues,
      setError,
      setFocus,
      clearErrors,
      formState: { errors },
      watch,
      reset,
    },
    isUpdate,
    isDisabledPedido,
    setIsUpdate,
    isFormProdutoEditing,
    setIsFormProdutoEditing,
    resetFormProdutoData,
    handleAddProduto,
  } = usePedidoVenda();

  const [qtd_pedido, val_pedido, val_desconto, acrescimo] = watch([
    'qtd_pedido',
    'val_pedido',
    'val_desconto',
    'acrescimo',
  ]);
  const [subTotal, setSubTotal] = useState('0');
  const [subTotalIsNegative, setSubTotalIsNegative] = useState(false);

  const watch_produto = watch('produtos');
  /**
   * CALCULO SUB TOTAL
   */
  useEffect(() => {
    const formatedQtdEmbalagemVenda = qtd_pedido || 0;
    let formatedValorUnitario = val_pedido || 0;
    let formatedAcrescimo = acrescimo || 0;
    let formatedDesconto = val_desconto || 0;
    if (val_pedido && val_pedido !== '') {
      formatedValorUnitario = Number(
        String(val_pedido).replace('.', '').replace(',', '.'),
      );
    }
    if (acrescimo && acrescimo !== '') {
      formatedAcrescimo = Number(
        String(acrescimo).replace('.', '').replace(',', '.'),
      );
    }
    if (val_desconto && val_desconto !== '') {
      formatedDesconto = Number(
        String(val_desconto).replace('.', '').replace(',', '.'),
      );
    }
    const subTotalCalculado =
      formatedQtdEmbalagemVenda *
      (formatedValorUnitario - formatedDesconto + formatedAcrescimo);
    if (typeof subTotalCalculado === 'number') {
      const novoSubTotal = moneyFormat(String(subTotalCalculado), 2);
      setSubTotal(novoSubTotal);
      setValue('val_total', novoSubTotal);

      if (subTotalCalculado < 0) {
        setSubTotalIsNegative(true);
        return;
      }
      setSubTotalIsNegative(false);
    }
  }, [acrescimo, val_desconto, qtd_pedido, setValue, val_pedido]);
  return (
    <Container>
      <Separator labelText="Itens" sidePadding="0 0" />
      <Row className="d-flex align-items-end justify-content-between mb-2">
        <Col sm={12} md={10}>
          <BuscaProduto
            label="Buscar Produto"
            placeholder="Selecione..."
            name="produtos"
            register={register}
            isError={!!errors.produtos}
            disabled={isFormProdutoEditing.isEdit || isDisabledPedido}
            control={control}
            setValue={setValue}
            buscaNasLojas={[getValues('cod_loja')?.value || codLoja]}
            customOptions={{
              hideBuscarPor: true,
              buscarPor: ['Produto'],
              showSelecionarItensContendo: false,
            }}
            getProduct={(selected: any) => {
              setValue(
                'val_pedido',
                moneyFormat(
                  selected[0].val_preco ? String(selected[0].val_preco) : '0',
                ),
              );
              setValue('produtos', {
                ...selected[0],
                value: selected[0].cod_produto,
                label: selected[0].des_produto,
              });
              setFocus('qtd_pedido');
            }}
            style={{ width: '100%' }}
          />
        </Col>
        <Col sm={12} md={2}>
          <ConfirmButton
            onClick={() => {
              setIsFormProdutoEditing({ isEdit: false, uuid: undefined });
              resetFormProdutoData();
              setSubTotal('0');
            }}
            style={{
              color: '#ffffff',
              background: '#cf9d08',
              width: '100%',
              marginTop: '28px',
            }}
            disabled={!watch_produto}
          >
            Limpar
          </ConfirmButton>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col sm={12} md={4}>
          <EntradaItensContainer>
            <Row className="bg-green">
              <Col sm={12} md={6}>
                <InputNumber
                  label="Quantidade"
                  maxLength={7}
                  max={999}
                  min={1}
                  placeholder="0"
                  name="qtd_pedido"
                  register={register}
                  disabled={!watch_produto}
                  isError={!!errors.qtd_pedido}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      handleAddProduto();
                      clearErrors();
                    }
                  }}
                />
              </Col>
              <Col sm={12} md={6}>
                <InputMoney
                  label="Valor Unitário"
                  placeholder="0,00"
                  min={0}
                  decimals={2}
                  maxLength={10}
                  name="val_pedido"
                  register={register}
                  control={control}
                  disabled={!watch_produto}
                  isError={!!errors.val_pedido}
                  showIcon={false}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      handleAddProduto();
                      clearErrors();
                    }
                  }}
                />
              </Col>
            </Row>
          </EntradaItensContainer>
        </Col>
        <Col sm={12} md={2}>
          <InputMoney
            label="Desconto"
            placeholder="0,00"
            min={0}
            decimals={2}
            name="val_desconto"
            register={register}
            control={control}
            disabled={!watch_produto}
            isError={!!errors.val_desconto}
            showIcon={false}
          />
        </Col>
        <Col sm={12} md={2}>
          <InputMoney
            label="Acréscimo"
            placeholder="0,00"
            min={0}
            decimals={2}
            name="acrescimo"
            register={register}
            control={control}
            disabled={!watch_produto}
            isError={!!errors.acrescimo}
            showIcon={false}
          />
        </Col>
        <Col
          sm={12}
          md={2}
          className={`sub-total ${subTotalIsNegative ? 'negative' : ''}`}
        >
          <span
            title={`${subTotalIsNegative ? 'SubTotal deve ser positivo' : ''}`}
          >
            SubTotal: {subTotal}
          </span>
        </Col>
        <Col sm={12} md={2} className="d-flex justify-content-end add">
          <Button
            variant="success"
            className="mt-auto mb-2 w-100"
            onClick={handleAddProduto}
            disabled={!watch_produto}
          >
            Adicionar
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
