import React, { useEffect } from 'react';
import { moneyFormat } from '~/utils/functions';
import { usePdvOnline } from '../../hooks/usePdvOnline';
import { CardTotalizadores, TotalizadoresContainer } from './styles';
import { calcRecebido } from './utils/calcRecebido';

export const Totalizadores: React.FC = () => {
  const { subTotal, finalizadorasTable, onTroco, onResta, troco, resta } =
    usePdvOnline();

  const recebido = calcRecebido(finalizadorasTable);
  const calculo = subTotal - recebido;

  useEffect(() => {
    onResta(calculo < 0 ? 0 : Number(calculo.toFixed(2)));
  }, [calculo, onResta]);

  useEffect(() => {
    if (resta >= 0) return onTroco(0);

    if (finalizadorasTable.length <= 0) return onTroco(0);

    if (recebido > subTotal) {
      onTroco(calculo);
    }
  }, [calculo, finalizadorasTable.length, onTroco, recebido, resta, subTotal]);

  return (
    <TotalizadoresContainer>
      <CardTotalizadores bgColor="#C9DEF9">
        <p>Subtotal: R$ {moneyFormat(subTotal.toString())}</p>
      </CardTotalizadores>
      <CardTotalizadores bgColor="#BFE8C1">
        <p>Recebido: R$ {moneyFormat(recebido.toString())}</p>
      </CardTotalizadores>
      <CardTotalizadores bgColor="#FFE6C1">
        <p>Resta: R$ {moneyFormat(resta.toString())}</p>
      </CardTotalizadores>
      <CardTotalizadores bgColor="#F3CAC9">
        <p>Troco: R$ {moneyFormat(troco.toString())}</p>
      </CardTotalizadores>
    </TotalizadoresContainer>
  );
};
