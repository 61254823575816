import React, { useCallback, useState } from 'react';
import { HeaderScreen } from '../HeaderScreen';
import { CustomButtonNovo } from '~/components/Buttons/CustomButtonNovo';
import { useMeuPlano } from '../../hooks/useMeuPlano';
import { CancelSubContainer } from './styles';
import { format } from 'date-fns';
import api from '~/services/api';
import { toast } from 'react-toastify';

export const CancelSubscription: React.FC = () => {
  const [confirmCancel, setConfirmCancel] = useState(false);
  const { handleScreen, cicloAtual } = useMeuPlano();
  const [showLoading, setShowLoading] = useState(false);

  const handleBackAcc = useCallback(() => {
    handleScreen('GERENCIAR-ASSINATURA');
    setConfirmCancel(false);
  }, [handleScreen]);

  const handleContinue = useCallback(async () => {
    try {
      setShowLoading(true);

      const response = await api.delete('/meu-plano/cancel-subscription/');

      if (!response.data.success) {
        toast.error('Falha no cancelamento da assinatura.');
        return;
      }

      setConfirmCancel(true);
    } catch (error) {
      console.error(error);
    } finally {
      setShowLoading(false);
    }
  }, []);

  const getAlerts = useCallback(() => {
    return [
      {
        alert: 1,
        message: `Se você cancelar, o acesso será mantido apenas até o dia ${
          cicloAtual.dta_fim &&
          format(new Date(cicloAtual.dta_fim), 'dd/MM/yyyy')
        }.`,
      },
      {
        alert: 2,
        message:
          'Após isso, sua conta será encerrada e todos os dados fiscais obrigatórios serão entregues de forma eletrônica.',
      },
      { alert: 3, message: 'Não haverá mais suporte após esse período.' },
      {
        alert: 4,
        message:
          'Sua base e seus dados serão excluídos de forma permanente e irreversível.',
      },
    ];
  }, [cicloAtual]);

  return (
    <CancelSubContainer>
      <HeaderScreen showButtonVoltar />
      {!confirmCancel && (
        <div className="content-alerts">
          <div className="alerts">
            {getAlerts().map((alert) => (
              <div key={alert.alert} className="alert-item">
                <span className="alert-circle">{alert.alert}</span>
                <p className="alert-message">{alert.message}</p>
              </div>
            ))}
          </div>
          <div className="content-buttons">
            <CustomButtonNovo
              label="Voltar à conta"
              width="10.625rem"
              onClick={handleBackAcc}
              disabled={showLoading}
            />
            <CustomButtonNovo
              label="Continue para cancelar"
              outline
              style={{ backgroundColor: '#BF5050' }}
              width="10.625rem"
              onClick={handleContinue}
              disabled={showLoading}
              showLoadingIcon={showLoading}
            />
          </div>
        </div>
      )}
      {confirmCancel && (
        <div className="confirm-cancel">
          <p>
            Sua contratação foi encerrada, o acesso será mantido apenas até o
            dia {format(new Date(cicloAtual.dta_fim), 'dd/MM/yyyy')}.
          </p>
          <CustomButtonNovo label="OK" onClick={handleBackAcc} />
        </div>
      )}
    </CancelSubContainer>
  );
};
