import { differenceInDays, format, isBefore, parseISO } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row, Alert } from 'react-bootstrap';
import { InputDate, InputMoney, InputSelect } from '~/components/NovosInputs';
import { useDevolucaoSaidaNFEForn } from '~/pages/DevolucaoESaidaNFDeFornecedor/DevolucaoSaidaNFEFornContext';
import {
  Parcela,
  SelectType,
} from '~/pages/DevolucaoESaidaNFDeFornecedor/protocols';

import clearForm from './clearForm.json';
import { formatCurrencyAsText, transformAsCurrency } from '~/utils/functions';
import Separator from '~/components/Separator';
import { ButtonItens } from './styles';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import api from '~/services/api';
import { toast } from 'react-toastify';
import { CalcDataCondicao } from '~/pages/DevolucaoESaidaNFDeFornecedor/functions/Procedures';
import { getFinalizadora } from '~/pages/DevolucaoESaidaNFDeFornecedor/services';
import { deleteItens, insertOrUpdate } from '~/utils/masterDetail';
import { nanoid } from 'nanoid';
import { FaEraser, FaSync } from 'react-icons/fa';
import { CustomButtonNovo } from '~/components/Buttons/CustomButtonNovo';
import { IoMdAddCircleOutline } from 'react-icons/io';

const MySwal = withReactContent(Swal);

export const FormParcelas: React.FC = () => {
  const [datasNaoInformadas, setDatasNaoInformadas] = useState<boolean>(false);
  const [finalizadoras, setFinalizadoras] = useState<SelectType[]>([]);

  const {
    getValues,
    setCurrentTab,
    produtos,
    parcelasFinanceiro,
    setParcelasFinanceiro,
    subTotais,
    isUpdate,
    formFinanceiro: {
      register,
      handleSubmit,
      control,
      reset,
      setValue,
      getValues: getValuesFinanceiro,
      formState: formStateFinanceiro,
      clearErrors,
      setError,
    },
    masterDetail,
    setMasterDetail,
    uuidParcela,
    setUuidParcela,
    codLoja,
  } = useDevolucaoSaidaNFEForn();

  useEffect(() => {
    (async () => {
      const options = await getFinalizadora(codLoja);
      setFinalizadoras(options);
    })();
  }, [codLoja]);

  useEffect(() => {
    if (parcelasFinanceiro.length !== 0) {
      const totalParcelas = parcelasFinanceiro.reduce(
        (acc, parcela) => acc + parcela.val_parcela,
        0,
      );
      const resultado = subTotais.totalNf - totalParcelas;
      setTimeout(() => {
        setValue(
          'val_financeiro',
          formatCurrencyAsText(resultado > 0 ? resultado : 0),
        );
      }, 20);
    } else {
      setValue('val_financeiro', formatCurrencyAsText(subTotais.totalNf));
    }
  }, [parcelasFinanceiro, subTotais.totalNf]);

  const handleClearForm = useCallback(() => {
    reset(clearForm);
    setDatasNaoInformadas(false);
    setUuidParcela(undefined);
  }, [reset]);

  const calcularDiasEntreDatas = useCallback(
    (dtaVencimento: string, dtaEmissao: string): number => {
      if (!dtaVencimento || !dtaEmissao) return 0;

      const dataVencimento = parseISO(dtaVencimento);
      const dataEmissao = parseISO(dtaEmissao);

      return differenceInDays(dataVencimento, dataEmissao);
    },
    [],
  );

  const validarDatas = useCallback(
    (dtaVencimento: string, dtaEmissao: string): boolean => {
      if (!dtaVencimento || !dtaEmissao) return false;

      const dataVencimento = parseISO(dtaVencimento);
      const dataEmissao = parseISO(dtaEmissao);

      return !isBefore(dataVencimento, dataEmissao);
    },
    [],
  );

  const formatToMonetaryValue = useCallback((input: number | string) => {
    if (typeof input === 'number') return input;

    const cleanedInput = input.replace(/[^0-9.,]/g, '');
    const standardizedInput = cleanedInput.replace(',', '.');
    const withoutLeadingZeros = standardizedInput.replace(/^0+(?!\.)/, '');
    const monetaryValue = parseFloat(withoutLeadingZeros);

    if (Number.isNaN(monetaryValue)) return 0;
    return monetaryValue;
  }, []);

  const handleAddParcela = handleSubmit(async () => {
    const uuid = uuidParcela?.uuid === undefined ? nanoid() : uuidParcela?.uuid;
    const { finalizadora, dta_vencimento, val_financeiro } =
      getValuesFinanceiro();

    const dta_emissao = getValues('dta_emissao');

    if (!validarDatas(dta_vencimento, dta_emissao)) {
      setError('dta_vencimento', { type: 'required' }, { shouldFocus: true });
      toast.warning(
        'A data de vencimento não pode ser anterior à data de emissão.',
      );
      return;
    }

    const registroDuplicado = parcelasFinanceiro.some(
      (parcela) =>
        parcela.cod_finalizadora === finalizadora.value &&
        parcela.dta_vencimento === dta_vencimento,
    );

    if (registroDuplicado) {
      const { isConfirmed } = await MySwal.fire({
        title: 'Já existe uma parcela para esse dia e finalizadora.',
        text: 'Deseja editar a parcela já existente?',
        showCancelButton: true,
        confirmButtonColor: '#07289e',
        cancelButtonColor: '#ff7b7b',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Cancelar',
      });

      if (!isConfirmed) return;

      const itemDuplicado = parcelasFinanceiro.find(
        (item) =>
          item.cod_finalizadora === finalizadora.value &&
          item.dta_vencimento === dta_vencimento,
      );

      if (itemDuplicado) itemDuplicado.val_parcela = val_financeiro;
    }

    const data: Parcela = {
      uuid,
      id: uuid,
      cod_parcela: uuidParcela?.cod_parcela,
      num_registro: parcelasFinanceiro.length + 1,
      cod_condicao: 2,
      des_condicao: 'DD - DIAS DA DATA',
      num_condicao: calcularDiasEntreDatas(dta_vencimento, dta_emissao),
      cod_finalizadora: finalizadora.value,
      des_finalizadora: `${finalizadora.value} - ${finalizadora.label}`,
      dta_vencimento,
      val_parcela: transformAsCurrency(val_financeiro),
    };
    const parcelasData: any[] = await insertOrUpdate(
      'parcelas',
      data,
      masterDetail,
      setMasterDetail,
    );

    const othersParcels = parcelasData.filter((p) => p.uuid !== uuid);
    const parcels = [...othersParcels, ...parcelasData];

    parcels.forEach((parcel) => {
      deleteItens('parcelas', parcel.uuid, masterDetail, setMasterDetail);
    });

    parcels.sort((a, b) => a.num_condicao - b.num_condicao);

    const seen = new Set<string>();
    const result: any[] = [];

    parcels.forEach((op) => {
      const key = [
        'cod_condicao',
        'cod_finalizadora',
        'cod_parcela',
        'des_condicao',
        'num_condicao',
      ]
        .map((k) => op[k])
        .join('|');

      if (!seen.has(key)) {
        seen.add(key);
        result.push(op);
      }
    });

    const parcelCorrigida = result.map((p) => {
      const [firstPart, ...restParts] = p.des_finalizadora.split(' - ');
      const lastPart = restParts.length ? restParts[restParts.length - 1] : '';
      const description = restParts.length
        ? `${firstPart} - ${lastPart}`
        : firstPart;

      return {
        ...p,
        des_finalizadora: description,
        val_parcela: formatToMonetaryValue(p.val_parcela || 0),
      };
    });

    parcelCorrigida.forEach(async (p) => {
      await insertOrUpdate('parcelas', p, masterDetail, setMasterDetail);
    });
    setParcelasFinanceiro(parcelCorrigida);
    setTimeout(() => {
      handleClearForm();

      if (parcelCorrigida.length !== 0) {
        const totalParcelas = parcelCorrigida.reduce(
          (acc, parcela) => acc + parcela.val_parcela,
          0,
        );
        const resultado = subTotais.totalNf - totalParcelas;
        setTimeout(() => {
          setValue(
            'val_financeiro',
            formatCurrencyAsText(resultado > 0 ? resultado : 0),
          );
        }, 20);
      } else {
        setValue('val_financeiro', formatCurrencyAsText(subTotais.totalNf));
      }
    }, 50);
  });

  const removeParcelasRecalculo = () => {
    const parcelasFind = masterDetail.find(
      (item) => item.obj_name === 'parcelas',
    );
    parcelasFind?.itens.insert.forEach((parcela) => {
      deleteItens('parcelas', parcela.uuid, masterDetail, setMasterDetail);
    });
  };

  const ajustarArrayRecalculoFinanceiro = useCallback((data: any[]) => {
    return data.map((item) => ({
      ...item,
      des_condicao: `${item.des_definicao} - ${item.des_condicao}`,
      des_finalizadora: `${item.cod_finalizadora} - ${item.des_finalizadora}`,
    }));
  }, []);

  const handleRecalculaFinanceiro = async () => {
    const isInValid = await validation();
    if (isInValid) return;
    const { cod_pessoa } = getValues('cod_pessoa');
    const { tipo_operacao } = getValues('cod_perfil');
    let realizaBuscaParcelas = true;
    if (parcelasFinanceiro.length !== 0) {
      await MySwal.fire({
        title:
          'Deseja pesquisar novamente as condições de pagamento deste parceiro/perfil?',
        text: 'As parcelas atuais poderão sofrer alteração de prazos, formas de pagamento e valores.',
        showCancelButton: true,
        confirmButtonColor: '#8850BF',
        cancelButtonColor: '#DE350B',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then((result) => {
        if (result.isConfirmed) {
          realizaBuscaParcelas = true;
        } else {
          realizaBuscaParcelas = false;
        }
      });
    }
    if (realizaBuscaParcelas) {
      try {
        const res = await api.get(
          `/nf-financeiro/parceiro/${cod_pessoa}/tipo-condicao/${tipo_operacao}`,
        );
        const { data } = res.data;
        if (data.length > 0) {
          const {
            cod_perfil: { cod_cc, cod_categoria },
            dta_emissao,
            dta_entrada,
          } = getValues();

          const { totalNf } = subTotais;
          const valParcela = data.length <= 0 ? 0 : totalNf / data.length;
          const options = data.map((item: any, index: number) => {
            const uuid =
              uuidParcela?.uuid === undefined ? nanoid() : uuidParcela?.uuid;
            const dta_vencimento = CalcDataCondicao(
              cod_cc,
              dta_emissao,
              dta_entrada,
              item.num_condicao,
              0,
            );
            return {
              uuid,
              id: uuid,
              cod_parcela: uuidParcela?.cod_parcela,
              num_registro: index + 1,
              num_condicao: item.num_condicao,
              cod_condicao: item.cod_condicao,
              des_condicao: `${item.des_definicao} - ${item.des_condicao}`,
              des_definicao: item.des_definicao,
              val_parcela: transformAsCurrency(valParcela),
              cod_finalizadora: item.cod_finalizadora,
              des_finalizadora: `${item.cod_finalizadora} - ${item.des_finalizadora}`,
              cod_cc,
              cod_categoria,
              dta_vencimento: `${format(
                new Date(dta_vencimento),
                'yyyy-MM-dd',
              )}`,

              flg_quitado: false,
            };
          });
          removeParcelasRecalculo();
          options.forEach(async (opt: any) => {
            await insertOrUpdate(
              'parcelas',
              opt,
              masterDetail,
              setMasterDetail,
            );
          });
          setParcelasFinanceiro(ajustarArrayRecalculoFinanceiro(options));
        } else {
          const {
            cod_perfil: { cod_cc, cod_categoria, cod_perfil },
            dta_emissao,
            dta_entrada,
          } = getValues();
          const apiResponse = await api.get(`/busca-parcelas/${cod_perfil}`);
          const { data: dataReponse } = apiResponse.data;
          if (dataReponse.length > 0) {
            const { totalNf } = subTotais;
            const valParcela =
              dataReponse.length <= 0 ? 0 : totalNf / dataReponse.length;
            const options = dataReponse.map((item: any, index: number) => {
              const uuid =
                uuidParcela?.uuid === undefined ? nanoid() : uuidParcela?.uuid;
              const dta_vencimento = CalcDataCondicao(
                cod_cc,
                dta_emissao,
                dta_entrada,
                item.num_condicao,
                0,
              );
              return {
                uuid,
                id: uuid,
                cod_parcela: uuidParcela?.cod_parcela,
                num_registro: index + 1,
                num_condicao: item.num_condicao,
                cod_condicao: item.cod_condicao,
                des_condicao: `${item.des_definicao} - ${item.des_condicao}`,
                des_definicao: item.des_definicao,
                val_parcela: transformAsCurrency(valParcela),
                cod_finalizadora: item.cod_finalizadora,
                des_finalizadora: `${item.cod_finalizadora} - ${item.des_finalizadora}`,
                cod_cc,
                cod_categoria,
                dta_vencimento: `${format(
                  new Date(dta_vencimento),
                  'yyyy-MM-dd',
                )}`,
                flg_quitado: false,
              };
            });
            removeParcelasRecalculo();
            options.forEach(async (opt: any) => {
              await insertOrUpdate(
                'parcelas',
                opt,
                masterDetail,
                setMasterDetail,
              );
            });
            setParcelasFinanceiro(ajustarArrayRecalculoFinanceiro(options));
          } else {
            Swal.fire({
              text: `Nenhuma condição de pagamento localizada para esse parceiro ou perfil.`,
              icon: 'info',
              showConfirmButton: true,
              confirmButtonText: 'OK',
            });
          }
        }
      } catch (error: any) {
        if (error.data && error.data.message) {
          toast.error(error.data.message);
          return;
        }
        toast.error(String(error));
      }
    }
  };

  const validation = useCallback(async () => {
    const cod_pessoa = getValues('cod_pessoa');
    const dataEmissao = getValues('dta_emissao');
    const dataEntrada = getValues('dta_entrada');

    if (cod_pessoa === undefined || cod_pessoa === '') {
      toast.warning('Fornecedor deve ser selecionado');
      return true;
    }
    if (dataEmissao === '' || dataEmissao === undefined) {
      toast.warning('Data de Emissão deve ser informada.');
      return true;
    }

    if (dataEntrada === '') {
      toast.warning('Data de Saída deve ser informada');
      return true;
    }
    if (produtos.length === 0) {
      toast.warning('Item(s) da NF deve(m) ser informado');
      return true;
    }
    return false;
  }, [getValues, produtos]);

  return (
    <>
      <Separator labelText="Financeiro" childrenWidth="150px">
        <ButtonItens
          type="button"
          onClick={() => {
            if (!isUpdate) {
              handleRecalculaFinanceiro();
            }
          }}
        >
          <FaSync color="#28a745" />
          <span>(Re) Calcular</span>
        </ButtonItens>
      </Separator>
      <Row
        className="d-flex align-items-center position-relative"
        style={{ zIndex: 3 }}
      >
        <Col sm={12} md={4}>
          <InputSelect
            label="Finalizadora"
            maxLength={50}
            placeholder="Selecione..."
            name="finalizadora"
            register={register}
            disabled={isUpdate}
            isError={!!formStateFinanceiro.errors.finalizadora}
            control={control}
            changeSelected={(selected) => {
              clearErrors('finalizadora');
              setValue('finalizadora', selected);
            }}
            options={finalizadoras}
          />
        </Col>
        <Col sm={12} md={2}>
          <InputDate
            register={register}
            label="Vencimento"
            name="dta_vencimento"
            disabled={isUpdate}
            control={control}
            isError={!!formStateFinanceiro.errors.dta_vencimento}
          />
        </Col>
        <Col sm={12} md={2}>
          <InputMoney
            label="Valor"
            placeholder="0,00"
            max={999999.01}
            min={0.0}
            disabled={isUpdate}
            name="val_financeiro"
            register={register}
            isError={!!formStateFinanceiro.errors.val_financeiro}
          />
        </Col>
        <Col
          md={2}
          sm={12}
          style={{ display: 'flex', height: '70px', alignItems: 'flex-end' }}
        >
          <CustomButtonNovo
            onClick={handleAddParcela}
            label="Adicionar"
            icon={IoMdAddCircleOutline}
            disabled={isUpdate}
            variant="confirm"
            width="100%"
          />
        </Col>
        <Col
          md={2}
          sm={12}
          style={{ display: 'flex', height: '70px', alignItems: 'flex-end' }}
        >
          <CustomButtonNovo
            onClick={handleClearForm}
            label="Limpar"
            icon={FaEraser}
            disabled={isUpdate}
            variant="clear"
            width="100%"
          />
        </Col>

        <Col sm={12} style={{ marginTop: '15px' }}>
          {datasNaoInformadas === true && (
            <Alert variant="warning">
              Para o cálculo do vencimento é necessário{' '}
              <b
                onClick={() => {
                  setCurrentTab('nfe');
                }}
                style={{ cursor: 'pointer' }}
              >
                informar
              </b>{' '}
              as datas de emissão e saída da NF.
            </Alert>
          )}
        </Col>
      </Row>
    </>
  );
};
