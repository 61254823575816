import styled from 'styled-components';

export const MudarDadosCobranca = styled.section`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-top: 25px;
    }
  }
`;
