import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled.div.attrs({
  className: 'container',
})`
  .MuiLinearProgress-colorPrimary {
    background-color: #fff !important;
  }
  .MuiLinearProgress-barColorPrimary {
    background-color: #c2a0f8 !important;
  }
  .containerMaterial {
    min-width: fit-content;
    width: auto;
    padding: 0px 4px;
    order: 1;
    margin: 0px 64px 2px 0px;
  }
  .linkMaterial {
    font-size: 14px;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ContainerLoader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerTable = styled(motion.div)`
  width: 100%;
  .MuiBadge-badge {
    display: none;
  }
`;

export const ContainerLimit = styled.div`
  width: 250px;
  display: grid;
  z-index: 10;
  flex-wrap: nowrap;
  gap: 10px;
  margin-bottom: 0.625rem !important;
  grid-template-columns: auto 1fr;
  align-items: center;
  label {
    margin-top: 10px;
  }

  @media screen and (max-width: 75rem) {
    margin-right: 0.625rem !important;
  }
  @media screen and (max-width: 47.125rem) {
    margin-left: 0.625rem !important;
  }
`;

export const ContainerCard = styled.div`
  position: relative;

  .btn-edit {
    position: absolute;
    top: 20px;
    right: 20px;
    border: none;
    background-color: rgba(0, 0, 0, 0);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    transition: all 0.5s linear;

    &:hover {
      background-color: rgba(0, 0, 0, 0.16);
    }
  }
`;

export const ContainerButtonsHandler = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
`;

export const SectionCards = styled.div`
  width: 100%;
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  /* border-top: 1px solid rgba(0, 0, 0, 0.2); */
  height: 100%;
`;

export const CardS = styled(motion.button)`
  width: 200px;
  height: 200px;
  border-radius: 0.25rem;
  margin: 0px 10px 10px 0px;
  border: none;
  transition: all 0.25s ease-in-out;
  font-family: 'Poppins', 'Roboto', sans-serif;

  color: #fff;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.16);
  opacity: 0.8;
  transform: scale(0.8);
  &.novo {
    background-color: ${({ theme: { colors } }) => colors.white};
    color: ${({ theme: { colors } }) => colors.primary};
    border: 1px solid ${({ theme: { colors } }) => colors.primary};
  }
  &:focus {
    outline: none;
    opacity: 1;
  }
  &:hover {
    opacity: 1;

    transform: scale(1.05);
  }

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  h3 {
    font-size: 16px;
    text-align: center;
    font-weight: 500;
  }

  ul {
    position: absolute;
    bottom: 0px;
    margin: 0;
    left: 0px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 63px;
    padding: 0px;
    font-size: 12px;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 10px;

    li {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 18px;
      font-weight: 500;

      svg {
        margin-right: 5px;
        font-size: 24px;
      }

      b {
        margin-right: 5px;
      }
    }
  }
`;

interface HeaderTopCardProps {
  backgroundColor: string;
}

export const HeaderTopCard = styled.div<HeaderTopCardProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
  background-color: ${(props) => props.backgroundColor};
  .css-13fq05f {
    background-color: transparent !important;
    border: none;
  }
  input.css-16kwnwk {
    background-color: transparent !important;
    font-size: 24px;
    border: none;
    color: #313638;
    &:focus {
      border: 1px solid #ccc;
    }
  }
`;
