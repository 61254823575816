import styled from 'styled-components';
import { BadgeSize } from '../../types/BadgeSize';

export const StyledBadge = styled.span<{ background: string; size: BadgeSize }>`
  display: inline-block;
  padding: 0.4em 0.8em;
  border-radius: 0.5em;
  color: #fff;
  font-weight: bold;
  text-align: center;
  background: ${({ background }) => background};

  ${({ size }) => {
    switch (size) {
      case 'extra-small':
        return 'font-size: 0.6rem;';
      case 'small':
        return 'font-size: 0.8rem;';
      case 'medium':
        return 'font-size: 1rem;';
      case 'large':
        return 'font-size: 1.2rem;';
      case 'extra-large':
        return 'font-size: 1.5rem;';
      default:
        return 'font-size: 1rem;';
    }
  }}
`;
