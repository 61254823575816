import React, { useState, useEffect, ChangeEvent, useContext } from 'react';
import { toast } from 'react-toastify';
import { Col, Row } from 'react-bootstrap';
import api from '~/services/api';
import ToggleDefault from '~/components/ToggleDefault';
import Separator from '~/components/Separator';
import { BuscaNcm } from './BuscaNcm';

import {
  INcm,
  tipoNaoIncidente,
  tipoPisCofinsEntrada,
  tipoPisCofinsSaida,
  tipoDivisorPreco,
  tipoIPI,
} from '~/pages/Produto/types';

import { useProduto } from '~/pages/Produto/ProdutoContext';

import ModalTransferir from './Gtin/ModalTransferir';

import { Gtin } from './Gtin';

import { Plu } from './Plu';
import {
  formProdutoBlankNew,
  MapeamentoValores,
  Select,
} from '../../protocols';

import {
  InputText,
  InputSelect,
  InputPeso,
  InputNumber2,
} from '~/components/NovosInputs';
import { SelectType } from '~/components/NovosInputs/InputSelect/protocols';
import { moedaFormatada } from '~/components/Inputs/InputPercent2/formatMask';
import { LojaContext } from '~/context/loja';
import { Tab, Tabs } from 'react-bootstrap';
import { AbaIcms } from './Abas/AbaIcms';
import { AbaIpi } from './Abas/AbaIpi';
import { AbaPisCofins } from './Abas/AbaPisCofins';
import { moneyFormat } from '~/utils/functions';
import { useQueryClient } from 'react-query';

const AbaGeral: React.FC = () => {
  const {
    formProduto,
    changeFormProduto,
    formProdutoLoja,
    changeFormProdutoLoja,
    mainLoja,
    tipoGtin,
    setTipoGtin,
    register,
    control,
    setValue,
    getValues,
    clearErrors,
    watch,
    formState: { errors },
    clearFields,
    handleResetClearField,
    setTributacoesEntrada,
    setTributacoesSaida,
    tributacoesEntrada,
    tributacoesSaida,
    ncmIsFromTheProduct,
    setNcmIsFromTheProduct,
    refSaida,
    optionsEntradaPisCofins,
    setOptionsEntradaPisCofins,
    setIpiHabilitar,
    isUpdate,
    tabGeral,
    setTabGeral,
    removeEditNaoPisCofinsCache,
    setHasErroTipoIncidente,
    tipoItem,
    idContabeis,
    handleTipoItemAndIdCtb,
    getDepartamentos,
    departamentos,
    getTipoItem,
    getUnidades,
    unidadesOptions,
  } = useProduto();
  const { loja } = useContext(LojaContext);
  const queryClient = useQueryClient();
  const [ncms, setNcms] = useState<Select[]>([]);
  const [showModalTransferir, setShowModalTransferir] = useState(false);
  const [gtinModalTransferir, setGtinModalTransferir] = useState('');
  const [infoProduto, setInfoProduto] = useState<any>({});

  const getEditNaoPisCofinsCache = () => {
    return queryClient.getQueryData(`edit_nao_pis_cofins_${28}`);
  };

  useEffect(() => {
    (async () => {
      getDepartamentos();
      getNcms();
      getTipoItem();
      setInfoProduto({});
      getUnidades();
      if (
        formProduto.ipi_tipo.value.value !== -1 &&
        formProduto.ipi_tipo.value.value !== ''
      ) {
        setIpiHabilitar(false);
      }
    })();
  }, []);

  const handleChangeInput = (valores: { entrada: string; saida: string }) => {
    const entradaFiltrada = tipoPisCofinsEntrada.find((item: Select) => {
      return Number(item.value) === Number(valores.entrada);
    });
    const saidaFiltrada = tipoPisCofinsSaida.find((item: Select) => {
      return Number(item.value) === Number(valores.saida);
    });
    setValue('cst_entrada', entradaFiltrada);
    setValue('cst_saida', saidaFiltrada);
  };

  const mapeamentoValoresCst: MapeamentoValores = {
    '-1': {
      entrada: loja.cst_pis_cof_entrada,
      saida: loja.cst_pis_cof_saida,
    },
    '0': {
      entrada: loja.cst_pis_cof_ent_aliq_zero,
      saida: loja.cst_pis_cof_saida_aliq_zero,
    },
    '1': {
      entrada: loja.cst_pis_cof_ent_monof,
      saida: loja.cst_pis_cof_saida_monof,
    },
    '2': {
      entrada: loja.cst_pis_cof_ent_subst,
      saida: loja.cst_pis_cof_saida_subst,
    },
    '3': {
      entrada: loja.cst_pis_cof_ent_imune,
      saida: loja.cst_pis_cof_saida_imune,
    },
    '4': {
      entrada: loja.cst_pis_cof_ent_susp,
      saida: loja.cst_pis_cof_saida_susp,
    },
    '5': {
      entrada: loja.cst_pis_cof_ent_monof,
      saida: loja.cst_pis_cof_saida,
    },
  };

  const handleChangeValoresCst = (
    tipo: keyof MapeamentoValores,
  ): { entrada: string; saida: string } | null => {
    if (tipo in mapeamentoValoresCst) {
      const valores = mapeamentoValoresCst[tipo];
      handleChangeInput(valores);
    }
    return null;
  };

  const getProdutoNCMTipoItem = () => {
    if (getEditNaoPisCofinsCache()) {
      return;
    }

    setHasErroTipoIncidente(false);
    setInfoProduto({});
    const cod_ncm = formProdutoLoja.cod_ncm.value;
    const cod_tipo_item = getValues('cod_tipo_item');

    if (cod_ncm) {
      try {
        if (isUpdate && ncmIsFromTheProduct) {
          api
            .get(
              `/produto/${formProduto.cod_produto.value}/${mainLoja.cod_loja}`,
            )
            .then((res) => {
              const { data, success } = res.data;
              if (!success) {
                setInfoProduto({});
              }

              setInfoProduto(data);
            });
        }
        if (
          cod_tipo_item?.value !== null &&
          cod_tipo_item?.value !== undefined
        ) {
          api
            .get(
              `/produto/ncm/${cod_ncm}/${cod_tipo_item.value}/${mainLoja.des_uf}`,
            )
            .then((res) => {
              const { data, success, message } = res.data;
              if (!success) {
                throw new Error(message);
              }
              setValue('cod_trib_entrada', {
                value: data?.cod_trib_entrada,
                label: '',
              });
              setValue('cod_tributacao', {
                value: data?.cod_tributacao,
                label: '',
              });
              changeFormProdutoLoja({
                ...formProdutoLoja,
                cod_trib_entrada: {
                  ...formProdutoLoja.cod_trib_entrada,
                  value: { value: data?.cod_trib_entrada, label: '' },
                },
                cod_tributacao: {
                  ...formProdutoLoja.cod_trib_entrada,
                  value: { value: data?.cod_tributacao, label: '' },
                },
              });
              if (!ncmIsFromTheProduct) {
                setValue('flg_nao_pis_cofins', data?.flg_nao_pis_cofins);
                const aliqDiferenciada = data?.cod_cred_pres
                  ? optionsEntradaPisCofins.find(
                      (option: any) => option.value === data?.cod_cred_pres,
                    )
                  : {
                      value: null,
                      label: '=Saída',
                    };
                setTimeout(() => {
                  setValue(
                    'tipo_nao_pis_cofins',
                    data?.flg_nao_pis_cofins
                      ? tipoNaoIncidente.find(
                          (tipo) => tipo.value === data?.tipo_nao_pis_cofins,
                        )
                      : null,
                  );
                  setValue('entrada_pis_cofins', aliqDiferenciada);
                }, 10);
                setValue(
                  'des_sped',
                  data?.des_tab_sped ? data?.des_tab_sped : null,
                );
                setValue(
                  'cod_sped',
                  data?.cod_tab_sped
                    ? data.cod_tab_sped.toString().padStart(3, '0')
                    : null,
                );
                changeFormProduto({
                  ...formProduto,
                  flg_nao_pis_cofins: {
                    ...formProduto.flg_nao_pis_cofins,
                    value: data?.flg_nao_pis_cofins,
                  },
                  tipo_nao_pis_cofins: {
                    ...formProduto.tipo_nao_pis_cofins,
                    value: data?.tipo_nao_pis_cofins,
                  },
                });
                setValue(
                  'flg_veda_cred',
                  infoProduto.produtoLoja && isUpdate
                    ? infoProduto?.produtoLoja?.flg_veda_cred
                    : data?.flg_veda_cred,
                );
                setValue(
                  'flg_veda_cred_lim',
                  infoProduto.produtoLoja && isUpdate
                    ? infoProduto?.produtoLoja?.flg_veda_cred_lim
                    : data?.flg_veda_cred_lim,
                );
                setValue(
                  'per_veda_cred_lim',
                  infoProduto.produtoLoja && isUpdate
                    ? infoProduto?.produtoLoja?.per_veda_cred_lim
                      ? moneyFormat(infoProduto?.produtoLoja?.per_veda_cred_lim)
                      : ''
                    : data?.per_veda_cred_lim
                    ? moneyFormat(data?.per_veda_cred_lim)
                    : '',
                );
                changeFormProdutoLoja({
                  ...formProdutoLoja,
                  cod_trib_entrada: {
                    ...formProdutoLoja.cod_trib_entrada,
                    value: { value: data?.cod_trib_entrada, label: '' },
                  },
                  cod_tributacao: {
                    ...formProdutoLoja.cod_trib_entrada,
                    value: { value: data?.cod_tributacao, label: '' },
                  },
                  flg_veda_cred: {
                    ...formProdutoLoja.flg_veda_cred,
                    value:
                      infoProduto.produtoLoja && isUpdate
                        ? infoProduto?.produtoLoja?.flg_veda_cred
                        : data?.flg_veda_cred,
                  },
                  flg_veda_cred_lim: {
                    ...formProdutoLoja.flg_veda_cred_lim,
                    value:
                      infoProduto.produtoLoja && isUpdate
                        ? infoProduto?.produtoLoja?.flg_veda_cred_lim
                        : data?.flg_veda_cred_lim,
                  },
                  per_veda_cred_lim: {
                    ...formProdutoLoja.per_veda_cred_lim,
                    value:
                      infoProduto.produtoLoja && isUpdate
                        ? infoProduto?.produtoLoja?.per_veda_cred_lim
                          ? infoProduto?.produtoLoja?.per_veda_cred_lim
                          : ''
                        : data?.per_veda_cred_lim
                        ? data?.per_veda_cred_lim
                        : '',
                  },
                });
              }
            });
        }
      } catch (e: any) {
        toast.error(e.message);
      }
    }
  };

  useEffect(() => {
    (async () => {
      const codTela: any = queryClient.getQueryData(`cod_tela_active`);
      if (codTela) {
        if (codTela.cod_tela === 28) {
          await getDepartamentos();
          getProdutoNCMTipoItem();
        }
      }
    })();
  }, [queryClient.getQueryData(`cod_tela_active`)]);

  useEffect(() => {
    const ncmUpdate: any = queryClient.getQueryData(`ncm_update`);
    if (ncmUpdate && isUpdate) {
      if (ncmUpdate === formProdutoLoja.cod_ncm.value) {
        setNcmIsFromTheProduct(false);
      }
    }
    queryClient.removeQueries('ncm_update');
  }, [queryClient.getQueryData(`ncm_update`)]);

  useEffect(() => {
    getProdutoNCMTipoItem();
  }, [
    formProdutoLoja.cod_ncm.value,
    formProduto.cod_tipo_item.value,
    getValues('cod_departamento'),
  ]);

  useEffect(() => {
    const reset_pis_cofins = getValues('reset_pis_cofins');
    if (reset_pis_cofins) {
      if (!formProduto?.flg_nao_pis_cofins?.value && refSaida.current) {
        const inputBuscaProduto: HTMLInputElement | null =
          refSaida.current.querySelector('[name="entrada_pis_cofins"]');
        if (inputBuscaProduto) {
          inputBuscaProduto.placeholder = '';
          inputBuscaProduto.value = '';
          setTimeout(() => {
            setValue('entrada_pis_cofins', { label: '= Saída', value: null });
          }, 0);
        }
      }
    }
  }, [formProduto?.flg_nao_pis_cofins?.value]);

  useEffect(() => {
    (async () => {
      const { data } = await api.get('/produto/consulta-credito-presumido');
      if (data.success && data.data) {
        const values = data.data.map((item: any) => ({
          value: item.cod_cred_pres,
          label: item.descricao,
        }));
        const options = [
          {
            value: null,
            label: '= Saída',
          },
          ...values,
        ];
        setOptionsEntradaPisCofins(options);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const { data } = await api.get('/tributacao');

      if (data.success && data.data) {
        const values = data.data.map((i: any) => ({
          value: i.cod_tributacao,
          label: `${i.des_tributacao} ${
            i.cod_situacao_tributaria ? ` - ${i.cod_situacao_tributaria}` : ''
          }`,
        }));
        setTributacoesEntrada(values);
      }
    })();
  }, []);
  /*
   * Responsável por buscar as Tributações de Saida
   */
  useEffect(() => {
    (async () => {
      const { data } = await api.get('/tributacao?type=saida');

      if (data.success && data.data) {
        const values = data.data.map((i: any) => ({
          value: i.cod_tributacao,
          label: `${i.des_tributacao} ${
            i.cod_situacao_tributaria ? ` - ${i.cod_situacao_tributaria}` : ''
          }`,
        }));

        setTributacoesSaida(values);
      }
    })();
  }, []);

  async function getNcms() {
    const res = await api.get('produto/ncms');
    const { data, success } = res.data;
    if (success) {
      const select = data.map((item: INcm) => {
        return {
          value: item.cod_ncm,
          label: item.descricao,
        };
      });
      setNcms(select);
    }
  }

  useEffect(() => {
    if (clearFields) {
      setValue('cod_trib_entrada', { label: undefined, value: undefined });
      return;
    }

    const { value: codTribEntrada } = formProdutoLoja.cod_trib_entrada.value;
    const tributacaoEntrada = tributacoesEntrada.find(
      (item: Select) => item.value === codTribEntrada,
    );

    setValue('cod_trib_entrada', tributacaoEntrada);
  }, [
    clearFields,
    formProdutoLoja.cod_trib_entrada.value,
    setValue,
    tributacoesEntrada,
  ]);

  useEffect(() => {
    const { tipo_nao_pis_cofins } = getValues();
    if (tipo_nao_pis_cofins) {
      handleChangeValoresCst(
        tipo_nao_pis_cofins.value as keyof MapeamentoValores,
      );
    }
  }, [watch('tipo_nao_pis_cofins')]);

  useEffect(() => {
    if (clearFields) {
      setValue('cod_tributacao', { label: undefined, value: undefined });
      return;
    }

    const { value: codTribSaida } = formProdutoLoja.cod_tributacao.value;
    const tributacaoSaida = tributacoesSaida.find(
      (item: Select) => item.value === codTribSaida,
    );

    setValue('cod_tributacao', tributacaoSaida);
  }, [
    clearFields,
    formProdutoLoja.cod_tributacao.value,
    setValue,
    tributacoesSaida,
  ]);

  useEffect(() => {
    setValue(
      'tipo_nao_pis_cofins',
      formProduto?.flg_nao_pis_cofins?.value
        ? tipoNaoIncidente.find(
            (i) =>
              i.value ===
              (formProduto?.tipo_nao_pis_cofins?.value?.value !== -1
                ? formProduto?.tipo_nao_pis_cofins?.value?.value
                : 0),
          ) || {
            label: 'Selecione o motivo',
            value: undefined,
          }
        : {
            label: '',
            value: undefined,
          },
    );
    handleChangeValoresCst(
      formProduto?.tipo_nao_pis_cofins?.value?.value as keyof MapeamentoValores,
    );
  }, [
    formProduto?.flg_nao_pis_cofins?.value,
    formProduto?.tipo_nao_pis_cofins?.value?.value,
  ]);

  useEffect(() => {
    if (!formProduto?.flg_nao_pis_cofins?.value) {
      watch('tipo_nao_pis_cofins');
      setValue('tipo_nao_pis_cofins', formProdutoBlankNew.tipo_nao_pis_cofins);
    }
  }, [formProduto?.flg_nao_pis_cofins?.value, setValue]);

  useEffect(() => {
    if (!formProduto?.flg_nao_pis_cofins?.value) {
      handleChangeValoresCst('-1' as keyof MapeamentoValores);
    }
  }, [formProduto?.flg_nao_pis_cofins?.value]);

  useEffect(() => {
    setValue(
      'ipi_tipo',
      tipoIPI.find((item: Select) => {
        return item.value === formProduto.ipi_tipo.value.value;
      }) ?? {
        value: '0',
        label: 'Selecione',
      },
    );
  }, [formProduto.ipi_tipo.value.value, setValue]);

  useEffect(() => {
    setValue(
      'des_unidade_compra',
      unidadesOptions.find((item: Select) => {
        return item.value === formProduto.des_unidade_compra.value.value;
      }) ?? {
        value: 'UN',
        label: 'UN',
      },
    );
  }, [formProduto.des_unidade_compra.value.value, setValue]);

  useEffect(() => {
    setValue(
      'des_unidade_venda',
      unidadesOptions.find((item: Select) => {
        return item.value === formProduto.des_unidade_venda.value.value;
      }) ?? {
        value: 'UN',
        label: 'UN',
      },
    );
  }, [formProduto.des_unidade_venda.value.value, setValue]);

  useEffect(() => {
    setValue(
      'des_unidade_medida_preco',
      tipoDivisorPreco.find((item: Select) => {
        return item.value === formProduto.des_unidade_medida_preco.value.value;
      }) ?? {
        value: undefined,
        label: 'Selecione',
      },
    );
  }, [formProduto.des_unidade_medida_preco.value.value, setValue]);

  return (
    <>
      <ModalTransferir
        show={showModalTransferir}
        gtin={gtinModalTransferir}
        handleModal={setShowModalTransferir}
      />

      <div className="row">
        {/* Form antes codigo de barras */}
        <Col sm={12} md={12} lg={9} xl={9}>
          <Row>
            <Col
              sm={12}
              md={6}
              lg={5}
              xl={4}
              className="d-flex align-items-center justify-content-space-between"
            >
              <Plu />
            </Col>

            <Col sm={12} md={3} lg={2} xl={2}>
              <ToggleDefault
                labelText="Fora de Linha?"
                setChecked={formProdutoLoja.flg_fora_linha.value}
                onSwitch={() => {
                  setValue(
                    'flg_fora_linha',
                    !formProdutoLoja.flg_fora_linha.value,
                  );
                  changeFormProdutoLoja({
                    ...formProdutoLoja,
                    flg_fora_linha: {
                      ...formProdutoLoja.flg_fora_linha,
                      value: !formProdutoLoja.flg_fora_linha.value,
                    },
                  });
                }}
              />
            </Col>

            <Col sm={12} md={3} lg={2} xl={2}>
              <ToggleDefault
                labelText="Bebida Alcoólica?"
                setChecked={formProduto.flg_alcoolico.value}
                onSwitch={() => {
                  setValue('flg_alcoolico', !formProduto.flg_alcoolico.value);
                  changeFormProduto({
                    ...formProduto,
                    flg_alcoolico: {
                      ...formProduto.flg_alcoolico,
                      value: !formProduto.flg_alcoolico.value,
                    },
                  });
                }}
              />
            </Col>
          </Row>

          <div className="col-sm-12 col-md-12 ">
            <div className="row">
              <div className="col-sm-12 col-md-7">
                <InputText
                  maxLength={100}
                  name="des_produto"
                  label="Descrição"
                  autoComplete="off"
                  placeholder="Informe a Descrição"
                  caseInput="upper"
                  toLowerCase={false}
                  register={register}
                  control={control}
                  isError={!!errors.des_produto}
                  onInput={(ev: ChangeEvent<HTMLInputElement>) => {
                    const { value } = ev.target;

                    // setValue('des_produto', value.toUpperCase());

                    clearErrors('des_produto');

                    changeFormProduto({
                      ...formProduto,
                      des_produto: {
                        ...formProduto.des_produto,
                        value: value.toUpperCase(),
                        isInvalid: value.trim() === '',
                      },
                    });
                  }}
                />
              </div>

              <div className="col-sm-12 col-md-5">
                <InputText
                  maxLength={50}
                  label="Reduzida"
                  name="des_reduzida"
                  autoComplete="off"
                  placeholder="Informe a Descrição Reduzida"
                  caseInput="upper"
                  toLowerCase={false}
                  control={control}
                  register={register}
                  isError={!!errors.des_reduzida}
                  onInput={(ev: ChangeEvent<HTMLInputElement>) => {
                    const { value } = ev.target;
                    clearErrors('des_reduzida');

                    changeFormProduto({
                      ...formProduto,
                      des_reduzida: {
                        ...formProduto.des_reduzida,
                        value: value.toUpperCase(),
                        isInvalid: value.trim() === '',
                      },
                    });
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-12">
                <InputSelect
                  label="Departamento"
                  placeholder="Selecione o Departamento"
                  name="cod_departamento"
                  register={register}
                  isError={!!errors.cod_departamento}
                  control={control}
                  options={departamentos as SelectType[]}
                  changeSelected={(selected: any) => {
                    setValue('cod_departamento', selected);
                    setNcmIsFromTheProduct(false);

                    handleResetClearField();
                    handleTipoItemAndIdCtb();

                    clearErrors('cod_departamento');
                    removeEditNaoPisCofinsCache();
                    changeFormProduto({
                      ...formProduto,
                      cod_tipo_item: {
                        ...formProduto.cod_tipo_item,
                        value: selected.cod_tipo_item,
                      },
                      cod_departamento: {
                        ...formProduto.cod_departamento,
                        value: selected,
                        isInvalid: selected.value === undefined,
                      },
                    });
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-12">
                <BuscaNcm
                  label="NCM"
                  value={formProdutoLoja.cod_ncm.value}
                  isUpdate={isUpdate}
                  required
                  invalid={!!errors.cod_ncm}
                  init={!!errors.cod_ncm}
                  isDisabled={!formProduto.cod_departamento.value.value}
                  refs={formProdutoLoja.cod_ncm.ref}
                  onChange={(value) => {
                    setNcmIsFromTheProduct(false);
                    setValue('cod_ncm', value);
                    clearErrors('cod_ncm');
                    removeEditNaoPisCofinsCache();
                    changeFormProdutoLoja({
                      ...formProdutoLoja,
                      cod_ncm: {
                        ...formProdutoLoja.cod_ncm,
                        value: value.value,
                        isInvalid: value.value === undefined,
                      },
                    });
                    setInfoProduto({});
                  }}
                  placeholder={
                    !formProduto.cod_departamento.value.value
                      ? 'Selecione o Departamento'
                      : 'Informe pelo menos 3 caracteres do Número, Descrição ou CEST'
                  }
                />
              </div>
            </div>

            <Row>
              <Col sm={12} md={6}>
                <InputSelect
                  label="Tipo do Item"
                  name="cod_tipo_item"
                  placeholder="- Selecione a Categoria -"
                  register={register}
                  isError={!!errors.cod_tipo_item}
                  control={control}
                  options={tipoItem as SelectType[]}
                  changeSelected={(selected) => {
                    clearErrors('cod_tipo_item');
                    setValue('cod_tipo_item', selected);
                    getProdutoNCMTipoItem();
                  }}
                />
              </Col>

              <Col sm={12} md={6}>
                <InputSelect
                  label="Indentificação Contábil"
                  name="cod_id_ctb"
                  placeholder="- Selecione um ID Contábil -"
                  register={register}
                  isError={!!errors.cod_id_ctb}
                  control={control}
                  options={idContabeis as SelectType[]}
                  changeSelected={(selected) => {
                    clearErrors('cod_id_ctb');
                    setValue('cod_id_ctb', selected);
                  }}
                />
              </Col>
            </Row>
          </div>
        </Col>

        {/* Codigo De barras */}
        <Col sm={12} md={12} lg={3} xl={3}>
          <Gtin
            onShowModalChanged={setShowModalTransferir}
            onGtinModalChanged={setGtinModalTransferir}
            tipoGtin={tipoGtin}
            setTipoGtin={setTipoGtin}
          />
        </Col>
      </div>

      {/* Fiscal */}
      <Row>
        <Col sm={12} md={12} lg={12} xl={12}>
          <Separator labelText="Fiscal" />
        </Col>

        <Col
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            minHeight: `${
              tabGeral === 'pisCofins' || tabGeral === 'ipi' ? '200px' : '250px'
            } `,
          }}
        >
          <Tabs
            id="controlled-produto"
            activeKey={tabGeral}
            onSelect={(k) => setTabGeral(k)}
          >
            <Tab eventKey="icms" title="ICMS">
              <AbaIcms />
            </Tab>

            <Tab eventKey="pisCofins" title="PIS/COFINS">
              <AbaPisCofins />
            </Tab>

            <Tab eventKey="ipi" title="IPI">
              <AbaIpi />
            </Tab>
          </Tabs>
        </Col>
      </Row>
      {/* UNIDADE / EMBALAGEM */}

      <Row>
        <Col sm={12} md={12} lg={12} xl={12}>
          <Separator labelText="Unidade / Embalagem - Compra" />
        </Col>

        <Col sm={12} md={2} lg={2} xl={2}>
          <InputSelect
            label="Unidade"
            name="des_unidade_compra"
            register={register}
            isError={!!errors.des_unidade_compra}
            control={control}
            options={unidadesOptions as SelectType[]}
            changeSelected={(selected) => {
              setValue('des_unidade_compra', selected);

              changeFormProduto({
                ...formProduto,
                des_unidade_compra: {
                  ...formProduto.des_unidade_compra,
                  value: selected,
                },
              });
            }}
          />
        </Col>

        <Col sm={12} md={2} lg={2} xl={2}>
          <InputNumber2
            label="C/"
            min={1}
            max={99999}
            maxLength={5}
            placeholder=""
            name="qtd_embalagem_compra"
            register={register}
            control={control}
            isError={!!errors.qtd_embalagem_compra}
            onBlur={(event: ChangeEvent<HTMLInputElement>) => {
              if (Number(event.target.value) <= 0)
                setValue('qtd_embalagem_compra', 1);
            }}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              if (Number(event.target.value) <= 99999) {
                setValue('qtd_embalagem_compra', event.target.value);
                changeFormProduto({
                  ...formProduto,
                  qtd_embalagem_compra: {
                    ...formProduto.qtd_embalagem_compra,
                    value: Number(event.target.value),
                  },
                });
              }
            }}
          />
        </Col>

        <Col sm={12} md={2} lg={2} xl={2}>
          <InputPeso
            label="Peso"
            min={0.001}
            placeholder=""
            decimals={3}
            name="val_peso"
            register={register}
            control={control}
            isError={!!errors.val_peso}
            onChange={(ev: any) => {
              const { value } = ev.target;

              const serializedValue = moedaFormatada(value, 3);
              setValue('val_peso', serializedValue);
              changeFormProduto({
                ...formProduto,
                val_peso: {
                  ...formProduto.val_peso,
                  value: serializedValue,
                },
              });
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col sm={12} md={12} lg={12} xl={12}>
          <Separator labelText="Unidade / Embalagem - Venda" />
        </Col>

        <Col sm={12} md={2} lg={2} xl={2}>
          <InputSelect
            label="Unidade"
            name="des_unidade_venda"
            register={register}
            isError={!!errors.des_unidade_venda}
            control={control}
            options={unidadesOptions as SelectType[]}
            changeSelected={(selected) => {
              setValue('des_unidade_venda', selected);

              changeFormProduto({
                ...formProduto,
                des_unidade_venda: {
                  ...formProduto.des_unidade_venda,
                  value: selected,
                },
              });
            }}
          />
        </Col>

        <Col sm={12} md={2} lg={2} xl={2}>
          <InputNumber2
            label="C/"
            min={0}
            max={99999}
            maxLength={5}
            placeholder=""
            name="qtd_embalagem_venda"
            register={register}
            control={control}
            isError={!!errors.qtd_embalagem_venda}
            onBlur={(event: ChangeEvent<HTMLInputElement>) => {
              if (Number(event.target.value) <= 0)
                setValue('qtd_embalagem_venda', 1);
            }}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              if (Number(event.target.value) <= 99999) {
                setValue('qtd_embalagem_venda', event.target.value);
                changeFormProduto({
                  ...formProduto,
                  qtd_embalagem_venda: {
                    ...formProduto.qtd_embalagem_venda,
                    value: Number(event.target.value),
                  },
                });
              }
            }}
          />
        </Col>

        <Col sm={12} md={2} lg={2} xl={2}>
          <InputPeso
            label="Peso Líquido"
            min={0.01}
            placeholder=""
            decimals={3}
            name="val_peso_emb"
            register={register}
            control={control}
            isError={!!errors.val_peso_emb}
            onBlur={(ev: any) => {
              if (ev.target.value === '' || ev.target.value === '0,000') {
                setValue('val_peso_emb', '1,000');
              }
            }}
            onInput={(ev: any) => {
              const { value } = ev.target;

              const serializedValue = moedaFormatada(value, 3);
              setValue('val_peso_emb', serializedValue);

              changeFormProduto({
                ...formProduto,
                val_peso_emb: {
                  ...formProduto.val_peso_emb,
                  value: serializedValue,
                },
              });
            }}
          />
        </Col>

        <Col sm={12} md={2} lg={2} xl={2}>
          <InputPeso
            label="Peso Bruto"
            min={0.01}
            placeholder=""
            decimals={3}
            name="val_peso_bruto"
            register={register}
            control={control}
            isError={!!errors.val_peso_bruto}
            onBlur={(ev: any) => {
              if (ev.target.value === '' || ev.target.value === '0,000') {
                setValue('val_peso_bruto', '1,000');
              }
            }}
            onInput={(ev: any) => {
              const { value } = ev.target;

              const serializedValue = moedaFormatada(value, 3);
              setValue('val_peso_bruto', serializedValue);
              changeFormProduto({
                ...formProduto,
                val_peso_bruto: {
                  ...formProduto.val_peso_bruto,
                  value: serializedValue,
                },
              });
            }}
          />
        </Col>

        <Col sm={12} md={3} lg={3} xl={2}>
          <InputPeso
            label="Divisor de Preços"
            min={0.01}
            placeholder=""
            decimals={3}
            name="qtd_divisor_preco"
            register={register}
            control={control}
            isError={!!errors.qtd_divisor_preco}
            onBlur={(ev: any) => {
              if (ev.target.value === '' || ev.target.value === '0,000') {
                setValue('qtd_divisor_preco', '1,000');
              }
            }}
            onInput={(ev: any) => {
              const { value } = ev.target;

              const serializedValue = moedaFormatada(value, 3);
              setValue('qtd_divisor_preco', serializedValue);

              changeFormProduto({
                ...formProduto,
                qtd_divisor_preco: {
                  ...formProduto.qtd_divisor_preco,
                  value: serializedValue,
                },
              });
            }}
          />
        </Col>

        <Col sm={12} md={2} lg={2} xl={2}>
          <InputSelect
            label="Tipo"
            name="des_unidade_medida_preco"
            register={register}
            isError={!!errors.des_unidade_medida_preco}
            control={control}
            options={tipoDivisorPreco as SelectType[]}
            changeSelected={(selected) => {
              setValue('des_unidade_medida_preco', selected);

              changeFormProduto({
                ...formProduto,
                des_unidade_medida_preco: {
                  ...formProduto.des_unidade_medida_preco,
                  value: selected,
                },
              });
            }}
          />
        </Col>

        {/* <div className="col-sm-12 col-md-2 position-relative">
             <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip
                      id=""
                      style={{
                        width: '500px',
                      }}
                    >
                      Alguns Exemplos: \n Caso preço da embalagem com
                      identificação de &#34Quilo&#34, como \n Café 250 GR,
                      parametrizar 0,250 no divisor e unidade KG. \n Caso preço
                      da embalagem com identificação &#34Litro&#34, como Cerveja
                      600 ML, parametrizar 0,600 no divisor e unidade LT. Caso
                      preço da embalagem com identificação de &#34Unidade&#34,
                      como Ovo c/30 Unidade, parametrizar 30 no divisor e
                      unidade UN.
                    </Tooltip>
                  }
                >
                  <GrCircleInformation
                    size={14}
                    color="#ffff"
                    style={{
                      position: 'absolute',
                      right: 12,
                      marginTop: '12px',
                    }}
                  />
                </OverlayTrigger>
          </div>
          */}
      </Row>

      {/* Novas Embalagens de Venda  */}
      {/* Comentado conforme task - KVXERP-1294 */}
      {/* <EmbalagemVenda /> */}
    </>
  );
};
export default AbaGeral;
