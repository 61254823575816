/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState, useCallback, useEffect } from 'react';
import { ImBlocked } from 'react-icons/im';
import { BiWindowClose } from 'react-icons/bi';
import { AiOutlineMenuFold } from 'react-icons/ai';
import { IoIosSearch, IoIosClose } from 'react-icons/io';
import { MdOutlineStore } from 'react-icons/md';
import {
  FaBuilding,
  FaRegBell,
  FaRegStar,
  FaRoute,
  FaWpforms,
  FaRegUserCircle,
  FaUserEdit,
} from 'react-icons/fa';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { Context } from '~/context/auth';
import { Container } from './styles';
import { Link } from 'react-router-dom';
import { v4 } from 'uuid';

import api from '~/services/api';

import icones from '~/config/icones';

import Logo from '../../assets/img/LOGO_KONVIX_H.png';
import { NavigationContext } from '~/context/navigation';
import Spinner from '@atlaskit/spinner';

import moment from 'moment';
import 'moment/locale/pt-br';
import useAuth from '~/hooks/useAuth';

import {
  ModulosTelas,
  Loja,
  telasModuloProps,
  ModuloData,
  Tela,
  DropdownUserProps,
} from './types';
import { LojaContext } from '~/context/loja';
import Separator from '../Separator';
import TelaCard from './Components/TelaCard';
import Modulo from './Components/Modulo';
import { InformacaoTelasAcessadasContext } from '~/context/InformacaoTelasAcessadas';
import { FilterContext } from '~/context/filter';
import { loadingContext } from '~/context/loading';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { IoDiamondOutline } from 'react-icons/io5';
import { GiExitDoor } from 'react-icons/gi';
import Trilha from './Components/Trilha';
import { FaClockRotateLeft } from 'react-icons/fa6';
import { IoTrophyOutline } from 'react-icons/io5';
import { useQueryClient } from 'react-query';
import BarraAssineJa from '../BarraAssineJa/index';

const Navbars: React.FC = () => {
  const user = useAuth();
  const MySwal = withReactContent(Swal);
  const { addScreenInfo, removeAllScreenInfo } = useContext(
    InformacaoTelasAcessadasContext,
  );
  moment.locale('pt-br');
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const {
    handleLogout,
    handleChangeUserData,
    handleNotificacao,
    user: contextUser,
  } = useContext(Context);

  const { codLoja, changeLoja } = useContext(LojaContext);
  const { addScreen, navmenu, screens, removeAllScreens } =
    useContext(NavigationContext);
  const { RemoveAllFilterPages } = useContext(FilterContext);

  const [openMenu, setOpenMenu] = useState(false);
  const [openLojas, setOpenLojas] = useState(false);
  const [openNotificacoes, setOpenNotificacoes] = useState(false);
  const [openConfig, setOpenConfig] = useState(false);
  const [telas, setTelas] = useState<ModulosTelas[]>([]);

  const [trilhas, setTrilhas] = useState<any[]>([]);
  const [trilhasFilter, setTrilhasFilter] = useState<any[]>([]);

  const [moreAccessed, setMoreAccessed] = useState<any>();
  const [recentes, setRecentes] = useState<any>();
  const [countTelas, setCountTelas] = useState(0);
  const [selectLojas, setSelectLojas] = useState<Loja[]>([]);
  const [filter, setFilter] = useState('');
  const [notificacoes, setNotificacoes] = useState<any>([]);
  const [showModal, setShowModal] = useState(false); // Será utilizado para notificações

  const [showTelas, setShowTelas] = useState<boolean>(true);
  const [showTrilhas, setShowTrilhas] = useState<boolean>(false);
  const [showConfigsPanel, setShowConfigsPanel] = useState<boolean>(false);
  const [showLojaPadraoPanel, setShowLojaPadraoPanel] =
    useState<boolean>(false);
  const [showFavorites, setShowFavorites] = useState<boolean>(false);
  const [showMoreAccessed, setShowMoreAccessed] = useState<boolean>(false);
  const [showRecentes, setShowRecentes] = useState<boolean>(false);
  const [showFiltroPorModulo, setShowFiltroPorModulo] =
    useState<boolean>(false);
  const [codModuloParaFiltro, setCodModuloParaFiltro] = useState<ModuloData>(
    {} as ModuloData,
  );

  const [reloadMoreAccessed, setReloadMoreAccessed] = useState(false);
  const [loadingLoja, setLoadingLoja] = useState<boolean>(false);

  const {
    handleLoadingPageSearch,
    setLoadingNotificaoTable,
    setLoadingNotificaoPage,
    handleLoadingPageSearchAll,
    handleLoadingTableSearchAll,
    loadingPageSearch,
    loadingTableSearch,
    setLoadingDepartamento,
    setLoadingCategoriaFinanceira,
    loadingNavBarNotificacao,
    setLoadingNavBarNotificacao,
    setLoadingCard,
  } = useContext(loadingContext);

  window.addEventListener('NetworkErrorEvent', function (event) {
    event.stopPropagation();
    event.preventDefault();

    // é chamado quando perde a conexão e desativa o loading de todas as telas
    // no componente search
    const existeTrue = loadingPageSearch.some(
      (objeto) => objeto.value === true,
    );

    if (existeTrue) {
      handleLoadingPageSearchAll(loadingPageSearch);
    }
    const existeTrueTable = loadingTableSearch.some(
      (objeto) => objeto.value === true,
    );
    if (existeTrueTable) {
      handleLoadingTableSearchAll(loadingTableSearch);
    }

    setLoadingDepartamento(false);
    setLoadingNotificaoTable(false);
    setLoadingNotificaoPage(false);
    setLoadingCategoriaFinanceira(false);
    setLoadingNavBarNotificacao(false);
    setLoadingCard(false);
  });

  const getArrayIndexed = function (
    arr: any[],
    fieldNameKey: string,
    fieldNameValue: string,
  ) {
    const r: any = {};
    for (let i = 0; i < arr.length; i++)
      r[`${arr[i][fieldNameKey]}`] = arr[i][fieldNameValue];
    return r;
  };

  const ulrTelas = getArrayIndexed(navmenu, 'id', 'url');

  const dropdownUserArray: DropdownUserProps[] = [
    { label: 'Minha empresa', to: '/loja', icon: FaBuilding },
    { label: 'Edição da conta', to: '/edicao-conta', icon: FaUserEdit },
    { label: 'Meu plano', to: '/meu-plano', icon: IoDiamondOutline },
  ];

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (user.user?.cod_controle !== undefined) {
        const { data } = await api.get(
          `/telasModulo/${user.user?.cod_controle}`,
        );
        if (data.success) {
          // Efetua o filtro para retirar os modulos que não tem telas vinculadas
          const dataFiltered = data.data.filter(
            (tela: any) => tela.telas.length > 0,
          );
          // Efetua map para vincular o componente da tela ao menu
          const telasPorModulo = dataFiltered.map(
            (telasEModulos: telasModuloProps) => {
              // Nesse map é verificado se existe um componente a vincular ao menu
              const addComponenteTelas = telasEModulos.telas.map((tela) => {
                if (
                  navmenu.filter((cpmnt: any) => cpmnt.id === tela.cod_tela)[0]
                ) {
                  return {
                    cod_tela: tela.cod_tela,
                    cod_modulo: tela.cod_modulo,
                    des_tela: tela.des_tela,
                    content: navmenu.filter(
                      (cpmnt: any) => cpmnt.id === tela.cod_tela,
                    )[0].content,
                    label_menu: tela.label_menu,
                    flg_visivel: tela.flg_visivel,
                    lib_icon: tela.lib_icon,
                    des_icon: tela.des_icon,
                    flg_favorita: tela.flg_favorita,
                    des_funcao: tela.des_funcao,
                    link_material: tela.link_material,
                    flg_abre_pesquisa: tela.flg_abre_pesquisa,
                  };
                }
                return {
                  cod_tela: tela.cod_tela,
                  cod_modulo: tela.cod_modulo,
                  des_tela: tela.des_tela,
                  content: null,
                  label_menu: tela.label_menu,
                  flg_visivel: tela.flg_visivel,
                  lib_icon: tela.lib_icon,
                  des_icon: tela.des_icon,
                  flg_favorita: tela.flg_favorita,
                  des_funcao: tela.des_funcao,
                  link_material: tela.link_material,
                  flg_abre_pesquisa: tela.flg_abre_pesquisa,
                };
              });

              // retorna menu com componente da tela vinculado
              return {
                cod_modulo: telasEModulos.cod_modulo,
                name: telasEModulos.des_modulo,
                telas: addComponenteTelas,
                num_ordem_menu: telasEModulos.num_ordem_menu,
              };
            },
          );

          setCountTelas(data.count.telas);
          setTelas(telasPorModulo);
        }
      }
      setLoading(false);
    })();
  }, [user.user?.cod_controle]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const queryDataTrilha: any =
        queryClient.getQueryData(`infoTrilha_menu`) || undefined;

      if (
        user.user?.cod_controle !== undefined &&
        openMenu &&
        !queryDataTrilha
      ) {
        const { data } = await api.get(
          `/modulo-trilhas/${user.user?.cod_controle}`,
        );
        if (data.success) {
          const trilhasFormat = data.data
            .map((trilha: any) => {
              const { lib_icon, des_icon } = trilha;
              const iconkeyTrilha = Object.keys(icones).find(
                (icon) => icon === lib_icon,
              );
              let IconTrilha = '';
              if (iconkeyTrilha) {
                const Lib = icones[iconkeyTrilha];
                IconTrilha = Lib[des_icon];
              }

              trilha.Icon = IconTrilha;
              const telasTrilhas =
                trilha.des_json?.map((tela: any) => {
                  const [filteredComponent] = navmenu.filter(
                    (cpmnt: any) => cpmnt.id === tela.cod_tela,
                  );
                  if (filteredComponent) {
                    tela.content = filteredComponent.content;
                  }
                  const { lib_icon: lib_icon_tela, des_icon: des_icon_tela } =
                    tela;
                  const iconkeyTela = Object.keys(icones).find(
                    (icon) => icon === lib_icon_tela,
                  );
                  let IconTela = '';
                  if (iconkeyTela) {
                    const Lib = icones[iconkeyTela];
                    IconTela = Lib[des_icon_tela];
                  }
                  if (ulrTelas[`${tela.cod_tela}`]) {
                    tela.url = ulrTelas[`${tela.cod_tela}`];
                  } else {
                    tela.url = '/app';
                  }
                  tela.Icon = IconTela;
                  return tela;
                }) || [];
              trilha.telas = telasTrilhas;
              return telasTrilhas.length > 0 ? trilha : null;
            })
            .filter(Boolean);
          queryClient.setQueryData(`infoTrilha_menu`, trilhasFormat);
          setTrilhas(trilhasFormat);
          setTrilhasFilter(trilhasFormat);
        }
      }
      setLoading(false);
    })();
  }, [user.user?.cod_controle, openMenu]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const { data } = await api.get(`/maisAcessadas`);
      if (data.success) {
        setMoreAccessed({
          data: data.data,
          codes: data.codes,
        });
      }
      setLoading(false);
    })();
  }, [reloadMoreAccessed]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await api.get(`/recentes`);
        if (data.success && data.data.length > 0) {
          setRecentes({
            data: data.data,
            codes: data.codes,
          });
          setLoading(false);
          return;
        }
        setLoading(false);
        setShowRecentes(false);
      } catch (error) {
        setLoading(false);
        setShowRecentes(false);
      }
    })();
  }, [reloadMoreAccessed]);

  useEffect(() => {
    (async () => {
      if (user.user?.id !== undefined) {
        const { data } = await api.get(`/lojas/usuario`);
        if (data.success && data.data) {
          const options = data.data.map((lojas: Loja) => {
            return {
              des_loja: `${
                lojas.cod_loja.toString().length === 1
                  ? `0${lojas.cod_loja}`
                  : lojas.cod_loja
              } - ${lojas.des_loja} (${lojas.des_cidade})`,
              cod_loja: lojas.cod_loja,
            };
          });
          setSelectLojas(options);
          getNotificacoes();
        }
      }
    })();
  }, [user.user?.id]);

  const getLojas = async () => {
    try {
      setLoadingLoja(true);
      const { data } = await api.get(`/lojas/usuario`);
      if (data.success && data.data) {
        const options = data.data.map((lojas: Loja) => {
          return {
            des_loja: `${
              lojas.cod_loja.toString().length === 1
                ? `0${lojas.cod_loja}`
                : lojas.cod_loja
            } - ${lojas.des_loja} (${lojas.des_cidade})`,
            cod_loja: lojas.cod_loja,
          };
        });
        setSelectLojas(options);
      }
      setLoadingLoja(false);
    } catch (error) {
      setLoadingLoja(false);
      console.log(error);
    }
  };

  const getNotificacoes = async () => {
    try {
      setLoadingNavBarNotificacao(true);
      const { data } = await api.post(`/central-de-notificacoes/notificacoes`, {
        tipo_notificacao_filtro: 0,
        des_pesquisa: '',
        orderBy: ['dta_cadastro desc'],
        start: 0,
        tipo_notificacao_prioridade: -1,
        limit: 5,
      });
      if (data.success) {
        setNotificacoes(data.data);
      }
      setLoadingNavBarNotificacao(false);
    } catch (error) {
      setLoadingNavBarNotificacao(false);
    }
  };

  // esc function para fechar o menu
  const escFunction = (event: KeyboardEvent) => {
    if (event.keyCode === 27) {
      setOpenMenu(false);
      setOpenLojas(false);
      setOpenConfig(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  const handleMenuClick = useCallback(() => {
    setOpenMenu(!openMenu);
    setOpenLojas(false);
    setOpenConfig(false);
    setOpenNotificacoes(false);
    setShowModal(false);
  }, [openMenu]);

  const handleLojaClick = useCallback(() => {
    if (!openLojas) {
      getLojas();
    }
    setOpenLojas(!openLojas);
    setOpenMenu(false);
    setOpenConfig(false);
    setOpenNotificacoes(false);
    setShowModal(false);
  }, [openLojas]);

  const handleNotificacoesClick = useCallback(() => {
    if (!openNotificacoes) {
      getNotificacoes();
    }
    setOpenNotificacoes(!openNotificacoes);
    setOpenLojas(false);
    setOpenMenu(false);
    setOpenConfig(false);
    setShowModal(false);
  }, [openNotificacoes]);

  const handleConfigClick = useCallback(() => {
    setOpenConfig(!openConfig);
    setOpenMenu(false);
    setOpenLojas(false);
    setOpenNotificacoes(false);
    setShowModal(false);
  }, [openConfig]);

  const handleLiberador = useCallback(async (codloja: any) => {
    try {
      const libValue = await api.get(
        `/usuario/buscar-liberador-financeiro/${codloja}`,
      );
      if (libValue.data.success === true) {
        return libValue.data.data.flg_lib_financeiro;
      }
    } catch (e: any) {
      toast.error(e.message);
    }
  }, []);

  const handlelojaStorage = useCallback(
    async (codloja: any) => {
      try {
        const res = await api.get(`/lojas/${codloja}`);
        const { success, message } = res.data;
        if (!success) throw new Error(message);
        const regime = res.data.data.tipo_regime;
        const temp = user.user;
        temp.loja = codloja;
        temp.tipo_regime = regime;
        const liberadorValue: boolean = await handleLiberador(codloja);
        temp.flg_lib_financeiro = liberadorValue;
        handleChangeUserData(temp);
      } catch (e: any) {
        toast.error(e.message);
      }
    },
    [handleChangeUserData, handleLiberador, user.user],
  );

  const handleSetLoja = useCallback(
    async (codloja) => {
      try {
        if (user.user) {
          const { data } = await api.put(`/usuarios/loja/${user.user.id}`, {
            cod_loja: codloja,
          });
          if (data.success) {
            // atualiza loja no context de loja, regime da tributação e usuário
            handlelojaStorage(codloja);
            changeLoja(codloja);
            // fecha menu de lojas
            setOpenLojas(false);
            toast.success(data.message);
          } else {
            toast.error(data.message);
          }
        }
      } catch (error: any) {
        toast.error(error);
      }
    },
    [user.user, handlelojaStorage, changeLoja],
  );

  const handleOpenTela = useCallback(
    (item) => {
      if (item.cod_tela === 280 && !contextUser?.flg_lib_financeiro) {
        return toast.warning(
          'Seu usuário não tem o privilégio de liberador da tesouraria, procure seu administrador do sistema.',
        );
      }

      if (item.content === null) {
        return toast.warning(
          'Tela não encontrada, entre em contato com o suporte',
        );
      }
      const screen = {
        content: item.content,
        label: item.label_menu,
        lib_icon: item.lib_icon,
        des_icon: item.des_icon,
        cod_tela: item.cod_tela,
        url: item.url,
        linkMaterial: item.link_material,
        flg_abre_pesquisa: item.flg_abre_pesquisa,
      };

      addScreen(screen);
      setOpenMenu(false);

      // Limpando filtro após acessar tela
      setShowFavorites(false);
      setShowMoreAccessed(false);
      setShowRecentes(false);
      setShowFiltroPorModulo(false);
      setShowTrilhas(false);
      setShowTelas(true);
      setShowLojaPadraoPanel(false);
      setShowConfigsPanel(false);
      setFilter('');

      handleLoadingPageSearch(true, item.cod_tela);

      addScreenInfo(screen);
    },
    [addScreen, contextUser, addScreenInfo, handleLoadingPageSearch],
  );

  const handleFavorite = useCallback(
    async (cod_tela: number, cod_modulo: number, flg_favorita: any) => {
      try {
        const { data } = await api.post(`/favoritarTela/${cod_tela}`, {
          flg_favorita,
        });

        if (data.success) {
          setTelas((prevTelas) =>
            prevTelas.map((modulo) => {
              if (Number(modulo.cod_modulo) === Number(cod_modulo)) {
                return {
                  ...modulo,
                  telas: modulo.telas.map((tela) =>
                    tela.cod_tela === cod_tela
                      ? { ...tela, flg_favorita: !flg_favorita }
                      : tela,
                  ),
                };
              }
              return modulo;
            }),
          );
        }
      } catch (error) {
        toast.error('Erro ao favoritar a tela');
      }
    },
    [],
  );

  const handleAddAccessed = useCallback(
    async (cod_tela) => {
      try {
        if (screens.length >= 6) {
          const filteredScreens = screens.find(
            (item) => item.cod_tela === cod_tela,
          );
          if (!filteredScreens) return;
        }
        const result = await api.post('/addMaisAcessada', {
          cod_tela,
        });

        setReloadMoreAccessed(!reloadMoreAccessed);
      } catch (error: any) {
        if (error.data !== undefined) {
          toast.error(error.data.message);
          return;
        }
        toast.error(String(error));
      }
    },
    [reloadMoreAccessed, screens],
  );

  const handleFiltroPorModulo = useCallback((data: ModuloData) => {
    setCodModuloParaFiltro(data);
  }, []);

  const handleFiltroPorTrilha = useCallback(
    (cod_trilha: any) => {
      const filteredTrilhas = trilhasFilter.filter(
        (trilha: any) => trilha.cod_trilha === cod_trilha,
      );
      setTrilhas(filteredTrilhas);
      if (cod_trilha === 0) {
        setTrilhas(trilhasFilter);
      }
    },
    [trilhas, trilhasFilter],
  );

  const renderTelas = useCallback(
    (telasModulo: ModulosTelas['telas']) => {
      return telasModulo.map((tela) => {
        if (tela.flg_visivel === true) {
          const { lib_icon, des_icon } = tela;
          const iconkey = Object.keys(icones).find((icon) => icon === lib_icon);
          let Icon = '';
          if (iconkey) {
            const Lib = icones[iconkey];
            Icon = Lib[des_icon];
          }
          if (ulrTelas[`${tela.cod_tela}`]) {
            tela.url = ulrTelas[`${tela.cod_tela}`];
          } else {
            tela.url = '/app';
          }
          return (
            <TelaCard
              key={tela.cod_tela}
              tela={tela}
              Icon={Icon}
              handleOpenTela={handleOpenTela}
              handleFavorite={handleFavorite}
              handleAddAccessed={handleAddAccessed}
              blocked={false}
            />
          );
        }
        return (
          <TelaCard tela={tela} key={tela.cod_tela} Icon={ImBlocked} blocked />
        );
      });
    },
    [handleAddAccessed, handleFavorite, handleOpenTela, ulrTelas],
  );

  const filterRenderModulo = useCallback(
    (renderModuloFilter: any) => {
      const filtro = renderModuloFilter.filter(
        (filtelas: any) =>
          filtelas.des_tela
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .includes(
              filter.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
            ) ||
          filtelas.des_funcao
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .includes(
              filter
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, ''),
            ),
      );

      return filtro;
    },
    [filter],
  );

  const filterRenderTrilhas = useCallback(
    (renderTrilhasFilter: any) => {
      const filtro = renderTrilhasFilter.filter((filtelas: any) =>
        filtelas.des_tela
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(filter.normalize('NFD').replace(/[\u0300-\u036f]/g, '')),
      );

      return filtro;
    },
    [filter],
  );

  const renderTrilhas = useCallback(() => {
    const trilhasRender = trilhas
      .map((trilha: any) => {
        const telasTrilhas =
          trilha.des_json?.map((tela: any) => {
            const [filteredComponent] = navmenu.filter(
              (cpmnt: any) => cpmnt.id === tela.cod_tela,
            );
            if (filteredComponent) {
              tela.content = filteredComponent.content;
            }
            const { lib_icon: lib_icon_tela, des_icon: des_icon_tela } = tela;
            const iconkeyTela = Object.keys(icones).find(
              (icon) => icon === lib_icon_tela,
            );
            let IconTela = '';
            if (iconkeyTela) {
              const Lib = icones[iconkeyTela];
              IconTela = Lib[des_icon_tela];
            }
            if (ulrTelas[`${tela.cod_tela}`]) {
              tela.url = ulrTelas[`${tela.cod_tela}`];
            } else {
              tela.url = '/app';
            }
            tela.Icon = IconTela;
            return tela;
          }) || [];
        const filterTelas = filterRenderTrilhas(telasTrilhas);
        trilha.telas = filterTelas;
        return filterTelas.length > 0 ? trilha : null;
      })
      .filter(Boolean);

    return (
      <Trilha
        blocked={false}
        trilhas={trilhasRender}
        trilhasFilter={trilhasFilter}
        handleFiltroPorTrilha={handleFiltroPorTrilha}
        handleOpenTela={handleOpenTela}
        handleAddAccessed={handleAddAccessed}
      />
    );
  }, [trilhas, trilhasFilter, filterRenderTrilhas]);

  const renderModulo = useCallback(() => {
    if (showMoreAccessed) {
      if (telas && moreAccessed && moreAccessed.data.length > 0) {
        const getMoreAccessed: ModulosTelas['telas'] = [];
        telas.map((telasModulo) => {
          telasModulo.telas.map((tela) => {
            getMoreAccessed.push(tela);
            return null;
          });
          return null;
        });

        let telasMaisAcessadas = getMoreAccessed;

        telasMaisAcessadas = getMoreAccessed.filter(
          (tela: ModulosTelas['telas'][0]) => {
            const acessos = moreAccessed.data.find(
              (ma: any) => ma.cod_tela === tela.cod_tela,
            );

            if (acessos) {
              tela.qtd_acesso = acessos.qtd_acesso;
            }
            return moreAccessed.codes.includes(tela.cod_tela) === true;
          },
        );
        telasMaisAcessadas.sort(function (a, b) {
          if (
            a.qtd_acesso &&
            b.qtd_acesso &&
            Number(a.qtd_acesso) < Number(b.qtd_acesso)
          ) {
            return 1;
          }
          if (
            a.qtd_acesso &&
            b.qtd_acesso &&
            Number(a.qtd_acesso) > Number(b.qtd_acesso)
          ) {
            return -1;
          }
          return 0;
        });

        const filtro = filterRenderModulo(telasMaisAcessadas);

        return (
          <Modulo
            descricao={`Suas (${filtro.length}) telas mais acessadas`}
            filtro={filtro}
            renderTelas={renderTelas}
          />
        );
      }
      return (
        <Modulo
          descricao="Nenhuma tela encontrada com o filtro selecionado."
          filtro={[{} as Tela]}
        />
      );
    }
    if (showRecentes) {
      if (telas && recentes) {
        const getRecentes: ModulosTelas['telas'] = [];
        telas.map((telasModulo) => {
          telasModulo.telas.map((tela) => {
            getRecentes.push(tela);
            return null;
          });
          return null;
        });

        let telasMaisAcessadas = getRecentes;

        telasMaisAcessadas = getRecentes.filter(
          (tela: ModulosTelas['telas'][0]) => {
            const acessos = recentes.data.find(
              (telaRecente: any) => telaRecente.cod_tela === tela.cod_tela,
            );
            if (acessos) {
              tela.dta_ult_acesso = new Date(acessos.dta_ult_acesso);
            }
            return recentes.codes.includes(tela.cod_tela) === true;
          },
        );
        telasMaisAcessadas.sort(function (a: any, b: any) {
          return b.dta_ult_acesso - a.dta_ult_acesso;
        });

        const filtro = filterRenderModulo(telasMaisAcessadas);

        return (
          <Modulo
            descricao="Acessos recentes"
            filtro={filtro}
            renderTelas={renderTelas}
          />
        );
      }
      return (
        <Modulo
          descricao="Nenhuma tela encontrada com o filtro selecionado."
          filtro={[{} as Tela]}
        />
      );
    }
    if (showFiltroPorModulo) {
      const todasAsTelas: ModulosTelas['telas'] = [];
      telas.map((telasModulo) => {
        telasModulo.telas.map((tela) => {
          todasAsTelas.push(tela);
          return null;
        });
        return null;
      });
      const telasPorCodModulo = todasAsTelas.filter(
        (tela) => tela.cod_modulo === codModuloParaFiltro.cod_modulo,
      );
      const filtro = filterRenderModulo(telasPorCodModulo);

      return (
        <Modulo
          descricao={codModuloParaFiltro.name}
          filtro={filtro}
          renderTelas={renderTelas}
        />
      );
    }
    return telas.map((telasModulo) => {
      let telasModuloFiltered = telasModulo.telas;

      if (showFavorites) {
        telasModuloFiltered = telasModulo.telas.filter(
          (tela) => tela.flg_favorita === true,
        );
      }
      const filtro = filterRenderModulo(telasModuloFiltered);

      if (filtro.length > 0) {
        return (
          <Modulo
            key={telasModulo.cod_modulo}
            descricao={telasModulo.name}
            filtro={filtro}
            renderTelas={renderTelas}
          />
        );
      }
      return null;
    });
  }, [
    showMoreAccessed,
    showRecentes,
    showFiltroPorModulo,
    telas,
    renderTelas,
    moreAccessed,
    recentes,
    codModuloParaFiltro,
    showFavorites,
    filterRenderModulo,
  ]);

  const renderPanelLojas = useCallback(() => {
    return (
      <>
        {selectLojas.map((lojas) => {
          if (lojas.cod_loja === codLoja) {
            return (
              <li key={lojas.cod_loja} className="painelMenuLoja">
                <button
                  type="button"
                  className="li-buttonLoja selected"
                  onClick={() => handleSetLoja(lojas.cod_loja)}
                >
                  {lojas.des_loja}
                </button>
              </li>
            );
          }
          return (
            <li key={lojas.cod_loja} className="painelMenuLoja">
              <button
                type="button"
                className="li-buttonLoja"
                onClick={() => handleSetLoja(lojas.cod_loja)}
              >
                {lojas.des_loja}
              </button>
            </li>
          );
        })}
      </>
    );
  }, [codLoja, handleSetLoja, selectLojas]);

  const renderPanelConfigs = useCallback(() => {
    return (
      <>
        {dropdownUserArray.map(({ label, to, icon: Icon }) => {
          const isUserRestricted = user.user.cod_controle === 2;
          const toUrl =
            to === '/edicao-conta' && isUserRestricted
              ? window.location.pathname
              : to;

          const handleClick = () => {
            if (isUserRestricted && to === '/edicao-conta') {
              toast.warning(
                'Edição de conta não permitida, fale com o administrador do sistema',
              );
            }
            setOpenConfig(false);
            setOpenMenu(false);
          };

          return (
            <li className="painelMenuLoja">
              <Link to={toUrl} onClick={handleClick}>
                <button type="button" className="li-buttonLoja">
                  <Icon size={20} color="white" />
                  {label}
                </button>
              </Link>
            </li>
          );
        })}
        <li className="painelMenuLoja">
          <button
            type="button"
            className="li-buttonConfig selected"
            onClick={() => handleLogout(screens)}
          >
            Sair
          </button>
        </li>
      </>
    );
  }, [handleLogout, screens, dropdownUserArray, user]);

  const onEscCloseMenu = useCallback((e: any) => {
    if (e.keyCode === 27) {
      setOpenMenu(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', () => setOpenMenu(false));
    return () => {
      document.removeEventListener('scroll', () => setOpenMenu(false));
    };
  }, []);

  const handleOpenNotificacao = (cod_notificacao: any) => {
    const item = telas[0].telas.filter(
      (cpmnt: any) => cpmnt.cod_tela === 205,
    )[0];
    if (screens.length >= 6) {
      const filteredScreens = screens.find(
        (tl) => tl.cod_tela === item.cod_tela,
      );
      setOpenNotificacoes(false);
      if (!filteredScreens) return;
    }
    handleOpenTela(item);
    setOpenNotificacoes(false);
    handleNotificacao(cod_notificacao);
  };

  const chamaSwetAlert = useCallback(async () => {
    const confirm = await MySwal.fire({
      title: ``,
      text: 'Deseja fechar todas as telas?',
      showCancelButton: true,
      confirmButtonColor: '#0065FF',
      cancelButtonColor: '#DE350B',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then(async (result: any) => {
      return result.isConfirmed;
    });
    return confirm;
  }, []);

  const handleCloseScreens = async () => {
    const confirmSearchWithoutFilter = await chamaSwetAlert();

    if (confirmSearchWithoutFilter) {
      if (screens.length > 1) {
        RemoveAllFilterPages();
        removeAllScreenInfo();
        removeAllScreens();
      }
    }
  };

  return (
    <Container>
      <nav aria-label="Navigation bar">
        <div className="nav-container">
          {/* Logo e botão do menu */}
          <div className="nav-logo">
            <a title="Home">
              <img src={Logo} alt="Logo" />
            </a>
            <div className="nav-openMenu">
              <button
                type="button"
                aria-controls="menu--openMenu"
                aria-haspopup="true"
                onClick={handleMenuClick}
              >
                <AiOutlineMenuFold />
                <span>Menu</span>
              </button>
            </div>
          </div>
          {/* Barra de pesquisa e painel do menu */}
          <div className="nav-form">
            <div className="nav-search">
              <label className="nav-form-search">
                <IoIosSearch focusable={false} aria-hidden="true" />
                <input
                  value={filter}
                  type="text"
                  placeholder="Pesquisar"
                  autoComplete="off"
                  spellCheck="false"
                  onClick={() => setOpenMenu(true)}
                  onChange={(e) => {
                    setFilter(e.target.value.toUpperCase());
                  }}
                  onKeyDown={(e) => onEscCloseMenu(e)}
                  onFocus={() => handleMenuClick()}
                />
                <div className="nav-close">
                  <button
                    type="button"
                    onClick={() => {
                      setOpenMenu(false);
                      setFilter('');
                    }}
                  >
                    <IoIosClose style={{ cursor: 'pointer' }} />
                  </button>
                </div>
              </label>
              {openMenu && (
                <div className="nav-dropPanel">
                  <div
                    className="backOpacity"
                    aria-label="closeMenu"
                    onClick={() => setOpenMenu(false)}
                    onKeyDown={() => setOpenMenu(false)}
                  />
                  <div className="nav-panelMenu">
                    <div className="nav-menuTelas">
                      <div className="nav-splitMenuTelas">
                        <div className="input-mobile">
                          <label className="nav-form-search">
                            <IoIosSearch focusable={false} aria-hidden="true" />
                            <input
                              value={filter}
                              type="text"
                              placeholder="Pesquisar"
                              autoComplete="off"
                              spellCheck="false"
                              onChange={(e) =>
                                setFilter(e.target.value.toUpperCase())
                              }
                            />
                            <div className="nav-close">
                              <button
                                type="button"
                                onClick={() => setOpenMenu(false)}
                              >
                                <IoIosClose style={{ cursor: 'pointer' }} />
                              </button>
                            </div>
                          </label>
                        </div>
                        <ul className="left-menu">
                          <div>
                            {/* Filtros padrão */}
                            <li
                              className={
                                showFavorites === false &&
                                showMoreAccessed === false &&
                                showFiltroPorModulo === false &&
                                showRecentes === false &&
                                showConfigsPanel === false &&
                                showLojaPadraoPanel === false &&
                                showTelas === false
                                  ? 'btn-activated'
                                  : ''
                              }
                            >
                              <button
                                type="button"
                                className="top-button"
                                onClick={() => {
                                  setShowFavorites(false);
                                  setShowMoreAccessed(false);
                                  setShowRecentes(false);
                                  setShowFiltroPorModulo(false);
                                  setShowTelas(false);
                                  setShowLojaPadraoPanel(false);
                                  setShowConfigsPanel(false);
                                  setShowTrilhas(true);
                                }}
                              >
                                <FaRoute /> Trilhas
                              </button>
                            </li>
                            <li
                              className={
                                showFavorites === false &&
                                showMoreAccessed === false &&
                                showFiltroPorModulo === false &&
                                showRecentes === false &&
                                showConfigsPanel === false &&
                                showLojaPadraoPanel === false &&
                                showTrilhas === false
                                  ? 'btn-activated'
                                  : ''
                              }
                            >
                              <button
                                type="button"
                                className="top-button"
                                onClick={() => {
                                  setShowFavorites(false);
                                  setShowMoreAccessed(false);
                                  setShowRecentes(false);
                                  setShowFiltroPorModulo(false);
                                  setShowTelas(true);
                                  setShowLojaPadraoPanel(false);
                                  setShowConfigsPanel(false);
                                  setShowTrilhas(false);
                                }}
                              >
                                <FaWpforms /> Telas ({countTelas})
                              </button>
                            </li>
                            <li
                              className={
                                showFavorites === false &&
                                showTrilhas === false &&
                                showMoreAccessed === false &&
                                showFiltroPorModulo === false &&
                                showRecentes
                                  ? 'btn-activated'
                                  : ''
                              }
                            >
                              <button
                                type="button"
                                className="li-button"
                                onClick={() => {
                                  setShowRecentes(true);
                                  setShowTelas(true);
                                  setShowMoreAccessed(false);
                                  setShowFavorites(false);
                                  setShowFiltroPorModulo(false);
                                  setShowLojaPadraoPanel(false);
                                  setShowConfigsPanel(false);
                                  setShowTrilhas(false);
                                }}
                              >
                                <FaClockRotateLeft /> Recentes
                              </button>
                            </li>
                            <li
                              className={
                                showFavorites === true &&
                                showTrilhas === false &&
                                showMoreAccessed === false &&
                                showFiltroPorModulo === false &&
                                showRecentes === false
                                  ? 'btn-activated'
                                  : ''
                              }
                            >
                              <button
                                type="button"
                                className="li-button"
                                onClick={() => {
                                  setShowFavorites(true);
                                  setShowMoreAccessed(false);
                                  setShowRecentes(false);
                                  setShowTelas(true);
                                  setShowFiltroPorModulo(false);
                                  setShowLojaPadraoPanel(false);
                                  setShowConfigsPanel(false);
                                  setShowTrilhas(false);
                                }}
                              >
                                <FaRegStar /> Favoritas
                              </button>
                            </li>
                            <li
                              className={
                                showFavorites === false &&
                                showTrilhas === false &&
                                showRecentes === false &&
                                showFiltroPorModulo === false &&
                                showMoreAccessed === true
                                  ? 'btn-activated'
                                  : ''
                              }
                            >
                              <button
                                type="button"
                                className="li-button"
                                onClick={() => {
                                  setShowMoreAccessed(true);
                                  setShowFavorites(false);
                                  setShowRecentes(false);
                                  setShowFiltroPorModulo(false);
                                  setShowTelas(true);
                                  setShowLojaPadraoPanel(false);
                                  setShowConfigsPanel(false);
                                  setShowTrilhas(false);
                                }}
                              >
                                <IoTrophyOutline /> Mais acessadas
                              </button>
                            </li>
                            {/* Filtro por Modulo */}
                            <li>
                              <Separator
                                background="transparent"
                                lineColor="#654694"
                                sidePadding="0px 0px 0px 10px"
                              />
                            </li>
                            {telas.map((telasPorModulo) => {
                              return (
                                <li
                                  key={telasPorModulo.cod_modulo}
                                  className={
                                    showFavorites === false &&
                                    showTrilhas === false &&
                                    showRecentes === false &&
                                    showMoreAccessed === false &&
                                    showFiltroPorModulo &&
                                    telasPorModulo.cod_modulo ===
                                      codModuloParaFiltro.cod_modulo
                                      ? 'btn-activated'
                                      : ''
                                  }
                                >
                                  <button
                                    type="button"
                                    className="li-button"
                                    onClick={() => {
                                      setShowMoreAccessed(false);
                                      setShowFavorites(false);
                                      setShowRecentes(false);
                                      setShowLojaPadraoPanel(false);
                                      setShowConfigsPanel(false);
                                      setShowTrilhas(false);
                                      setShowTelas(true);
                                      setShowFiltroPorModulo(true);
                                      handleFiltroPorModulo({
                                        cod_modulo: telasPorModulo.cod_modulo,
                                        name: telasPorModulo.name,
                                      });
                                    }}
                                  >
                                    {telasPorModulo.name}
                                  </button>
                                </li>
                              );
                            })}
                          </div>
                          <div className="responsive-menus">
                            <li
                              className={
                                showFavorites === false &&
                                showTrilhas === false &&
                                showMoreAccessed === false &&
                                showFiltroPorModulo === false &&
                                showRecentes === false &&
                                showTelas === false &&
                                showLojaPadraoPanel
                                  ? 'btn-activated'
                                  : ''
                              }
                            >
                              <button
                                type="button"
                                className="li-button"
                                onClick={() => {
                                  setShowLojaPadraoPanel(true);
                                  setShowConfigsPanel(false);
                                  setShowTrilhas(false);
                                  setShowRecentes(false);
                                  setShowTelas(false);
                                  setShowMoreAccessed(false);
                                  setShowFavorites(false);
                                  setShowFiltroPorModulo(false);
                                }}
                              >
                                Loja padrão
                              </button>
                            </li>
                            <li>
                              <button
                                type="button"
                                className="li-button"
                                onClick={() => {
                                  setShowLojaPadraoPanel(false);
                                  setShowConfigsPanel(false);
                                  setShowTelas(true);
                                }}
                              >
                                Notificações
                              </button>
                            </li>
                            <li
                              className={
                                showTrilhas === false &&
                                showFavorites === false &&
                                showMoreAccessed === false &&
                                showFiltroPorModulo === false &&
                                showRecentes === false &&
                                showTelas === false &&
                                showConfigsPanel
                                  ? 'btn-activated'
                                  : ''
                              }
                            >
                              <button
                                type="button"
                                className="li-button"
                                onClick={() => {
                                  setShowLojaPadraoPanel(false);
                                  setShowTrilhas(false);
                                  setShowConfigsPanel(true);

                                  setShowRecentes(false);
                                  setShowTelas(false);
                                  setShowMoreAccessed(false);
                                  setShowFavorites(false);
                                  setShowFiltroPorModulo(false);
                                }}
                              >
                                {String(user.user?.usuario).length > 9
                                  ? `${user.user?.usuario?.substring(0, 9)}...`
                                  : user.user?.usuario}
                              </button>
                            </li>
                          </div>
                        </ul>
                        {loading ? (
                          <div className="right-menu">
                            <div
                              className="right-menuHeader mt-5"
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <span>
                                CARREGANDO...{' '}
                                <Spinner size="small" appearance="invert" />
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div className="right-menu">
                            <div className="right-menuHeader">
                              <span>
                                {showTelas &&
                                  filter.trim() !== '' &&
                                  `Resultados para ${filter.toLowerCase()}`}
                                {showTelas &&
                                  filter.trim() === '' &&
                                  `Pesquise para filtrar as telas`}
                                {showLojaPadraoPanel &&
                                  'Selecione sua loja padrão'}
                              </span>
                            </div>
                            <div className="righ-menuTelas">
                              <ul className="ul-telasModulos">
                                {showTrilhas && renderTrilhas()}
                                {showTelas && telas && renderModulo()}
                                {showLojaPadraoPanel && renderPanelLojas()}
                                {showConfigsPanel && renderPanelConfigs()}
                              </ul>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* menus da direita */}
          <div className="nav-menus">
            {/* Menu de FECHAR JANELA */}

            <div className="separator" />
            <div className="nav-menu">
              <a className="btn-closeWindows" onClick={handleCloseScreens}>
                <BiWindowClose />
                <span style={{ whiteSpace: 'nowrap' }}>Fechar telas</span>
              </a>
            </div>
            <div className="separator" />
            {/* Menu de lojas */}
            <div className="nav-menu">
              <button type="button" onClick={handleLojaClick}>
                <MdOutlineStore />
                <span>Loja</span>
              </button>
              {openLojas && (
                <div className="nav-dropPanelLojas">
                  <div className="nav-panelMenuLojas">
                    <div
                      className="backOpacityLoja"
                      aria-label="closeMenuLoja"
                      onClick={() => setOpenLojas(false)}
                      onKeyDown={() => setOpenLojas(false)}
                    />
                    <div className="nav-menuLojas">
                      <div className="menuHeader">
                        <span>Selecione uma loja padrão</span>
                      </div>

                      <ul className="menuLojas" style={{ minWidth: '400px' }}>
                        {loadingLoja && (
                          <span
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <Spinner size="medium" appearance="invert" />
                          </span>
                        )}
                        {!loadingLoja &&
                          selectLojas.map((lojas) => {
                            if (lojas.cod_loja === codLoja) {
                              return (
                                <li key={lojas.cod_loja}>
                                  <button
                                    type="button"
                                    className="li-buttonLoja selected"
                                    onClick={() =>
                                      handleSetLoja(lojas.cod_loja)
                                    }
                                  >
                                    {lojas.des_loja}
                                  </button>
                                </li>
                              );
                            }
                            return (
                              <li key={lojas.cod_loja}>
                                <button
                                  type="button"
                                  className="li-buttonLoja"
                                  onClick={() => handleSetLoja(lojas.cod_loja)}
                                >
                                  {lojas.des_loja}
                                </button>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* Menu de notificações */}
            <div className="separator" />
            <div className="nav-menu">
              <button type="button" onClick={handleNotificacoesClick}>
                <FaRegBell style={{ fontSize: '18px' }} />
                <span>Notificações</span>
              </button>
              {openNotificacoes && (
                <div className="nav-dropPanelLojas">
                  <div className="nav-panelMenuLojas">
                    <div
                      className="backOpacityLoja"
                      aria-label="closeMenuLoja"
                      onClick={() => setOpenNotificacoes(false)}
                      onKeyDown={() => setOpenNotificacoes(false)}
                    />
                    <div className="nav-menuLojas">
                      {loadingNavBarNotificacao === false &&
                        notificacoes.length === 0 && (
                          <div className="menuHeader">
                            <span>Nenhuma nova notificação</span>
                          </div>
                        )}

                      {loadingNavBarNotificacao && (
                        <div className="right-menu">
                          <div
                            className="right-menuHeader mt-5"
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              minWidth: '500px',
                            }}
                          >
                            <span>
                              <Spinner size="medium" appearance="invert" />
                            </span>
                          </div>
                        </div>
                      )}

                      <ul className="menuLojas">
                        {loadingNavBarNotificacao === false &&
                          notificacoes.length > 0 &&
                          notificacoes.map((notificacao: any) => {
                            return (
                              <li
                                style={{
                                  backgroundColor: `${
                                    notificacao.flg_lido ? '' : '#57069e'
                                  }`,
                                }}
                                key={notificacao.cod_notificacao}
                              >
                                <Link to="/central-notificacao" key={v4()}>
                                  <button
                                    type="button"
                                    className="li-buttonLoja"
                                    onClick={() => {
                                      handleOpenNotificacao(
                                        notificacao.cod_notificacao,
                                      );
                                    }}
                                  >
                                    {`#${notificacao.cod_notificacao} - ${
                                      notificacao.des_nome
                                    } -
                                  ${moment(
                                    notificacao.dta_cadastro_view,
                                    'DD/MM/YYYY HH:mm:ss',
                                  ).fromNow()}`}
                                  </button>
                                </Link>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* Menu de configurações */}
            <div className="separator" />
            <div className="nav-menu">
              <button type="button" onClick={handleConfigClick}>
                <span className="nav-user-logged">
                  <FaRegUserCircle size={20} />
                  {String(user.user?.usuario).length > 7
                    ? `${user.user?.usuario?.substring(0, 7)}...`
                    : user.user?.usuario}
                </span>
                <RiArrowDropDownLine />
              </button>
              {openConfig && (
                <div className="nav-dropPanelLojas">
                  <div className="nav-panelMenuLojas">
                    <div
                      className="backOpacityLoja"
                      aria-label="closeMenuLoja"
                      onClick={() => setOpenConfig(false)}
                      onKeyDown={() => setOpenConfig(false)}
                    />
                    <div className="nav-menuLojas">
                      <ul className="menuLojas">
                        {dropdownUserArray.map(({ label, to, icon: Icon }) => {
                          const isUserRestricted = user.user.cod_controle === 2;
                          const toUrl =
                            to === '/edicao-conta' && isUserRestricted
                              ? window.location.pathname
                              : to;

                          const handleClick = () => {
                            if (isUserRestricted && to === '/edicao-conta') {
                              toast.warning(
                                'Edição de conta não permitida, fale com o administrador do sistema',
                              );
                            }
                            setOpenConfig(false);
                          };

                          return (
                            <li>
                              <Link to={toUrl} onClick={handleClick}>
                                <button type="button" className="li-buttonLoja">
                                  <Icon size={20} color="white" />
                                  {label}
                                </button>
                              </Link>
                            </li>
                          );
                        })}
                        <li>
                          <button
                            type="button"
                            className="li-buttonConfig selected"
                            onClick={() => handleLogout(screens)}
                          >
                            <GiExitDoor size={20} color="white" />
                            Sair
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
      <BarraAssineJa />
    </Container>
  );
};

export default Navbars;
