import styled from 'styled-components';

export const TagContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  span {
    width: 50%;
    background-color: #9fc5f8;
    padding: 10px;
    font-weight: 600;
    border-radius: 8px;
    font-size: large;
    text-align: center;
    border: 1px solid rgb(100, 166, 253);
  }
`;
