/* eslint-disable no-console */
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { BiPlus } from 'react-icons/bi';
import { toast } from 'react-toastify';
import { BuscaParceiro, InputCpfCnpj } from '~/components/NovosInputs';
import Separator from '~/components/Separator';
import { pontuaCpfCnpj, validaCPF, validarCNPJ } from '~/utils/functions';
import { useFocusTabFinalizacao } from '../../hooks/useFocusTabFinalizacao';
import { usePdvOnline } from '../../hooks/usePdvOnline';
import { tabFinalizacaoServices } from '../../services/tab-finalizacao';
import { FormaPagamento } from '../FormaPagamento';

import {
  BuscaParceiroContent,
  ButtonAddCliente,
  ClienteContent,
  CpfCnpjContent,
  FinalizacaoContainer,
  FinalizacaoContent,
  LabelBuscaProduto,
} from './styles';
import { Spinner } from 'react-bootstrap';

export const TabFinalizacao: React.FC = () => {
  const {
    finalizacaoContainertRef,
    isOpenModalFunction,
    onOpenModal,
    isModalOpen,
    handlePessoa,
    pessoa,
    formTabFinalizacao,
  } = usePdvOnline();

  const { handleInputFocus } = useFocusTabFinalizacao();
  const [isCnpj, setIsCnpj] = useState(false);
  const [fetching, setFetching] = useState(false);

  const {
    registerTabFinalizacao,
    controlTabFinalizacao,
    watchTabFinalizacao,
    setValueTabFinalizacao,
    resetFieldTabFinalizacao,
    formStateTabFinalizacao: { errors },
  } = formTabFinalizacao;

  const watchNumCpfCnpj: string | undefined =
    watchTabFinalizacao('num_cpf_cnpj');
  const watchParceiro = watchTabFinalizacao('busca_parceiro');

  const delay = useCallback((ms: number): Promise<void> => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }, []);

  useEffect(() => {
    if (pessoa && pessoa.cod_pessoa && pessoa.num_cpf_cnpj) {
      setValueTabFinalizacao(
        'num_cpf_cnpj',
        pontuaCpfCnpj(pessoa.num_cpf_cnpj || ''),
      );
      handleInputFocus('forma_finalizadora_0');
    }
  }, [handleInputFocus, pessoa, setValueTabFinalizacao]);

  useEffect(() => {
    if (!finalizacaoContainertRef.current) return;

    if (pessoa?.num_cpf_cnpj) handleInputFocus('forma_finalizadora_0');
    else handleInputFocus('num_cpf_cnpj');
  }, [finalizacaoContainertRef, handleInputFocus, pessoa?.num_cpf_cnpj]);

  const getCliente = useCallback(
    async (cpfcnpj: string, resetAllFields: boolean) => {
      const cleanedValue = cpfcnpj.replace(/[_\-./]/g, '');

      setFetching(true);

      const { data } = await tabFinalizacaoServices.getClienteCpfCnpj(
        cleanedValue,
      );

      if (data) {
        const cfpCnpj = pontuaCpfCnpj(data.num_cpf_cnpj);

        const payload = {
          value: data.cod_pessoa,
          label: `${data.cod_pessoa} - ${data.nome_pessoa} (${cfpCnpj})`,
        };

        setValueTabFinalizacao('num_cpf_cnpj', cfpCnpj);
        setValueTabFinalizacao('busca_parceiro', payload);
        handlePessoa({ cod_pessoa: data.cod_pessoa, num_cpf_cnpj: cfpCnpj });
        setFetching(false);
      } else {
        if (resetAllFields) resetFieldTabFinalizacao('num_cpf_cnpj');

        resetFieldTabFinalizacao('busca_parceiro');
        handlePessoa({ cod_pessoa: undefined, num_cpf_cnpj: undefined });
        setFetching(false);
      }
    },
    [handlePessoa, resetFieldTabFinalizacao, setValueTabFinalizacao],
  );

  const getClienteBuscaParceiro = useCallback(async () => {
    const shouldDelay = !pessoa?.num_cpf_cnpj;
    const msDelay = shouldDelay ? 2000 : 0;
    await delay(msDelay);

    if (pessoa?.num_cpf_cnpj) await getCliente(pessoa.num_cpf_cnpj || '', true);
  }, [delay, getCliente, pessoa?.num_cpf_cnpj]);

  useEffect(() => {
    getClienteBuscaParceiro();
  }, []);

  useEffect(() => {
    if (pessoa && !pessoa.cod_pessoa && pessoa.num_cpf_cnpj)
      getClienteBuscaParceiro();
  }, [pessoa]);

  useEffect(() => {
    const handleClearCpfCnpj = (ev: KeyboardEvent) => {
      if (finalizacaoContainertRef.current && ev.key === 'Backspace') {
        const inputCpfCnpj: HTMLInputElement | null =
          finalizacaoContainertRef.current.querySelector(
            '[name="num_cpf_cnpj"]',
          );
        if (inputCpfCnpj) {
          const value = inputCpfCnpj.value.replace(/[_\-./]/g, '');
          if (value.length <= 3) {
            resetFieldTabFinalizacao('num_cpf_cnpj');
            handlePessoa({ num_cpf_cnpj: null });
          }
        }
      }
    };

    window.addEventListener('keydown', handleClearCpfCnpj);
    return () => window.removeEventListener('keydown', handleClearCpfCnpj);
  }, [finalizacaoContainertRef, handlePessoa, resetFieldTabFinalizacao]);

  const handleCadastroAutomatico = useCallback(() => {
    if (watchNumCpfCnpj) {
      const iscnpj = watchNumCpfCnpj.replace(/[_\-./]/g, '').length > 11;

      const isValid = iscnpj
        ? validarCNPJ(watchNumCpfCnpj ?? '')
        : validaCPF(watchNumCpfCnpj ?? '');

      if (!isValid && watchNumCpfCnpj) {
        toast.warning(`${iscnpj ? 'CNPJ' : 'CPF'} informado não é válido`);
        handleInputFocus('num_cpf_cnpj');
        return;
      }
    }

    onOpenModal('cadastroCliente', {
      cadastrarCliente: { num_cpf_cnpj: watchNumCpfCnpj },
    });
  }, [handleInputFocus, onOpenModal, watchNumCpfCnpj]);

  return (
    <FinalizacaoContainer ref={finalizacaoContainertRef}>
      <Separator labelText="Cliente" background="transparent" color="black" />

      <FinalizacaoContent>
        <ClienteContent>
          <CpfCnpjContent>
            <div>
              <InputCpfCnpj
                label="CPF/CNPJ:"
                name="num_cpf_cnpj"
                control={controlTabFinalizacao}
                register={registerTabFinalizacao}
                setValue={setValueTabFinalizacao}
                isError={!!errors.num_cpf_cnpj}
                isDisabled={isOpenModalFunction || isModalOpen.isOpen}
                onKeyDown={(ev) => {
                  if (ev.key === 'Backspace') {
                    setValueTabFinalizacao('busca_parceiro', '');
                    handlePessoa({
                      cod_pessoa: undefined,
                      num_cpf_cnpj: undefined,
                    });
                    setIsCnpj(false);
                  }
                }}
                onInput={async (ev: ChangeEvent<HTMLInputElement>) => {
                  const { value } = ev.target;
                  const cpfcnpj = pontuaCpfCnpj(value);

                  await delay(2500);

                  setIsCnpj(cpfcnpj.length === 18);

                  if (cpfcnpj.length === 18) {
                    await getCliente(cpfcnpj, false);
                    return;
                  }

                  if (cpfcnpj.length === 14 && !isCnpj)
                    await getCliente(cpfcnpj, false);
                }}
                onPaste={(ev) => {
                  const { clipboardData } = ev;
                  const pastedText = clipboardData.getData('text');
                  const cpfcnpj = pontuaCpfCnpj(pastedText);

                  setIsCnpj(cpfcnpj.length === 18);
                  handlePessoa({
                    cod_pessoa: undefined,
                    num_cpf_cnpj: undefined,
                  });
                }}
              />
            </div>
            <ButtonAddCliente
              aria-label="Abrir modal de cadastro de cliente"
              onClick={handleCadastroAutomatico}
              type="button"
              disabled={!!watchParceiro || isModalOpen.isOpen || fetching}
            >
              {!fetching && <BiPlus size={23} color="white" />}
              {fetching && <Spinner animation="border" size="sm" />}
            </ButtonAddCliente>
          </CpfCnpjContent>
          <BuscaParceiroContent>
            <LabelBuscaProduto htmlFor="busca">Cliente</LabelBuscaProduto>
            <BuscaParceiro
              placeholder="Selecione..."
              name="busca_parceiro"
              register={registerTabFinalizacao}
              isError={!!errors.busca_parceiro}
              control={controlTabFinalizacao}
              disabled={isOpenModalFunction || isModalOpen.isOpen}
              customOptions={{ buscarPor: { cliente: true } }}
              changeSelected={(selected: any) => {
                const { value, num_cpf_cnpj, cod_pessoa, nome_pessoa } =
                  selected;

                const label = `${cod_pessoa} - ${nome_pessoa} (${pontuaCpfCnpj(
                  num_cpf_cnpj,
                )})`;

                setValueTabFinalizacao('busca_parceiro', { value, label });
                setValueTabFinalizacao(
                  'num_cpf_cnpj',
                  pontuaCpfCnpj(num_cpf_cnpj),
                );
                handlePessoa({
                  cod_pessoa,
                  num_cpf_cnpj: pontuaCpfCnpj(num_cpf_cnpj),
                });
              }}
            />
          </BuscaParceiroContent>
        </ClienteContent>
        <Separator
          labelText="Forma de Pagamento"
          background="transparent"
          color="black"
        />
        <div>
          <FormaPagamento />
        </div>
      </FinalizacaoContent>
    </FinalizacaoContainer>
  );
};
