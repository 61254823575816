import React, {
  ChangeEvent,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Alert, Button, Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Loja from '~/components/Loja';
import api from '~/services/api';

import {
  Container,
  FileContainer,
  InputFileContainer,
  TableContainer,
} from './styles';

import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-toastify';
import { DataGrid, GridColumns } from '@material-ui/data-grid';
import { errorHandler } from '~/utils/ErrorHandler';
import { useQuery } from 'react-query';
import FooterDefault from '~/components/FooterDefault';
import useAuth from '~/hooks/useAuth';
import { AiFillEye } from 'react-icons/ai';

import LinearProgress from '@material-ui/core/LinearProgress';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-solarized_dark';
import 'ace-builds/webpack-resolver';
import FormDefault from '~/components/FormDefault';

export interface Select {
  value: string | number | undefined;
  label: string | undefined;
}

interface FormProps {
  cod_loja: number | Array<number> | undefined;
}

interface Option {
  label: string;
  value: string;
}

interface File {
  des_nome_arquivo: string;
  original_name: string;
}

interface Log {
  cod_log: number;
  des_fornecedor: string;
  cod_xml: number;
  num_chave_acesso: string;
  num_nf: number;
  num_serie_nf: string;
  motivo: string;
  dta_cadastro: string;
}
export interface LogFilesProps {
  logs: {
    date: string;
    fileName: string;
  }[];
  logFound: boolean;
}

const MySwal = withReactContent(Swal);

const ProcessamentoManualXml: React.FC = () => {
  const { user } = useAuth();
  const [form, setForm] = useState<FormProps>({} as FormProps);
  const [currentTab, setCurrentTab] = useState<any>('0');
  const [accessKeys, setAccessKeys] = useState<readonly Option[]>([]);
  const [accessKey, setAccessKey] = useState<string | undefined>('');
  const [logs, setLogs] = useState<Log[]>([]);
  const [fileList, setFileList] = useState<File[]>([]);
  const [enableQuery, setEnableQuery] = useState(false);
  const [acbrLog, setAcbrLog] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [dtaExecucao, setDtaExecucao] = useState<Date>();

  const InputFilesRef = useRef<HTMLInputElement>(null);

  const { register, control, setValue, getValues } = useForm({
    reValidateMode: 'onChange',
    mode: 'onSubmit',
  });

  // useEffect(() => {
  //   setFileList([]);
  //   setLogs([]);
  //   setAccessKeys([]);
  //   setAccessKey(undefined);
  //   setValue('arquivos', null);
  //   setEnableQuery(false);
  // }, [currentTab, setValue]);

  const columns: GridColumns = [
    {
      field: 'id',
      headerName: 'Id',
      hide: true,
      width: 80,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'cod_log',
      headerName: 'Seq',
      width: 70,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'des_fornecedor',
      headerName: 'Fornecedor',
      width: 250,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        return (
          <span
            style={{
              fontSize: '11px',
            }}
          >
            {row.des_fornecedor}
          </span>
        );
      },
    },
    {
      field: 'cod_xml',
      headerName: 'Cód. XML',
      width: 100,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'num_chave_acesso',
      headerName: 'Chave Acesso',
      width: 300,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        return (
          <span
            style={{
              fontSize: '11px',
            }}
          >
            {row.num_chave_acesso}
          </span>
        );
      },
    },
    {
      field: 'num_nf',
      headerName: 'Num. NF-e',
      width: 110,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'num_serie_nf',
      headerName: 'Série',
      width: 80,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'motivo',
      headerName: 'Log',
      width: 300,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        return (
          <span
            title={row.motivo}
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100%',
            }}
          >
            {row.motivo}
          </span>
        );
      },
    },
    {
      field: 'dta_cadastro',
      headerName: 'Data',
      width: 200,
      sortable: false,
      disableColumnMenu: true,
    },
  ];

  const getLogs = useCallback(async () => {
    try {
      const keys = accessKeys.map((key) => key.value);
      // Verificar se ambos, keys e fileList, estão vazios ou não definidos
      if (!keys.length && (!fileList || !fileList.length)) {
        console.log(
          'Não há chaves de acesso ou arquivos listados para consulta.',
        );
        return;
      }

      const res = await api.get('/xml-manual/logs', {
        params: {
          lista_num_chave_acesso: keys,
          lista_xml_arquivo: fileList,
          tipo: currentTab,
          cod_loja: form.cod_loja,
          dtaExecucao: dtaExecucao || new Date(),
        },
      });

      // Processamento dos dados recebidos
      const { data } = res.data;
      if (data) {
        const regs = data.map((log: Log) => {
          return {
            id: log.cod_log,
            cod_log: log.cod_log,
            cod_xml: log.cod_xml,
            dta_cadastro: log.dta_cadastro,
            motivo: log.motivo,
            num_chave_acesso: log.num_chave_acesso,
            num_nf: log.num_nf,
            num_serie_nf: log.num_serie_nf,
            des_fornecedor: log.des_fornecedor,
          };
        });

        // Atualização do estado com os logs processados
        setLogs(regs);
      }
    } catch (error: any) {
      setEnableQuery(false);
      console.error('Erro ao buscar logs:', error);
    }
  }, [dtaExecucao, accessKeys, fileList]);

  const handleUploadFiles = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      setLoading(true);
      const { files } = event.currentTarget;

      if (!files || files.length === 0) {
        toast.warning('Nenhum arquivo selecionado.');
        return;
      }
      const formData = new FormData();
      const fileListNames = fileList.map((file) => file.original_name);

      let isDuplicate = false;

      for (let i = 0; i < files.length; i++) {
        if (files[i].type !== 'text/xml') {
          toast.warning(
            'Arquivo adicionado com formato não permitido, certifique-se de adicionar arquivos no formato XML',
          );
          return;
        }
      }

      for (let i = 0; i < files.length; i++) {
        if (fileListNames.includes(files[i].name)) {
          toast.warning('Arquivo já adicionado.');
          isDuplicate = true;
          // eslint-disable-next-line no-continue
          continue;
        }
        formData.append('arquivos', files[i]);
      }

      if (isDuplicate && formData.getAll('arquivos').length === 0) {
        setLoading(false);
        return;
      }

      formData.append('cod_loja', String(form.cod_loja));
      const res = await api.post('/salvar-xml', formData);
      const { data } = res.data;
      setFileList([...fileList, ...data]);
    } catch (error) {
      console.error('Falha ao fazer upload:', error);
    } finally {
      setLoading(false);
      if (InputFilesRef && InputFilesRef.current) {
        InputFilesRef.current.value = '';
      }
    }
  };

  const createOption = (label: string) => ({
    label,
    value: label,
  });

  const validateAccessKey = (key: string) => {
    const hasExists = accessKeys.find((value) => key === value.value);
    if (hasExists) {
      toast.warning('Chave de acesso já selecionada.');
      return false;
    }
    return true;
  };

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!accessKey) return;

    if (['Enter', 'Tab'].indexOf(event.key) > -1) {
      if (!validateAccessKey(accessKey)) {
        return event.preventDefault();
      }
      setAccessKeys((prev) => [...prev, createOption(accessKey)]);
      setAccessKey('');
      event.preventDefault();
    }
  };

  const validateInputs = useCallback(() => {
    const hasInvalidKeys = (keys: readonly Option[]) =>
      keys.some((key) => !/^[0-9]{44}$/.test(key.value));

    if (!form.cod_loja) {
      toast.warning('Informe a Loja para o processamento.');
      return false;
    }

    if (Number(currentTab) === 0 && (!fileList || fileList.length < 1)) {
      toast.warning('Insira o(s) arquivo(s) para o processamento.');
      return false;
    }

    if (Number(currentTab) !== 0 && (!accessKeys || accessKeys.length < 1)) {
      toast.warning('Insira a(s) chave(s) para o processamento.');
      return false;
    }

    if (Number(currentTab) !== 0 && hasInvalidKeys(accessKeys)) {
      toast.warning(
        'Há chave(s) de acesso inválida(s). Por favor, faça a regularização antes de processar.',
      );
      return false;
    }

    return true;
  }, [accessKeys, currentTab, fileList, form.cod_loja]);

  const onProcess = useCallback(async () => {
    if (!validateInputs()) return;

    try {
      setLoading(true);
      const keys = accessKeys.map((key) => key.value);
      const data = {
        lista_num_chave_acesso: keys,
        lista_xml_arquivo: fileList,
        loja: form.cod_loja,
      };

      setEnableQuery(true);
      setDtaExecucao(new Date());
      const res = await api.post(`/processar-xml-manual/${currentTab}`, data);
      const { success, message } = res.data;
      if (success) {
        toast.success(message);
      } else {
        toast.error(
          message || 'Processamento falhou sem uma mensagem de erro.',
        );
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [accessKeys, currentTab, fileList, form.cod_loja, validateInputs]);

  useQuery('logs_processamento_manual', getLogs, {
    enabled: enableQuery,
    refetchInterval: 5 * 1000,
    // staleTime
  });

  const handleRemoveItem = (name: string) => {
    const dt = new DataTransfer();
    const files = getValues('arquivos');

    for (let i = 0; i < files.length; i++) {
      if (files[i].name !== name) {
        dt.items.add(files[i]);
      }
    }

    setValue('arquivos', dt.files);

    if (dt.files.length < 1) {
      if (InputFilesRef.current) {
        InputFilesRef.current.value = '';
      }
    }

    const newFileList = fileList.filter((file) => {
      return file.original_name !== name;
    });

    setFileList(newFileList);
  };

  const handleClick = async () => {
    if (fileList.length > 0) {
      const shouldContinue = await MySwal.fire({
        title: ``,
        text: `Carregar um novo arquivo removerá os arquivos existentes. Deseja prosseguir?`,
        showCloseButton: true,
        confirmButtonColor: '#0065FF',
        cancelButtonColor: '#ccc',
        showDenyButton: true,
        confirmButtonText: 'Sim',
        denyButtonText: 'Não',
      }).then(async (result) => {
        if (result.isConfirmed) {
          return true;
        }
        return false;
      });
      if (shouldContinue) {
        setFileList([]);
        if (InputFilesRef && InputFilesRef.current) {
          InputFilesRef.current.value = '';
          InputFilesRef.current.click();
        }
      }
      return;
    }

    if (InputFilesRef) {
      InputFilesRef.current?.click();
    }
  };

  const resetLoja = useCallback(() => {
    if (user.loja_data) {
      setForm((prev) => ({ ...prev, cod_loja: user.loja_data.cod_loja }));
      setValue('loja', user.loja_data.cod_loja);
    }
  }, [setValue, user]);

  const downloadAcbrLog = useCallback(
    async (isVisualization: boolean) => {
      setLoading(true);
      try {
        const response = await api.get(
          `/transmitir-nfe/download-acbr-log/${form.cod_loja}/`,
        );
        if (isVisualization && response.data) {
          setAcbrLog(response.data);
        }

        if (!isVisualization) {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: 'text/plain' }),
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'acbr.log');
          document.body.appendChild(link);
          link.click();

          // Verifica se o link tem um parentNode antes de tentar removê-lo
          if (link.parentNode) {
            link.parentNode.removeChild(link);
          }
          window.URL.revokeObjectURL(url);
        }
        setLoading(false);
      } catch (err: any) {
        toast.warn(err.data);
        setLoading(false);
      }
    },
    [form.cod_loja],
  );

  useEffect(() => {
    resetLoja();
  }, [user, resetLoja]);

  return (
    <Container>
      <FormDefault
        codTela={198}
        title="Processamento Manual de XML"
        onSave={() => Promise.resolve()}
        onCancel={() => []}
        onClearFields={() => []}
        onNew={() => []}
        onDelete={() => []}
        onReturnSearch={() => []}
        isDelete={false}
        isNew={false}
        isCancel={false}
        isClear={false}
        isSave
      >
        <Loja
          selectedLoja={form.cod_loja}
          onChange={(e) => {
            setForm({ ...form, cod_loja: e });
          }}
        />
        <div className="mt-2">
          <Tabs
            id="type-process"
            activeKey={currentTab}
            onSelect={(tab: string | null) => setCurrentTab(tab)}
          >
            <Tab eventKey="0" title="Arquivo">
              <Row>
                <Col sm={12} className="mb-2">
                  <InputFileContainer>
                    <Controller
                      name="arquivos"
                      control={control}
                      render={({ field: { onChange } }) => {
                        return (
                          <input
                            {...register('arquivos')}
                            type="file"
                            multiple
                            ref={InputFilesRef}
                            accept="text/xml"
                            // className={
                            //   isError ? 'form-control is-invalid' : 'form-control'
                            // }
                            // value={value}
                            // placeholder={placeholder}
                            onChange={async (
                              event: ChangeEvent<HTMLInputElement>,
                            ) => {
                              onChange(event.currentTarget.files);
                              await handleUploadFiles(event);

                              // onChange(event.target.value.toUpperCase());
                            }}
                            // {...rest}
                          />
                        );
                      }}
                    />
                  </InputFileContainer>
                  <Button
                    onClick={handleClick}
                    disabled={loading}
                    className="mt-2"
                    style={{
                      backgroundColor: '#57069e',
                      outline: 'none',
                      boxShadow: 'none',
                    }}
                  >
                    Selecionar Arquivos
                  </Button>
                </Col>
              </Row>
              <Row className="d-flex flex-row mb-2">
                {fileList.map((file) => {
                  return (
                    <div
                      key={file.des_nome_arquivo}
                      style={{
                        maxWidth: '150px',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <div className="d-flex gap-4">
                        <FileContainer>
                          <span
                            className="align-self-center"
                            style={{
                              color: 'blue',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              width: 'calc(100% - 25px)',
                            }}
                          >
                            {file.original_name}
                          </span>
                          <span
                            className="file-item-close"
                            onClick={
                              loading
                                ? undefined
                                : () => handleRemoveItem(file.original_name)
                            }
                            style={{ minWidth: '5px' }}
                          >
                            x
                          </span>
                        </FileContainer>
                      </div>
                    </div>
                  );
                })}
              </Row>
            </Tab>
            <Tab eventKey="1" title="Chave de Acesso">
              <Row className="mt-2">
                <Col sm={12}>
                  <Form.Group>
                    <Form.Label>Chaves de Acesso</Form.Label>
                    <CreatableSelect
                      components={{ DropdownIndicator: null }}
                      value={accessKeys}
                      inputValue={accessKey}
                      isClearable
                      isMulti
                      isDisabled={loading}
                      menuIsOpen={false}
                      placeholder="Digite o número da chave de acesso e pressione enter"
                      onChange={(value) => {
                        setAccessKeys(value);
                      }}
                      onKeyDown={handleKeyDown}
                      onInputChange={(value) => setAccessKey(value)}
                    />
                  </Form.Group>
                </Col>
                <Col sm={12} className="mt-2">
                  <Alert variant="warning" className="mb-2">
                    Atenção: O servidor da SEFAZ só permite 20 buscas por hora,
                    não faça buscas acima desse limite, se o fizer você estará
                    impedido de fazer mais buscas pelo período de 1 hora.
                  </Alert>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="2" title="Log's do Servidor">
              <Row>
                <Col sm={12} style={{ padding: '5px 0px 5px 11px' }}>
                  <Button
                    onClick={() => downloadAcbrLog(true)}
                    disabled={loading}
                    title="Visualizar"
                    variant="success"
                    size="sm"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '3px',
                    }}
                  >
                    Carregar Último Arquivo
                    <AiFillEye size={16} className="btn-icon" />
                  </Button>
                </Col>
              </Row>
            </Tab>
          </Tabs>
          {loading && (
            <LinearProgress
              style={{ background: '#c2a0f8', color: '#8850BF' }}
            />
          )}
          {currentTab !== '2' && (
            <>
              <TableContainer>
                <DataGrid
                  // rowsPerPageOptions={[5, 10, 15]}
                  rows={logs}
                  columns={columns}
                  pageSize={10}
                  // pagination={undefined}
                  localeText={{
                    noRowsLabel: 'Nenhum registro encontrado...',
                    columnMenuLabel: 'Menu',
                    columnMenuFilter: 'Filtrar',
                    columnMenuHideColumn: 'Esconder',
                    columnMenuUnsort: 'Não ordenar',
                    columnMenuSortAsc: 'Ordernar ASC',
                    columnMenuSortDesc: 'Ordernar DESC',
                    columnMenuShowColumns: 'Mostrar columnas',
                  }}
                />
              </TableContainer>

              <FooterDefault codTela={198}>
                <Row className="mt-2">
                  <Col sm={12} className="d-flex justify-content-end">
                    <Button
                      className="btn-processar"
                      disabled={loading}
                      onClick={() => onProcess()}
                    >
                      {loading ? 'Em execução' : 'Processar'}
                    </Button>
                  </Col>
                </Row>
              </FooterDefault>
            </>
          )}
          {currentTab === '2' && (
            <AceEditor
              placeholder="Log não carregado"
              mode="xml"
              theme="solarized_dark"
              name="blah2"
              fontSize={14}
              showPrintMargin={false}
              showGutter={false}
              highlightActiveLine
              value={acbrLog}
              readOnly
              width="100%"
              setOptions={{
                enableBasicAutocompletion: false,
                enableLiveAutocompletion: false,
                enableSnippets: false,
                showLineNumbers: false,
                tabSize: 2,
                wrap: true,
              }}
            />
          )}
        </div>
      </FormDefault>
    </Container>
  );
};

export default ProcessamentoManualXml;
