import api from '~/services/api';
import { dataFormProps } from '../types';

const TrilhaApi = {
  getTrilhas: async (): Promise<any> => {
    try {
      const { data } = await api.get(`/trilhas`);
      if (data.success) {
        return {
          success: data.success,
          data: data.data,
        };
      }
    } catch (error) {
      return {
        success: false,
        data: [],
      };
    }
  },
  updateTrilhas: async (formData: any): Promise<any> => {
    try {
      const { data } = await api.put(`/order-trilhas`, {
        trilhas_order: [...formData],
      });
      if (data.success) {
        return {
          success: data.success,
          message: data.message,
        };
      }
    } catch (error) {
      console.log(error);
    }
  },
  getTelas: async (cod_controle: number): Promise<any> => {
    try {
      const { data } = await api.get(`/telasModulo/${cod_controle}`);
      if (data.success) {
        const telas: any[] = [];
        data.data.forEach((modulo: any) => {
          if (modulo.telas) {
            modulo.telas.forEach((tela: any) => {
              telas.push({
                value: tela.cod_tela,
                label: tela.des_tela,
                des_modulo: modulo.des_modulo.toUpperCase(),
                cod_modulo: tela.cod_modulo,
              });
            });
          }
        });
        telas.sort((a, b) => a.label.localeCompare(b.label));

        return {
          success: data.success,
          data: telas,
        };
      }
      return {
        success: false,
        data: [],
      };
    } catch (error) {
      return {
        success: false,
        data: [],
      };
    }
  },
  postTrilha: async (formData: dataFormProps): Promise<any> => {
    try {
      const request = await api.post(`/trilha`, {
        ...formData,
      });
      const { success, message } = request.data;

      return {
        success,
        message,
      };
    } catch (error) {
      console.log(error);
    }
  },
  putTrilha: async (
    cod_trilha: number,
    formData: dataFormProps,
  ): Promise<any> => {
    try {
      const request = await api.put(`/trilha/${cod_trilha}`, {
        ...formData,
      });
      const { success, message } = request.data;

      return {
        success,
        message,
      };
    } catch (error) {
      console.log(error);
    }
  },

  getTelasTrilha: async (cod_trilha: number): Promise<any> => {
    try {
      const { data } = await api.get(`/trilha/${cod_trilha}`);
      if (data.success) {
        return {
          success: data.success,
          data,
        };
      }
      return {
        success: false,
        data: [],
      };
    } catch (error) {
      return {
        success: false,
        data: [],
      };
    }
  },
  deleteTelaTrilhas: async (cod_trilha: number): Promise<any> => {
    const { data } = await api.delete(`/trilha/${cod_trilha}`);

    return {
      success: data.success,
      message: data.message,
    };
  },
};

export default TrilhaApi;
