import * as yup from 'yup';

const cidadeSchema = yup.object({
  value: yup.number().required('O valor (value) da cidade é obrigatório.'),
  ufValue: yup.string().required('O valor do estado (ufValue) é obrigatório.'),

  label: yup.string().required('O label da cidade é obrigatório.'),
  cidade: yup.string().required('O nome da cidade é obrigatório.'),
});

const customerDataSchema = yup.object().shape({
  des_nome: yup.string().required('O nome é obrigatório.'),

  des_email: yup
    .string()
    .email('Digite um e-mail válido.')
    .required('O e-mail é obrigatório.'),

  num_cpf_cnpj: yup.string().required('O CPF ou CNPJ é obrigatório.'),

  num_telefone: yup
    .string()
    .matches(
      /^\(?\d{2}\)?\s?\d{4,5}-?\d{4}$/,
      'Digite um número de telefone válido no formato (XX) XXXXX-XXXX.',
    )
    .required('O telefone é obrigatório.'),

  num_cep: yup
    .string()
    .matches(/^\d{5}-?\d{3}$/, 'Digite um CEP válido no formato XXXXX-XXX.')
    .required('O CEP é obrigatório.'),

  des_rua: yup
    .string()
    .required('O nome da rua é obrigatório.')
    .max(200, 'O nome da rua deve ter no máximo 200 caracteres.'),

  des_numero: yup
    .string()
    .required('O número é obrigatório.')
    .max(10, 'O número deve ter no máximo 10 caracteres.'),

  des_complemento: yup
    .string()
    .nullable()
    .max(100, 'O complemento deve ter no máximo 100 caracteres.'),

  des_bairro: yup
    .string()
    .required('O bairro é obrigatório.')
    .max(100, 'O bairro deve ter no máximo 100 caracteres.'),

  des_cidade: cidadeSchema,
});

export default customerDataSchema;
