import React, { useCallback, useState } from 'react';
import { FaRegCreditCard } from 'react-icons/fa6';
import { useMeuPlano } from '../../hooks/useMeuPlano';
import { HeaderScreen } from '../HeaderScreen';
import { ScreenName } from '../ScreenName';
import { CustomButtonNovo } from '~/components/Buttons/CustomButtonNovo';
import { MudarDadosPagamento } from './styles';
import PaymentAccordion from '../PaymentAccordion';
import { paymentMethods } from '../../utils/paymentMethods';
import { MdChevronRight } from 'react-icons/md';
import { serviceMeuPlano } from '../../services/meu-plano';
import { getYear } from 'date-fns';
import { toast } from 'react-toastify';

export const ChangePaymentMethod: React.FC = () => {
  const {
    handleScreen,
    screen,
    methodFaturamento,
    formCard,
    codSeqPlano,
    formClienteContratacao,
    isDemonstracao,
    tipoStatus,
    contratacaoClienteFuturo,
  } = useMeuPlano();
  const { getValuesCard, triggerCard, setValueCard } = formCard;
  const { triggerClienteContratacao } = formClienteContratacao;
  const [showLoading, setShowLoading] = useState(false);

  const validaDadosCliente = useCallback(async (): Promise<boolean> => {
    const isValid = await triggerClienteContratacao();

    if (!isValid) {
      return false;
    }

    return true;
  }, [triggerClienteContratacao]);

  const validaDadosCartao = useCallback(async (): Promise<boolean> => {
    const isValid = await triggerCard();

    if (!isValid) {
      return false;
    }
    return true;
  }, [triggerCard]);

  const changePaymentMethod = useCallback(async () => {
    try {
      if (!methodFaturamento) return;

      setShowLoading(true);
      setValueCard('tela', 'MUDAR-FORMA-PAGAMENTO');
      const tipo_forma_pagamento =
        methodFaturamento === 'cartao-credito' ? 1 : 0;

      let payload: any = {};

      if (tipo_forma_pagamento === 1) {
        if (!(await validaDadosCartao())) {
          return false;
        }
        if (!(await validaDadosCliente())) {
          return false;
        }
        setValueCard('tela', '');
        const expiry = getValuesCard('expiry');

        const number = getValuesCard('number');
        const holderName = getValuesCard('name');
        const expiryMonth = expiry.split('/')[0];
        const expiryYear = expiry.split('/')[1];
        const ccv = getValuesCard('cvc');

        const name = getValuesCard('des_nome');
        const email = getValuesCard('des_email');
        const cpfCnpj = getValuesCard('num_cpf_cnpj');
        const postalCode = getValuesCard('num_cep');
        const addressNumber = getValuesCard('num_endereco');
        const addressComplement = getValuesCard('des_complemento');
        const phone = getValuesCard('num_telefone') || '';
        const mobilePhone = getValuesCard('num_celular') || '';

        const anoAtual = getYear(new Date());
        const preYear = String(anoAtual).substring(0, 2);

        payload = {
          creditCard: {
            holderName,
            number,
            expiryMonth,
            expiryYear: `${preYear}${expiryYear}`,
            ccv,
          },
          creditCardHolderInfo: {
            name,
            email,
            cpfCnpj,
            postalCode,
            addressNumber,
            addressComplement,
            phone,
            mobilePhone,
          },
        };
      } else {
        if (!(await validaDadosCliente())) {
          return false;
        }
        payload = {};
      }

      let cod_seq_plano = codSeqPlano;

      if (!isDemonstracao && contratacaoClienteFuturo?.tipo_status === 2) {
        cod_seq_plano = contratacaoClienteFuturo?.cod_seq_plano;
      }

      const response = await serviceMeuPlano.post(
        `/change-payment-method/${cod_seq_plano}/${tipo_forma_pagamento}`,
        payload,
      );

      toast.success(response.message);

      handleScreen('HOME');
    } catch (error) {
      console.error(error);
    } finally {
      setShowLoading(false);
    }
  }, [
    codSeqPlano,
    contratacaoClienteFuturo,
    getValuesCard,
    handleScreen,
    isDemonstracao,
    methodFaturamento,
    setValueCard,
    validaDadosCartao,
    validaDadosCliente,
  ]);

  if (screen === 'HOME') {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          cursor: `${
            isDemonstracao && tipoStatus.tipoStatus !== 2
              ? 'not-allowed'
              : 'pointer'
          }`,
        }}
      >
        <FaRegCreditCard
          size={130}
          color={`${
            isDemonstracao && tipoStatus.tipoStatus !== 2
              ? '#9c9c9c'
              : '#6F42C1'
          }`}
        />
        <p
          style={{
            textAlign: 'center',
            fontSize: '1.25rem',
            lineHeight: '1.875rem',
          }}
        >
          Mudar forma de pagamento
        </p>
      </div>
    );
  }

  return (
    <MudarDadosPagamento>
      <HeaderScreen showButtonVoltar={screen === 'MUDAR-FORMA-PAGAMENTO'} />
      <div>
        <ScreenName name="Mudar forma de pagamento" />
        <span>
          A atualização de seus dados de pagamento ou forma de pagamento não
          ativa, faz upgrade ou estende sua assinatura.
        </span>
        <hr />
      </div>
      <div className="content">
        <div className="methods">
          <PaymentAccordion
            methods={paymentMethods}
            screen="mudar-dados-pagamento"
          />
          {methodFaturamento === 'cartao-credito' && (
            <span style={{ marginTop: '0.5rem' }}>
              Ao adiquirir e ativar o plano Premium, você autoriza a{' '}
              <strong>konvix</strong> a realizar cobranças mensais automáticas
              até o cancelamento. Se houver alguma alteração no preço, você
              receberá um aviso com antecedência. Você pode consultar a data de
              renovação ou cancelar a assinatura quando quiser na página da sua
              conta. Não são emitidos reembolsos parciais. São aplicáveis os
              termos e condições do Premium. Você concorda em convidar somente
              pessoas que moram no mesmo endereço que o seu. Esta é uma compra
              que será processada por um dos parceiros de pagamento da{' '}
              <strong>Konvix</strong>, de acordo com seus termos e condições. Ao
              clicar em &#34;Mudar forma de pagamento&#34;, você dá ciência e
              aceita os termos e as condições desta transação.
            </span>
          )}
          <div className="button">
            <CustomButtonNovo
              disabled={false}
              onClick={async () => {
                await changePaymentMethod();
              }}
              label={
                !methodFaturamento ? 'Avançar' : 'Mudar forma de pagamento'
              }
              width="14.375rem"
              icon={!methodFaturamento ? MdChevronRight : undefined}
              showLoading={showLoading}
              iconPosition={!methodFaturamento ? 'right' : undefined}
            />
          </div>
        </div>
      </div>
    </MudarDadosPagamento>
  );
};
