import React from 'react';
import {
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Checkbox from '@atlaskit/checkbox';
import { TableEmpty } from '../../styles';
import { useAceiteDePagamento } from '../../hooks/useAceiteDePagamento';

export const DataTable: React.FC = () => {
  const { titulosApagar, loader, handleCheck } = useAceiteDePagamento();
  return (
    <>
      <TableContainer style={{ maxHeight: 440 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Vincular?</TableCell>
              <TableCell>Loja</TableCell>
              <TableCell>Docto</TableCell>
              <TableCell>Núm. NF</TableCell>
              <TableCell>Série NF</TableCell>
              <TableCell>Finalizadora</TableCell>
              <TableCell>Valor</TableCell>
              <TableCell>Entrada</TableCell>
              <TableCell>Vencimento</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {titulosApagar && !loader && titulosApagar.length > 0 && (
              <>
                {titulosApagar.map((item: any) => {
                  return (
                    <TableRow key={item.cod_conta_pagar}>
                      <TableCell
                        style={{
                          display: 'flex',
                          marginLeft: '1rem',
                        }}
                      >
                        <Checkbox
                          isChecked={item.flg_boleto}
                          onChange={() => handleCheck(item.cod_parcela_pagar)}
                        />
                      </TableCell>
                      <TableCell>{item.cod_loja}</TableCell>
                      <TableCell>{item.num_docto}</TableCell>
                      <TableCell>{item.num_nf}</TableCell>
                      <TableCell>{item.num_serie}</TableCell>
                      <TableCell>{item.des_finalizadora}</TableCell>
                      <TableCell>
                        {new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        }).format(item.val_parcela)}
                      </TableCell>
                      <TableCell className="alignDate">
                        {item.dta_entrada}
                      </TableCell>
                      <TableCell className="alignDate">
                        {item.dta_vencimento}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </>
            )}
          </TableBody>
        </Table>
        {loader && <LinearProgress />}
        <>
          {titulosApagar && !loader && titulosApagar.length === 0 && (
            <TableEmpty>
              <h3>Nenhum título encontrado conforme critérios informados!</h3>
            </TableEmpty>
          )}
        </>
      </TableContainer>
    </>
  );
};
