import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;

  .btn-importar {
    display: inline-block;
    width: 100%;
    text-align: center;
    background-color: #a3c293;
    color: #fff;
    border: none;
    margin-top: 25px;
    padding: 0.5rem;
    font-size: 0.9rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .btn-importar:hover {
    background-color: #91ad80;
  }
`;
