import { IconType } from 'react-icons';
import { BiSolidCartDownload } from 'react-icons/bi';
import { BsFillBriefcaseFill } from 'react-icons/bs';
import { FaEye } from 'react-icons/fa';
import { FaMoneyBill1Wave } from 'react-icons/fa6';
import { MdClose, MdManageSearch } from 'react-icons/md';

interface FunctionsMapProps {
  icon: IconType;
  label: string;
  shortcut: string;
}

export const functionsMap: FunctionsMapProps[] = [
  { icon: MdManageSearch, label: 'Buscar Vendas', shortcut: '1' },
  { icon: BiSolidCartDownload, label: 'Importar Pedido', shortcut: '2' },
  { icon: BsFillBriefcaseFill, label: 'Reforço', shortcut: '3' },
  { icon: FaMoneyBill1Wave, label: 'Sangria', shortcut: '4' },
  { icon: FaEye, label: 'Resumo de Caixa', shortcut: '5' },
  { icon: MdClose, label: 'Fechar Caixa', shortcut: '0' },
];
