import React, {
  createContext,
  useState,
  ReactNode,
  useEffect,
  useCallback,
  useContext,
} from 'react';
import api from '~/services/api';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema, finalizadora } from './validations';

import {
  Categoria,
  Conta,
  Condicao,
  FormFinalizadora,
  FormFinalizadoraLoja,
  Loja,
} from './protocols';

import useAuth from '~/hooks/useAuth';

type FinalizadoraContextData = {
  categorias: any[];
  contas: any[];
  condicoes: any[];
  onChangeFormFinalizadora: (form: FormFinalizadora) => void;
  formFinalizadora: FormFinalizadora;
  formFinalizadoraLoja: FormFinalizadoraLoja;
  onChangeFormFinalizadoraLoja: (form: FormFinalizadoraLoja) => void;
  initLoja: boolean;
  handleInitLoja: (value: boolean) => void;
  handleClearFormFinalizadoraLoja: () => void;
  handleClearForm: () => void;
  onChangeInit: (value: boolean) => void;
  init: boolean;
  getContas: (value: number) => void;
  lojasUser: any;
  getLojas: () => void;
  onChangeFormFinalizadoraValue: (form: FormFinalizadoraLoja) => void;
  handleResetFormLoja: () => void;
  getContasAll: () => void;
  contasAll: any;
  onEdit: boolean;
  setOnEdit: (value: boolean) => void;
  flagConfTesAuto: boolean;
  setFlagConfTestAuto: (value: boolean) => void;
};

type FormContextData = {
  register: any;
  handleSubmit: any;
  formState: any;
  setValue: any;
  control: any;
  reset: any;
  setError: any;
  clearErrors: any;
  getValues: any;
  watch: any;

  //  formLojaFinalizadora
  handleSubmitFinalizadora: any;
  formStateFinalizadora: any;
  FinalizadoraRegister: any;
  FinalizadoraControl: any;
  getValuesFinalizadora: any;
  setValueFinalizadora: any;
};

export const FormContext = createContext({} as FormContextData);
export const FinalizadoraContext = createContext({} as FinalizadoraContextData);

interface FinalizadoraContextProviderProps {
  children: ReactNode;
}

export function FinalizadoraContextProvider({
  children,
}: FinalizadoraContextProviderProps): JSX.Element {
  const infoLoja = JSON.parse(localStorage.YOUR_KEY_USER_DATA);
  const user = useAuth();
  const [descLoja, setDescLoja] = useState('');
  const [contasAll, setContasAll] = useState('');
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    setError,
    clearErrors,
    getValues,
    formState: { isDirty, errors },
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  const {
    register: FinalizadoraRegister,
    handleSubmit: handleSubmitFinalizadora,
    control: FinalizadoraControl,
    reset: resetFinalizadora,
    getValues: getValuesFinalizadora,
    setValue: setValueFinalizadora,
    formState: formStateFinalizadora,
  } = useForm({
    resolver: yupResolver(finalizadora),
    reValidateMode: 'onBlur',
  });

  const formFinalizadoraLojaBlank: FormFinalizadoraLoja = {
    cod_loja: {
      value: infoLoja.loja,
      isInvalid: false,
      isRequired: false,
    },
    des_loja: {
      value: descLoja,
      isInvalid: false,
      isRequired: false,
    },
    des_tecla: {
      value: '0',
      isInvalid: false,
      isRequired: true,
    },
    num_condicao: {
      value: 30,
      isInvalid: false,
      isRequired: false,
    },
    cod_condicao: {
      value: { label: '', value: 2 },
      isInvalid: false,
      isRequired: true,
    },
    cod_cc: {
      value: { label: '', value: undefined },
      isInvalid: true,
      isRequired: true,
    },
    cod_categoria: {
      value: { label: '', value: undefined },
      isInvalid: true,
      isRequired: true,
    },
    flg_inativa: {
      value: false,
      isInvalid: false,
      isRequired: false,
    },
  };

  const formFinalizadoraBlank: FormFinalizadora = {
    cod_finalizadora: {
      value: undefined,
      isInvalid: false,
      isRequired: false,
    },
    des_finalizadora: {
      value: '',
      isInvalid: true,
      isRequired: true,
    },
    flg_pdv: {
      value: false,
      isInvalid: true,
      isRequired: false,
    },
    tipo_finalizadora: {
      value: { label: '', value: undefined },
      isInvalid: true,
      isRequired: true,
    },
    lojas: {
      lojas: [],
      isInvalid: false,
      isRequired: false,
    },
  };

  const [init, setInit] = useState(false);
  const [initLoja, setInitLoja] = useState(false);
  const [onEdit, setOnEdit] = useState(false);
  const [flagConfTesAuto, setFlagConfTestAuto] = useState(false);
  const [contas, setContas] = useState([]);
  const [condicoes, setCondicoes] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [lojasUser, setLojasUser] = useState([]);

  const [formFinalizadora, setFormFinalizadora] = useState<FormFinalizadora>(
    formFinalizadoraBlank,
  );
  const [formFinalizadoraLoja, setFormFinalizadoraLoja] =
    useState<FormFinalizadoraLoja>(formFinalizadoraLojaBlank);

  const getLojas = async () => {
    if (user.user?.id !== undefined) {
      const { data } = await api.get(`/lojas/usuario`);
      if (data.success && data.data) {
        const options = data.data.map((loja: Loja) => {
          return {
            des_loja: `${
              loja.cod_loja.toString().length === 1
                ? `0${loja.cod_loja}`
                : loja.cod_loja
            } - ${loja.des_loja} (${loja.des_cidade})`,
            cod_loja: loja.cod_loja,
          };
        });
        const lojaSelected = options.find(
          (item: any) => item.cod_loja === infoLoja.loja,
        );
        setDescLoja(lojaSelected.des_loja);
        setValueFinalizadora('des_loja', lojaSelected.des_loja);
        setLojasUser(options);
      }
    }
  };

  const onChangeFormFinalizadora = useCallback(
    (form: FormFinalizadora) => {
      setFormFinalizadora(form);
      if (init) {
        setInit(false);
      }
    },
    [init],
  );
  const onChangeFormFinalizadoraValue = useCallback(
    (form: FormFinalizadoraLoja) => {
      setValueFinalizadora('des_loja', form.des_loja.value);
      setValueFinalizadora('cod_loja', form.cod_loja.value);
      setValueFinalizadora('cod_cc', form?.cod_cc.value);
      setValueFinalizadora('cod_categoria', form?.cod_categoria.value);
      setValueFinalizadora('cod_condicao', form?.cod_condicao.value);
      setValueFinalizadora('num_condicao', form?.num_condicao.value);
      setValueFinalizadora('des_tecla', form?.des_tecla.value);
      if (init) {
        setInit(false);
      }
    },
    [init],
  );

  const onChangeFormFinalizadoraLoja = useCallback(
    (form: FormFinalizadoraLoja) => {
      setFormFinalizadoraLoja(form);
    },
    [],
  );

  const onChangeInit = (value: boolean) => {
    setInit(value);
  };

  const handleResetFormLoja = () => {
    setValueFinalizadora('des_tecla', '0');
    setValueFinalizadora('cod_condicao', {
      label: 'DIAS DA DATA - DD',
      value: 2,
    });
    setOnEdit(false);
    setValueFinalizadora('cod_loja', infoLoja.loja);
    setValueFinalizadora('num_condicao', 30);
  };

  const handleClearFormFinalizadoraLoja = (): void => {
    setFormFinalizadoraLoja(formFinalizadoraLojaBlank);
    handleInitLoja(false);
    resetFinalizadora();
    handleResetFormLoja();
    getLojas();
    const { cod_loja } = getValuesFinalizadora();
    getContas(cod_loja);
  };

  const handleClearForm = (): void => {
    setFormFinalizadora(formFinalizadoraBlank);
    onChangeInit(false);
    reset();
    setOnEdit(false);
    resetFinalizadora();
    handleResetFormLoja();
    setFlagConfTestAuto(false);
    setValue('tipo_finalizadora', '');
    setValue('tipo_operacao_cartao', {
      value: -1,
      label: 'Nenhum',
    });
    setValue('cod_categoria', {
      value: null,
      label: 'Selecione',
    });
    setValue('cod_cc', {
      value: null,
      label: 'Selecione',
    });
  };

  useEffect(() => {
    getCategorias();
    getLojas();
    getCondicoes();
  }, []);

  useEffect(() => {
    getContas(infoLoja.loja);
  }, [infoLoja.loja]);

  const getCategorias = async () => {
    const res = await api.get(`/categorias`);
    const { data } = res.data;
    const options = data.map((categoria: Categoria) => ({
      label: categoria.descricao ?? '',
      value: categoria.cod_subcategoria,
    }));
    setCategorias(options.length > 0 ? options : []);
  };

  const getCondicoes = async () => {
    const res = await api.get('/condicao');
    const { data } = res.data;
    const options = data.map((condicao: Condicao) => ({
      label: `${condicao.des_condicao} - ${condicao.des_definicao}`,
      value: condicao.cod_condicao,
    }));

    setCondicoes(options);
  };

  const getContas = async (codLoja = 1) => {
    const res = await api.get(`/contas/loja/${codLoja}`);
    const { data } = res.data;
    const options = data.map((conta: Conta) => ({
      label: `${conta.cod_banco} - (${conta.des_cc})`,
      value: conta.cod_cc,
    }));
    setContas(options);
  };

  const getContasAll = async () => {
    const res = await api.get(`/contas/loja/`);
    const { data } = res.data;
    const options = data.map((conta: Conta) => ({
      label: `${conta.cod_banco} - (${conta.des_cc})`,
      value: conta.cod_cc,
    }));
    setContasAll(options);
  };

  const handleInitLoja = (value: boolean): void => {
    setInitLoja(value);
  };

  return (
    <FinalizadoraContext.Provider
      value={{
        categorias,
        contas,
        condicoes,
        onChangeFormFinalizadora,
        formFinalizadora,
        formFinalizadoraLoja,
        onChangeFormFinalizadoraLoja,
        initLoja,
        handleInitLoja,
        handleClearFormFinalizadoraLoja,
        handleClearForm,
        onChangeInit,
        getContas,
        init,
        lojasUser,
        onEdit,
        setOnEdit,
        getLojas,
        onChangeFormFinalizadoraValue,
        handleResetFormLoja,
        getContasAll,
        contasAll,
        setFlagConfTestAuto,
        flagConfTesAuto,
      }}
    >
      <FormContext.Provider
        value={{
          register,
          handleSubmit,
          control,
          reset,
          setError,
          clearErrors,
          setValue,
          getValues,
          formState: { isDirty, errors },
          watch,
          handleSubmitFinalizadora,
          formStateFinalizadora,
          FinalizadoraRegister,
          FinalizadoraControl,
          getValuesFinalizadora,
          setValueFinalizadora,
        }}
      >
        {children}
      </FormContext.Provider>
    </FinalizadoraContext.Provider>
  );
}

export const useFinalizadora = (): FinalizadoraContextData => {
  return useContext(FinalizadoraContext);
};
export const useFormContext = (): FormContextData => {
  return useContext(FormContext);
};
