import React from 'react';
import { TagContainer } from './styles';

export const TagLabel: React.FC<{ label: string }> = ({ label }) => {
  return (
    <TagContainer>
      <span>{label}</span>
    </TagContainer>
  );
};
