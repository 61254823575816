import React from 'react';
import { CancelamentoNFeContextProvider } from './CancelamentoNFeContext';
import { CancelamentoNFeContent } from './CancelamentoNFeContent';

const CancelamentoDeNota: React.FC = () => {
  return (
    <CancelamentoNFeContextProvider>
      <CancelamentoNFeContent />
    </CancelamentoNFeContextProvider>
  );
};

export default CancelamentoDeNota;
