import { createGlobalStyle } from 'styled-components';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { lighten } from '@material-ui/core';

export default createGlobalStyle`
* {
  margin: 0px;
  padding: 0px;
  outline: none;
  box-sizing: border-box;

  .MuiDataGrid-columnsContainer, .MuiTableRow-head, .MuiTableCell-head{
    background-color: ${({ theme: { colors } }) => colors.nav} !important;
    color: #fff !important;
  }
  .MuiTableCell-stickyHeader {
    background-color: ${({ theme: { colors } }) => colors.nav} !important;
    color: #fff !important;
  }
}
html, body, #root {
  height: 100%;
}
body {
  font: 14px 'Jost', sans-serif!important;
  background: #E5E5E5;
  color:  #313638;
  -webkit-font-smoothing: antialiased !important;
}
ul {
  list-style: none;

}

.MuiDataGrid-root{
  border-radius: 20px;
}
.MuiDataGrid-window::-webkit-scrollbar, .MuiTableContainer-root::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.MuiDataGrid-window::-webkit-scrollbar-track, .MuiTableContainer-root::-webkit-scrollbar-track  {
  background: #f1f1f1!important;
}

.modal-open{

  .modal-backdrop {
    background-color: var(--ds-blanket, rgba(9, 30, 66, 0.54));
  }
  
  }
}

/* Handle */
.MuiDataGrid-window::-webkit-scrollbar-thumb, .MuiTableContainer-root::-webkit-scrollbar-thumb {
  background: ${({ theme: { colors } }) =>
    lighten(colors.primary, 0.4)}!important;
}

/* Handle on hover */
.MuiDataGrid-window::-webkit-scrollbar-thumb:hover, .MuiTableContainer-root::-webkit-scrollbar-thumb:hover {
  background:${({ theme: { colors } }) => colors.primary}!important;
}


.css-19eczu {
    padding: 0px 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }


.swal2-styled{
  padding: 3px 37px;
  height: 30px;
  border-radius: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px!important;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
  line-height: 26.01px!important;

  .actions {
    z-index: 0;
  }
}

.swal2-actions{
  z-index: 0 !important;
}

.btn-success {
  background-color: #309063;
  border-color: #72ab90;
}

.customalert-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-bottom: 16px;
}

.customalert-icon {
  font-size: 10px;
  margin: 0 0 auto;
}

.customalert-title {
  margin: 0;
}


`;
