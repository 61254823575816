import React, { useState, useEffect, useContext, useCallback } from 'react';
import { LojaContext } from '~/context/loja';

import api from '~/services/api';

import history from '~/services/history';
import {
  setUserToken,
  setExpirationToken,
  getUserToken,
  removeExpirationToken,
  removeUserToken,
  expiredToken,
  setUserData,
  setNotification,
  removeUserData,
  getUserData,
} from '~/services/user';

import { useQueryClient } from 'react-query';

interface controleAcessoTela {
  cod_controle: number;
  cod_tela: number;
  flg_read: boolean;
}

interface lojaData {
  cod_loja: number;
  des_loja: string;
  des_uf: string;
  tipo_integra_frente: number;
  tipo_regime: number;
}

export interface User {
  id: number;
  email: string;
  usuario?: string;
  loja?: number;
  cod_controle?: number;
  des_foto?: string;
  controleAcessoTela: controleAcessoTela[];
  tipo_regime?: number;
  flg_admin: boolean;
  flg_superadmin: boolean;
  loja_data: lojaData;
  num_cpf_cnpj: string;
  num_celular: string;
  timezone: string;
  cod_pessoa: number;
  flg_lib_financeiro?: boolean;
  precos_arredondamentos: any[];
}

interface ResponseLogin {
  success: boolean;
  token?: string;
  data?: {
    id: number;
    email: string;
    name: string;
    usuario?: string;
    loja?: number;
    cod_controle?: number;
    des_foto?: string;
    controleAcessoTela: controleAcessoTela[];
    flg_superadmin: boolean;
    flg_admin: boolean;
    loja_data: lojaData;
    num_cpf_cnpj: string;
    num_celular: string;
    timezone: string;
    cod_pessoa: number;
    precos_arredondamentos: any[];
    acesso: Acesso;
  };
  expiresIn?: number;
  message?: string;
}
interface Ciclo {
  flg_atual: boolean;
  cod_seq_ciclo_anterior: number | null;
  dta_cadastro: string;
  cod_seq_ciclo: number;
  des_status_integracao: string;
  cod_contratacao: number;
  cod_aplicacao: number;
  dta_vencimento: string;
  des_evento_integracao: string | null;
  cod_seq_pagamento: number;
  tipo_status: number;
  dta_fim_tolerancia: string;
  dta_inicio: string;
  cod_seq_ciclo_proximo: number | null;
  dta_fim: string;
  dta_alteracao: string;
  flg_ativo: boolean;
  des_tipo_status: string;
  val_plano: number;
}

interface ContratacaoCliente {
  tipo_ciclo: number;
  dta_cadastro: string;
  cod_contratacao: number;
  cod_seq_plano: number;
  tipo_contratacao: number;
  des_ciclo_integracao: string;
  cod_cliente: number;
  tipo_status: number;
  cod_seq_pagamento: number;
  dta_inicio: string;
  dta_fim: string;
  dta_alteracao: string;
  des_chave_integracao: string;
  cod_contratacao_anterior: number;
  val_plano: number;
}

interface FormaPagamento {
  des_nome: string;
  tipo_forma_pagamento: number;
  des_chave_token_cartao: string;
  des_forma_pagamento_integracao: string;
  dta_cadastro: string;
  des_dados_json: string;
  dta_alteracao: string;
  cod_seq_pagamento: number;
  cod_cliente: number;
  flg_ativo: boolean;
}

interface ContratacaoPlano {
  des_nome: string;
  cod_usuario_cadastro: number | null;
  cod_usuario_alteracao: number | null;
  dta_cadastro: string;
  cod_controle: number;
  cod_seq_plano: number;
  val_plano_mensal: number;
  dta_alteracao: string;
  des_plano: string;
  flg_inativo: boolean;
  val_plano: number;
  val_plano_anual: number;
}

interface Acesso {
  ciclo_passado: Ciclo;
  ciclo_atual: Ciclo;
  ciclo_futuro: Ciclo | null;
  cod_cliente: number;
  flg_ativo: boolean;
  cod_seq_plano: number;
  flg_demonstracao: boolean;
  contratacao_cliente: ContratacaoCliente;
  contratacao_cliente_futuro: ContratacaoCliente;
  contratacao_plano: ContratacaoPlano;
  contratacao_plano_futuro: ContratacaoPlano;
  ciclo_atual_forma_pagamento: FormaPagamento;
  ciclo_futuro_forma_pagamento: FormaPagamento | null;
  contratacao_atual_forma_pagamento: FormaPagamento;
  contratacao_futuro_forma_pagamento: FormaPagamento;
  cod_aplicacao: number;
  des_plano: string;
  sk: string;
  tipo_status_app: number;
  dta_demonstracao_fim: Record<string, never>;
  pk: string;
  des_status_motivo: string | null;
  tipo_status: number;
  des_tipo_status: string;
  cod_controle: number;
}

export default function useAuth() {
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [isExpired, setIsExpired] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [isFreeTrial, setIsFreeTrial] = useState<boolean>(false);
  const [user, setUser] = useState<User>({} as User);

  const [loading, setLoading] = useState(true);
  const { changeLoja } = useContext(LojaContext);
  const queryClient = useQueryClient();

  useEffect(() => {
    const token = getUserToken();
    const userData = getUserData();

    if (token) {
      if (expiredToken()) {
        setLoading(false);
        setAuthenticated(false);
        return;
      }
      const acessoLength =
        (userData?.acesso && Object.keys(userData?.acesso).length) || 0;

      if (
        process.env.NODE_ENV === 'production' &&
        acessoLength !== 0 &&
        window.location.hostname !== 'jussara.app.konvix.com.br'
      ) {
        validateLicense(userData.acesso);
      }
      api.defaults.headers.Authorization = `Bearer ${token}`;
      setAuthenticated(true);

      if (userData) {
        setUser(userData);
        changeLoja(Number(userData.loja));
      }
    }
    setLoading(false);
  }, []);

  const handleChangeUserData = useCallback(
    (userData: User) => {
      setUser(userData);
      setUserData(userData);
      if (userData.loja) {
        changeLoja(userData.loja);
      }
    },
    [changeLoja],
  );

  const handleNotificacao = useCallback((userData: User) => {
    setNotification(userData);
  }, []);

  // }, [changeLoja, setUser]);

  const handleLogin = async (
    email: string,
    password: string,
  ): Promise<void | string> => {
    setIsExpired((value) => (value ? !value : value));
    const { data } = await api.post<ResponseLogin>('/session', {
      email,
      password,
    });

    if (data.token && data.expiresIn && data.data) {
      // set the user's token
      setUserToken(data.token);

      // stores the expiration date of the token
      setExpirationToken(data.expiresIn);

      // stores user data
      setUserData(data.data);
      // stores user data
      setUser(data.data);

      api.defaults.headers.Authorization = `Bearer ${data.token}`;
      setAuthenticated(true);
      const acessoLength =
        (data.data?.acesso && Object.keys(data.data?.acesso).length) || 0;

      if (
        process.env.NODE_ENV === 'production' &&
        acessoLength !== 0 &&
        window.location.hostname !== 'jussara.app.konvix.com.br'
      ) {
        validateLicense(data.data?.acesso);
      }
      data.data.loja && changeLoja(Number(data.data.loja));

      history.push('/app');
    }
  };

  const validateLicense = (data: Acesso) => {
    if (data) {
      if (data?.flg_ativo) {
        if (Number(data?.contratacao_cliente.tipo_contratacao) === 0) {
          const dtaFim = data?.ciclo_atual.dta_fim;
          const dataObjeto = new Date(dtaFim);
          const dataAtual = new Date();

          const diferencaMilissegundos =
            dataObjeto.getTime() - dataAtual.getTime();
          const diferencaDias =
            Math.ceil(diferencaMilissegundos / (1000 * 60 * 60 * 24)) - 1;
          let phrase = '';
          if (diferencaDias >= 1) {
            phrase = `Seu período de teste na Konvix termina em ${diferencaDias} dias. Vamos juntos simplificar a gestão do seu negócio?`;
          }
          if (diferencaDias === 0) {
            phrase = `Último dia do seu trial! Não deixe para depois – Assine agora e continue aproveitando! 🚀`;
          }
          setIsFreeTrial(true);
          setMessage(phrase);
        }
      } else {
        setIsExpired(true);
      }
    }
  };

  const handleLogout = (screens: any = []) => {
    if (screens.length > 1) {
      const confirm = window.confirm(
        'Existem telas abertas, ao sair os dados da tela serão perdidos, deseja continuar?',
      );
      if (confirm) {
        setAuthenticated(false);
        setIsExpired(false);
        setIsFreeTrial(false);
        removeUserToken();
        removeExpirationToken();
        removeUserData();

        api.defaults.headers.Authorization = undefined;

        // Remove todas as consultas do cache
        queryClient.clear();

        history.push('/');
      }
    } else {
      setAuthenticated(false);

      removeUserToken();
      removeExpirationToken();
      removeUserData();

      api.defaults.headers.Authorization = undefined;

      history.push('/');
    }
  };

  return {
    authenticated,
    isExpired,
    message,
    isFreeTrial,
    loading,
    handleLogin,
    handleLogout,
    user,
    setUser,
    handleChangeUserData,
    handleNotificacao,
  };
}
