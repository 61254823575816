import { GridRowParams } from '@material-ui/data-grid';
import DefaultLoader from '~/components/DefaultLoader';
import FormDefault from '~/components/FormDefault';
import Search from '~/components/Search';
import api from '~/services/api';
import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Tooltip from '@atlaskit/tooltip';

import { ModalDeCorrecoes } from './Components';
import {
  alteraStatusdoXml,
  formataItensParaTabela,
  validaItensDoXML,
} from './Funcoes/Validacao';
import {
  fillFieldsDefaults,
  getFirstKey,
  getFirstKeyId,
  getKeyByPrefix,
  getKeyIdByPrefix,
} from './Funcoes/Validacao/validaAtributosJson';
import { ItensFormatados, ProductDataToTable } from './protocols';
import { CapaDivergencias } from './screens/CapaDivergencias';
import { DadosDoXml } from './screens/DadosDoXml';
import { ItensDaNF } from './screens/ItensDaNF';
import { ParametrosDeValidacao } from './screens/ParametrosDeValidacao';
import { TotaisDoXML } from './screens/TotaisDoXML';
import { ButtonForm, Container } from './styles';
import { useValidadorXML } from './ValidadorContext';
import {
  isFornNotRegisteredOrInactive,
  isNFProcessede,
} from './validations/validationFunctions';
import { uppercaseValues } from './validations/uppercaseValues';
import { corrigirArrayDeObjetos } from './Funcoes/Validacao/corrigirArrayDeObjetos';
import { Spinner } from 'react-bootstrap';
import { MdDownload } from 'react-icons/md';
import { ModalPDF } from './Components/ModalDanfe/ModalPDF';

const MySwal = withReactContent(Swal);

const ValidadorXMLContent: React.FC = () => {
  const {
    dadosDaCapa,
    setDadosDaCapa,
    setShowXMLData,
    setShowTotais,
    setShowTable,
    setShowParams,
    parametrosValidacao,
    setParametrosValidacao,
    dadosErpDoFornecedor,
    setDadosErpDoFornecedor,
    isValidXML,
    setIsValidXML,
    setDadosDoXML,
    itensDaTabela,
    setItensDaTabela,
    showModalDeCorrecoes,
    setShowModalDeCorrecoes,
    itemACorrigir,
    returnDadosRevalidados,
    setCamposAlterados,
    resetFormData,
    isUpdate,
    setUpdate,
    showSearch,
    setShowSearch,
    hasFornecedor,
    setHasFornecedor,
    onSetCounterValida,
    formParamsValidacao: { setValue, getValues },
    isOpenModal,
  } = useValidadorXML();
  const [loader, setLoader] = useState<boolean>(false);
  const [isLoadingDownloadXML, setLoadingDownloadXML] =
    useState<boolean>(false);

  // Chamada temporária dos dados do xml (será retornado um json no futuro)
  async function loadNFXmlData({
    cod_loja,
    num_chave_acesso,
    filename,
  }: {
    cod_loja: number;
    num_chave_acesso: string;
    filename: string;
  }): Promise<any> {
    try {
      const newItens = [];

      // buscar XML transformado em Json
      const { data } = await api.post(`/nf/buscar-arquivo-xml`, {
        cod_loja,
        num_chave_acesso,
        filename,
      });

      const xml: any = data.data;
      for (let i = 0; i < xml.itens[0].length; i++) {
        /**
         * Valida Itens
         */
        const item = fillFieldsDefaults(xml.itens[0][i], {
          CEST: '',
          EXTIPI: '',
          vSeg: 0,
          vDesc: 0,
        });
        /**
         * Valida ICMS
         */
        const firstKeyICMS = getFirstKeyId(xml.itens[0][i].imposto.ICMS);
        item.imposto.ICMS[firstKeyICMS] = fillFieldsDefaults(
          getFirstKey(xml.itens[0][i].imposto.ICMS),
          {
            pICMS: 0,
            CST: '',
            pRedBC: 0,
            vICMS: 0,
            vBC: 0,
            vICMSST: 0,
            vBCST: 0,
            pICMSST: 0,
            pST: 0,
            pRedBCST: 0,
            pMVAST: 0,
            CSOSN: 0,
            pFCP: 0,
            pFCPST: 0,
            vFCP: 0,
            vFCPST: 0,
          },
        );
        /**
         * Valida PIS
         */
        const firstKeyPIS = getFirstKeyId(xml.itens[0][i].imposto.PIS);
        item.imposto.PIS[firstKeyPIS] = fillFieldsDefaults(
          getFirstKey(xml.itens[0][i].imposto.PIS),
          {
            CST: '',
            vBC: 0,
            pPIS: 0,
            vPIS: 0,
          },
        );
        /**
         * Valida COFINS
         */
        const firstKeyCOFINS = getFirstKeyId(xml.itens[0][i].imposto.COFINS);
        item.imposto.COFINS[firstKeyCOFINS] = fillFieldsDefaults(
          getFirstKey(xml.itens[0][i].imposto.COFINS),
          {
            CST: '',
            vBC: 0,
            pCOFINS: 0,
            vCOFINS: 0,
          },
        );
        /**
         * Valida IPI
         */
        if (xml.itens[0][i].imposto.IPI === undefined) {
          xml.itens[0][i].imposto.IPI = {
            IPITrib: {
              pIPI: 0,
              vIPI: 0,
            },
          };
        }
        const firstKeyIPI = getKeyIdByPrefix(
          xml.itens[0][i].imposto.IPI,
          'IPI',
        );
        item.imposto.IPI[firstKeyIPI] = fillFieldsDefaults(
          getKeyByPrefix(xml.itens[0][i].imposto.IPI, 'IPI'),
          {
            CST: '',
            vIPI: 0,
            pIPI: 0,
          },
        );
        item.imposto.icmsKey = firstKeyICMS;
        newItens.push(item);
      }
      xml.itens[0] = newItens;
      return xml;
    } catch (error) {
      toast.error(error);
      return null;
    }
  }

  const trimObjectValues = (obj: { [key: string]: any }) => {
    const trimmedObj: { [key: string]: any } = {};
    Object.keys(obj).forEach((key) => {
      const value = obj[key];

      if (typeof value === 'string') trimmedObj[key] = value.trim();
      else trimmedObj[key] = value;
    });
    return trimmedObj;
  };

  async function loadErpFornecedorData(num_cpf_cnpj: string): Promise<any> {
    const { data } = await api.get(`/dados-do-fornecedor/${num_cpf_cnpj}`);

    if (data.success && data.data.length > 0) {
      const fornecedorData = trimObjectValues(data.data[0]);
      const fornData = {
        cod_fornecedor: {
          value: fornecedorData.cod_fornecedor || '',
          invalid: false,
        },
        num_cpf_cnpj: {
          value: fornecedorData.num_cpf_cnpj || '',
          invalid: false,
        },
        num_rg_ie: {
          value: fornecedorData.num_ie || '',
          invalid: false,
        },
        des_fornecedor: {
          value: fornecedorData.des_fornecedor || '',
          invalid: false,
        },
        apelido_fantasia: {
          value: fornecedorData.apelido_fantasia || '',
          invalid: false,
        },
        des_uf: {
          value: fornecedorData.des_uf || '',
          invalid: false,
        },
        des_logradouro: {
          value: fornecedorData.des_logradouro || '',
          invalid: false,
        },
        num_endereco: {
          value: fornecedorData.num_endereco || '',
          invalid: false,
        },
        des_bairro: {
          value: fornecedorData.des_bairro || '',
          invalid: false,
        },
        num_cep: {
          value: fornecedorData.num_cep || '',
          invalid: false,
        },
        num_telefone: {
          value: fornecedorData.num_telefone || '',
          invalid: false,
        },
        des_cidade: {
          value: fornecedorData.des_cidade || '',
          invalid: false,
        },
        flg_produtor_rural: {
          value: fornecedorData.flg_produtor_rural,
          invalid: false,
        },
      };
      setDadosErpDoFornecedor(fornData);
      return fornData;
    }
  }

  const onRowClick = async (param: GridRowParams) => {
    setLoader(true);
    setUpdate(true);
    const {
      row: {
        cod_xml,
        cod_loja,
        cod_perfil,
        des_perfil,
        des_loja,
        des_uf,
        num_cpf_cnpj,
        nome_pessoa,
        num_nf,
        num_serie_nf,
        dta_emissao,
        num_chave_acesso,
        cod_pessoa,
        flg_fornecedor,
        flg_inativo,
        tipo_status,
        tipo_status_sefaz,
        tipo_status_manifesto,
        // flg_validada_confirmada,
        flg_considera_icms_st,
        flg_considera_icms_op,
      },
    } = param;

    let shouldOpen = true;

    if (tipo_status === -2) {
      toast.warning(
        'Esse XML foi cancelado e por conta disso está indisponível',
      );
      setLoader(false);
      shouldOpen = false;
      return;
    }

    if (tipo_status === -1) {
      toast.warning('O XML selecionado ainda está pendente de processamento.');
      setLoader(false);
      shouldOpen = false;
      return;
    }

    try {
      /** Carrega dados da API */

      const [xmlData, dadosDoFornecedor] = await Promise.all([
        loadNFXmlData({
          cod_loja,
          num_chave_acesso,
          filename: 'nfe-proc.json',
        }),
        loadErpFornecedorData(num_cpf_cnpj),
      ]);

      if (!xmlData) {
        setLoader(false);
        shouldOpen = false;
        return;
      }
      const itensArray = corrigirArrayDeObjetos(xmlData.itens[0]);

      xmlData.itens[0] = itensArray;
      setDadosDoXML(uppercaseValues(xmlData));
      setValue('tipo', {
        value: 0,
        label: 'Compra',
      });
      const capa = {
        cod_xml_cap: cod_xml,
        cod_loja,
        loja: des_loja,
        des_uf,
        num_cpf_cnpj,
        fornecedor: nome_pessoa,
        num_nfe: num_nf,
        serie: num_serie_nf,
        emissao: dta_emissao,
        val_total_nfe: xmlData?.total.ICMSTot.vNF,
        chave_nfe: num_chave_acesso,
        cod_pessoa,
        flg_fornecedor,
        flg_inativo,
        tipo_status,
        tipo_status_sefaz,
        tipo_status_manifesto,
        // flg_validada_confirmada,
      };

      setDadosDaCapa(capa);

      setParametrosValidacao({
        ...parametrosValidacao,
        cod_perfil,
        icms_st: flg_considera_icms_st,
        icms_op: flg_considera_icms_op,
      });

      /**
       * Valida Fornecedor
       */
      console.log({
        cod_pessoa,
        flg_fornecedor,
        flg_inativo,
        des_uf: dadosDoFornecedor?.des_uf.value || '',
      });
      console.log({ dadosDoFornecedor });
      const isValidFornecedor = await isFornNotRegisteredOrInactive({
        cod_pessoa,
        flg_fornecedor,
        flg_inativo,
        des_uf: dadosDoFornecedor?.des_uf.value || '',
      });
      if (isValidFornecedor.invalid) {
        setHasFornecedor(false);
        console.log('isValidFornecedor.invalid');
        toast.warning(
          `${isValidFornecedor.message}! Regularize a situação para proseguir.`,
        );
        setIsValidXML(false);
      }

      /**
       * Valida NF já Processada ou Efetivada
       */

      const isNFProcessed = await isNFProcessede(tipo_status);
      if (isNFProcessed.invalid) {
        const continua = await MySwal.fire({
          title: isNFProcessed.title,
          text: isNFProcessed.message,
          showCancelButton: true,
          confirmButtonColor: '#8850BF',
          cancelButtonColor: '#DE350B',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        }).then((result) => {
          if (result.isConfirmed) {
            return true;
          }
          return false;
        });
        if (!continua) {
          toast.warn('Operação cancelada.');
          setIsValidXML(false);
          shouldOpen = false;
          setLoader(false);
          return;
        }
      }

      /**
       * Caso a NF já esteja validada, processada ou efetivada
       */
      if (tipo_status >= 2) {
        setValue('perfil', { label: des_perfil, value: cod_perfil });
        const { data } = await api.post('/items-xml-validada-confirmada', {
          cod_xml,
        });
        const newItem: any = [];
        if (xmlData) {
          const itensForPromise = data.data.map(async (item: any, i: any) => {
            const dadosParaFormatacao: any = {
              item: {
                index: String(i + 1),
                edicao: false,
                erpCodProduto: data.data[i].cod_produto,
                erpCodGtin: data.data[i].cod_gtin,
                xmlCProd: xmlData?.itens[0][i].cProd,
                xmlCean: xmlData?.itens[0][i].cEAN,
                xmlCeanTrib: xmlData?.itens[0][i].cEANTrib,
                xProd: xmlData?.itens[0][i].xProd,
                xmlCfop: xmlData?.itens[0][i].CFOP,
                xmlUCom: xmlData?.itens[0][i].uCom,
                xmlUTrib: xmlData?.itens[0][i].uTrib,
                // xmlpICMS: dadosDoXML?.itens[0][i].imposto.ICMS.ICMS00?.pICMS,
                xmlpICMS:
                  xmlData?.itens[0][i].imposto.ICMS?.[
                    xmlData?.itens[0][i].imposto.icmsKey
                  ]?.pICMS || 0,
                xmlpRedBCST:
                  xmlData?.itens[0][i].imposto.ICMS?.[
                    xmlData?.itens[0][i].imposto.icmsKey
                  ]?.pRedBCST || 0,
                xmlpMVAST:
                  xmlData?.itens[0][i].imposto.ICMS?.[
                    xmlData?.itens[0][i].imposto.icmsKey
                  ]?.pMVAST,
                xmlpST: xmlData?.itens[0][i].imposto.ICMS.ICMS60?.pST,
                xmlCEST: xmlData?.itens[0][i].CEST,
                xmlNCM: xmlData?.itens[0][i].NCM,
                xmlIcmsCST:
                  xmlData?.itens[0][i].imposto.ICMS.CST ||
                  xmlData?.itens[0][i].imposto.ICMS?.[
                    xmlData?.itens[0][i].imposto.icmsKey
                  ]?.CST,
                xmlpRedBC:
                  xmlData?.itens[0][i].imposto.ICMS?.[
                    xmlData?.itens[0][i].imposto.icmsKey
                  ]?.pRedBC || 0,
                xmlpICMSST:
                  xmlData?.itens[0][i].imposto.ICMS?.[
                    xmlData?.itens[0][i].imposto.icmsKey
                  ]?.pICMSST || 0,
                xmlvProd: xmlData?.itens[0][i].vProd || 0,
                xmlvBC:
                  xmlData?.itens[0][i].imposto.ICMS?.[
                    xmlData?.itens[0][i].imposto.icmsKey
                  ]?.vBC || 0,
                xmlCSOSN:
                  xmlData?.itens[0][i].imposto.ICMS.ICMSSN102?.CSOSN || 0,
                xmlvICMSST:
                  xmlData?.itens[0][i].imposto.ICMS?.[
                    xmlData?.itens[0][i].imposto.icmsKey
                  ]?.vICMSST || 0,
                des_unidade_compra: xmlData?.itens[0][i].uCom,
                perfil: cod_perfil,
              },
              fornecedor: {
                uf: dadosDoFornecedor.des_uf.value,
                cod_fornecedor: Number(dadosDoFornecedor.cod_fornecedor.value),
              },
              loja: {
                uf: capa.des_uf,
                cod_loja: Number(cod_loja),
              },
              parametrosValidacao: {
                cod_perfil,
                icms_st: flg_considera_icms_st,
                icms_op: flg_considera_icms_op,
              },
              dadosDaCapa: capa,
              dadosErpDoFornecedor: {
                cod_fornecedor: {
                  value: dadosDoFornecedor.cod_fornecedor.value || '',
                  invalid: false,
                },
                num_cpf_cnpj: {
                  value: dadosDoFornecedor.num_cpf_cnpj.value || '',
                  invalid: false,
                },
                num_rg_ie: {
                  value: dadosDoFornecedor.num_rg_ie.value || '',
                  invalid: false,
                },
                des_fornecedor: {
                  value: dadosDoFornecedor.des_fornecedor.value || '',
                  invalid: false,
                },
                apelido_fantasia: {
                  value: dadosDoFornecedor.apelido_fantasia.value || '',
                  invalid: false,
                },
                des_uf: {
                  value: dadosDoFornecedor.des_uf.value || '',
                  invalid: false,
                },
                des_logradouro: {
                  value: dadosDoFornecedor.des_logradouro.value || '',
                  invalid: false,
                },
                num_endereco: {
                  value: dadosDoFornecedor.num_endereco.value || '',
                  invalid: false,
                },
                des_bairro: {
                  value: dadosDoFornecedor.des_bairro.value || '',
                  invalid: false,
                },
                num_cep: {
                  value: dadosDoFornecedor.num_cep.value || '',
                  invalid: false,
                },
                num_telefone: {
                  value: dadosDoFornecedor.num_telefone.value || '',
                  invalid: false,
                },
                des_cidade: {
                  value: dadosDoFornecedor.des_cidade.value || '',
                  invalid: false,
                },
                flg_produtor_rural: {
                  value: dadosDoFornecedor.flg_produtor_rural.value,
                  invalid: false,
                },
              },
            };
            const itensFormatados = await formataItensParaTabela(
              dadosParaFormatacao,
            );
            const produtoNaoEncontrado = itensFormatados.statusDaValidacao.find(
              (status) => status.codStatus === 1,
            );

            let itensValidados = itensFormatados;
            if (!produtoNaoEncontrado) {
              itensValidados = await validaItensDoXML(
                itensFormatados,
                capa,
                parametrosValidacao,
                dadosDoFornecedor,
              );
            }
            const qtdStatusBloq = itensValidados.statusDaValidacao.filter(
              (status) => status.restricao === 'BLOQ',
            );
            const qtdStatusNe = itensValidados.statusDaValidacao.filter(
              (status) => status.restricao === 'NE',
            );
            const qtdStatusMix = itensValidados.statusDaValidacao.filter(
              (status) => status.restricao === 'MIX ',
            );
            const qtdStatusInf = itensValidados.statusDaValidacao.filter(
              (status) => status.restricao === 'INF',
            );
            itensValidados.qtdStatusBloq = Number(qtdStatusBloq?.length);
            itensValidados.qtdStatusNe = Number(qtdStatusNe?.length);
            itensValidados.qtdStatusMix = Number(qtdStatusMix?.length);
            itensValidados.qtdStatusInf = Number(qtdStatusInf?.length);
            newItem.push(itensValidados);
          });

          await Promise.all(itensForPromise);

          newItem.sort(function (a: ProductDataToTable, b: ProductDataToTable) {
            if (a.index < b.index) {
              return -1;
            }
            if (a.index > b.index) {
              return 1;
            }
            return 0;
          });
          setItensDaTabela(newItem);
        }
        setShowTotais('0');
        setShowTable('0');
        setShowXMLData('0');
        setShowParams('1');
        setIsValidXML(true);
      }
    } catch (error) {
      toast.error(
        'Houve um problema ao carregar os dados. Entre em contato com o suporte.',
      );
    } finally {
      if (shouldOpen) {
        setShowSearch(false);
      }
      setLoader(false);
    }
  };

  const newData = () => {
    setUpdate(false);
    setShowSearch(false);
    resetFormData();
  };
  const formataItens = useCallback((): ItensFormatados[] => {
    const itens = itensDaTabela.map((item) => {
      return {
        num_item: item.index,
        cod_produto: item.cod_produto,
        cod_gtin: item.cod_gtin_principal,
        cfop: item.cfop,
        cod_id_ctb: item.cod_id_ctb,
        cod_trib_entrada: item.cod_trib_entrada,
        des_unidade: item.des_unidade_compra,
        qtd_embalagem: item.qtd_embalagem_compra,
      };
    });
    return itens;
  }, [itensDaTabela]);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const perfil = getValues('perfil');

      if (!perfil || !perfil.value) {
        toast.warning('É Necessário Selecionar um perfil para Prosseguir.');
        return;
      }
      if (!isValidXML) {
        toast.warning('É Necessário Validar o XML para Prosseguir.');
        return;
      }
      if (itensDaTabela.length === 0) {
        toast.warning('Nenhum Item Encontrado no XML.');
        return;
      }
      const isValidated = await alteraStatusdoXml({
        codXml: Number(dadosDaCapa.cod_xml_cap),
        tipoIcmsSt: parametrosValidacao.icms_st,
        TipoIcmsOp: parametrosValidacao.icms_op,
        codPerfil: Number(parametrosValidacao.cod_perfil),
        dadosErpDoFornecedor,
        itensDaTabela,
      });
      if (isValidated) {
        setLoader(true);
        const itens = formataItens();
        try {
          const res = await api.post('/grava-validacao-xml', {
            cod_xml: Number(dadosDaCapa.cod_xml_cap),
            itens,
            parametrosValidacao,
          });
          const { message, success } = res.data;
          if (success) {
            toast.success(message);
            setUpdate(false);
            setShowSearch(false);
            setLoader(false);
            resetFormData();
          }
        } catch (error: any) {
          setLoader(false);
          if (error.data && error.data.message) {
            toast.error(error.data.message);
            return;
          }
          toast.error(String(error));
        }
        setLoader(false);
        return;
      }
      toast.warning(
        'NF-e não validada. Verifique os conflitos existentes, seja na capa ou nos itens.',
      );
      setLoader(false);
    },
    [
      dadosDaCapa.cod_xml_cap,
      dadosErpDoFornecedor,
      formataItens,
      isValidXML,
      itensDaTabela,
      parametrosValidacao.cod_perfil,
      parametrosValidacao.icms_op,
      parametrosValidacao.icms_st,
      resetFormData,
      setShowSearch,
      setUpdate,
    ],
  );

  const handleDownloadXML = async (): Promise<any> => {
    setLoadingDownloadXML(true);
    const { cod_loja, chave_nfe } = dadosDaCapa;
    try {
      // baixar XML
      const { data } = await api.post(`/nf/baixar-arquivo-xml`, {
        cod_loja,
        num_chave_acesso: chave_nfe,
        filename: `nfe-proc.xml`,
      });

      const url = window.URL.createObjectURL(
        new Blob([data.data], { type: 'application/xml' }),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${chave_nfe}.xml`);
      document.body.appendChild(link);
      link.click();

      // Verifica se o link tem um parentNode antes de tentar removê-lo
      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }
      window.URL.revokeObjectURL(url);
      setLoadingDownloadXML(false);
      return data;
    } catch (error) {
      setLoadingDownloadXML(false);
      toast.error(error);
      return null;
    }
  };

  if (loader) {
    return <DefaultLoader />;
  }

  // Botão Customizável para baixar XML
  const ButtonDownloadXML = () => {
    return (
      <Tooltip position="bottom" content="Baixar XML">
        <ButtonForm
          onClick={handleDownloadXML}
          disabled={isLoadingDownloadXML}
          className="download"
        >
          {isLoadingDownloadXML ? (
            <Spinner animation="border" variant="light" size="sm" />
          ) : (
            <>
              <MdDownload /> Baixar XML
            </>
          )}
        </ButtonForm>
      </Tooltip>
    );
  };
  return (
    <Container>
      {showSearch && (
        <Search
          codTela={58}
          newData={newData}
          onRowClick={onRowClick}
          onlyReport
        />
      )}
      {!showSearch && (
        <FormDefault
          codTela={58}
          title="Validador de XML de NF-e de Entrada"
          codigoRegistro={[
            { value: dadosDaCapa.cod_xml_cap, des_campo: 'Código' },
          ]}
          onSave={async (e) => {
            if (!hasFornecedor) {
              toast.warning(
                `Fornecedor não cadastrado ou inativo! Regularize a situação para proseguir.`,
              );
              return;
            }
            await handleSubmit(e);
            onSetCounterValida(0);
          }}
          onCancel={() => {
            resetFormData();
            setShowSearch(true);
          }}
          onNew={newData}
          onDelete={() => null}
          onClearFields={resetFormData}
          isUpdate={isUpdate}
          onReturnSearch={() => {
            setShowSearch(true);
            setUpdate(false);
            resetFormData();
          }}
          isNew={false}
          isDelete={false}
          customButtonsUpdate={<ButtonDownloadXML />}
        >
          <DadosDoXml />
          <ParametrosDeValidacao />
          {isValidXML && <CapaDivergencias />}
          <ItensDaNF />
          {isValidXML && <TotaisDoXML />}

          {/* Modal de correções */}
          <ModalDeCorrecoes
            showModal={showModalDeCorrecoes}
            setShowFalse={setShowModalDeCorrecoes}
            itemACorrigir={itemACorrigir}
            parametrosValidacao={parametrosValidacao}
            dadosDaCapa={dadosDaCapa}
            dadosErpDoFornecedor={dadosErpDoFornecedor}
            returnDadosRevalidados={(item) => returnDadosRevalidados(item)}
            setCamposAlterados={setCamposAlterados}
          />
          {isOpenModal && <ModalPDF />}
        </FormDefault>
      )}
    </Container>
  );
};

export default ValidadorXMLContent;
