import React, { useCallback, useContext, useEffect, useState } from 'react';
import FormDefault from '~/components/FormDefault';
import ButtonAtlaskit from '@atlaskit/button';
import { Container, BtnGroupContainer, ButtonForm } from './styles';
import {
  InputSelect,
  Loja,
  InputDate,
  BuscaProduto,
} from '~/components/NovosInputs';
import Separator from '~/components/Separator';
import { useReprocessaPisCofins } from './ReprocessaPisCofinsContext';
import { LojaContext } from '~/context/loja';
import { SelectType } from '~/components/NovosInputs/InputSelect/protocols';
import { SelectProps } from './types';
import { Spinner } from 'react-bootstrap';
import Tooltip from '@atlaskit/tooltip';
import { BsFillLightningFill } from 'react-icons/bs';
import api from '~/services/api';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const ReprocessaPisCofinsContent: React.FC = () => {
  const {
    formReprocessaPisCofins: {
      watch,
      reset,
      control,
      register,
      setValue,
      setError,
      getValues,
      setFocus,
      formState: { errors },
      clearErrors,
      handleSubmit,
      trigger,
    },
  } = useReprocessaPisCofins();
  const { codLoja, loja } = useContext(LojaContext);
  const [tipo_reprocessamento, setTipo_reprocessamento] = useState<SelectProps>(
    {
      value: 0,
      label: 'PIS/COFINS',
    },
  );
  const [tipo_parametro, setTipo_parametro] = useState<SelectProps>({
    value: 0,
    label: 'Tudo',
  });
  const [periodo, setPeriodo] = useState<SelectProps>({
    value: undefined,
    label: 'Selecione',
  });
  const [periodoIsDisabled, setPeriodoIsDisabled] = useState(false);
  const [tipoIsDisabled, setTipoIsDisabled] = useState(false);
  const [isLoadingReprocessamento, setIsLoadingReprocessamento] =
    useState<boolean>(false);

  const selectedLoja_watch = watch('cod_loja');

  // Condição para parâmetros e desabilitação dos botões
  useEffect(() => {
    if (tipo_reprocessamento.value === 1) {
      setTipoIsDisabled(true);
      setTipo_parametro({
        label: "NF's",
        value: 1,
      });
    } else {
      setTipo_parametro({
        label: 'Tudo',
        value: 0,
      });
      setTipoIsDisabled(false);
    }
  }, [tipo_reprocessamento]);

  // Condição para data e período
  useEffect((): void => {
    const today = new Date();
    let year = today.getFullYear();
    const month = today.getMonth() + 1;
    let lastMonth = today.getMonth();

    if (periodo) {
      clearErrors('dta_inicial');
      clearErrors('dta_final');
      // preencher data com mês anterior
      if (periodo.value === 1) {
        // caso esteja em janeiro, pegar mês anterior com base no ano anterior (dezembro)
        if (lastMonth === 0) {
          lastMonth = 12;
          year -= 1;
        }
        const dta_inicial = new Date(year, lastMonth - 1, 1);
        const dta_final = new Date(year, lastMonth, 0);
        setValue('dta_inicial', dta_inicial.toLocaleDateString('en-CA'));
        setValue('dta_final', dta_final.toLocaleDateString('en-CA'));
        setPeriodoIsDisabled(true);
        return;
      }

      // preencher data com mês atual
      if (periodo.value === 2) {
        const dta_inicial = new Date(year, month - 1, 1);
        const dta_final = today;

        setValue('dta_inicial', dta_inicial.toLocaleDateString('en-CA'));
        setValue('dta_final', dta_final.toLocaleDateString('en-CA'));
        setPeriodoIsDisabled(true);
        return;
      }
      setValue('dta_inicial', '');
      setValue('dta_final', '');
      setPeriodoIsDisabled(false);
    }
  }, [periodo]);

  useEffect(() => {
    handleResetForm();
  }, []);
  const optionsPeriodo = [
    { label: 'Selecionar', value: -1 },
    { label: 'Mês Anterior', value: 1 },
    { label: 'Mês Atual', value: 2 },
  ];

  const handleResetForm = () => {
    setTipo_reprocessamento({ value: 0, label: 'PIS/COFINS' });
    setTipo_parametro({ value: 0, label: 'Tudo' });
    setPeriodo({ label: 'Selecionar', value: -1 });
    reset({
      busca_produto: null,
      tipo_reprocessamento: { value: 0, label: 'PIS/COFINS' },
      tipo_parametro: { value: 0, label: 'Tudo' },
      dta_periodo: '',
      cod_loja: {
        value: loja.cod_loja,
        label: `${loja.des_loja} (${loja.des_cidade})`,
      },
    });
  };

  // Formata o objeto que faz a requisição
  const formatterRequestData = (data: any) => {
    return {
      cod_loja: data.cod_loja.value,
      tipo_reprocessamento: data.tipo_reprocessamento.value,
      tipo_parametro: data.tipo_parametro.value,
      dta_final: data.dta_final,
      dta_inicial: data.dta_inicial,
      busca_produto: data.busca_produto,
    };
  };

  // Valida a data de início para que não seja maior que data final
  const validateDates = (dta_inicial: string, dta_final: string) => {
    const startDate = new Date(dta_inicial);
    const endDate = new Date(dta_final);
    return startDate <= endDate;
  };
  // Valida as informações do formulário e faz o reprocessamento
  const onSave = handleSubmit(async (dataForm) => {
    if (!validateDates(dataForm.dta_inicial, dataForm.dta_final)) {
      toast.warning(
        'Data inicial maior que a data final, selecione um período válido.',
      );
      return;
    }
    try {
      const titleModal =
        tipo_reprocessamento.value === 0
          ? `O reprocesso irá recalcular os valores baseado no ID, Fornecedor, NCM ou Produto.
    Deseja continuar mesmo assim?`
          : `O reprocesso irá replicar o CEST baseado no NCM.
    Deseja continuar mesmo assim?`;
      const result = await MySwal.fire({
        title: ``,
        text: titleModal,

        showCancelButton: true,
        confirmButtonColor: '#07289e',
        cancelButtonColor: '#ff7b7b',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      });
      if (result.isConfirmed) {
        setIsLoadingReprocessamento(true);
        const requestData = formatterRequestData(dataForm);
        const { data } = await api.post(
          `/reprocessa-pis-cofins/reprocessa`,
          requestData,
        );
        if (data.success) {
          toast.success(data.message);
          handleResetForm();
        }
      }
    } catch (error: any) {
      toast.warning(error.message || error);
    } finally {
      setIsLoadingReprocessamento(false);
    }
  });

  // Botão Customizável para Reprocessar
  const ButtonReprocessar = () => {
    return (
      <Tooltip position="bottom" content="Reprocessar">
        <ButtonForm
          onClick={onSave}
          disabled={isLoadingReprocessamento}
          className="reprocess"
        >
          {isLoadingReprocessamento === true ? (
            <Spinner animation="border" variant="light" size="sm" />
          ) : (
            <>
              <BsFillLightningFill fontSize="1.25rem" color="#fff" />
              REPROCESSAR
            </>
          )}
        </ButtonForm>
      </Tooltip>
    );
  };

  return (
    <Container>
      <FormDefault
        codTela={293}
        title="REPROCESSAMENTO PIS/COFINS/CEST"
        onSave={() => Promise.resolve()}
        onCancel={() => []}
        onClearFields={handleResetForm}
        onNew={() => []}
        onDelete={() => []}
        onReturnSearch={() => []}
        isDelete={false}
        isSave
        isNew={false}
        isCancel={false}
        customButtons={<ButtonReprocessar />}
      >
        {' '}
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <Loja
              label="Loja"
              placeholder="Selecione"
              register={register}
              isError={!!errors.cod_loja}
              control={control}
              name="cod_loja"
              getValues={getValues}
              changeSelected={(selected: any) => {
                clearErrors('cod_loja');
                setValue('cod_loja', selected);
              }}
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <Separator labelText="Período (De/Até)" />
          </div>
          <div className="col-sm-12 col-md-6">
            <Separator labelText="Parâmetros" />
          </div>
          <div className="col-sm-12 col-md-2" id="dta_periodo">
            <InputSelect
              placeholder="Selecione"
              name="dta_periodo"
              register={register}
              isError={false}
              control={control}
              changeSelected={function (selected: SelectType) {
                clearErrors('dta_periodo');
                setValue('dta_periodo', selected);
                setPeriodo(selected);
              }}
              options={optionsPeriodo}
            />
          </div>
          <div className="col-sm-12 col-md-2 " style={{ marginTop: '-1.3rem' }}>
            <InputDate
              label="Data Inicial"
              id="dta_inicial"
              name="dta_inicial"
              register={register}
              control={control}
              disabled={periodoIsDisabled}
              isError={!!errors.dta_inicial}
              maxDate={new Date().toISOString().slice(0, 10)}
              onChange={(e: any) => {
                clearErrors('dta_inicial');
                setValue('dta_inicial', e.target.value);
              }}
            />
          </div>
          <div className="col-sm-12 col-md-2" style={{ marginTop: '-1.3rem' }}>
            <InputDate
              label="Data Final"
              id="dta_final"
              name="dta_final"
              register={register}
              control={control}
              disabled={periodoIsDisabled}
              maxDate={new Date().toISOString().slice(0, 10)}
              isError={!!errors.dta_final}
              onChange={(e: any) => {
                clearErrors('dta_final');
                setValue('dta_final', e.target.value);
              }}
            />
          </div>

          <div className="col-sm-12 col-md-3">
            <BtnGroupContainer>
              <ButtonAtlaskit
                key="pis/cofins"
                className={
                  tipo_reprocessamento.value === 0
                    ? 'btn-group btn-group-active'
                    : 'btn-group'
                }
                value="0"
                style={{
                  height: '37px',
                  marginLeft: '0px',
                  paddingLeft: '0px',
                  borderRadius: '4px 0px 0px 4px',
                }}
                type="button"
                isDisabled={false}
                onClick={() => {
                  setTipo_reprocessamento({ value: 0, label: 'PIS/COFINS' });
                  setValue('tipo_reprocessamento', {
                    value: 0,
                    label: 'PIS/COFINS',
                  });
                }}
              >
                PIS/COFINS
              </ButtonAtlaskit>
              <ButtonAtlaskit
                key="cest"
                className={
                  tipo_reprocessamento.value === 1
                    ? 'btn-group btn-group-active'
                    : 'btn-group'
                }
                style={{
                  color: '#fff',
                  height: '37px',
                  borderRadius: '0px 4px 4px 0px',
                }}
                type="button"
                isDisabled={false}
                onClick={() => {
                  setTipo_reprocessamento({ value: 1, label: 'CEST' });
                  setValue('tipo_reprocessamento', { value: 1, label: 'CEST' });
                }}
              >
                CEST
              </ButtonAtlaskit>
            </BtnGroupContainer>
          </div>
          <div className="col-sm-12 col-md-3">
            <BtnGroupContainer>
              <ButtonAtlaskit
                key="tudo"
                className={
                  tipo_parametro.value === 0
                    ? 'btn-group btn-group-active'
                    : 'btn-group'
                }
                value="0"
                style={{
                  height: '37px',
                  marginLeft: '0px',
                  paddingLeft: '0px',
                  borderRadius: '4px 0px 0px 4px',
                }}
                type="button"
                isDisabled={tipoIsDisabled}
                onClick={() => {
                  setTipo_parametro({ value: 0, label: 'Tudo' });
                  setValue('tipo_parametro', { value: 0, label: 'Tudo' });
                }}
              >
                Tudo
              </ButtonAtlaskit>
              <ButtonAtlaskit
                key="nfs"
                className={
                  tipo_parametro.value === 1
                    ? 'btn-group btn-group-active'
                    : 'btn-group'
                }
                style={{
                  color: '#fff',
                  height: '37px',
                }}
                type="button"
                isDisabled={false}
                onClick={() => {
                  setTipo_parametro({ value: 1, label: "NF's" });
                  setValue('tipo_parametro', { value: 1, label: "NF's" });
                }}
              >
                {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
                {`NF's`}
              </ButtonAtlaskit>
              <ButtonAtlaskit
                key="vendaspdv"
                className={
                  tipo_parametro.value === 2
                    ? 'btn-group btn-group-active'
                    : 'btn-group'
                }
                style={{
                  color: '#fff',
                  height: '37px',

                  borderRadius: '0px 4px 4px 0px',
                }}
                type="button"
                isDisabled={tipoIsDisabled}
                onClick={() => {
                  setTipo_parametro({ value: 2, label: 'Vendas PDV' });
                  setValue('tipo_parametro', { value: 2, label: 'Vendas PDV' });
                }}
              >
                Vendas PDV
              </ButtonAtlaskit>
            </BtnGroupContainer>
          </div>
          <div className="col-sm-12 col-md-12">
            <Separator labelText="Produto (Opcional)" />
          </div>
          <div className="col-sm-12 col-md-12" style={{ marginTop: '-1.3rem' }}>
            <BuscaProduto
              placeholder="Busque por Código, GTIN ou Descrição"
              name="busca_produto"
              register={register}
              isError={!!errors.busca_produto}
              control={control}
              buscaNasLojas={[1]}
              buscaItensInativos
              setValue={setValue}
              customOptions={{
                hideBuscarPor: true,
                buscarPor: ['Produto'],
                showSelecionarItensContendo: false,
              }}
              getProduct={(selected: any) => {
                clearErrors('busca_produto');

                setValue('busca_produto', {
                  label: `${selected[0].cod_produto} - ${selected[0].des_produto}`,
                  value: selected[0].cod_produto,
                });
              }}
            />
          </div>
        </div>
      </FormDefault>
    </Container>
  );
};

export default ReprocessaPisCofinsContent;
