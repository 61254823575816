/* eslint-disable import/no-extraneous-dependencies */
import { GridRowParams } from '@material-ui/data-grid';
import { format, getUnixTime, parseISO } from 'date-fns';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  BuscaParceiro,
  InputAsyncSelect,
  InputMoney,
  InputNumber,
  InputTextArea,
  Loja,
  ContaBanco,
  Categoria,
  Finalizadora,
  InputJuros,
} from '~/components/NovosInputs';
import { toast } from 'react-toastify';
import DefaultLoader from '~/components/DefaultLoader';

import { Container, SeparatorVertical } from './styles';
import {
  Condicao,
  IContasReceber,
  Parcela,
  ReceiptData,
  Select,
} from './types';

import FormDefault from '~/components/FormDefault';

import InputNumberDias from '~/components/Inputs/inputNumberDias';

import Search from '~/components/Search';
import ToggleDefault from '~/components/ToggleDefault';
import api from '~/services/api';
import { errorHandler } from '~/utils/ErrorHandler';
import {
  formatCurrencyAsText,
  getDataUtil,
  transformAsCurrency,
} from '~/utils/functions';
import { LojaContext } from '~/context/loja';
import ImpressaoRecibo from '~/utils/classes/ImpressaoRecibo/ImpressaoRecibo';

import { InputDate } from '~/components/NovosInputs/InputDate';
import { useContasAReceber } from './ContasAReceberContext';
import { useQueryClient } from 'react-query';

import { createDateWithoutTimezone } from '~/utils/createDateWithoutTimezone ';
import { ModalRecibo } from './Components/ModalRecibo';

const ContasAReceberContent: React.FC = () => {
  const queryClient = useQueryClient();
  const {
    formContasAReceber: {
      watch,
      reset,
      control,
      register,
      setValue,
      getValues,
      formState: { errors },
      clearErrors,
      handleSubmit,
    },
    setRecibo,
    setShowModal,
    isUpdate,
    setUpdate,
  } = useContasAReceber();

  const [loader, setLoader] = useState<boolean>(false);
  const { codLoja, loja } = useContext(LojaContext);
  const [iniInicializado, setInitInicializado] = useState(false);
  const [showSearch, setShowSearch] = useState(true);
  const [condicoes, setCondicoes] = useState<Select[]>([]);
  const [somaCondicao, setSomaCondicao] = useState(false);
  const [flgAceite, setFlgAceite] = useState<boolean>(false);

  const [lojaRetriever, setLojaRetriever] = useState<number | null>(null);
  const [contaBancoRetriever, setContaBancoRetriever] = useState<number | null>(
    null,
  );

  const [codSeqQuitacao, setCodSeqQuitacao] = useState<number>();
  const [receiptParcelaData, setReceiptParcelaData] = useState<Parcela[]>([]);
  const [displabeButtonJuros, setDisplabeButtonJuros] =
    useState<boolean>(false);
  const [inputArr, setInputArr] = useState<any[]>([]);
  const [codParcelaReceber, setCodParcelaReceber] = useState<number | null>(
    null,
  );
  const [contaQuitada, setContaQuitada] = useState(false);

  const containerRef = useRef<HTMLDivElement | null>(null);

  const flgQuitado = watch('flg_quitado');
  const flg_leitura_vdaa = watch('flg_leitura_vda');

  const [formData, setFormData] = useState<IContasReceber>({
    cod_loja: {
      value: codLoja,
      isInvalid: true,
      isRequired: true,
    },
    num_condicao: {
      value: 30,
      isInvalid: false,
      isRequired: true,
      invalidLabel: undefined,
    },
    cod_condicao: {
      value: { value: 2, label: 'DIAS DA DATA - DD' },
      isInvalid: false,
      isRequired: true,
      invalidLabel: undefined,
    },

    num_cupom_fiscal: {
      value: undefined,
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
  });

  const getCondicoes = async () => {
    try {
      const res = await api.get('/condicao');
      const { success, data, message } = res.data;
      if (!success) throw new Error(message);
      const options: Select[] = data.map((condicao: Condicao) => ({
        label: `${condicao.des_condicao} - ${condicao.des_definicao}`,
        value: condicao.cod_condicao,
      }));
      setCondicoes(options);
    } catch (error: any) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getCondicoes();

    setLojaRetriever(codLoja);
    setValue('cod_loja', {
      value: loja.cod_loja,
      label: `${loja.des_loja} (${loja.des_cidade})`,
    });
  }, []);

  /**
   * Reseta form para criação de novo registro
   */
  const resetFormData = useCallback(() => {
    setInputArr([]);
    setContaQuitada(false);
    reset({
      cod_loja: {
        value: undefined,
        label: 'Selecione',
      },
      cod_cc: {
        value: null,
        label: 'Selecione',
      },
      cod_categoria_receber: {
        value: null,
        label: 'Selecione',
      },
      cod_finalizadora_receber: {
        value: null,
        label: 'Selecione',
      },
      cod_pessoa: {
        value: null,
        label: '',
      },
      dta_emissao: '',
      dta_entrada: '',
      val_parcela: '',
      num_docto: null,
      condicao: null,
      dta_vencimento: '',
      flg_quitado: false,
      dta_quitada: '',
      flg_leitura_vda: false,
      num_pdv: 0,
      num_cupom_fiscal: 0,
      des_observacao: '',
      val_juros: '',
    });

    setDisplabeButtonJuros(false);
    setInitInicializado(false);
    setUpdate(false);
    clearErrors();
    setLojaRetriever(null);
    setContaBancoRetriever(null);
    setFormData({
      ...formData,
      cod_loja: {
        value: codLoja,
        isInvalid: true,
        isRequired: true,
      },

      num_condicao: {
        value: 0,
        isInvalid: false,
        isRequired: true,
        invalidLabel: undefined,
      },
      cod_condicao: {
        value: { value: 2, label: 'DIAS DA DATA - DD' },
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },

      num_cupom_fiscal: {
        value: 0,
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
    });

    setFlgAceite(false);
  }, [codLoja, formData, clearErrors, reset, setUpdate]);

  const handleChangeFinalizadora2 = (newValue: any, isInvalid: boolean) => {
    setSomaCondicao(true);

    const object = condicoes.find(
      (condicao) => condicao.value === newValue.cod_condicao,
    );
    setValue('condicao', object);
    clearErrors('condicao');
    setFormData({
      ...formData,
      num_condicao: {
        ...formData.num_condicao,
        value: newValue.num_condicao,
        isInvalid: newValue.num_condicao < 1,
      },
    });
  };

  const handleChangeNumCondicao = useCallback(
    (val: number) => {
      let isInvalid = false;
      if (
        typeof transformAsCurrency(val || 0) !== 'number' &&
        transformAsCurrency(val || 0) < 0
      ) {
        isInvalid = true;
      }
      setFormData({
        ...formData,
        num_condicao: {
          ...formData.num_condicao,
          value: val,
          isInvalid,
        },
      });
    },
    [formData],
  );

  /**
   * Validações
   */
  const validaInputsParaSubmit = useCallback((): boolean => {
    setInitInicializado(false);
    const data = formData;
    let isInvalid = false;

    if (typeof data.cod_condicao.value.value !== 'number') {
      isInvalid = true;
      data.cod_condicao.isInvalid = isInvalid;
    }

    setFormData(data);
    return isInvalid;
  }, [formData, setFormData, getValues]);

  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;
    setUpdate(true);
    setFlgAceite(true);

    const {
      cod_conta_receber,
      cod_banco_cc,

      cod_loja,
      des_loja,
      des_loja_cidade,
      cod_pessoa,
      nome_pessoa,
      des_cidade,
      des_uf,
      cod_categoria,
      descricao,
      dta_emissao,
      dta_entrada,
      val_parcela,
      num_docto,
      cod_finalizadora,
      des_finalizadora,
      num_condicao,
      cod_condicao,
      dta_vencimento,
      flg_quitado,
      dta_quitada,
      flg_leitura_vda,
      num_pdv,
      num_cupom_fiscal,
      des_observacao,
      cod_seq_quitacao,
      val_juros,
      cod_parcela_receber,
    } = row;
    setReceiptParcelaData([
      {
        cod_loja,
        num_docto,
        cod_pessoa,
        nome_pessoa,
        cod_finalizadora,
        des_finalizadora,
        val_parcela,
        dta_entrada,
        dta_vencimento,
        val_juros,
      },
    ]);

    setContaQuitada(flg_quitado);

    setDisplabeButtonJuros(flg_quitado);

    setValue('val_juros', formatCurrencyAsText(val_juros));

    setCodSeqQuitacao(cod_seq_quitacao);
    setLojaRetriever(cod_loja);

    setValue('cod_conta_receber', cod_conta_receber);

    setFormData({
      ...formData,

      cod_loja: {
        value: cod_loja,
        isInvalid: false,
        isRequired: true,
      },

      num_condicao: {
        value: num_condicao,
        isInvalid: false,
        isRequired: true,
        invalidLabel: undefined,
      },
      cod_condicao: {
        value:
          cod_condicao === 0
            ? { label: 'Dias da data', value: cod_condicao }
            : { label: 'Teste', value: cod_condicao },
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },

      num_cupom_fiscal: {
        value: num_cupom_fiscal,
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
    });

    // Preencher Loja
    setLojaRetriever(cod_loja);
    setValue('cod_loja', {
      value: cod_loja,
      label: `${des_loja} (${des_loja_cidade})`,
    });

    // Preencher banco
    setValue('cod_cc_receber', cod_banco_cc);
    setContaBancoRetriever(cod_banco_cc);

    // Preencher Parceiro
    setValue('cod_pessoa', {
      label: `${row.cod_pessoa} - ${nome_pessoa} ${
        des_cidade === null || des_uf === null
          ? ''
          : ` (${des_cidade} - ${des_uf})`
      }`,
      value: row.cod_pessoa,
    });

    // Preenchendo Categoria
    setValue('cod_categoria_receber', {
      value: cod_categoria,
      label: descricao,
    });

    // Preenchendo Finalizadora
    setValue('cod_finalizadora_receber', {
      value: cod_finalizadora,
      label: des_finalizadora,
    });

    setValue(
      'dta_emissao',
      format(createDateWithoutTimezone(dta_emissao), 'yyy-MM-dd'),
    );
    setValue(
      'dta_entrada',
      format(createDateWithoutTimezone(dta_entrada), 'yyy-MM-dd'),
    );
    setValue('val_parcela', formatCurrencyAsText(val_parcela));
    setValue('num_docto', num_docto);
    setValue('des_finalizadora', des_finalizadora);
    setValue(
      'condicao',
      condicoes.find((condicao: Select) => condicao.value === cod_condicao),
    );
    setValue('num_condicao', num_condicao);
    setValue(
      'dta_vencimento',
      dta_vencimento
        ? format(createDateWithoutTimezone(dta_vencimento), 'yyy-MM-dd')
        : '',
    );
    setValue('flg_quitado', flg_quitado);
    setValue(
      'dta_quitada',
      dta_quitada === null || !flg_quitado
        ? ''
        : format(createDateWithoutTimezone(dta_quitada), 'yyy-MM-dd'),
    );
    setValue('flg_leitura_vda', flg_leitura_vda);
    setValue('num_pdv', num_pdv);
    setValue('num_cupom_fiscal', num_cupom_fiscal);
    setValue('des_observacao', des_observacao);

    setSomaCondicao(false);

    setLoader(false);
    setShowSearch(false);

    setCodParcelaReceber(cod_parcela_receber);
  };

  const onNew = () => {
    resetFormData();
    setUpdate(false);
    setLojaRetriever(codLoja);
    setValue('cod_loja', {
      value: loja.cod_loja,
      label: `${loja.des_loja} (${loja.des_cidade})`,
    });
  };

  const generateRecibo = useCallback(
    async (data: ReceiptData, baixar = false, show = false) => {
      const impressaoRecibo = new ImpressaoRecibo(data, receiptParcelaData);
      const reciboGerado = await impressaoRecibo.baixarRecibo(baixar);
      setRecibo(reciboGerado);
      if (show) setShowModal(true);
    },
    [receiptParcelaData, setRecibo, setShowModal],
  );

  const formatterRequestData = (data: any) => {
    return {
      cod_loja: data.cod_loja.value,
      cod_cc: data.cod_cc_receber.value,
      cod_pessoa: data.cod_pessoa.value,
      cod_categoria: data.cod_categoria_receber.value,
      dta_emissao: data.dta_emissao,
      dta_entrada: data.dta_entrada,
      val_parcela: transformAsCurrency(data.val_parcela),
      num_docto: Number(data.num_docto),
      cod_finalizadora: data.cod_finalizadora_receber.value,
      des_finalizadora: data.cod_finalizadora_receber.label,
      num_condicao: formData.num_condicao.value,
      cod_condicao: data.condicao.cod_condicao,
      dta_vencimento: data.dta_vencimento,
      flg_quitado: data.flg_quitado,
      dta_quitada: data.dta_quitada,
      flg_leitura_vda: data.flg_leitura_vda,
      num_caixa: data.num_caixa,
      num_pdv: data.num_caixa,
      num_cupom_fiscal: formData.num_cupom_fiscal.value,
      des_observacao: data.des_observacao,
      val_juros: transformAsCurrency(data.val_juros),
      cod_parcela_receber: codParcelaReceber,
    };
  };

  const handleButtonCalcJuros = async () => {
    const { cod_loja, val_parcela, dta_vencimento, dta_quitada } = getValues();

    if (!cod_loja.value || !val_parcela || !dta_vencimento || !dta_quitada) {
      return toast.warning(
        'Para cálculo do juros informe data de vencimento e quitação.',
      );
    }

    try {
      const { data } = await api.post(`/conta-receber/val-juros`, {
        cod_loja: cod_loja.value,
        val_parcela: transformAsCurrency(val_parcela),
        dta_vencimento,
        dta_quitada,
      });

      if (data.success) {
        setValue('val_juros', formatCurrencyAsText(data.data.val_juros));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const delay = (ms: number): Promise<void> => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const onSave = handleSubmit(async (data) => {
    if (isUpdate && inputArr.length > 0) {
      inputArr.forEach((input) => {
        if ('name' in input === false) return;

        data[input.name] = { label: input.label, value: input.value };
        setValue(input.name, { label: input.label, value: input.value });
      });
    }
    await delay(800);
    if (validaInputsParaSubmit()) {
      setInitInicializado(true);
      return;
    }

    if (
      getUnixTime(parseISO(data.dta_entrada)) <
      getUnixTime(parseISO(data.dta_emissao))
    ) {
      setLoader(false);
      toast.warning(
        'Data de entrada deve ser maior ou igual à data de emissão',
      );
      return;
    }

    if (
      getUnixTime(parseISO(data.dta_vencimento)) <
      getUnixTime(parseISO(data.dta_emissao))
    ) {
      setLoader(false);
      toast.warning(' Vencimento deve ser maior ou igual a data de emissao');
      return;
    }

    if (
      getUnixTime(parseISO(data.dta_quitada)) <
      getUnixTime(parseISO(data.dta_entrada))
    ) {
      setLoader(false);
      toast.warning(
        'Data de Quitação deve ser maior ou igual à Data de Entrada',
      );
      return;
    }

    const requestData = formatterRequestData(data);
    if (isUpdate) {
      const res = await api.put(
        `/contas-receber/${data.cod_conta_receber}`,
        requestData,
      );

      if (res.data.success) {
        toast.success(res.data.message);
        setShowSearch(true);
      }
      return;
    }

    const res = await api.post('/contas-receber', requestData);

    if (res.data.success) {
      resetFormData();
      toast.success(res.data.message);
    } else {
      setLoader(false);
    }
  });

  const handleDelete = async () => {
    const { cod_conta_receber } = getValues();
    const { data } = await api.delete(`/conta-receber/${cod_conta_receber}`);

    if (data.success) {
      resetFormData();
      setShowSearch(true);
      setUpdate(false);
      toast.success('Conta removida com sucesso');
    }
  };

  const getReceipt = async () => {
    try {
      const res = await api.get('/quitacao/recibo', {
        params: {
          cod_seq_quitacao: codSeqQuitacao,
        },
      });

      const { success, message } = res.data;
      if (!success) throw new Error(message);
      if (res.data.data.length > 0)
        generateRecibo(res.data.data[0], true, true);
    } catch (error: any) {
      toast.warning(error.data);
    }
  };

  const updateArrayInput = useCallback(
    (selected: any, nameInput: string) => {
      if (isUpdate) {
        const itemValue = inputArr.find((i) => i.name === nameInput);

        if (itemValue) {
          itemValue.label = selected.label;
          itemValue.value = selected.value;
        }
      }
    },
    [inputArr, isUpdate],
  );

  useEffect(() => {
    const numCondicao = formData.num_condicao.value;
    const emissao = getValues('dta_emissao');

    if (numCondicao && emissao && somaCondicao) {
      const diaUtil = getDataUtil(emissao, numCondicao);
      setValue('dta_vencimento', diaUtil);
    }
  }, [formData.num_condicao.value, somaCondicao]);

  useEffect(() => {
    if (!flgQuitado) {
      setValue('dta_quitada', '');
    }
  }, [flgQuitado]);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;

      if (
        containerRef.current?.contains(target) &&
        target.tagName === 'INPUT'
      ) {
        const input = target as HTMLInputElement;
        const value = getValues(input.name);

        if (!value || !value?.value) return;

        const newEntry = {
          name: input.name,
          value: value.value,
          label: value.label,
        };

        setValue(newEntry.name, {
          value: newEntry.value,
          label: newEntry.label,
        });

        setInputArr((prev) => {
          const exists = prev.some(
            (item) =>
              item.name === newEntry.name && item.value === newEntry.value,
          );

          return exists ? prev : [...prev, newEntry];
        });
      }
    };

    if (!isUpdate) return;

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [getValues, inputArr, isUpdate, setValue]);

  if (loader) {
    return <DefaultLoader />;
  }

  return (
    <Container ref={containerRef}>
      {showSearch && (
        <Search
          codTela={46}
          newData={() => {
            resetFormData();
            setLojaRetriever(codLoja);
            setValue('cod_loja', {
              value: loja.cod_loja,
              label: `${loja.des_loja} (${loja.des_cidade})`,
            });
            setShowSearch(false);
          }}
          onRowClick={onRowClick}
        />
      )}

      {!showSearch && (
        <FormDefault
          codTela={46}
          title="Contas a Receber"
          onSave={async () => {
            await onSave();
          }}
          codigoRegistro={[
            {
              value: getValues('cod_conta_receber'),
              des_campo: 'Código',
            },
          ]}
          onCancel={() => {
            resetFormData();
            setLojaRetriever(codLoja);
            setValue('cod_loja', {
              value: loja.cod_loja,
              label: `${loja.des_loja} (${loja.des_cidade})`,
            });
            setShowSearch(true);
          }}
          isUpdate={isUpdate}
          onNew={() => onNew()}
          onDelete={handleDelete}
          onClearFields={() => {
            resetFormData();
            setLojaRetriever(codLoja);
            setValue('cod_loja', {
              value: loja.cod_loja,
              label: `${loja.des_loja} (${loja.des_cidade})`,
            });
          }}
          onReturnSearch={() => {
            setShowSearch(true);
            setUpdate(false);
            resetFormData();
            setLojaRetriever(codLoja);
            setValue('cod_loja', {
              value: loja.cod_loja,
              label: `${loja.des_loja} (${loja.des_cidade})`,
            });
            queryClient.removeQueries('cache_bancos');
          }}
          showSwitch={false}
          showReceiptButton={contaQuitada}
          getReceipt={() => getReceipt()}
        >
          <>
            <div className="row">
              <div
                className={`col-sm-12 col-md-8 ${
                  formData.cod_loja.value === undefined &&
                  iniInicializado === true
                    ? 'loja'
                    : ''
                }`}
                style={{ zIndex: 3 }}
              >
                <Loja
                  label="Loja"
                  placeholder="Selecione"
                  register={register}
                  isError={!!errors.cod_loja}
                  control={control}
                  name="cod_loja"
                  changeSelected={(selected: any) => {
                    clearErrors('cod_loja');
                    setLojaRetriever(selected.cod_loja);
                    setValue('cod_loja', selected);
                  }}
                />
              </div>
              <div className="col-sm-12 col-md-4" style={{ zIndex: 2 }}>
                <ContaBanco
                  label="Conta"
                  placeholder="Selecione"
                  register={register}
                  isError={!!errors.cod_cc_receber}
                  control={control}
                  name="cod_cc_receber"
                  loja={lojaRetriever}
                  retriever={contaBancoRetriever}
                  changeSelected={(selected: any) => {
                    clearErrors('cod_cc_receber');
                    setContaBancoRetriever(null);
                    setValue('cod_cc_receber', selected);
                    updateArrayInput(selected, 'cod_cc_receber');
                  }}
                />
              </div>
            </div>
            <div className="row" style={{ marginBottom: '5px' }}>
              <div
                className={`col-sm-12 ${isUpdate ? 'col-md-6' : 'col-md-12'}`}
                style={{
                  zIndex: 1,
                  minWidth: `${isUpdate ? '500px' : ''}`,
                }}
              >
                <BuscaParceiro
                  label="Parceiro"
                  placeholder="Informe pelo menos 3 caracteres do Cliente, Fornecedor ou Loja"
                  name="cod_pessoa"
                  register={register}
                  isError={!!errors.cod_pessoa}
                  control={control}
                  customOptions={{
                    buscarPor: {
                      cliente: true,
                      fornecedor: true,
                      loja: true,
                    },
                  }}
                  changeSelected={(selected: any) => {
                    clearErrors('cod_pessoa');
                    const cod = selected.cod_pessoa ? selected.cod_pessoa : '';
                    const nome = selected.nome_pessoa
                      ? selected.nome_pessoa
                      : '';
                    const cidade = selected.des_cidade
                      ? ` (${selected.des_cidade} - ${selected.des_uf})`
                      : '';
                    setValue('cod_pessoa', {
                      value: selected.value,
                      label: `${cod} - ${nome} ${cidade}`,
                    });

                    updateArrayInput(selected, 'cod_pessoa');
                  }}
                />
              </div>
              {isUpdate && (
                <>
                  <div
                    className="col-sm-12 col-md-1"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <SeparatorVertical />
                  </div>
                  <div
                    className="col-sm-12 col-md-1"
                    style={{
                      textAlign: 'center',
                      zIndex: 0,
                    }}
                  >
                    <ToggleDefault
                      labelText="Automático?"
                      disabled
                      setChecked={flg_leitura_vdaa}
                      onSwitch={() =>
                        setValue('flg_leitura_vda', !flg_leitura_vdaa)
                      }
                    />
                  </div>
                  <div className="col-sm-12 col-md-2">
                    <InputNumber
                      label="PDV"
                      name="num_pdv"
                      isError={!!errors.num_pdv}
                      placeholder=""
                      min={0}
                      max={99999999999}
                      maxLength={9999999999}
                      register={register}
                      disabled
                    />
                  </div>
                  <div className="col-sm-12 col-md-2">
                    <InputNumber
                      label="Cupom"
                      name="num_cupom_fiscal"
                      placeholder=""
                      isError={false}
                      min={0}
                      max={99999999999}
                      maxLength={9999999999}
                      register={register}
                      disabled
                    />
                  </div>
                </>
              )}
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-4">
                <Categoria
                  label="Categoria"
                  placeholder="Selecione"
                  tipoCategoria="Receita"
                  register={register}
                  isError={!!errors.cod_categoria_receber}
                  control={control}
                  name="cod_categoria_receber"
                  changeSelected={(selected: any) => {
                    clearErrors('cod_categoria_receber');
                    setValue('cod_categoria_receber', selected);
                    updateArrayInput(selected, 'cod_categoria_receber');
                  }}
                />
              </div>
              <div className="col-sm-12 col-md-2">
                <InputDate
                  label="Emissão"
                  placeholder=""
                  name="dta_emissao"
                  register={register}
                  control={control}
                  isError={!!errors.dta_emissao}
                />
              </div>
              <div className="col-sm-12 col-md-2">
                <InputDate
                  label="Entrada"
                  placeholder=""
                  name="dta_entrada"
                  register={register}
                  control={control}
                  isError={!!errors.dta_entrada}
                />
              </div>
              <div className="col-sm-12 col-md-2">
                <InputMoney
                  label="Valor"
                  min={0.0}
                  max={999999.01}
                  placeholder="0,00"
                  register={register}
                  name="val_parcela"
                  isError={!!errors.val_parcela}
                />
              </div>
              <div className="col-sm-12 col-md-2">
                <InputNumber
                  name="num_docto"
                  label="Nº Docto"
                  register={register}
                  isError={!!errors.num_docto}
                  min={0}
                  max={999999999999999}
                  maxLength={15}
                  placeholder="Informe o Nº Docto"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-2">
                <Finalizadora
                  label="Finalizadora"
                  placeholder="Selecione"
                  register={register}
                  isError={!!errors.cod_finalizadora_receber}
                  control={control}
                  name="cod_finalizadora_receber"
                  loja={lojaRetriever}
                  changeSelected={(selected: any) => {
                    clearErrors('cod_finalizadora_receber');
                    setValue('cod_finalizadora_receber', selected);
                    handleChangeFinalizadora2(
                      selected,
                      typeof selected.value !== 'number',
                    );
                    updateArrayInput(selected, 'cod_finalizadora_receber');
                  }}
                />
              </div>
              <div className="col-sm-12 col-md-2">
                <div>
                  <InputNumberDias
                    label="Condição"
                    min={0}
                    max={999}
                    value={formData.num_condicao.value}
                    placeholder=""
                    isNull
                    isDisabled={flgAceite}
                    isRequired={!!errors.num_condicao}
                    setInvalid={!!errors.num_condicao}
                    iniInicializado={!!errors.num_condicao}
                    onBlur={(event: any) => {
                      if (event.target.value === '') {
                        handleChangeNumCondicao(0);
                      }
                    }}
                    onChange={(newValue: number) => {
                      if (newValue) {
                        handleChangeNumCondicao(
                          Number(String(newValue).replace('-', '')),
                        );
                      } else {
                        handleChangeNumCondicao(newValue);
                      }
                    }}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <InputAsyncSelect
                  label="Tipo de Condição"
                  maxLength={50}
                  placeholder="Selecione a Condição"
                  name="condicao"
                  register={register}
                  isError={!!errors.condicao}
                  control={control}
                  changeSelected={(selected) => {
                    clearErrors('condicao');
                    setValue('condicao', selected);
                    updateArrayInput(selected, 'condicao');
                  }}
                  api={{
                    route: '/condicao',
                    method: 'get',
                    fields: ['des_definicao', 'des_condicao'],
                    searchBeforeFilter: true,
                  }}
                />
              </div>
              <div className="col-sm-12 col-md-2">
                <InputDate
                  label="Vencimento"
                  placeholder=""
                  name="dta_vencimento"
                  register={register}
                  control={control}
                  isError={!!errors.dta_vencimento}
                />
              </div>
              <div className="col-sm-12 col-md-1">
                <ToggleDefault
                  labelText="Quitado?"
                  setChecked={flgQuitado}
                  onSwitch={() => {
                    setValue('flg_quitado', !flgQuitado);

                    if (isUpdate) setContaQuitada(false);
                  }}
                />
              </div>
              <div className="col-sm-12 col-md-2">
                <InputDate
                  label="Quitação"
                  placeholder=""
                  name="dta_quitada"
                  register={register}
                  control={control}
                  isError={!!errors.dta_quitada}
                  isDisabled={!flgQuitado}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-2">
              <InputJuros
                label="Juros"
                min={0.0}
                max={999999.01}
                placeholder="0,00"
                register={register}
                disabled={displabeButtonJuros}
                buttonDisabled={displabeButtonJuros || !flgQuitado}
                control={control}
                clickedButton={handleButtonCalcJuros}
                name="val_juros"
                isError={!!errors.val_juros}
              />
            </div>
            <div className="row">
              <div
                className="col-sm-12 col-md-12"
                style={{ marginTop: '20px' }}
              >
                <div>
                  <InputTextArea
                    maxLength={500}
                    label=""
                    placeholder="Informe a Observação"
                    name="des_observacao"
                    register={register}
                    isError={false}
                    control={control}
                    rows={4}
                    resize={false}
                  />
                </div>
              </div>
            </div>
            <ModalRecibo />
          </>
        </FormDefault>
      )}
    </Container>
  );
};

export default ContasAReceberContent;
