import 'react-credit-cards-2/dist/es/styles-compiled.css';

import React, { useCallback, useEffect, useState } from 'react';
import { InputCpfCnpj, InputText } from '~/components/NovosInputs';
import { CreditCardContent } from './styles';
import { useMeuPlano } from '../../hooks/useMeuPlano';
import Cards from 'react-credit-cards-2';
import { formatUtils } from '../../utils/format-utils';
import Separator from '~/components/Separator';
import { ScreenPaymentsProps } from '../../types/context';

// https://www.npmjs.com/package/react-credit-cards-2
// https://github.com/felquis/react-credit-cards-2#readme

const translations = {
  valid: 'Válido até',
  month: 'Mês',
  year: 'Ano',
  cvc: 'Código de Segurança',
  cardHolder: 'Titular do Cartão',
  number: 'Número do Cartão',
};

export const CreditCard: React.FC<{
  screen: ScreenPaymentsProps;
}> = ({ screen }) => {
  const { formCard, methodFaturamento } = useMeuPlano();

  const [card, setCard] = useState<any>({
    number: '',
    expiry: '',
    cvc: '',
    name: '',
    focus: '',
  });

  const {
    registerCard,
    controlCard,
    setValueCard,
    getValuesCard,
    watchCard,
    formStateCard: { errors },
  } = formCard;

  const cvc = watchCard('cvc');
  const creditCardHolderName = watchCard('name');

  useEffect(() => {
    if (methodFaturamento === 'cartao-credito') {
      const names = ['number', 'expiry', 'cvc', 'name'];
      names.forEach((name) => {
        const value = getValuesCard(name);
        setCard((prev: any) => ({ ...prev, [name]: value }));
      });
    }
  }, [getValuesCard, methodFaturamento]);

  const handleInputFocus = useCallback((evt) => {
    setCard((prev: any) => ({ ...prev, focus: evt.target.name }));
  }, []);

  const handleInputChange = useCallback(
    (evt) => {
      const { name, value } = evt.target;

      if (name === 'number') {
        const cardNumber = formatUtils.formatCreditCardNumber(value);
        setCard((prev: any) => ({ ...prev, number: cardNumber }));
        setValueCard(name, cardNumber);
      } else {
        setCard((prev: any) => ({
          ...prev,
          [name]: String(value).toUpperCase(),
        }));
        setValueCard(name, String(value).toUpperCase());
      }
    },
    [setValueCard],
  );

  const handleDateFormat = (event: any) => {
    const { value } = event.target;

    event.target.value = formatUtils.formatExpirationDate(value);
    setValueCard('expiry', formatUtils.formatExpirationDate(value));
    setCard((prev: any) => ({
      ...prev,
      expiry: formatUtils.formatExpirationDate(value),
    }));
  };

  const handleFormat = (event: any) => {
    const { value, name } = event.target;

    if (name === 'num_cep') {
      event.target.value = formatUtils.formatCEP(value);
      setValueCard(name, formatUtils.formatCEP(value));
    } else if (name === 'num_celular') {
      event.target.value = formatUtils.formatCellphone(value);
      setValueCard(name, formatUtils.formatCellphone(value));
    } else if (name === 'num_telefone') {
      event.target.value = formatUtils.formatPhone(value);
      setValueCard(name, formatUtils.formatPhone(value));
    }
  };

  return (
    <CreditCardContent>
      <Cards
        number={card.number}
        expiry={card.expiry}
        cvc={cvc}
        name={creditCardHolderName}
        focused={card.focus}
        locale={translations}
        placeholders={{ name: 'Seu Nome Aqui' }}
      />
      <div className="inputs-content">
        <div className="grid-container">
          <InputText
            maxLength={22}
            label="Número do cartão"
            placeholder="Número do cartão"
            name="number"
            control={controlCard}
            register={registerCard}
            isError={!!errors.number}
            onInput={handleInputChange}
            onFocus={handleInputFocus}
          />
          <InputText
            maxLength={5}
            label="Data de validade"
            placeholder="MM/YY"
            name="expiry"
            control={controlCard}
            register={registerCard}
            isError={!!errors.expiry}
            onInput={(e) => handleDateFormat(e)}
            onFocus={handleInputFocus}
          />

          <InputText
            maxLength={3}
            label="CVV"
            placeholder="000"
            name="cvc"
            control={controlCard}
            register={registerCard}
            isError={!!errors.cvc}
            // onInput={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>

        <InputText
          maxLength={50}
          label="Nome no cartão"
          placeholder="Nome do titular do cartão"
          name="name"
          control={controlCard}
          register={registerCard}
          isError={!!errors.name}
          // onInput={handleInputChange}
          onFocus={handleInputFocus}
        />

        {screen === 'mudar-dados-pagamento' && (
          <>
            <Separator labelText="Informações básicas" />

            <InputText
              maxLength={100}
              label="Nome completo"
              caseInput="upper"
              placeholder="Informe seu nome completo"
              name="des_nome"
              control={controlCard}
              toLowerCase={false}
              register={registerCard}
              isError={!!errors.des_nome}
            />
            <InputText
              maxLength={200}
              label="E-mail"
              placeholder="Informe seu e-mail"
              name="des_email"
              control={controlCard}
              toLowerCase
              register={registerCard}
              isError={!!errors.des_email}
              type="email"
            />
            <InputCpfCnpj
              label="CPF/CNPJ"
              name="num_cpf_cnpj"
              control={controlCard}
              register={registerCard}
              setValue={setValueCard}
              isError={!!errors.num_cpf_cnpj}
              isDisabled={false}
            />

            <Separator labelText="Endereço de cobrança" />

            <div className="endereco-container">
              <InputText
                maxLength={9}
                label="CEP"
                caseInput="upper"
                placeholder="00000-000"
                name="num_cep"
                control={controlCard}
                toLowerCase={false}
                register={registerCard}
                isError={!!errors.num_cep}
                onInput={(e) => handleFormat(e)}
              />
              <InputText
                maxLength={200}
                label="Complemento"
                placeholder="Informe o complemento"
                name="des_complemento"
                control={controlCard}
                toLowerCase
                register={registerCard}
                isError={!!errors.des_complemento}
              />
            </div>
            <div className="dados-basicos">
              <InputText
                maxLength={9}
                label="Número do endereço"
                caseInput="upper"
                placeholder="Número"
                name="num_endereco"
                control={controlCard}
                toLowerCase={false}
                register={registerCard}
                isError={!!errors.num_endereco}
              />
              <InputText
                maxLength={15}
                label="Celular"
                placeholder="Informe o celular"
                name="num_celular"
                control={controlCard}
                toLowerCase
                register={registerCard}
                isError={!!errors.num_celular}
                onInput={handleFormat}
              />
              <InputText
                maxLength={14}
                label="Telefone"
                placeholder="Informe o telefone"
                name="num_telefone"
                control={controlCard}
                toLowerCase
                register={registerCard}
                isError={!!errors.num_telefone}
                onInput={handleFormat}
              />
            </div>
          </>
        )}
      </div>
    </CreditCardContent>
  );
};
