// eslint-disable-next-line import/no-named-default
import { default as AddButton } from '@atlaskit/button';
import {
  DataGrid,
  GridCellEditCommitParams,
  GridColumns,
  GridRowParams,
} from '@material-ui/data-grid';
import { CancelButton, ConfirmButton } from '~/components/Buttons';
import DefaultLoader from '~/components/DefaultLoader';
import FormDefault from '~/components/FormDefault';
import InputSelect from '~/components/Inputs/InputSelect';
import InputText from '~/components/Inputs/InputText';
import Loja from '~/components/Loja';
import Search from '~/components/Search';
import Separator from '~/components/Separator';
import SeparatorVertical from '~/components/SeparatorVertical';
import ToggleDefault from '~/components/ToggleDefault';
import useAuth from '~/hooks/useAuth';
import api from '~/services/api';
import { nanoid } from 'nanoid';
import React, { useCallback, useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { BsPlusCircle } from 'react-icons/bs';
import { FaRegEye } from 'react-icons/fa';
import { FcPlus } from 'react-icons/fc';
import { GoPencil } from 'react-icons/go';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import { MdDeleteForever } from 'react-icons/md';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { ButtonRow, Container, Modal } from './styles';
import {
  AddEtqLoja,
  IAlturas,
  IdataAddEtiqueta,
  IEtiquetas,
  IFormData,
  IFormDataEtiqueta,
  IFormFiltraEtiqueta,
  IGrupos,
  IImpressoraItem,
  ILarguras,
  IModeloEtiquetas,
  IPrecoArredonda,
  Options,
} from './types';

const MySwal = withReactContent(Swal);

const TabelaDePreco: React.FC = () => {
  const user = useAuth();
  const [loader, setLoader] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState(false);
  const [showSearch, setShowSearch] = useState(true);
  const [key, setKey] = useState<any>('etiquetas');
  const [etiquetas, setEtiquetas] = useState<Options[]>([]);
  const [impresoras, setImpresoras] = useState<Options[]>([]);
  const [impresorasAtivas, setImpresorasAtivas] = useState<Options[]>([]);
  const [alturas, setAlturas] = useState<Options[]>([]);
  const [larguras, setLarguras] = useState<Options[]>([]);
  const [grupos, setGrupos] = useState<Options[]>([]);
  const [modeloEtiquetas, setModeloEtiquetas] = useState<IModeloEtiquetas[]>(
    [],
  );
  const [etiquetasLojas, setEtiquetasLojas] = useState<AddEtqLoja[]>([]);
  const [iniInicializado, setInitInicializado] = useState(false);
  const [iniInicializadoEtq, setInitInicializadoEtq] = useState(false);
  const [iniInicializadoAdd, setInitInicializadoAdd] = useState(false);
  const [attGetEtiquetas, setAttGetEtiquetas] = useState(false);
  const [codigoTabEtiqueta, setCodigoTabEtiqueta] = useState('');
  const [arredondaPara, setArredondaPara] = useState<any>({
    '0': false,
    '1': false,
    '2': false,
    '3': false,
    '4': false,
    '5': false,
    '6': false,
    '7': false,
    '8': false,
    '9': false,
  });

  const [formData, setFormData] = useState<IFormData>({
    cod_preco: {
      value: undefined,
      isInvalid: false,
      isRequired: false,
    },
    des_preco: {
      value: '',
      isInvalid: true,
      isRequired: true,
    },
    tipo_preco: {
      value: { label: undefined, value: undefined },
      isInvalid: true,
      isRequired: true,
    },
    flg_inativo: {
      value: false,
      isInvalid: false,
      isRequired: false,
    },
    flg_arredonda: {
      value: false,
      isInvalid: false,
      isRequired: false,
    },
  });
  const [formDataEtiqueta, setFormDataEtiqueta] = useState<IFormDataEtiqueta>({
    cod_loja: {
      value: '',
      isInvalid: false,
      isRequired: true,
    },
    etiqueta: {
      value: {
        label: undefined,
        value: undefined,
        qtd_altura: undefined,
        qtd_largura: undefined,
        des_foto: undefined,
      },
      isInvalid: true,
      isRequired: true,
    },
    impressora: {
      value: {
        label: '',
        value: undefined,
      },
      isInvalid: true,
      isRequired: true,
    },
  });
  const [formFiltraEtiqueta, setFormFiltraEtiqueta] =
    useState<IFormFiltraEtiqueta>({
      largura: {
        value: { label: undefined, value: undefined },
        largura: undefined,
      },
      altura: {
        value: { label: undefined, value: undefined },
        altura: undefined,
      },
      grupo: {
        value: { label: undefined, value: undefined },
      },
    });

  const [dataAddEtiqueta, setDataAddEtiqueta] = useState<IdataAddEtiqueta>({
    cod_modelo_etiqueta: {
      value: undefined,
      isInvalid: false,
      isRequired: false,
    },
    des_etiqueta: {
      value: '',
      isInvalid: false,
      isRequired: false,
    },
  });
  const [modalData, setModalData] = useState({
    codigo: '',
    descricao: '',
    grupo: '',
    largura: '',
    altura: '',
    linguagem: '',
    des_foto: '',
  });
  const [arredondamentos, setArredondamentos] = useState<any[]>([
    {
      id: 0,
      quandoTerminarEm: 0,
      alterarPara: 9,
      arredondarPara: 0,
    },
    {
      id: 1,
      quandoTerminarEm: 1,
      alterarPara: 9,
      arredondarPara: 0,
    },
    {
      id: 2,
      quandoTerminarEm: 2,
      alterarPara: 9,
      arredondarPara: 0,
    },
    {
      id: 3,
      quandoTerminarEm: 3,
      alterarPara: 5,
      arredondarPara: 1,
    },
    {
      id: 4,
      quandoTerminarEm: 4,
      alterarPara: 5,
      arredondarPara: 1,
    },
    {
      id: 5,
      quandoTerminarEm: 5,
      alterarPara: 5,
      arredondarPara: 1,
    },
    {
      id: 6,
      quandoTerminarEm: 6,
      alterarPara: 9,
      arredondarPara: 1,
    },
    {
      id: 7,
      quandoTerminarEm: 7,
      alterarPara: 9,
      arredondarPara: 1,
    },
    {
      id: 8,
      quandoTerminarEm: 8,
      alterarPara: 9,
      arredondarPara: 1,
    },
    {
      id: 9,
      quandoTerminarEm: 9,
      alterarPara: 9,
      arredondarPara: 1,
    },
  ]);
  const [editEtiqueta, setEditEtiqueta] = useState(false);
  const colunasTabArredondamento: GridColumns = [
    {
      field: 'id',
      headerName: 'Id',
      width: 90,
      hide: true,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'quandoTerminarEm',
      headerName: 'Quando Terminar Em',
      type: 'number',
      width: 300,
      sortable: false,
      cellClassName: 'super-app-theme--cell',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
    },
    {
      field: 'alterarPara',
      headerName: 'Alterar Para',
      type: 'number',
      width: 300,
      editable: true,
      sortable: false,
      cellClassName: 'super-app-theme--cell',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
    },
    {
      field: '',
      headerName: 'Arredondar Para',
      width: 400,
      headerAlign: 'center',
      sortable: false,
      cellClassName: 'super-app-theme--cell',
      headerClassName: 'super-app-theme--header',
      renderCell: (params: { row: any }) => {
        const { row } = params;
        const onEdit = () => {
          row.arredondarPara = row.arredondarPara === 0 ? 1 : 0;
          setArredondaPara((prev: any) => ({
            ...prev,
            [row.id]: !arredondaPara[row.id],
          }));
        };
        return (
          <>
            <ButtonRow type="button" onClick={onEdit}>
              {row.arredondarPara === 0 ? (
                <>
                  Baixo{' '}
                  <IoMdArrowDropdown size={20} style={{ color: '#8850BF' }} />
                </>
              ) : (
                <>
                  Cima{' '}
                  <IoMdArrowDropup size={20} style={{ color: '#8850BF' }} />
                </>
              )}
            </ButtonRow>
          </>
        );
      },
    },
  ];
  const colunasTabEtiqueta: GridColumns = [
    {
      field: 'id',
      headerName: 'Id',
      hide: true,
      width: 200,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'loja',
      headerName: 'Loja',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      width: 200,
    },
    {
      field: 'idLoja',
      hide: true,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'des_parametro',
      headerName: 'Impressora Padrão',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      flex: 1,
    },
    {
      field: 'etiqueta',
      headerName: 'Etiqueta',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      flex: 1,
    },
    {
      field: 'idEtiqueta',
      hide: true,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'altura',
      hide: true,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'largura',
      hide: true,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'des_foto',
      hide: true,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: '',
      headerName: 'Ações',
      sortable: false,
      headerAlign: 'center',
      width: 300,
      headerClassName: 'super-app-theme--header',
      renderCell: (params: { row: any }) => {
        const onEdit = () => {
          const { row } = params;
          setFormDataEtiqueta({
            ...formDataEtiqueta,
            cod_loja: {
              value: Number(row.idLoja),
              isInvalid: false,
              isRequired: false,
            },
            etiqueta: {
              value: {
                label: row.etiqueta,
                value: row.idEtiqueta,
                qtd_altura: row.altura,
                qtd_largura: row.largura,
                des_foto: row.des_foto,
              },
              isInvalid: false,
              isRequired: true,
            },
            impressora: {
              value: {
                value: row.cod_parametro_etiqueta,
                label: row.des_parametro,
              },
              isInvalid: false,
              isRequired: true,
            },
          });
          setCodigoTabEtiqueta(row.id);
          setEditEtiqueta(true);
        };
        const onDelete = () => {
          const { row } = params;
          handleRemoveEtiqueta(row.id);
        };
        const onVisualise = () => {
          const { row } = params;
          handleShow();
          setModalData({
            ...modalData,
            codigo: row.id,
            descricao: row.etiqueta,
            grupo: row.etiqueta,
            largura: row.largura,
            altura: row.altura,
            des_foto: row.des_foto,
          });
        };

        return (
          <>
            <ButtonRow
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
              onClick={onEdit}
              title="Editar"
            >
              <GoPencil size={20} style={{ color: '#72ab90' }} />
            </ButtonRow>
            <ButtonRow type="button" onClick={onDelete} title="Excluir">
              <MdDeleteForever size={20} style={{ color: '#e63c3c' }} />
            </ButtonRow>
            <ButtonRow type="button" title="Visualizar" onClick={onVisualise}>
              <FaRegEye size={20} style={{ color: '#3c7ae6' }} />
            </ButtonRow>
          </>
        );
      },
    },
  ];
  const tiposPreco = [
    {
      value: 1,
      label: 'PADRÃO',
    },
    {
      value: 2,
      label: 'CLUBE/FIDELIDADE',
    },
    {
      value: 3,
      label: 'EXCEPCIONAL',
    },
    {
      value: 4,
      label: 'OFERTA',
    },
    {
      value: 5,
      label: 'COMPENSATÓRIO',
    },
    {
      value: 6,
      label: 'ATACADO',
    },
    {
      value: 7,
      label: 'ECOMMERCE',
    },
    {
      value: 8,
      label: 'FORÇA DE VENDA',
    },
  ];
  const inativo = [
    {
      value: 0,
      label: 'Não',
    },
    {
      value: 1,
      label: 'Sim',
    },
  ];

  useEffect(() => {
    (async () => {
      const { data } = await api.get('/etiquetas');
      if (data.success) {
        if (data.message !== 'Nenhum registro encontrado.') {
          const options = data.data
            .filter(
              (etq: IEtiquetas) =>
                etq.flg_preco_de_por === false ||
                (formData?.tipo_preco?.value?.value !== undefined &&
                  [2, 4, 6].includes(formData?.tipo_preco?.value?.value)),
            )
            .map((etq: IEtiquetas) => ({
              label: etq.des_etiqueta,
              value: etq.cod_etiqueta,
              des_foto: etq.des_foto,
              qtd_altura: etq.qtd_altura,
              qtd_largura: etq.qtd_largura,
            }));
          setEtiquetas(options);
        }
      }
    })();
  }, [attGetEtiquetas, formData.tipo_preco.value]);

  useEffect(() => {
    (async () => {
      if (formDataEtiqueta.cod_loja.value) {
        const { data } = await api.get(
          `/impressora-etiquetas/${formDataEtiqueta.cod_loja.value}`,
        );

        if (data.success) {
          const options = data.data.map((item: IImpressoraItem) => ({
            label: item.des_parametro,
            value: item.cod_parametro_etiqueta,
          }));

          const optAtivos = data.data.flatMap((item: IImpressoraItem) => {
            if (item.flg_inativo !== true) {
              return [
                {
                  label: item.des_parametro,
                  value: item.cod_parametro_etiqueta,
                },
              ];
            }
            return [];
          });

          setImpresoras(options);
          setImpresorasAtivas(optAtivos);
        }
      }
    })();
  }, [formDataEtiqueta.cod_loja.value]);

  useEffect(() => {
    (async () => {
      const { data } = await api.get('/modeloEtiqueta', {
        params: {
          largura: formFiltraEtiqueta.largura.largura,
          altura: formFiltraEtiqueta.altura.altura,
          grupo: formFiltraEtiqueta.grupo.value?.value,
        },
      });
      if (data.success) {
        if (data.message !== 'Nenhum registro encontrado.') {
          const eti: IModeloEtiquetas[] = data.data.flatMap((et: any) => {
            if (!et.flg_inativo) return [{ ...et, key: nanoid() }];
            return [];
          });

          setModeloEtiquetas(eti);
          return;
        }
        setModeloEtiquetas([]);
      }
    })();
  }, [
    formFiltraEtiqueta.largura.value,
    formFiltraEtiqueta.altura.value,
    formFiltraEtiqueta.grupo.value,
    formFiltraEtiqueta.largura.largura,
    formFiltraEtiqueta.altura.altura,
  ]);
  useEffect(() => {
    (async () => {
      const { data } = await api.get('/larguraEtiqueta');
      if (data.success) {
        if (data.message !== 'Nenhum registro encontrado.') {
          const options = data.data.map((larg: ILarguras) => ({
            label: `${larg.qtd_largura}mm`,
            value: nanoid(),
            largura: larg.qtd_largura,
          }));
          options.unshift({
            label: 'Todos',
            value: undefined,
          });
          setLarguras(options);
        }
      }
    })();
  }, []);
  useEffect(() => {
    (async () => {
      const { data } = await api.get('/alturaEtiqueta');
      if (data.success) {
        if (data.message !== 'Nenhum registro encontrado.') {
          const options = data.data.map((alt: IAlturas) => ({
            label: `${alt.qtd_altura}mm`,
            value: nanoid(),
            altura: alt.qtd_altura,
          }));
          options.unshift({
            label: 'Todos',
            value: undefined,
          });
          setAlturas(options);
        }
      }
    })();
  }, []);
  useEffect(() => {
    (async () => {
      const { data } = await api.get('/grupoEtiqueta');
      if (data.success) {
        if (data.message !== 'Nenhum registro encontrado.') {
          const options = data.data.map((gru: IGrupos) => ({
            label: gru.des_grupo,
            value: gru.cod_grupo_etiqueta,
          }));
          options.unshift({
            label: 'Todos',
            value: undefined,
          });
          setGrupos(options);
        }
      }
    })();
  }, []);

  const onRowClick = async (param: GridRowParams) => {
    setLoader(true);
    setUpdate(true);
    const { row } = param;

    const { cod_preco, des_preco, tipo_preco, flg_inativo, flg_arredonda } =
      row;
    const tipo = tiposPreco.filter((tp) => tp.value === tipo_preco);

    setFormData({
      cod_preco: {
        value: cod_preco,
        isInvalid: false,
        isRequired: false,
      },
      des_preco: {
        value: des_preco,
        isInvalid: false,
        isRequired: true,
      },
      tipo_preco: {
        value: tipo[0],
        isInvalid: false,
        isRequired: true,
      },
      flg_inativo: {
        value: flg_inativo,
        isInvalid: false,
        isRequired: false,
      },
      flg_arredonda: {
        value: flg_arredonda,
        isInvalid: false,
        isRequired: false,
      },
    });
    if (flg_arredonda) {
      const { data } = await api.get(`/preco/arredondamento/${cod_preco}`);
      if (data.success) {
        if (data.message !== 'Nenhum registro encontrado.') {
          const options = data.data.map((pa: IPrecoArredonda) => {
            return {
              id: nanoid(),
              quandoTerminarEm: pa.num_termino,
              alterarPara: pa.num_altera,
              arredondarPara: pa.tipo_arredonda,
            };
          });

          setArredondamentos(options);
        }
      }
    }

    const { data } = await api.get(`/etiquetasLoja/${cod_preco}`);
    if (data.success) {
      if (data.message !== 'Nenhum registro encontrado.') {
        const options = data.data.map((etq: any) => {
          return {
            id: nanoid(),
            loja: etq.cod_loja,
            idLoja: etq.cod_loja,
            des_parametro: etq.des_parametro,
            cod_parametro_etiqueta: etq.cod_parametro_etiqueta,
            etiqueta: etq.des_etiqueta,
            idEtiqueta: etq.cod_etiqueta,
            largura: etq.qtd_largura,
            altura: etq.qtd_altura,
            des_foto: etq.des_foto,
          };
        });
        setEtiquetasLojas(options);
      }
    }

    setShowSearch(false);
    setLoader(false);
  };

  const newData = () => {
    toast.warning(
      'No momento não é possível cadastrar novas tabelas. Customize uma das tabelas fixas já existentes.',
    );
    // setUpdate(false);
    // setShowSearch(false);
    // resetFormData();
  };

  const resetFormData = useCallback(() => {
    setUpdate(false);
    setKey('etiquetas');
    setFormData({
      cod_preco: {
        value: undefined,
        isInvalid: false,
        isRequired: false,
      },
      des_preco: {
        value: '',
        isInvalid: true,
        isRequired: true,
      },
      tipo_preco: {
        value: { label: undefined, value: undefined },
        isInvalid: true,
        isRequired: true,
      },
      flg_inativo: {
        value: false,
        isInvalid: false,
        isRequired: false,
      },
      flg_arredonda: {
        value: false,
        isInvalid: false,
        isRequired: false,
      },
    });
    setFormDataEtiqueta({
      cod_loja: {
        value: '',
        isInvalid: false,
        isRequired: false,
      },
      etiqueta: {
        value: {
          label: undefined,
          value: undefined,
          qtd_altura: undefined,
          qtd_largura: undefined,
          des_foto: undefined,
        },
        isInvalid: true,
        isRequired: true,
      },
      impressora: {
        value: {
          label: '',
          value: undefined,
        },
        isInvalid: true,
        isRequired: true,
      },
    });
    setFormFiltraEtiqueta({
      largura: {
        value: { label: undefined, value: undefined },
        largura: undefined,
      },
      altura: {
        value: { label: undefined, value: undefined },
        altura: undefined,
      },
      grupo: {
        value: { label: undefined, value: undefined },
      },
    });
    setDataAddEtiqueta({
      cod_modelo_etiqueta: {
        value: undefined,
        isInvalid: false,
        isRequired: false,
      },
      des_etiqueta: {
        value: '',
        isInvalid: false,
        isRequired: false,
      },
    });
    setEtiquetas([]);
    setEtiquetasLojas([]);
    setArredondamentos([
      {
        id: 0,
        quandoTerminarEm: 0,
        alterarPara: 9,
        arredondarPara: 0,
      },
      {
        id: 1,
        quandoTerminarEm: 1,
        alterarPara: 9,
        arredondarPara: 0,
      },
      {
        id: 2,
        quandoTerminarEm: 2,
        alterarPara: 9,
        arredondarPara: 0,
      },
      {
        id: 3,
        quandoTerminarEm: 3,
        alterarPara: 5,
        arredondarPara: 1,
      },
      {
        id: 4,
        quandoTerminarEm: 4,
        alterarPara: 5,
        arredondarPara: 1,
      },
      {
        id: 5,
        quandoTerminarEm: 5,
        alterarPara: 5,
        arredondarPara: 1,
      },
      {
        id: 6,
        quandoTerminarEm: 6,
        alterarPara: 9,
        arredondarPara: 1,
      },
      {
        id: 7,
        quandoTerminarEm: 7,
        alterarPara: 9,
        arredondarPara: 1,
      },
      {
        id: 8,
        quandoTerminarEm: 8,
        alterarPara: 9,
        arredondarPara: 1,
      },
      {
        id: 9,
        quandoTerminarEm: 9,
        alterarPara: 9,
        arredondarPara: 1,
      },
    ]);
    setAttGetEtiquetas(!attGetEtiquetas);
    setInitInicializado(false);
    setInitInicializadoAdd(false);
    setInitInicializadoEtq(false);
  }, [attGetEtiquetas]);

  const validaInputsEtiqueta = useCallback(
    (inputs: IFormDataEtiqueta): boolean => {
      for (let i = 0; i < Object.keys(inputs).length; i++) {
        if (
          formDataEtiqueta[Object.keys(inputs)[i] as keyof IFormDataEtiqueta]
            .isRequired &&
          formDataEtiqueta[Object.keys(inputs)[i] as keyof IFormDataEtiqueta]
            .isInvalid
        ) {
          return true;
        }
      }
      return false;
    },
    [formDataEtiqueta],
  );

  const handleDescricao = (val: string) => {
    setFormData({
      ...formData,
      des_preco: {
        ...formData.des_preco,
        value: val,
        isInvalid: val === '',
      },
    });
  };

  const handleTipo = useCallback(
    (val: { label: string; value: number }, isInvalid: boolean) => {
      setFormData({
        ...formData,
        tipo_preco: {
          ...formData.tipo_preco,
          value: val,
          isInvalid,
        },
      });
    },
    [formData],
  );

  const handleInativo = useCallback(() => {
    setFormData({
      ...formData,
      flg_inativo: {
        ...formData.flg_inativo,
        value: !formData.flg_inativo.value,
      },
    });
  }, [formData]);

  const handleArredonda = useCallback(() => {
    setFormData({
      ...formData,
      flg_arredonda: {
        ...formData.flg_arredonda,
        value: !formData.flg_arredonda.value,
      },
    });
    if (!formData.flg_arredonda.value) {
      setKey('arredondamento');
      return;
    }
    setKey('etiquetas');
  }, [formData]);

  const handleLoja = (val: any, isInvalid: boolean) => {
    if (val === formDataEtiqueta.cod_loja.value) {
      setFormDataEtiqueta({
        ...formDataEtiqueta,
        cod_loja: {
          ...formDataEtiqueta.cod_loja,
          value: val,
          isInvalid,
        },
        impressora: {
          value: formDataEtiqueta.impressora.value,
          isInvalid: formDataEtiqueta.impressora.isInvalid,
          isRequired: formDataEtiqueta.impressora.isRequired,
        },
      });
    } else {
      setFormDataEtiqueta({
        ...formDataEtiqueta,
        cod_loja: {
          ...formDataEtiqueta.cod_loja,
          value: val,
          isInvalid,
        },
        impressora: {
          value: {
            label: '',
            value: undefined,
          },
          isInvalid: true,
          isRequired: true,
        },
      });
    }

    // setFormDataEtiqueta({
    //   ...formDataEtiqueta,
    //   impressora: {
    //     value: formDataEtiqueta.impressora.value,
    //     isInvalid: formDataEtiqueta.impressora.isInvalid,
    //     isRequired: formDataEtiqueta.impressora.isRequired
    //   }
    // })
  };

  const handleEtiqueta = useCallback(
    (
      val: {
        label: string;
        value: number;
        qtd_altura: number;
        qtd_largura: number;
        des_foto: string;
      },
      isInvalid: boolean,
    ) => {
      setFormDataEtiqueta({
        ...formDataEtiqueta,
        etiqueta: {
          ...formDataEtiqueta.etiqueta,
          value: val,
          isInvalid,
        },
      });
    },
    [formDataEtiqueta],
  );

  const handleAddEtiqueta = async () => {
    if (validaInputsEtiqueta(formDataEtiqueta)) {
      setInitInicializadoEtq(true);
      return;
    }
    // Editar etiqueta
    if (codigoTabEtiqueta !== '') {
      const rows = etiquetasLojas.filter((etq) => etq.id !== codigoTabEtiqueta);
      const data: AddEtqLoja = {
        id: codigoTabEtiqueta,
        loja: String(formDataEtiqueta.cod_loja.value),
        idLoja: String(formDataEtiqueta.cod_loja.value),
        des_parametro: formDataEtiqueta.impressora.value.label,
        cod_parametro_etiqueta: formDataEtiqueta.impressora.value.value,
        etiqueta: formDataEtiqueta.etiqueta.value?.label
          ? formDataEtiqueta.etiqueta.value.label
          : '',
        idEtiqueta: formDataEtiqueta.etiqueta.value?.value
          ? String(formDataEtiqueta.etiqueta.value.value)
          : '',
        largura: String(formDataEtiqueta.etiqueta.value?.qtd_largura),
        altura: String(formDataEtiqueta.etiqueta.value?.qtd_altura),
        des_foto: String(formDataEtiqueta.etiqueta.value?.des_foto),
      };
      const tmp = [...rows, data];
      tmp.sort((a, b) => parseFloat(a.idLoja) - parseFloat(b.idLoja));
      setEtiquetasLojas(tmp);
      setFormDataEtiqueta({
        cod_loja: {
          value: '',
          isInvalid: false,
          isRequired: false,
        },
        etiqueta: {
          value: {
            label: undefined,
            value: undefined,
            qtd_altura: undefined,
            qtd_largura: undefined,
            des_foto: undefined,
          },
          isInvalid: true,
          isRequired: true,
        },
        impressora: {
          value: {
            label: '',
            value: undefined,
          },
          isInvalid: true,
          isRequired: true,
        },
      });
      setCodigoTabEtiqueta('');
      setInitInicializadoEtq(false);
      setEditEtiqueta(false);
      return;
    }
    // Adicionar nova etiqueta
    const loja = etiquetasLojas.filter((etq) => {
      return Number(etq.idLoja) === Number(formDataEtiqueta.cod_loja.value);
    });
    let subs: any;
    if (loja.length > 0) {
      subs = await MySwal.fire({
        title: `Existe uma etiqueta cadastrada para a loja ${loja[0].idLoja}`,
        text: `Deseja substituí-la?`,

        showCancelButton: true,
        confirmButtonColor: '#07289e',
        cancelButtonColor: '#ff7b7b',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then(async (result) => {
        if (result.isConfirmed) {
          return true;
        }
        return false;
      });
    }
    if (subs === false) {
      return;
    }
    const data: AddEtqLoja = {
      id: nanoid(),
      loja: String(formDataEtiqueta.cod_loja.value),
      idLoja: String(formDataEtiqueta.cod_loja.value),
      des_parametro: String(formDataEtiqueta.impressora.value.label),
      cod_parametro_etiqueta: formDataEtiqueta.impressora.value.value,
      etiqueta: formDataEtiqueta.etiqueta.value?.label
        ? formDataEtiqueta.etiqueta.value.label
        : '',
      idEtiqueta: formDataEtiqueta.etiqueta.value?.value
        ? String(formDataEtiqueta.etiqueta.value.value)
        : '',
      largura: String(formDataEtiqueta.etiqueta.value?.qtd_largura),
      altura: String(formDataEtiqueta.etiqueta.value?.qtd_altura),
      des_foto: String(formDataEtiqueta.etiqueta.value?.des_foto),
    };
    if (subs === undefined || subs === true) {
      const rows = etiquetasLojas.filter(
        (etq) => Number(etq.idLoja) !== Number(formDataEtiqueta.cod_loja.value),
      );
      const tmp = [...rows, data];
      tmp.sort((a, b) => parseFloat(a.idLoja) - parseFloat(b.idLoja));
      setEtiquetasLojas(tmp);
    } else {
      const tmp = [...etiquetasLojas, data];
      tmp.sort((a, b) => parseFloat(a.idLoja) - parseFloat(b.idLoja));
      setEtiquetasLojas(tmp);
    }

    setFormDataEtiqueta({
      ...formDataEtiqueta,
      etiqueta: {
        value: {
          label: undefined,
          value: undefined,
          qtd_altura: undefined,
          qtd_largura: undefined,
          des_foto: undefined,
        },
        isInvalid: true,
        isRequired: true,
      },
    });
    setCodigoTabEtiqueta('');
    setInitInicializadoEtq(false);
  };
  const handleRemoveEtiqueta = useCallback(
    (id) => {
      MySwal.fire({
        title: `Remover etiqueta`,
        text: `Deseja realmente remover a etiqueta?`,
        showCancelButton: true,
        confirmButtonColor: '#07289e',
        cancelButtonColor: '#ff7b7b',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then(async (result) => {
        if (result.isConfirmed) {
          const rows = etiquetasLojas.filter((etq) => etq.id !== id);
          setEtiquetasLojas(rows);
          setFormDataEtiqueta({
            cod_loja: {
              value: user.user?.loja,
              isInvalid: false,
              isRequired: false,
            },
            etiqueta: {
              value: {
                label: undefined,
                value: undefined,
                qtd_altura: undefined,
                qtd_largura: undefined,
                des_foto: undefined,
              },
              isInvalid: true,
              isRequired: true,
            },
            impressora: {
              value: {
                label: '',
                value: undefined,
              },
              isInvalid: true,
              isRequired: true,
            },
          });
          setCodigoTabEtiqueta('');
          setInitInicializadoEtq(false);
        }
      });
    },
    [etiquetasLojas, user.user?.loja],
  );
  const validaInputsParaSubmit = useCallback(
    (inputs: IFormData): boolean => {
      for (let i = 0; i < Object.keys(inputs).length; i++) {
        if (
          formData[Object.keys(inputs)[i] as keyof IFormData].isRequired &&
          formData[Object.keys(inputs)[i] as keyof IFormData].isInvalid
        ) {
          return true;
        }
      }
      return false;
    },
    [formData],
  );
  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoader(true);
      try {
        if (validaInputsParaSubmit(formData)) {
          setInitInicializado(true);
          setLoader(false);
          return;
        }

        if (
          etiquetasLojas.length <= 0 &&
          formData.tipo_preco.value?.value !== 7
        ) {
          setInitInicializadoEtq(true);
          toast.warning('Ao menos uma etiqueta deve ser selecionada');
          setLoader(false);
          return;
        }
        if (isUpdate) {
          const { data } = await api.put(`/preco/${formData.cod_preco.value}`, {
            des_preco: formData.des_preco.value,
            tipo_preco: formData.tipo_preco.value?.value,
            flg_inativo: formData.flg_inativo.value,
            arredonda: formData.flg_arredonda.value ? arredondamentos : null,
            etiquetas: etiquetasLojas,
          });

          if (data.success) {
            toast.success(data.message);
            setUpdate(false);
            resetFormData();
            setLoader(false);
            setShowSearch(true);
            return;
          }

          toast.warning(data.message);
          setLoader(false);
          return;
        }
        const { data } = await api.post('/preco', {
          des_preco: formData.des_preco.value,
          tipo_preco: formData.tipo_preco.value?.value,
          flg_inativo: formData.flg_inativo.value,
          arredonda: formData.flg_arredonda.value ? arredondamentos : null,
          etiquetas: etiquetasLojas,
        });

        if (data.success) {
          toast.success(data.message);
          resetFormData();
        } else {
          toast.warning(data.message);
        }
        setLoader(false);
      } catch (error: any) {
        setLoader(false);
        toast.warning(error);
      }
    },
    [
      arredondamentos,
      etiquetasLojas,
      formData,
      isUpdate,
      resetFormData,
      validaInputsParaSubmit,
    ],
  );

  const handleCancel = useCallback(() => {
    resetFormData();
    setShowSearch(true);
  }, []);

  const handleDelete = useCallback(async () => {
    try {
      const notAllow =
        formData.tipo_preco.value?.value === 4 ||
        formData.tipo_preco.value?.value === 1;

      if (notAllow)
        return toast.warning('Tabela de Preço fixa, não permite ser inativada');

      const { data } = await api.delete(`/preco/${formData.cod_preco.value}`);
      if (data.success) {
        resetFormData();
        setShowSearch(true);
        toast.success('Tabela de Preço inativada com sucesso');
      }
    } catch (error: any) {
      toast.error(error.data.message);
    }
  }, [
    formData.cod_preco.value,
    formData.tipo_preco.value?.value,
    resetFormData,
  ]);

  const editColumn = useCallback(
    ({ id, field, value }: GridCellEditCommitParams) => {
      // buscar linha com esse id
      const row = arredondamentos.filter((aut) => aut.id === id);
      // editar linha
      if ((value as number) < 10 && (value as number) > -1) {
        row[0][field] = value;
        const rows = arredondamentos.filter((aut) => aut.id !== id);
        const tmp = [...row, ...rows];
        tmp.sort(
          (a, b) =>
            parseFloat(a.quandoTerminarEm) - parseFloat(b.quandoTerminarEm),
        );
        setArredondamentos(tmp);
        return true;
      }
      row[0][field] = 9;
      const rows = arredondamentos.filter((aut) => aut.id !== id);
      const tmp = [...row, ...rows];
      tmp.sort(
        (a, b) =>
          parseFloat(a.quandoTerminarEm) - parseFloat(b.quandoTerminarEm),
      );
      setArredondamentos(tmp);
    },
    [arredondamentos],
  );

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const showModal = useCallback(() => {
    if (show) {
      return (
        <Modal size="lg" show={show} onHide={handleClose} centered>
          <Modal.Header>
            <Modal.Title>{modalData.descricao}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              style={{
                marginTop: '20px',
                display: 'flex',
                padding: '20px',
                border: '1px dashed #bdbdbd',
                borderRadius: '10px',
              }}
            >
              <div style={{ width: '100%' }}>
                <div style={{ width: '95%' }}>
                  <Separator
                    labelText={`${modalData.largura}mm`}
                    background="#57069e"
                    color="#fff"
                    lineColor="#9c9c9c"
                    textPos="center"
                    marginTop="0px"
                    borderStyle="dotted"
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    width: '100%',
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <img
                      src={modalData.des_foto}
                      alt={modalData.descricao}
                      style={{
                        width: '100%',
                      }}
                    />
                  </div>

                  <div
                    style={{
                      width: '10%',
                      marginTop: '-30px',
                    }}
                  >
                    <SeparatorVertical
                      labelText={`${modalData.altura}mm`}
                      color="#9c9c9c"
                      lineColor="#9c9c9c"
                      textPos="center"
                      borderStyle="dotted"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButton onClick={handleClose}>Fechar</ConfirmButton>
          </Modal.Footer>
        </Modal>
      );
    }
    return '';
  }, [
    modalData.altura,
    modalData.des_foto,
    modalData.descricao,
    modalData.largura,
    show,
  ]);

  const [showNovaEtiqueta, setShowNovaEtiqueta] = useState(false);

  const handleCloseNovaEtiqueta = () => setShowNovaEtiqueta(false);
  const handleShowNovaEtiqueta = () => setShowNovaEtiqueta(true);

  const handleLargura = useCallback(
    (val: any) => {
      setFormFiltraEtiqueta({
        ...formFiltraEtiqueta,
        largura: {
          ...formFiltraEtiqueta.largura,
          value: { label: val.label, value: val.value },
          largura: val.largura,
        },
      });
    },
    [formFiltraEtiqueta],
  );
  const handleAltura = useCallback(
    (val: any) => {
      setFormFiltraEtiqueta({
        ...formFiltraEtiqueta,
        altura: {
          ...formFiltraEtiqueta.altura,
          value: { label: val.label, value: val.value },
          altura: val.altura,
        },
      });
    },
    [formFiltraEtiqueta],
  );
  const handleGrupo = useCallback(
    (val: { label: string; value: number }) => {
      setFormFiltraEtiqueta({
        ...formFiltraEtiqueta,
        grupo: {
          ...formFiltraEtiqueta.grupo,
          value: val,
        },
      });
    },
    [formFiltraEtiqueta],
  );
  const setDataEtiqueta = useCallback(
    (cod_modelo_etiqueta) => {
      setDataAddEtiqueta({
        ...dataAddEtiqueta,
        cod_modelo_etiqueta: {
          ...dataAddEtiqueta.cod_modelo_etiqueta,
          value: cod_modelo_etiqueta,
        },
      });
      handleShowAddEtiqueta();
    },
    [dataAddEtiqueta],
  );
  const novaEtiqueta = useCallback(() => {
    return (
      <Modal
        size="lg"
        show={showNovaEtiqueta}
        onHide={handleCloseNovaEtiqueta}
        centered
      >
        <Modal.Header>
          <Modal.Title>Catálogo de Etiquetas</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ minHeight: '600px' }}>
          <div className="row">
            <div className="col-4">
              <InputSelect
                label="Largura"
                options={larguras}
                value={formFiltraEtiqueta.largura.value}
                onChange={(newValue: any, isInvalid = true) => {
                  handleLargura(newValue);
                }}
              />
            </div>
            <div className="col-4">
              <InputSelect
                label="Altura"
                options={alturas}
                value={formFiltraEtiqueta.altura.value}
                onChange={(newValue: any, isInvalid = true) => {
                  handleAltura(newValue);
                }}
              />
            </div>
            <div className="col-4">
              <InputSelect
                label="Grupo"
                options={grupos}
                value={formFiltraEtiqueta.grupo.value}
                onChange={(newValue: any, isInvalid = true) => {
                  handleGrupo(newValue);
                }}
              />
            </div>
          </div>
          <div className="display-de-etiquetas">
            {modeloEtiquetas.length <= 0 && (
              <div
                style={{
                  marginTop: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                  textAlign: 'center',
                  padding: '20px',
                  border: '1px dashed #bdbdbd',
                  borderRadius: '10px',
                }}
              >
                <p
                  style={{
                    color: '#9c9ca1',
                    width: '100%',
                    marginTop: '10px',
                  }}
                >
                  Nenhum registro encontrado
                </p>
              </div>
            )}
            {modeloEtiquetas.length > 0 &&
              modeloEtiquetas.map((modelo) => (
                <div
                  key={modelo.key}
                  style={{
                    marginTop: '20px',
                    display: 'flex',
                    padding: '20px',
                    border: '1px dashed #bdbdbd',
                    borderRadius: '10px',
                  }}
                >
                  <div style={{ width: '80%' }}>
                    <div style={{ width: '95%' }}>
                      <Separator
                        labelText={`${modelo.qtd_largura}mm`}
                        background="#57069e"
                        color="#fff"
                        lineColor="#9c9c9c"
                        textPos="center"
                        marginTop="0px"
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        width: '100%',
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        <img
                          src={modelo.des_foto}
                          alt={modelo.des_foto}
                          style={{
                            width: '100%',
                          }}
                        />
                      </div>

                      <div
                        style={{
                          width: '10%',
                          marginTop: '-30px',
                        }}
                      >
                        <SeparatorVertical
                          labelText={`${modelo.qtd_altura}mm`}
                          color="#9c9c9c"
                          lineColor="#9c9c9c"
                          textPos="center"
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ width: '30%' }}>
                    <button
                      type="button"
                      style={{
                        marginTop: '20px',
                        width: '100%',
                        height: '90%',
                        backgroundColor: 'transparent',
                        border: 'none',
                      }}
                      onClick={() =>
                        setDataEtiqueta(modelo.cod_modelo_etiqueta)
                      }
                    >
                      <FcPlus size={60} />
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButton onClick={handleCloseNovaEtiqueta}>
            Fechar
          </ConfirmButton>
        </Modal.Footer>
      </Modal>
    );
  }, [
    alturas,
    formFiltraEtiqueta.altura.value,
    formFiltraEtiqueta.grupo.value,
    formFiltraEtiqueta.largura.value,
    grupos,
    handleAltura,
    handleGrupo,
    handleLargura,
    larguras,
    modeloEtiquetas,
    setDataEtiqueta,
    showNovaEtiqueta,
  ]);
  const handleChangeDescricao = useCallback(
    (val: string, isInvalid: boolean) => {
      setDataAddEtiqueta({
        ...dataAddEtiqueta,
        des_etiqueta: {
          ...dataAddEtiqueta.des_etiqueta,
          value: val,
          isInvalid,
        },
      });
    },
    [dataAddEtiqueta],
  );

  const [showAddEtiqueta, setShowAddEtiqueta] = useState(false);
  const handleCloseAddEtiqueta = () => {
    setDataAddEtiqueta({
      cod_modelo_etiqueta: {
        value: undefined,
        isInvalid: false,
        isRequired: false,
      },
      des_etiqueta: {
        value: '',
        isInvalid: false,
        isRequired: false,
      },
    });
    setShowAddEtiqueta(false);
  };
  const handleShowAddEtiqueta = () => setShowAddEtiqueta(true);
  const submitEtiqueta = useCallback(async () => {
    try {
      if (
        dataAddEtiqueta.des_etiqueta.value === '' ||
        dataAddEtiqueta.des_etiqueta.value.trim() === '' ||
        dataAddEtiqueta.des_etiqueta.value === undefined
      ) {
        toast.warn('Informe a descrição');
        return;
      }
      const { data } = await api.post('/etiqueta', {
        cod_modelo_etiqueta: dataAddEtiqueta.cod_modelo_etiqueta.value,
        des_etiqueta: dataAddEtiqueta.des_etiqueta.value,
      });
      if (data.success) {
        toast.success(data.message);
        setAttGetEtiquetas(!attGetEtiquetas);
        handleCloseAddEtiqueta();
      } else {
        toast.warning(data.message);
      }
    } catch (erro: any) {
      toast.warning(erro);
    }
  }, [
    attGetEtiquetas,
    dataAddEtiqueta.cod_modelo_etiqueta.value,
    dataAddEtiqueta.des_etiqueta.value,
  ]);

  const addEtiqueta = useCallback(() => {
    return (
      <Modal show={showAddEtiqueta} onHide={handleCloseAddEtiqueta} centered>
        <Modal.Header>
          <Modal.Title>Adicionar Etiqueta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row" style={{ marginTop: '3px' }}>
            <div className="col-12">
              <InputText
                label="Descrição"
                value={dataAddEtiqueta.des_etiqueta.value}
                minLength={4}
                placeholder="Informe a Descrição"
                isEmpty
                isNull
                isUndefined
                isRequired={dataAddEtiqueta.des_etiqueta.isRequired}
                setInvalid={dataAddEtiqueta.des_etiqueta.isInvalid}
                iniInicializado={iniInicializadoAdd}
                onChange={(newValue: string, isInvalid = true) => {
                  handleChangeDescricao(newValue, isInvalid);
                }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <CancelButton onClick={handleCloseAddEtiqueta}>Cancelar</CancelButton>
          <ConfirmButton onClick={submitEtiqueta}>Salvar</ConfirmButton>
        </Modal.Footer>
      </Modal>
    );
  }, [
    dataAddEtiqueta.des_etiqueta.isInvalid,
    dataAddEtiqueta.des_etiqueta.isRequired,
    dataAddEtiqueta.des_etiqueta.value,
    handleChangeDescricao,
    iniInicializadoAdd,
    showAddEtiqueta,
    submitEtiqueta,
  ]);

  if (loader) {
    return (
      <Container>
        <DefaultLoader />
      </Container>
    );
  }

  return (
    <Container>
      {showSearch && (
        <Search codTela={27} newData={newData} onRowClick={onRowClick} />
      )}
      {!showSearch && (
        <FormDefault
          codTela={27}
          title="Tabela de Preços"
          labelButtonTrash="Inativar"
          codigoRegistro={[
            {
              value: formData.cod_preco.value,
              des_campo: 'Código',
            },
          ]}
          onSave={async (data) => {
            await handleSubmit(data);
          }}
          onCancel={() => {
            resetFormData();
            setShowSearch(true);
          }}
          onNew={newData}
          onDelete={handleDelete}
          onClearFields={resetFormData}
          deleteMessage="Deseja realmente inativar o registro ?"
          isUpdate={isUpdate}
          onReturnSearch={() => {
            setShowSearch(true);
            setUpdate(false);
            resetFormData();
          }}
        >
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <InputText
                label="Descrição"
                value={formData.des_preco.value}
                maxLength={25}
                placeholder="Informe a Descrição"
                isEmpty
                isRequired={formData.des_preco.isRequired}
                setInvalid={formData.des_preco.isInvalid}
                iniInicializado={iniInicializado}
                onChange={(newValue: string) => {
                  handleDescricao(newValue);
                }}
              />
            </div>
            <div className="col-sm-12 col-md-6" style={{ zIndex: 5 }}>
              <InputSelect
                label="Tipo"
                placeholder="Informe o Tipo"
                isDisabled={isUpdate}
                options={tiposPreco}
                value={formData.tipo_preco.value}
                isRequired={formData.tipo_preco.isRequired}
                setInvalid={formData.tipo_preco.isInvalid}
                iniInicializado={iniInicializado}
                onChange={(newValue: any, isInvalid = true) => {
                  handleTipo(newValue, isInvalid);
                }}
              />
            </div>
            <div className="col-12 flags">
              <div className="flag">
                <ToggleDefault
                  labelText="Aplica Regra de Arredondamento?"
                  setChecked={formData.flg_arredonda.value}
                  onSwitch={handleArredonda}
                />
              </div>
              {/* <div className="flag">
                <ToggleDefault
                  labelText="Inativo?"
                  setChecked={formData.flg_inativo.value}
                  onSwitch={handleInativo}
                />
              </div> */}
            </div>
          </div>
          <div className="row mt-4">
            <Tabs
              id="controlled-tab-cliente"
              activeKey={key}
              onSelect={(k) => setKey(k)}
            >
              {formData.flg_arredonda.value && (
                <Tab eventKey="arredondamento" title="Arredondamento">
                  <div className="col-sm-12 col-md-12">
                    <Separator labelText="Arredondamento para cálculo da sugestão" />
                    <div
                      className="col-sm-12 col-md-12"
                      style={{ height: 630, width: '100%' }}
                    >
                      <DataGrid
                        rows={arredondamentos}
                        columns={colunasTabArredondamento}
                        pageSize={10}
                        disableColumnFilter
                        disableColumnSelector
                        disableColumnMenu
                        hideFooterSelectedRowCount
                        onCellEditCommit={(params) => {
                          editColumn(params);
                        }}
                        localeText={{
                          noRowsLabel: 'Nenhum registro encontrado',
                          columnMenuLabel: 'Menu',
                          columnMenuFilter: 'Filtrar',
                          columnMenuHideColumn: 'Esconder',
                          columnMenuUnsort: 'Não ordenar',
                          columnMenuSortAsc: 'Ordernar ASC',
                          columnMenuSortDesc: 'Ordernar DESC',
                          columnMenuShowColumns: 'Mostrar colunas',
                        }}
                      />
                    </div>
                  </div>
                </Tab>
              )}
              <Tab eventKey="etiquetas" title="Etiquetas">
                <div className="row">
                  <div className="col-sm-12 col-md-4 mt-2">
                    <Loja
                      onChange={(val) => handleLoja(val, false)}
                      selectedLoja={Number(
                        formDataEtiqueta.cod_loja.value
                          ? formDataEtiqueta.cod_loja.value
                          : user.user?.loja,
                      )}
                      isDisabled={editEtiqueta}
                    />
                  </div>
                  <div className="col-sm-12 col-md-3">
                    <InputSelect
                      label="Impressora Padrão"
                      options={impresorasAtivas}
                      value={formDataEtiqueta.impressora.value}
                      isRequired={formDataEtiqueta.impressora.isRequired}
                      setInvalid={formDataEtiqueta.impressora.isInvalid}
                      iniInicializado={iniInicializadoEtq}
                      onChange={(newValue: any, isInvalid = true) => {
                        setFormDataEtiqueta({
                          ...formDataEtiqueta,
                          impressora: {
                            ...formDataEtiqueta.impressora,
                            value: newValue,
                            isInvalid,
                          },
                        });
                      }}
                    />
                  </div>
                  <div className="col-sm-12 col-md-3">
                    <AddButton
                      type="button"
                      className="inputButton"
                      onClick={handleShowNovaEtiqueta}
                    >
                      <BsPlusCircle color="#fff" />
                    </AddButton>
                    <InputSelect
                      label="Etiquetas"
                      options={etiquetas}
                      value={formDataEtiqueta.etiqueta.value}
                      isRequired={formDataEtiqueta.etiqueta.isRequired}
                      setInvalid={formDataEtiqueta.etiqueta.isInvalid}
                      iniInicializado={iniInicializadoEtq}
                      onChange={(newValue: any, isInvalid = true) => {
                        handleEtiqueta(newValue, isInvalid);
                      }}
                      textPaddingLeft="40px"
                    />
                  </div>
                  <div className="col-sm-12 col-md-2">
                    <div className="actButtonsCredito">
                      <ConfirmButton
                        style={{ width: '100%' }}
                        onClick={handleAddEtiqueta}
                      >
                        Confirmar
                      </ConfirmButton>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div
                      className="col-sm-12 col-md-12 mt-4 dataTable"
                      style={{ height: 280, width: '101%' }}
                    >
                      <DataGrid
                        style={{ height: 280 }}
                        rows={etiquetasLojas}
                        columns={colunasTabEtiqueta}
                        autoHeight
                        pageSize={5}
                        columnBuffer={8}
                        disableColumnFilter
                        disableColumnMenu
                        disableColumnSelector
                        hideFooterSelectedRowCount
                        localeText={{
                          noRowsLabel: 'Nenhum registro encontrado',
                          columnMenuLabel: 'Menu',
                          columnMenuFilter: 'Filtrar',
                          columnMenuHideColumn: 'Esconder',
                          columnMenuUnsort: 'Não ordenar',
                          columnMenuSortAsc: 'Ordernar ASC',
                          columnMenuSortDesc: 'Ordernar DESC',
                          columnMenuShowColumns: 'Mostrar colunas',
                        }}
                      />
                    </div>
                  </div>
                  {showModal()}
                  {novaEtiqueta()}
                  {addEtiqueta()}
                </div>
              </Tab>
            </Tabs>
          </div>
        </FormDefault>
      )}
    </Container>
  );
};

export default TabelaDePreco;
