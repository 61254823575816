import styled from 'styled-components';

export const ContainerCardMeuPlano = styled.div`
  display: flex;
  flex-direction: column;

  cursor: pointer;

  border: 0.0625rem solid #57069e;

  &:hover {
    box-shadow: 0rem 0.25rem 0.5rem rgba(87, 6, 158, 0.2);
  }

  padding: 0.9375rem;
  border-radius: 0.5rem;
`;
