import { DataGrid, GridColumns, GridRowParams } from '@material-ui/data-grid';
import DefaultLoader from '~/components/DefaultLoader';
import FormDefault from '~/components/FormDefault';
import { BuscaProduto, InputText } from '~/components/NovosInputs';
import Search from '~/components/Search';
import Separator from '~/components/Separator';
import api from '~/services/api';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FieldValues } from 'react-hook-form';
import { MdDeleteForever } from 'react-icons/md';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { useMarcas } from './hooks/useMarcas';
import { ButtonRow, Container } from './style';
import { FormMarcas, ProdutoProps } from './types';
import { formMarcasBlank } from './utils/formMarcasBlank';
import { sortByDesProduto } from './utils/sortByDesProduto';
import { verifyProducts } from './utils/verifyProducts';
import { loadingContext } from '~/context/loading';

const MySwal = withReactContent(Swal);

export const MarcasContent: React.FC = () => {
  const { formTela } = useMarcas();
  const { setLoading } = useContext(loadingContext);

  const [showSearch, setShowSearch] = useState(true);
  const [isUpdate, setIsUpdate] = useState(false);
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(0);

  const [produtos, setProdutos] = useState<ProdutoProps[]>([]);
  const [formMarcas, setFormMarcas] = useState<FormMarcas>(formMarcasBlank);

  const { register, formState, handleSubmit, reset, setValue, control } =
    formTela;

  const { errors, isSubmitting } = formState;

  useEffect(() => {
    const data = formMarcas.produtos.map(
      ({ cod_produto, des_produto }: ProdutoProps) => ({
        id: cod_produto,
        cod_produto,
        des_produto,
      }),
    );

    setProdutos(data);
  }, [formMarcas.produtos]);

  const tabColumns: GridColumns = [
    {
      field: 'cod_produto',
      headerName: 'Código',
      width: 150,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'des_produto',
      headerName: 'Descrição',
      flex: 1,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: '',
      headerName: 'Ação',
      sortable: false,
      width: 150,
      headerAlign: 'center',
      cellClassName: 'super-app-theme--cell',
      headerClassName: 'super-app-theme--header',
      disableColumnMenu: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        const onRemove = () => {
          handleRemoveProduto(row.cod_produto);
        };
        return (
          <ButtonRow type="button" onClick={onRemove} style={{ width: '100%' }}>
            <MdDeleteForever size={20} style={{ color: '#e63c3c' }} /> Remover
          </ButtonRow>
        );
      },
    },
  ];

  const resetFormData = useCallback(() => {
    setIsUpdate(false);
    reset();
    setFormMarcas(formMarcasBlank);
  }, [reset]);

  const onNewData = useCallback(() => {
    setShowSearch(false);
    resetFormData();
  }, [resetFormData]);

  const handleDeleteMarca = useCallback(async () => {
    const res = await api.delete(`/marcas/${formMarcas.cod_marca}`);
    const { success, message } = res.data;

    if (success) {
      resetFormData();
      setShowSearch(true);
      return toast.success(message);
    }
  }, [formMarcas.cod_marca, resetFormData]);

  const onRowClick = useCallback(
    async (param: GridRowParams) => {
      const { row } = param;
      setIsUpdate(true);

      const { cod_marca, des_marca } = row;

      const res = await api.get(`/marcas/${cod_marca}`);
      const { success, data, message } = res.data;

      if (!success) throw new Error(message);

      const options = data.map(
        ({ cod_produto, des_produto }: ProdutoProps) => ({
          id: cod_produto,
          cod_produto,
          des_produto,
        }),
      );

      setFormMarcas({
        cod_marca,
        des_marca,
        produtos: options,
      });

      setValue('des_marca', des_marca);

      setLoader(false);
      setShowSearch(false);
    },
    [setValue],
  );

  const handleAddProduto = (value: ProdutoProps[]) => {
    const { produtos: prods } = formMarcas ?? { produtos: [] };

    const hasExists = prods.some((prod) => {
      return value.some((val) => val.cod_produto === prod.cod_produto);
    });

    if (hasExists)
      return toast.warning(
        'Produto já relacionado na lista de produtos vinculados.',
      );

    const produtosAdd = [...prods, ...value].sort((a, b) =>
      a.des_produto.localeCompare(b.des_produto),
    );

    setFormMarcas({
      ...formMarcas,
      produtos: produtosAdd,
    });
    setTimeout(() => {
      setValue('produtos', {
        value: null,
        label: 'Selecione...',
      });
    }, 50);
  };

  const handleRemoveProduto = useCallback(
    (cod_produto: string) => {
      const { produtos: prods } = formMarcas;

      const produtosFiltrados = prods.filter(
        (produto: ProdutoProps) => produto.cod_produto !== cod_produto,
      );
      const produtosOrdernados = sortByDesProduto(produtosFiltrados);

      setFormMarcas({
        ...formMarcas,
        produtos: produtosOrdernados,
      });
    },
    [formMarcas],
  );

  const upsertMarca = useCallback(
    async (data: FieldValues) => {
      setLoader(true);

      const upsert = {
        des_marca: data.des_marca,
        produtos: formMarcas.produtos,
      };
      setLoading(true);

      try {
        if (isUpdate) {
          const res = await api.put(`/marcas/${formMarcas.cod_marca}`, upsert);
          const { message } = res.data;
          toast.success(message);
          setShowSearch(true);
          setLoader(false);
          return resetFormData();
        }

        const res = await api.post('/marcas', upsert);
        const { message } = res.data;
        toast.success(message);
        setLoader(false);
        return resetFormData();
      } finally {
        setLoader(false);
      }
    },
    [formMarcas.cod_marca, formMarcas.produtos, isUpdate, resetFormData],
  );

  const onSubmit = handleSubmit(async (data) => {
    const productsWithMarcas = await verifyProducts(
      formMarcas,
      formMarcas.produtos,
    );

    if (productsWithMarcas?.exists) {
      setLoading(false);
      MySwal.fire({
        html: `${productsWithMarcas?.message}`,
        icon: 'warning',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#dc3545',
        confirmButtonText: 'Confirmar',
        cancelButtonText: `Cancelar`,
      }).then((result) => {
        if (result.isConfirmed) {
          return upsertMarca(data);
        }
      });
    } else {
      return upsertMarca(data);
    }
  });

  if (loader) {
    return (
      <Container>
        <DefaultLoader />
      </Container>
    );
  }

  return (
    <Container>
      {showSearch && (
        <Search codTela={281} newData={onNewData} onRowClick={onRowClick} />
      )}
      {!showSearch && (
        <FormDefault
          codTela={281}
          title="Cadastro de Marcas"
          onSave={async () => {
            await onSubmit();
          }}
          codigoRegistro={[
            {
              value: formMarcas.cod_marca,
              des_campo: 'Código',
            },
          ]}
          onCancel={() => setShowSearch(true)}
          isUpdate={isUpdate}
          onNew={onNewData}
          onDelete={handleDeleteMarca}
          onClearFields={resetFormData}
          onReturnSearch={() => {
            setShowSearch(true);
            resetFormData();
          }}
        >
          <Row>
            <Col sm={12} md={12}>
              <InputText
                label="Descrição"
                placeholder="Informe a Descrição"
                register={register}
                name="des_marca"
                isError={!!errors.des_marca}
                maxLength={50}
                disabled={isSubmitting}
                autoComplete="off"
                toLowerCase={false}
              />
            </Col>
          </Row>
          <Separator labelText="Relação de Produtos Vinculados" />
          <Row>
            <Col sm={12} md={12}>
              <BuscaProduto
                label="Pesquisar"
                placeholder="Busque por Código, GTIN ou Descrição"
                name="produtos"
                register={register}
                isError={!!errors.produtos}
                control={control}
                buscaNasLojas={[1]}
                customOptions={{
                  hideBuscarPor: true,
                  buscarPor: ['Produto'],
                  showSelecionarItensContendo: true,
                }}
                getProduct={(selecteds: any) => {
                  const data: ProdutoProps[] = selecteds.map(
                    (selected: any) => ({
                      cod_produto: selected.cod_produto as string,
                      des_produto: selected.des_produto as string,
                    }),
                  );
                  handleAddProduto(data);
                }}
                setValue={setValue}
              />
            </Col>
            <Col
              sm={12}
              md={12}
              className="mt-3 w-100"
              style={{ height: '25rem' }}
            >
              <DataGrid
                rows={produtos}
                pageSize={5}
                disableColumnMenu
                columns={tabColumns}
                page={page}
                onPageChange={(p) => setPage(p)}
                localeText={{
                  noRowsLabel: 'Nenhum registro encontrado...',
                  columnMenuLabel: 'Menu',
                  columnMenuFilter: 'Filtrar',
                  columnMenuHideColumn: 'Esconder',
                  columnMenuUnsort: 'Não ordenar',
                  columnMenuSortAsc: 'Ordernar ASC',
                  columnMenuSortDesc: 'Ordernar DESC',
                  columnMenuShowColumns: 'Mostrar columnas',
                }}
              />
            </Col>
          </Row>
        </FormDefault>
      )}
    </Container>
  );
};
