import styled from 'styled-components';

export const ContainerMeuPlano = styled.section`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  padding: 1.25rem;

  .meu-plano-content-top {
    display: grid;
    grid-template-columns: 66.5% 33.5%;
    gap: 0.625rem;
    padding: 0.625rem;
  }

  .meu-plano-content-bottom {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.625rem;
    padding: 0.625rem;
  }
`;
