import React, { useCallback, useState } from 'react';
import { GridRowParams } from '@material-ui/data-grid';

import Spinner from '@atlaskit/spinner';
import { toast } from 'react-toastify';
import Search from '~/components/Search';

import { Container } from './styles';
import FormDefault from '~/components/FormDefault';
import api from '~/services/api';
import { formatCurrencyAsText, transformAsCurrency } from '~/utils/functions';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputPeso, InputText } from '~/components/NovosInputs';
import { schema } from './validations/schema';
import defaultData from './validations/defaultData.json';

const CadastroTara: React.FC = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  /**
   * Reseta form para criação de novo registro
   */
  const resetFormData = useCallback(() => {
    reset(defaultData);
    setUpdate(false);
  }, [reset]);

  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;
    const { cod_tara, des_tara, val_tara } = row;

    setValue('cod_tara', cod_tara);
    setValue('des_tara', des_tara);
    setValue('val_tara', formatCurrencyAsText(val_tara));

    setUpdate(true);
    setLoader(false);
    setShowSearch(false);
  };

  const onSave = handleSubmit(async (dataForm) => {
    try {
      if (isUpdate) {
        const { data } = await api.put(`/tara/${dataForm.cod_tara}`, {
          des_tara: dataForm.des_tara,
          val_tara: transformAsCurrency(dataForm.val_tara),
        });
        if (data.success) {
          toast.success(data.message.replace('!', ''));
          resetFormData();
          setShowSearch(true);
          return;
        }
      }
      const { data } = await api.post('/tara', {
        des_tara: dataForm.des_tara,
        val_tara: transformAsCurrency(dataForm.val_tara),
      });
      if (data.success) {
        toast.success(data.message.replace('!', ''));
        resetFormData();
        setShowSearch(false);
      } else {
        toast.warning(data.message);
      }
    } finally {
      setLoader(false);
    }
  });

  const handleDelete = async () => {
    const cod_tara = getValues('cod_tara');
    await api.delete(`/tara/${cod_tara}`);
    resetFormData();
    setShowSearch(true);
    toast.success('Tara removida com sucesso');
  };

  const onNew = () => {
    resetFormData();
    setUpdate(false);
  };

  if (loader) {
    return (
      <Container>
        <div className="w-100 h-100 d-flex justify-conten-center align-items-center">
          <Spinner />
        </div>
      </Container>
    );
  }

  return (
    <Container>
      {showSearch && (
        <Search
          newData={() => setShowSearch(false)}
          onRowClick={onRowClick}
          codTela={33}
        />
      )}

      {!showSearch && (
        <FormDefault
          codTela={33}
          title="Cadastro de Tara"
          codigoRegistro={[
            {
              value: getValues('cod_tara'),
              des_campo: 'Código',
            },
          ]}
          onSave={async () => {
            await onSave();
          }}
          onCancel={() => {
            resetFormData();
            setShowSearch(true);
          }}
          isUpdate={isUpdate}
          onNew={() => onNew()}
          onDelete={handleDelete}
          onClearFields={() => resetFormData()}
          onReturnSearch={() => {
            setShowSearch(true);
            setUpdate(false);
            resetFormData();
          }}
          showSwitch={false}
        >
          <div className="row">
            <div className="col-sm-12 col-md-10">
              <InputText
                label="Descrição"
                maxLength={50}
                placeholder="Informe a Descrição da Taro"
                name="des_tara"
                caseInput="upper"
                register={register}
                isError={!!errors.des_tara}
                control={control}
              />
            </div>

            {/* Peso */}
            <div className="col-sm-12 col-md-2">
              <InputPeso
                label="Peso"
                placeholder="0,000"
                decimals={2}
                // min={0.001}
                // max={999999}
                name="val_tara"
                control={control}
                register={register}
                isError={!!errors.val_tara}
              />
            </div>
          </div>
        </FormDefault>
      )}
    </Container>
  );
};

export default CadastroTara;
