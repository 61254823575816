import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Context } from '~/context/auth';
import imgLogin from '~/assets/img/img-login.png';
import imgLogo from '~/assets/img/logo.png';
import {
  ButtonSubmit,
  Container,
  ContainerForm,
  ContainerImage,
  Form,
  Image,
  LinkRecovery,
  StepButton,
  StoreContainer,
  StoreText,
  ModalHeader,
} from './styles';
import api, { getAPIUrl } from '~/services/api';
import axios from 'axios';
import { InputText } from '~/components/NovosInputs';
import { useForm } from 'react-hook-form';
import { MdOutlineNavigateNext } from 'react-icons/md';
import { AiTwotoneShop } from 'react-icons/ai';
import { IoMdCheckmark } from 'react-icons/io';
import { Modal } from 'react-bootstrap';
import { validarCNPJ } from '~/utils/functions';
import {
  removeUserToken,
  removeExpirationToken,
  removeUserData,
  getUserData,
  getUserToken,
} from '~/services/user';

interface LoginRequestProps {
  empresa: string;
  email: string;
  password: string;
}

interface ResponseRecovery {
  success: boolean;
  message: string;
}

const MySwal = withReactContent(Swal);

const Login: React.FC = () => {
  const { handleLogin } = useContext(Context);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [empresa, setEmpresa] = useState<string>('');
  const [loadingNextStep, setLoadingNextStep] = useState<boolean>(false);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(false);
  const [apiSuccess, setApiSuccess] = useState<boolean>(false);
  const [disableField, setDisableField] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onBlur',
  });

  // Limpa o localStorage ao acessar a página de login para evitar requests desnecessárias
  useEffect(() => {
    const token = getUserToken();
    const userData = getUserData();
    if (userData && token) {
      removeUserData();
      removeUserToken();
      removeExpirationToken();
    }
  }, []);

  const onLogin = handleSubmit(async (data) => {
    const { email, password } = data;
    setLoading(true);
    try {
      await handleLogin(email.trim(), password);
    } catch (err) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  });

  const handleEmpresaValidation = (data: any) => {
    const { tipo_status, des_app_url, des_nome_espaco } = data;
    switch (tipo_status) {
      case 0:
        setValue('empresa', des_nome_espaco);
        setEmpresa(des_app_url);
        setStep(1);
        setDisableField(true);
        break;
      case 2:
        window.location.href = des_app_url;
        break;
      case 3:
        window.location.href = des_app_url;
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const isLocal = !window.location.hostname.includes('konvix.com.br');
    const environment = process.env.NODE_ENV;
    if (environment === 'development' || isLocal) {
      setDisableField(true);
      setStep(1);
      const parsedUrl = new URL(window.location.href);
      setEmpresa(parsedUrl.host);
      return;
    }
    setLoadingRequest(true);
    const fetchData = async () => {
      setLoading(true);
      try {
        const apiURL = getAPIUrl(
          String(process.env.REACT_APP_SERVER_API_ADMIN),
        );
        const endPointValidacao = `${apiURL}/v1/validacao-url`;
        const { data } = await axios.post(endPointValidacao);
        if (data.success) {
          handleEmpresaValidation(data.data);
        }
      } catch (err: any) {
        toast.warn('Não foi possível buscar a empresa informada.');
        setLoading(false);
        setLoadingRequest(false);
      } finally {
        setLoading(false);
        setLoadingRequest(false);
      }
    };
    fetchData();
  }, []);

  const validateEmpresa = async () => {
    if (empresa.trim() === '') {
      toast.warn('Informe o nome do espaço ou CNPJ');
      setError('empresa', { type: 'focus' });

      return;
    }
    setLoadingNextStep(true);

    const delay = (ms: number) =>
      new Promise((resolve) => setTimeout(resolve, ms));
    try {
      const apiURL = getAPIUrl(String(process.env.REACT_APP_SERVER_API_ADMIN));
      const isCnpj = validarCNPJ(empresa);
      const { data } = await axios.post(`${apiURL}/v1/validacao-empresa`, {
        num_cnpj: isCnpj ? empresa : null,
        des_nome_espaco: !isCnpj ? empresa : null,
      });
      await delay(500);
      setApiSuccess(data.success);
      if (data.success) {
        const { des_app_url } = data.data;
        window.location.href = des_app_url;
        return;
      }
      toast.warning(data.message);
    } catch (error) {
      toast.warning('Empresa não encontrada.');
    } finally {
      setLoadingNextStep(false);
    }
  };

  const handleRecoveryEmail = async () => {
    const { recovery_email } = getValues();
    if (recovery_email === null || recovery_email === '') {
      setError('recovery_email', { type: 'focus' });
      return;
    }
    try {
      const { data } = await api.post<ResponseRecovery>('/recovery', {
        email: recovery_email,
      });

      if (data.success) {
        close();

        MySwal.fire({
          html: data.message,
          icon: 'success',
          showConfirmButton: false,
        });
      } else {
        toast.warning(data.message);
      }
    } catch (error) {
      toast.warning(error);
    } finally {
      setValue('recovery_email', '');
    }
  };

  const close = () => setIsOpen(false);
  const open = () => setIsOpen(true);
  return (
    <Container>
      <ContainerImage>
        <Image src={imgLogin} alt="" />
      </ContainerImage>

      <ContainerForm>
        {loadingRequest ? (
          <CircularProgress size={30} style={{ color: '#57069e' }} />
        ) : (
          <Form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <img src={imgLogo} alt="" className="logo" />

            {step === 0 ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '20px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    position: 'relative',
                    width: '80%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div style={{ width: '85%' }}>
                    <InputText
                      name="empresa"
                      label="Empresa"
                      placeholder="Informe o nome do espaço ou CNPJ"
                      register={register}
                      isError={!!errors.empresa}
                      isDisabled={disableField}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        clearErrors('empresa');
                        const lowercaseValue = e.target.value.toLowerCase();
                        setEmpresa(lowercaseValue);
                        setValue('empresa', lowercaseValue);
                      }}
                      maxLength={14}
                    />
                  </div>
                  <StepButton
                    isSuccess={apiSuccess}
                    type="submit"
                    onClick={validateEmpresa}
                  >
                    {loadingNextStep ? (
                      <CircularProgress
                        size={15}
                        style={{ color: '#ffffff' }}
                      />
                    ) : !apiSuccess ? (
                      <MdOutlineNavigateNext color="" />
                    ) : (
                      <IoMdCheckmark />
                    )}
                  </StepButton>
                </div>
              </div>
            ) : (
              <>
                <StoreContainer>
                  <AiTwotoneShop color="#57069e" size={30} />
                  <StoreText>{empresa}</StoreText>
                </StoreContainer>
                <InputText
                  name="email"
                  label="Email"
                  register={register}
                  control={control}
                  isError={false}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const lowercaseValue = e.target.value.toLowerCase();
                    setValue('email', lowercaseValue);
                  }}
                  maxLength={99}
                />
                <InputText
                  name="password"
                  label="Senha"
                  register={register}
                  control={control}
                  isError={false}
                  type="password"
                  maxLength={999}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <LinkRecovery type="button" onClick={open}>
                    Esqueceu sua senha?
                  </LinkRecovery>
                </div>
                <ButtonSubmit type="submit" onClick={onLogin}>
                  {loading ? (
                    <CircularProgress size={15} style={{ color: '#ffffff' }} />
                  ) : (
                    <span>Entrar</span>
                  )}
                </ButtonSubmit>
              </>
            )}
          </Form>
        )}
      </ContainerForm>

      <Modal show={isOpen} onHide={close} animation>
        <ModalHeader>Digite seu email para recuperar sua senha</ModalHeader>
        <div style={{ padding: '20px' }}>
          <InputText
            name="recovery_email"
            label="Email"
            register={register}
            control={control}
            isError={!!errors.recovery_email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              clearErrors('recovery_email');
              const lowercaseValue = e.target.value.toLowerCase();
              setValue('recovery_email', lowercaseValue);
            }}
            maxLength={99}
          />
          <ButtonSubmit type="button" onClick={() => handleRecoveryEmail()}>
            Enviar
          </ButtonSubmit>
        </div>
      </Modal>
    </Container>
  );
};

export default Login;
