import styled from 'styled-components';

export const MudarDadosPagamento = styled.section`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .methods {
      display: flex;
      flex-direction: column;
      width: 53%;

      .button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-top: 1.5625rem;
      }
    }
  }
`;
