import React from 'react';
import { MeuPlanoContent } from './MeuPlanoContent';
import { MeuPlanoProvider } from './contexts/MeuPlanoContext';

export const MeuPlano: React.FC = () => {
  return (
    <MeuPlanoProvider>
      <MeuPlanoContent />
    </MeuPlanoProvider>
  );
};
