import React from 'react';

import { BsFillLightningFill, BsCheck } from 'react-icons/bs';

import moment from 'moment';

import Search from '~/components/Search';

import DefaultLoader from '~/components/DefaultLoader';
import FormDefault from '~/components/FormDefault';
import { ClipLoader } from 'react-spinners';

import { Container, ButtonCustom } from './styles';

import { useAlteracaoPrecoEntradaFornecedor } from './AlteracaoPrecoEntradaFornecedorContext';
import TableContainerComponent from './Component/TableContainerComponent';
import Cards from './Component/Cards';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { HiOutlineDocumentText } from 'react-icons/hi';
import { ModalPDF } from './Component/ModalDanfe/ModalPDF';

const AlteracaoPrecoEntradaFornecedorContent: React.FC = () => {
  const {
    showSearch,
    onRowClick,
    loader,
    codFornecedor,
    isUpdate,
    resetFormData,
    setShowSearch,
    validateNFConferida,
    descartarAlteracoes,
    marcarNFConferida,
    conferida,
    aplicarAlteracoes,
    loja,
    cnpj,
    numNf,
    entrada,
    fornecedor,
    codSeqNF,
    isOpenModal,
    setLoadingPDF,
    getDanfe,
    loadingPDF,
  } = useAlteracaoPrecoEntradaFornecedor();
  const MySwal = withReactContent(Swal);

  if (loader) {
    return (
      <Container>
        <DefaultLoader />
      </Container>
    );
  }

  const chamaSwetAlert = async () => {
    const confirm = await MySwal.fire({
      title: ``,
      text: 'Alterações de preços pendentes? Deseja',
      width: 800,
      showCancelButton: true,
      showDenyButton: true,
      confirmButtonColor: '#0065FF',
      denyButtonColor: '#ffc107',
      cancelButtonColor: '#DE350B',
      confirmButtonText: 'Aplicar Alterações',
      denyButtonText: `Descartar e apenas Confirmar`,
      cancelButtonText: 'Cancelar',
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        aplicarAlteracoes();
      } else if (result.isDenied) {
        descartarAlteracoes();
        marcarNFConferida();
      }
      return result.isConfirmed;
    });
    return confirm;
  };

  return (
    <Container>
      {showSearch && (
        <Search
          codTela={210}
          newData={() => []}
          onRowClick={onRowClick}
          onlyReport
        />
      )}

      {!showSearch && (
        <FormDefault
          codTela={210}
          title="Alteração de Preços por Entrada de Fornecedor"
          codigoRegistro={[{ value: codSeqNF, des_campo: 'Cód. Seq. NF' }]}
          onSave={() => Promise.resolve()}
          onCancel={() => setShowSearch(true)}
          isUpdate={isUpdate}
          onNew={resetFormData}
          onDelete={() => []}
          onClearFields={resetFormData}
          onReturnSearch={() => {
            resetFormData();
            setShowSearch(true);
          }}
          isClear={false}
          isSave
          isNew={false}
          isDelete={false}
        >
          <div className="col-sm-12 table-exibition">
            <table className="table">
              <thead>
                <tr>
                  <th>Loja</th>
                  <th>Fornecedor</th>
                  <th>CNPJ</th>
                  <th>NF</th>
                  <th>Entrada</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{loja}</td>
                  <td>{`${codFornecedor} - ${fornecedor}`}</td>
                  <td>{cnpj}</td>
                  <td>{numNf}</td>
                  <td>{moment(entrada).format('DD/MM/YYYY')}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <TableContainerComponent />
          <Cards />

          {isOpenModal && <ModalPDF />}

          <div className="row" style={{ marginTop: '1.5625rem' }}>
            <div
              className="col-12"
              style={{
                display: 'flex',
                justifyContent: 'end',
              }}
            >
              <ButtonCustom
                type="button"
                style={{
                  backgroundColor: '#2d136c',
                }}
                disabled={loadingPDF}
                onClick={() => {
                  setLoadingPDF(true);
                  getDanfe();
                }}
              >
                {loadingPDF && <ClipLoader size={20} color="#ffff" />}
                {!loadingPDF && (
                  <>
                    <div>
                      <HiOutlineDocumentText fontSize="28px" color="#fff" />
                    </div>
                    <div style={{ paddingTop: '15px' }}>
                      <p>DANFE</p>
                    </div>
                  </>
                )}
              </ButtonCustom>

              <ButtonCustom
                type="button"
                disabled={conferida}
                style={{
                  width: '300px',
                  backgroundColor: `${conferida ? 'green' : '#007bff'}`,
                }}
                onClick={async () => {
                  const validateNF = validateNFConferida();
                  if (validateNF) {
                    await chamaSwetAlert();
                  } else {
                    marcarNFConferida();
                  }
                }}
              >
                <BsCheck fontSize="40px" color="#fff" />
                {conferida ? 'NF JÁ CONFERIDA!' : 'MARCAR NF CONFERIDA?'}
              </ButtonCustom>
              <ButtonCustom
                type="button"
                style={{
                  width: '300px',
                  backgroundColor: '#8850bf',
                  marginRight: '-10px',
                }}
                onClick={() => aplicarAlteracoes()}
              >
                <BsFillLightningFill fontSize="20px" color="#fff" />
                APLICAR ALTERAÇÕES
              </ButtonCustom>
            </div>
          </div>
        </FormDefault>
      )}
    </Container>
  );
};

export default AlteracaoPrecoEntradaFornecedorContent;
