import styled from 'styled-components';

export const DadosClienteContainer = styled.div<{ insideAccordion: boolean }>`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  .form-dados {
    display: flex;
    flex-direction: column;

    width: ${(props) => (props.insideAccordion ? '100%' : '50%')};

    .content-divider {
      display: flex;
      gap: 0.625rem;

      input {
        width: 18.4375rem;
      }

      @media only screen and (max-width: 1399px) {
        input {
          width: 15.625rem;
        }
      }
      @media only screen and (max-width: 1199px) {
        input {
          width: 12.8125rem;
        }
      }
      @media only screen and (max-width: 991px) {
        flex-direction: column;
        input {
          width: 18.75rem;
        }
      }
    }
  }
`;
