import pdfMake from 'pdfmake/build/pdfmake';
import {
    LojaApuracaoProps,
} from '../types';
import { moneyFormat, } from '~/utils/functions';
import { format } from 'date-fns';
import { createDateWithoutTimezone } from '~/utils/createDateWithoutTimezone ';

class PedidoVendaPDF {
    private docDefinition: any;

    constructor(
        private infos: any,
    ) {


        this.docDefinition = {
            pageSize: 'A4',
            pageOrientation: 'portrait',
            pageMargins: [30, 60, 30, 40],
            defaultStyle: {
                fontSize: 6,
                color: '#3f4254'
            },
            fonts: {
                FontAwesome: {
                    normal: 'FontAwesome.ttf',
                    bold: 'FontAwesome.ttf',
                    italics: 'FontAwesome.ttf',
                    bolditalics: 'FontAwesome.ttf'
                }
            },
            content: this.createContent(),
        };
    }

    public static formatEndereco(infos: any) {
        const {
            des_logradouro_loja,
            num_endereco_loja,
            des_bairro_loja,
            des_cidade_loja,
            des_uf_loja,
        } = infos;

        if (
            !des_logradouro_loja &&
            !num_endereco_loja &&
            !des_bairro_loja &&
            !des_cidade_loja &&
            !des_uf_loja
        ) {
            return "Endereço não informado";
        }
        const endereco = `${des_logradouro_loja || "Logradouro não informado"} - Nº: ${num_endereco_loja || "Número de endereço não informado"} - ${des_bairro_loja || "Bairro não informado"}, ${des_cidade_loja || "Cidade não informada"} - ${des_uf_loja || "UF não informada"}`;
        return endereco;
    }
    private createHeader() {
        const { infos } = this;

        const des_negociacao = ['Orçamento', 'Pedido', 'PDV Online']
        const des_status = ['Pendente', 'Parcial', 'Total', 'Cancelado', 'Recusado']
        const endereco = PedidoVendaPDF.formatEndereco(infos);
        const negociacao = des_negociacao[infos.tipo_negociacao] || 'Não informado'
        const status = des_status[infos.tipo_status] || 'Não informado'
        return [
            {
                layout: 'noBorders',
                table: {
                    widths: ['100%'],
                    body: [
                        [
                            {
                                text: infos.des_loja, fontSize: 16, bold: true, alignment: 'left',
                                margin: [10, 5, 0, 5],
                            },
                        ],
                        [
                            {
                                text: `${endereco}\nTelefone: ${infos.num_telefone_loja}\nCNPJ: ${infos.num_cnpj_loja}\nNegociação: ${negociacao}\nStatus: ${status}`,
                                alignment: 'left',
                                fontSize: 10,
                                margin: [10, 5, 0, 5],
                            },
                        ],

                    ],
                },
            },
            PedidoVendaPDF.createDividerHeader(),
        ];
    }


    private createSubHeader() {
        const { infos } = this;
        const dta_emissao = infos.dta_emissao ? format(createDateWithoutTimezone(infos.dta_emissao), 'dd/MM/yyyy') : 'Não informada'
        const nome_pessoa = infos.nome_pessoa ? infos.nome_pessoa : 'Não informado'
        const nome_vendedor = infos.des_vendedor ? infos.des_vendedor : 'Não informado'

        return [
            {
                layout: 'noBorders',
                table: {
                    widths: ['100%'],
                    body: [
                        [
                            {
                                columns: [
                                    { text: `CLIENTE \n ${nome_pessoa}    `, alignment: 'left', fontSize: 10, },
                                    { text: `Pedido: ${infos.num_pedido} \nVendedor: ${nome_vendedor}\nData: ${dta_emissao}`, alignment: 'right', fontSize: 10, },

                                ],
                                margin: [10, 30, 10, 30]
                            },
                        ],
                    ],

                }
            },
            PedidoVendaPDF.createDividerHeader(),
        ]
    }

    private createFooterTable() {
        const { infos } = this;
        const val_total_produtos = infos.item_info.reduce((total: number, item: any) => total + (item.qtd_pedido * item.val_pedido), 0) || 0;
        const val_total_venda = infos.item_info.reduce((total: number, item: any) => total + ((item.val_pedido - item.val_desconto + item.val_acrescimo) * item.qtd_pedido), 0) || 0;

        return [
            {
                layout: 'noBorders',
                table: {
                    widths: ['100%'],
                    body: [
                        [
                            {
                                columns: [
                                    { text: `Valor total dos produtos: R$ ${moneyFormat(String(val_total_produtos))} \nTotal: R$ ${moneyFormat(String(val_total_venda))}`, alignment: 'right', fontSize: 14 },

                                ],
                                margin: [10, 30, 10, 30]
                            },
                        ],
                    ],

                }
            },
        ]
    }

    private static createDividerHeader() {
        return {
            canvas: [
                {
                    type: 'line',
                    x1: 0,
                    y1: 0,
                    x2: 535,
                    y2: 0,
                    lineWidth: 1,
                    lineColor: '#cbccce',
                },
            ],
            margin: [0, 0, 0, 0],
        };
    }


    private createContent() {
        const { infos } = this;
        const headerRow = [
            { text: 'Código', bold: true, fontSize: 10 },
            { text: 'Nome do Produto', bold: true, fontSize: 10 },
            { text: 'Qtd.', bold: true, fontSize: 10 },
            { text: 'Preço Un.', bold: true, fontSize: 10 },
            { text: 'Desc.', bold: true, fontSize: 10 },
            { text: 'Acresc.', bold: true, fontSize: 10 },
            { text: 'Preço Total', bold: true, fontSize: 10 },
        ];


        const bodyRows = infos.item_info.map((item: any) => [
            { text: item.cod_produto, fontSize: 10 },
            { text: item.des_produto, fontSize: 10 },
            { text: item.qtd_pedido, fontSize: 10 },
            { text: moneyFormat(String(item.val_pedido)), fontSize: 10 },
            { text: moneyFormat(String(item.val_desconto)), fontSize: 10 },
            { text: moneyFormat(String(item.val_acrescimo)), fontSize: 10 },
            { text: moneyFormat(String((item.val_pedido - item.val_desconto + item.val_acrescimo) * item.qtd_pedido)), fontSize: 10 },
        ]);

        const content: any = []
        content.push(...this.createHeader())
        content.push(...this.createSubHeader())


        const table = [
            {
                layout: 'noBorders',
                table: {
                    widths: ['100%'],
                    body: [
                        [
                            { text: 'ITENS:', fontSize: 14, bold: true, margin: [10, 30, 10, 0] },


                        ],
                        [
                            {

                                table: {
                                    headerRows: 1,
                                    widths: ['10%', '35%', '9%', '10%', '12%', '12%', '12%'],
                                    body: [headerRow, ...bodyRows],
                                },

                                margin: [10, 0, 10, 20],
                                layout: {
                                    hLineColor: (rowIndex: any) => {
                                        return rowIndex === 1 ? '#cbccce' : '#fff';
                                    },
                                    vLineColor: '#fff'

                                }
                            },
                        ]
                    ]
                }
            },
            PedidoVendaPDF.createDividerHeader(),
        ]
        content.push(table);
        content.push(...this.createFooterTable())
        return content;
    }


    public generatePDF(): void {
        const pdfDocGenerator = pdfMake.createPdf(this.docDefinition);
        pdfDocGenerator.open();
    }



}

export default PedidoVendaPDF;
