import * as yup from 'yup';

export const schema = yup.object({
  num_certificado: yup.string().required(),
  ambiente: yup.object().shape({
    label: yup.string().required(),
    value: yup.number().required(),
  }),
  id_csc: yup.number().positive().required(),
  token_csc: yup
    .string()
    .min(16, 'O campo token_csc deve ter no mínimo 16 caracteres.')
    .max(36, 'O campo token_csc deve ter no máximo 36 caracteres.')
    .required('O campo token_csc é obrigatório.'),
});
