const optionsData = [
  {
    value: ['FOSCO', 'BRILHANTE', 'ACETINADO', 'TEXTURIZADO'],
    label: 'Acabamento',
  },
  {
    value: ['64GB', '128GB', '256GB'],
    label: 'Capacidade',
  },
  {
    value: ['1L', '2L', '5L', '10L', '20L', '30L', '40L', '50L', '100L'],
    label: 'Capacidade (Litros)',
  },
  {
    value: [
      'AMARELO',
      'AZUL',
      'BEGE',
      'BRANCO',
      'CASTANHO',
      'CINZA',
      'DOURADO',
      'LARANJA',
      'LILÁS',
      'MARROM',
      'PRETO',
      'ROSA',
      'ROXO',
      'VERDE',
      'VERMELHO',
      'VIOLETA',
    ],
    label: 'Cor',
  },
  {
    value: ['LISTRADO', 'LISO', 'XADREZ', 'FLORAL'],
    label: 'Estampa',
  },
  {
    value: ['LAVANDA', 'CÍTRICO', 'AMADEIRADO'],
    label: 'Fragrância',
  },
  {
    value: ['MASCULINO', 'FEMININO'],
    label: 'Gênero',
  },
  {
    value: ['CHOCOLATE', 'MORANGO', 'NATURAL'],
    label: 'Sabor',
  },
  {
    value: [
      '34',
      '36',
      '38',
      '40',
      '42',
      '44',
      '46',
      '48',
      '50',
      '52',
      '54',
      '56',
      '58',
      '60',
    ],
    label: 'Tamanho Calça',
  },
  {
    value: ['36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46'],
    label: 'Tamanho Calçado',
  },
  {
    value: [
      'U',
      'PP',
      'P',
      'M',
      'G',
      'GG',
      'XG',
      'XGG',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
    ],
    label: 'Tamanho Camisa',
  },
  {
    value: ['53-54', '55-56', '57-58', '59-60', '61-62', '63-64'],
    label: 'Tamanho Capacete',
  },
  {
    value: ['14”', '16”', '18”'],
    label: 'Tamanho da Roda',
  },
  {
    value: ['110V', '220V', '380V'],
    label: 'Voltagem',
  },
  {
    value: ['100ML', '200ML', '500ML'],
    label: 'Volume',
  },
];

export default optionsData;
