import styled from 'styled-components';

export const ContentCard = styled.div<{
  planoPagamento: 'MENSAL' | 'ANUAL';
}>`
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 1.25rem;
  flex-wrap: wrap;
  padding: 1.25rem;

  .card-content {
    border: 0.0625rem solid #57069e;
    border-radius: 8px;
    box-shadow: 0rem 0.25rem 0.5rem rgba(87, 6, 158, 0.2);
    width: 300px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.05);
    }

    /* Título do plano */
    h2 {
      font-size: 22px;
      font-weight: bold;
      color: #333;
      margin: 0 0 15px;
      text-align: left; /* Alinhado à esquerda */
      text-transform: uppercase;
    }

    /* Preço */
    .plan-price {
      margin: 10px 0 15px;
      text-align: left;

      strong {
        font-size: 28px; /* Mais destaque ao preço */
        color: #57069e;
        font-weight: bold;
        display: block;
      }

      small {
        display: block;
        font-size: 12px;
        color: #333333;
        margin-top: 5px;
      }
    }

    /* Destaque do desconto */
    .discount-info {
      background-color: #fff6e5; /* Cor suave de fundo */
      color: #ff9c0c; /* Cor do texto */
      padding: 10px;
      border-radius: 5px;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 15px;
      text-align: center;
    }

    /* Lista de Benefícios */
    .plan-features {
      list-style: none;
      padding: 0;
      margin: 15px 0;
      width: 100%;

      li {
        font-size: 14px;
        color: #666;
        margin-bottom: 10px;
        position: relative;
        padding-left: 25px;

        &::before {
          content: '✔';
          position: absolute;
          left: 0;
          color: #57069e;
          font-weight: bold;
        }
      }
    }

    /* Botão */
    button {
      background-color: white;
      color: #57069e;
      border: 0.0625rem solid #57069e;
      border-radius: 4px;
      padding: 10px 20px;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
      transition: all 0.3s ease;
      margin-top: auto;

      &:hover {
        background-color: #9900ff !important;
        color: white !important;
      }
    }
  }
`;

export const ContainerPlanosDisponiveis = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1.125rem;

  .content-steps {
    display: flex;
    flex-direction: column;
    align-items: center;

    .footer-buttons {
      display: flex;
      justify-content: space-between;
      width: 50%;
      align-content: center;

      .btn-step-next {
        background-color: #9900ff;
        color: white;
      }
    }
  }
`;
