import styled from 'styled-components';

export const DadosFaturamentoContainer = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  .payment-accordion {
    display: flex;
    width: 50%;
    margin-top: 15px;
  }
`;
