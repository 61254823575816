import styled from 'styled-components';

export const CancelSubContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  .content-alerts {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .alerts {
      display: flex;
      flex-direction: column;

      .alert-item {
        display: flex;
        align-items: center;
        margin-bottom: 0.625rem;
      }

      .alert-circle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1.875rem;
        height: 1.875rem;
        background-color: #6f42c1;
        color: #fff;
        border-radius: 50%;
        font-weight: bold;
        margin-right: 0.625rem;
      }

      .alert-message {
        margin: 0;
        font-size: 1rem;
        color: #333;
      }
    }

    .content-buttons {
      display: flex;
      gap: 0.9375rem;
      margin-top: 0.625rem;
    }
  }

  .confirm-cancel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      font-size: large;
    }
  }
`;
