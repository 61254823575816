import React, { useCallback, useState } from 'react';
import { useMeuPlano } from '../../hooks/useMeuPlano';
import { HeaderContent } from './styles';
import { IoChevronBackOutline } from 'react-icons/io5';
import { SwitchPlans } from '../SwitchPlans';
import { ProgressBar } from './components/ProgressBar';
import { CustomButtonNovo } from '~/components/Buttons/CustomButtonNovo';

interface HeaderScreenProps {
  showButtonVoltar?: boolean;
  showMensalAnual?: boolean;
  showTrilha?: boolean;
  stepTrilha?: number;
}

export const HeaderScreen: React.FC<HeaderScreenProps> = ({
  showButtonVoltar = false,
  showMensalAnual = false,
  showTrilha = false,
  stepTrilha = undefined,
}) => {
  const {
    handleScreen,
    handlePlanoPagamento,
    planoPagamento,
    handleMethodFaturamento,
    setCustomerDataToForm,
    formCard: { resetFormCard },
  } = useMeuPlano();

  const [isOn, setIsOn] = useState(false);

  const handleBack = useCallback(() => {
    handleScreen('HOME');
    setIsOn(false);
    handlePlanoPagamento('MENSAL');
    handleMethodFaturamento(null);
    resetFormCard();
    setCustomerDataToForm();
  }, [
    handleMethodFaturamento,
    handlePlanoPagamento,
    handleScreen,
    resetFormCard,
    setCustomerDataToForm,
  ]);

  const handleToggle = useCallback(
    (state: boolean) => {
      setIsOn(state);
      const plano = state ? 'ANUAL' : 'MENSAL';
      handlePlanoPagamento(plano);
    },
    [handlePlanoPagamento],
  );

  return (
    <HeaderContent>
      {showButtonVoltar && (
        <CustomButtonNovo
          disabled={false}
          onClick={handleBack}
          label="Voltar"
          width="6.25rem"
          icon={IoChevronBackOutline}
          outline
        />
      )}
      {showMensalAnual && (
        <div className="switch-planos">
          <span
            style={{ color: planoPagamento === 'MENSAL' ? '#7211c7' : 'black' }}
          >
            Mensal
          </span>
          <SwitchPlans isOn={isOn} onToggle={handleToggle} onColor="#8820e4" />
          <span
            style={{ color: planoPagamento === 'ANUAL' ? '#7211c7' : 'black' }}
          >
            Anual
          </span>
        </div>
      )}
      {showTrilha && <ProgressBar step={stepTrilha || 0} />}
    </HeaderContent>
  );
};
