import styled from 'styled-components';

export const HeaderContent = styled.header`
  display: flex;
  justify-content: space-between;

  .switch-planos {
    display: flex;
    align-items: center;
    gap: 10px;

    border: 0.0625rem solid #57069e;
    padding: 5px;
    border-radius: 5px;

    span {
      font-size: large;
      font-weight: 600;
    }
  }
`;
