import api from '~/services/api';
import { Filter } from '../types';
import { Color } from 'react-color';

interface returnProps {
  message: string;
  success: boolean;
}
interface returnWidthDataProps {
  message: string;
  data: any;
  success: boolean;
}

export async function updateCard(
  codCard: number | undefined,
  des_card: string,
  cor: Color,
  cor_texto: Color,
  filters: Filter[],
  isCardFixed: boolean,
  tipoAcesso: number,
  cod_tela: number,
  cod_visao?: number | null,
): Promise<returnProps> {
  const { data } = await api.put(`/tela-cards/${codCard}`, {
    des_card,
    cor,
    cor_texto,
    filters,
    isCardFixed,
    tipoAcesso,
    cod_tela,
    cod_visao,
  });
  return data;
}

export async function updateCardWithoutFilter(
  codigo_card: number | undefined,
  des_card: string,
  cor: Color,
  cor_texto: Color,
  isCardFixed: boolean,
  codTela: number,
  tipoAcesso: number,
  cod_visao: number | null,
): Promise<returnProps> {
  const { data } = await api.put(`/tela-cards/name-card/${codigo_card}`, {
    des_card,
    cor,
    cor_texto,
    isCardFixed,
    codTela,
    tipoAcesso,
    cod_visao,
  });
  return data;
}

export async function getFiltrosObrigatorios(
  codTelaProp: number,
): Promise<returnWidthDataProps> {
  const { data } = await api.get(
    `/tela-campos-filtros-obrigatorios/${codTelaProp}`,
  );
  return data;
}

export async function getFiltrosCard(
  cod_card: number,
): Promise<returnWidthDataProps> {
  const { data } = await api.get(`/tela-card/filtros/${cod_card}`);
  return data;
}

export async function getCards(
  codTelaProp: number,
  tipo_acesso: number,
): Promise<returnWidthDataProps> {
  const { data } = await api.get(`/tela-cards/${tipo_acesso}/${codTelaProp}`);
  return data;
}

export async function getCardsUsuario(
  codTelaProp: number,
): Promise<returnWidthDataProps> {
  const { data } = await api.get(`/tela-usuario/${codTelaProp}`);
  return data;
}
export async function getFiltrosCardOriginal(
  cod_card_original: number,
): Promise<returnWidthDataProps> {
  const { data } = await api.get(`/filtro-card/${cod_card_original}`);
  return data;
}

export async function postNewCard(
  cod_tela: number,
  des_card: string,
  cor: Color,
  cor_texto: Color,
  user: string,
  filtersProps: any[],
  isCardFixed: boolean,
  tipoAcesso: number,
  cod_visao: number | null,
  codCard?: number,
): Promise<returnWidthDataProps> {
  const { data } = await api.post('/tela-cards', {
    cod_tela,
    des_card,
    cor,
    cor_texto,
    filters: filtersProps,
    isCardFixed,
    tipoAcesso,
    cod_visao,
    codCard,
  });
  return data;
}

export async function deleteCard(cod_card: number): Promise<returnProps> {
  const { data } = await api.delete(`/tela-cards/${cod_card}`);
  return data;
}
