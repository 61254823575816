import * as yup from 'yup';
import { transformAsCurrency } from '~/utils/functions';

export const schema = yup
  .object({
    des_nome: yup.string().trim().required(),
    des_nome_espaco: yup.string().required(),
    cod_grupo: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    timezone: yup.object().shape({
      label: yup.string().required(),
      value: yup.string().required(),
    }),
  })
  .required();
