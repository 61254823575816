import React from 'react';
import { DadosCliente } from '../DadosCliente';
import { ScreenPaymentsProps } from '../../types/context';

export const Boleto: React.FC<{ screen: ScreenPaymentsProps }> = ({
  screen,
}) => {
  if (screen === 'mudar-dados-pagamento')
    return <DadosCliente insideAccordion />;

  return (
    <p style={{ fontSize: '18px', lineHeight: '27px' }}>
      Clique no botão <strong>concluir</strong> para gerar e acessar seu boleto.
    </p>
  );
};
