import styled, { keyframes } from 'styled-components';

const progressAnimation = keyframes`
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
`;

const regressAnimation = keyframes`
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
`;

export const ProgressContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
`;

export const Step = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  color: ${({ active }) => (active ? '#8A2BE2' : '#666')};

  svg {
    width: 40px;
    height: 40px;
    fill: ${({ active }) => (active ? '#8A2BE2' : '#ccc')};
    margin-bottom: 5px;
  }
`;

export const IconWrapper = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid ${({ active }) => (active ? '#8A2BE2' : '#ccc')};
  background-color: ${({ active }) => (active ? '#f3e8ff' : 'transparent')};

  svg {
    width: 24px;
    height: 24px;
    color: ${({ active }) => (active ? '#8A2BE2' : '#ccc')};
  }
`;

export const Line = styled.div<{ progress: number; animate: boolean }>`
  position: relative;
  flex: 1;
  height: 2px;
  top: -8px;
  background: #ccc;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 2px;
    width: ${({ progress }) => progress}%;

    background: #8a2be2;
    animation: ${({ animate, progress }) =>
        animate
          ? progress === 100
            ? progressAnimation
            : regressAnimation
          : 'none'}
      0.5s forwards;
  }
`;
