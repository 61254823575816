import * as yup from 'yup';

export const schema = yup
  .object({
    des_produto: yup.string().trim().required(),
    des_reduzida: yup.string().trim().required(),
    cod_departamento: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    cod_ncm: yup.object().shape({
      label: yup.string(),
      value: yup.number().required(),
    }),
    val_peso: yup.string().required(),
    cod_id_ctb: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    cod_tipo_item: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
  })
  .required();
