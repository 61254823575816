/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import api from '~/services/api';
import { ResponseDefaultProps } from '../types/service';

const normalizedRoute = (route: string) =>
  route.startsWith('/') ? route.slice(1) : route;

const index = async (route: string): Promise<ResponseDefaultProps<any[]>> => {
  const { data } = await api.get<ResponseDefaultProps<any[]>>(
    `/meu-plano/${normalizedRoute(route)}`,
  );
  return data;
};

const post = async (
  route: string,
  payload: any,
): Promise<ResponseDefaultProps<any[]>> => {
  const { data } = await api.post<ResponseDefaultProps<any[]>>(
    `/meu-plano/${normalizedRoute(route)}`,
    { ...payload },
  );
  return data;
};

export const serviceMeuPlano = { index, post };
