export default {
  title: 'dark',
  colors: {
    primary: '#57069e',
    secondary: '#c2a0f8',
    submenu: '#46057e',
    danger: '#F96666',
    success: '#41d3bd',
    info: '#2fc6b0',
    warning: '#ffef0a',
    light: '#fcfcfe',
    dark: '#212336',
    nav: '#8850BF',
    white: '#fff',
    background: '#ffffff',
    color: '#282828',
  },
};

// #46057e
// #57069e
// #671ea7
// #6f42c1
// #c2a0f8
