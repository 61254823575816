import { yupResolver } from '@hookform/resolvers/yup';
import { GridRowParams } from '@material-ui/data-grid';
import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import DefaultLoader from '~/components/DefaultLoader';
import { Fildset } from '~/components/Fildset';
import FormDefault from '~/components/FormDefault';
import {
  InputFilesLabel,
  InputNumber,
  InputRadio,
  InputSelect,
  InputText,
} from '~/components/NovosInputs';
import Search from '~/components/Search';
import ToggleDefault from '~/components/ToggleDefault';
import api from '~/services/api';

import { Container } from './styles';
import { IModeloEtiqueta, SelectProps } from './types';
import { schema } from './validations';

const ModeloDeEtiqueta: React.FC = () => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [loader, setLoader] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);
  const [tipoLinguagem, setTipoLinguagem] = useState<number>(0);
  const [flagInativo, setFlagInativo] = useState<boolean>(false);
  const [flagDepor, setFlagDepor] = useState<boolean>(false);
  const [uploadFile, setUploadFile] = useState<boolean>(false);
  const [defaultOptions, setDefaultOptions] = useState<any>([]);

  const formBlank = {
    grupo_de_etiqueta: undefined,
    largura: 0,
    altura: 0,
    inativo: false,
    depor: false,
    des_foto: '',
  };

  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;
    const {
      cod_grupo_etiqueta,
      cod_modelo_etiqueta,
      des_foto,
      qtd_altura,
      qtd_largura,
      tipo_linguagem,
      flg_inativo,
      flg_preco_de_por,
    } = row;
    const options = defaultOptions.filter(
      (option: any) => Number(cod_grupo_etiqueta) === option.value,
    );
    reset({
      largura: qtd_largura,
      altura: qtd_altura,
      inativo: flg_inativo,
      depor: flg_preco_de_por,
      des_foto,
    });
    setValue('grupo_de_etiqueta', options[0]);
    setValue('cod_modelo_etiqueta', cod_modelo_etiqueta);
    setTipoLinguagem(tipo_linguagem);
    setValue('grupo_de_etiqueta', options[0]);
    setFlagInativo(flg_inativo);
    setFlagDepor(flg_preco_de_por);
    setUpdate(true);
    setShowSearch(false);
  };

  const resetFormData = () => {
    reset(formBlank);
    setTipoLinguagem(0);
    setFlagDepor(false);
    setFlagInativo(false);
    setUpdate(false);
  };

  useEffect(() => {
    (async () => {
      const { data } = await api.get(`/grupo-de-etiqueta/`);
      if (data.success) {
        if (data.data.length > 0) {
          const options = data.data.map((gru: IModeloEtiqueta) => ({
            label: gru.des_grupo,
            value: gru.cod_grupo_etiqueta,
          }));
          setDefaultOptions(options);
        }
        setLoader(false);
      }
    })();
  }, []);

  const salvarArquivo = async (arquivos: any) => {
    if (arquivos.length > 0) {
      const formData = new FormData();

      for (let i = 0; i < arquivos.length; i++) {
        formData.append('arquivos', arquivos[i]);
      }
      const res = await api.post(
        '/modelo-de-etiqueta/salvar-arquivo',
        formData,
      );
      if (res.data.success) {
        setValue(
          'des_foto',
          `https://estatico.erp.konvix.com.br/images/etiquetas/${res.data.data[0].des_nome_arquivo}`,
        );
      } else {
        toast.warn(res.data.message);
      }
      setUploadFile(false);
    }
    setUploadFile(false);
  };

  const onSubmit = handleSubmit(async (data) => {
    delete data.arquivos;

    if (isUpdate) {
      const res = await api.put(
        `/modelo-de-etiqueta/${data.cod_modelo_etiqueta}`,
        {
          ...data,
        },
      );

      const { success, message } = res.data;

      if (!success) {
        return toast.warning(message);
      }

      if (success) {
        resetFormData();
        setShowSearch(true);
        return toast.success(message);
      }
    }

    const res = await api.post('/modelo-de-etiqueta', {
      ...data,
    });

    const { success, message } = res.data;

    if (!success) {
      return toast.warning(message);
    }

    resetFormData();
    return toast.success(message);
  });

  const handleDelete = async () => {
    const values = getValues();
    const { cod_modelo_etiqueta } = values;
    const res = await api.delete(`/modelo-de-etiqueta/${cod_modelo_etiqueta}`);
    const { message } = res.data;
    toast.success(message);
    resetFormData();
    setShowSearch(true);
  };

  const onNewData = () => {
    resetFormData();
    setShowSearch(false);
  };

  if (loader) {
    return <DefaultLoader />;
  }

  return (
    <Container>
      {showSearch && (
        <Search codTela={196} newData={onNewData} onRowClick={onRowClick} />
      )}

      {!showSearch && (
        <FormDefault
          codTela={196}
          title="Cadastro de Modelo de Etiqueta"
          codigoRegistro={[
            { value: getValues('cod_modelo_etiqueta'), des_campo: 'Código' },
          ]}
          onSave={async () => {
            await onSubmit();
          }}
          onCancel={() => setShowSearch(true)}
          isUpdate={isUpdate}
          onNew={resetFormData}
          onDelete={handleDelete}
          onClearFields={resetFormData}
          isDisabled={uploadFile}
          onReturnSearch={() => {
            resetFormData();
            setShowSearch(true);
          }}
        >
          <Row>
            <Col sm={5}>
              <InputSelect
                label="Grupo de Etiqueta"
                placeholder="Selecione..."
                maxLength={50}
                name="grupo_de_etiqueta"
                register={register}
                isError={!!errors.grupo_de_etiqueta}
                control={control}
                options={defaultOptions}
                changeSelected={(selected: SelectProps) => {
                  clearErrors('grupo_de_etiqueta');
                  setValue('grupo_de_etiqueta', selected);
                }}
              />
            </Col>
            <Col sm={2}>
              <InputNumber
                label="Largura"
                maxLength={3}
                max={31}
                min={1}
                placeholder=""
                name="largura"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  if (event.target.value.length <= 3) {
                    setValue('largura', event.target.value);
                  }
                }}
                control={control}
                register={register}
                isError={!!errors.largura}
              />
            </Col>
            <Col sm={2}>
              <InputNumber
                label="Altura"
                maxLength={3}
                max={31}
                min={1}
                placeholder=""
                name="altura"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  if (event.target.value.length <= 3) {
                    setValue('altura', event.target.value);
                  }
                }}
                control={control}
                register={register}
                isError={!!errors.altura}
              />
            </Col>
            <Col>
              <ToggleDefault
                labelText="Inativo ?"
                setChecked={flagInativo}
                onSwitch={() => {
                  setValue('inativo', !flagInativo);
                  setFlagInativo(!flagInativo);
                }}
              />
            </Col>
            <Col>
              <ToggleDefault
                labelText="Possui DE/POR ?"
                setChecked={flagDepor}
                onSwitch={() => {
                  setValue('flg_preco_de_por', !flagDepor);
                  setFlagDepor(!flagDepor);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={9}>
              <InputText
                label="URL ou caminho do ícone"
                maxLength={500}
                placeholder="Informe a Url ou caminho do ícone"
                name="des_foto"
                register={register}
                control={control}
                disabled={false}
                toLowerCase
                isError={!!errors.des_foto}
              />
            </Col>
            <p
              style={{
                marginTop: '40px',
                maxWidth: '70px',
                height: '40px',
                fontSize: '20px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              OU
            </p>
            <Col sm={2}>
              <InputFilesLabel
                accept="image/.jpg,.jpeg,.png"
                isError={false}
                loading={uploadFile}
                name="arquivos"
                placeholder="Escolher Arquivo"
                register={register}
                control={control}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setUploadFile(true);
                  salvarArquivo(e.currentTarget.files);
                  e.target.value = '';
                }}
              />
            </Col>
          </Row>
          <Fildset label="Linguagem">
            <>
              <InputRadio
                label="etqNenhuma"
                name="tipoLinguagem"
                value={0}
                register={register}
                disabled={false}
                checked={tipoLinguagem === 0}
                onChange={(e: SyntheticEvent<HTMLInputElement>) =>
                  setTipoLinguagem(Number(e.currentTarget.value))
                }
                isError={!!errors.tipoLinguagem}
              />
              <InputRadio
                label="etqPPlb"
                name="tipoLinguagem"
                value={2}
                register={register}
                disabled={false}
                checked={tipoLinguagem === 2}
                onChange={(e: SyntheticEvent<HTMLInputElement>) =>
                  setTipoLinguagem(Number(e.currentTarget.value))
                }
                isError={!!errors.tipoLinguagem}
              />
              <InputRadio
                label="etqEpl2"
                name="tipoLinguagem"
                value={4}
                register={register}
                disabled={false}
                checked={tipoLinguagem === 4}
                onChange={(e: SyntheticEvent<HTMLInputElement>) =>
                  setTipoLinguagem(Number(e.currentTarget.value))
                }
                isError={!!errors.tipoLinguagem}
              />
              <InputRadio
                label="etqPpla"
                name="tipoLinguagem"
                value={1}
                register={register}
                disabled={false}
                checked={tipoLinguagem === 1}
                onChange={(e: SyntheticEvent<HTMLInputElement>) =>
                  setTipoLinguagem(Number(e.currentTarget.value))
                }
                isError={!!errors.tipoLinguagem}
              />
              <InputRadio
                label="etqZPLII"
                name="tipoLinguagem"
                value={3}
                register={register}
                disabled={false}
                checked={tipoLinguagem === 3}
                onChange={(e: SyntheticEvent<HTMLInputElement>) =>
                  setTipoLinguagem(Number(e.currentTarget.value))
                }
                isError={!!errors.tipoLinguagem}
              />
            </>
          </Fildset>
        </FormDefault>
      )}
    </Container>
  );
};

export default ModeloDeEtiqueta;
