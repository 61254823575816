import React, { ChangeEvent, useCallback, useState } from 'react';
import { DataGrid } from '@material-ui/data-grid';

import InputRadioButton from '~/components/Inputs/InputRadioButton';
import { tiposOrgaoPublico } from '../../defaultData';
import { Col } from 'react-bootstrap';
import Separator from '~/components/Separator';
import {
  InputMaskTelefone,
  InputMoney,
  InputSelect,
  InputText,
  InputDate,
  InputNumber2,
} from '~/components/NovosInputs';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import ToggleDefault from '~/components/ToggleDefault';
import { CancelButton, ConfirmButton } from '~/components/Buttons';

import limitDefaults from '../../defaultData/limite.json';
import { useClienteContext } from '../../ClienteContext';
import { GoPencil } from 'react-icons/go';
import { MdDeleteForever } from 'react-icons/md';
import { formatCurrencyAsText } from '~/utils/functions';
import { ButtonRow } from '../../styles';
import { deleteItens } from '~/utils/masterDetail';

export const AbaCreditoProfissional: React.FC = () => {
  const [initStatusPdv, setInitStatusPdv] = useState<boolean>(false);
  const [updateLimite, setUpdateLimite] = useState<boolean>(false);

  const {
    tipoPessoa,
    convenios,
    conveniosAtivos,
    finalizadoras,
    statusDoPdv,
    orgaoPublico,
    tipoOrgaoPublico,
    setTipoOrgaoPublico,
    empresaConvenio,
    microEmpresa,
    setMicroEmpresa,
    produtorRural,
    setProdutorRural,
    setLimiteAEditar,
    limitesDeCredito,
    initFinalizadora,
    setInitFinalizadora,
    register,
    control,
    setValue,
    formState: { errors },
    limiteRegister,
    limiteControl,
    resetLimite,
    setValueLimite,
    formStateLimite,
    handleOrgaoPublico,
    handleEmpresaConvenio,
    handleLimite,
    setLimitesDeCredito,
    handleClearlLimite,
    masterDetail,
    setMasterDetail,
  } = useClienteContext();

  const handleRemovelLimite = useCallback(
    async (uuid) => {
      const limiteCredito: any[] = await deleteItens(
        'limite',
        uuid,
        masterDetail,
        setMasterDetail,
      );
      setLimitesDeCredito(limiteCredito);
      handleClearlLimite();
    },
    [handleClearlLimite, masterDetail],
  );

  const colunasLimiteDeCredito = [
    {
      field: 'uuid',
      headerName: 'Id',
      hide: true,
      width: 90,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'des_finalizadora',
      headerName: 'Finalizadora',
      width: 292,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'statusPdv',
      headerName: 'Status PDV',
      width: 250,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'val_limite_view',
      headerName: 'R$ Limite',
      width: 137,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'debito',
      headerName: 'Débito',
      width: 137,
      sortable: false,
      headerClassName: 'super-app-theme--header',
      renderCell(field: any) {
        const { val_limite, saldo } = field.row;
        return (
          <span>
            R$ {formatCurrencyAsText(Number(val_limite) - Number(saldo))}
          </span>
        );
      },
    },
    {
      field: 'saldo',
      headerName: 'Saldo Disponível',
      width: 176,
      sortable: false,
      headerClassName: 'super-app-theme--header',
      renderCell(field: any) {
        const { saldo } = field.row;
        return <span>R$ {formatCurrencyAsText(saldo)}</span>;
      },
    },
    {
      field: '',
      headerName: 'Ações',
      width: 180,
      sortable: false,
      headerClassName: 'super-app-theme--header',
      // disableClickEventBubbling: true,
      renderCell: (params: { row: any }) => {
        const onEdit = () => {
          const { row } = params;
          setValueLimite('finalizadora', {
            label: row.des_finalizadora,
            value: row.cod_finalizadora,
          });
          setValueLimite(
            'limiteDeCredito',
            formatCurrencyAsText(Number(row.val_limite), 2, 2),
          );
          setValueLimite('statusPdv', {
            label: row.statusPdv,
            value: row.cod_status_pdv,
          });
          setLimiteAEditar({
            uuid: row.uuid,
            cod_cliente: row.cod_cliente,
          });
          setUpdateLimite(true);

          const elm = document.getElementById('collapseOne');
          if (elm) {
            elm.removeAttribute('class');
            elm.classList.add('accordion-collapse', 'collapse', 'show');
          }
        };
        const onDelete = () => {
          const { row } = params;
          setUpdateLimite(false);
          handleRemovelLimite(row.uuid);
        };

        return (
          <>
            <ButtonRow type="button" onClick={onEdit}>
              <GoPencil size={20} style={{ color: '#72ab90' }} /> Editar
            </ButtonRow>
            <ButtonRow type="button" onClick={onDelete}>
              <MdDeleteForever size={20} style={{ color: '#e63c3c' }} /> Excluir
            </ButtonRow>
          </>
        );
      },
    },
  ];

  return (
    <div className="row">
      <Col sm={12}>
        <Separator labelText="Especificações" />
      </Col>
      <Col sm={12} md={5} className="d-flex">
        <ToggleDefault
          name="orgaoPublico"
          labelText="Órgão Público?"
          setChecked={orgaoPublico}
          disabled={tipoPessoa === 0}
          onSwitch={() => {
            handleOrgaoPublico();
          }}
        />
        <InputRadioButton
          name="tipoOrgaoPublico"
          value={tipoOrgaoPublico}
          label=""
          options={tiposOrgaoPublico}
          isDisabled={tipoPessoa === 0 || orgaoPublico === false}
          control={control}
          onChange={(val: string | number | undefined) => {
            setTipoOrgaoPublico(Number(val));
            setValue('tipoOrgaoPublico', Number(val));
          }}
        />
      </Col>
      <Col sm={12} md={3} className="d-flex">
        <ToggleDefault
          name="empresaConvenio"
          disabled={tipoPessoa === 0}
          labelText="Emp. Convênio?"
          setChecked={empresaConvenio}
          onSwitch={() => {
            handleEmpresaConvenio();
          }}
        />
        <div
          className="d-flex"
          style={{ marginLeft: '10px', marginTop: '2px' }}
        >
          <InputNumber2
            label="Dia Fechamento"
            maxLength={2}
            max={31}
            min={1}
            control={control}
            placeholder=""
            name="diaFechamento"
            register={register}
            disabled={tipoPessoa === 0 || empresaConvenio === false}
            isError={empresaConvenio && !!errors.diaFechamento}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              if (Number(event.target.value) <= 31) {
                setValue('diaFechamento', Math.abs(Number(event.target.value)));
              }
            }}
          />
        </div>
      </Col>
      <Col sm={6} md={2}>
        <ToggleDefault
          disabled={tipoPessoa === 0}
          labelText="Micro Empresa?"
          setChecked={microEmpresa}
          onSwitch={() => {
            setMicroEmpresa(!microEmpresa);
            setValue('microEmpresa', !microEmpresa);
          }}
        />
      </Col>
      <Col sm={6} md={2}>
        <ToggleDefault
          labelText="Produtor Rural?"
          setChecked={produtorRural}
          onSwitch={() => {
            setProdutorRural(!produtorRural);
            setValue('produtorRural', !produtorRural);
          }}
        />
      </Col>
      <Col sm={12}>
        <Separator labelText="Profissional" />
      </Col>
      <Col sm={12}>
        <InputSelect
          label="Conveniado"
          placeholder="Nenhum"
          name="conveniado"
          register={register}
          isError={!!errors.conveniado}
          control={control}
          options={conveniosAtivos}
          changeSelected={(selected) => {
            setValue('conveniado', selected);
          }}
        />
      </Col>
      <Col sm={12} md={5}>
        <InputText
          label="Empresa (Trabalho)"
          maxLength={50}
          placeholder="Informe a Empresa"
          name="desEmpresaTrabalho"
          toLowerCase={false}
          register={register}
          disabled={false}
          isError={!!errors.desEmpresaTrabalho}
        />
      </Col>
      <Col sm={12} md={2}>
        <InputMaskTelefone
          label="Telefone"
          name="telefoneTrabalho"
          isError={!!errors.telefoneTrabalho}
          control={control}
        />
      </Col>
      <Col sm={12} md={3}>
        <InputText
          label="Cargo"
          maxLength={50}
          placeholder="Informe o Cargo"
          name="cargo"
          toLowerCase={false}
          register={register}
          disabled={false}
          isError={!!errors.cargo}
        />
      </Col>
      <Col sm={12} md={2}>
        <InputDate
          label="Admissão"
          placeholder=""
          name="admissao"
          register={register}
          isError={!!errors.admissao}
          control={control}
        />
      </Col>
      <Col sm={12}>
        <Separator labelText="Limite de Crédito" childrenWidth="170px">
          <button
            type="button"
            className="btnLimiteCredito"
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            aria-expanded="false"
            aria-controls="collapseOne"
            style={{
              marginTop: '-7px',
              backgroundColor: '#F4F5F7',
            }}
          >
            <AiOutlinePlusCircle
              size={16}
              style={{
                marginTop: '-2px',
                color: 'green',
                marginRight: '3px',
              }}
            />
            Adicionar Novo Limite
          </button>
        </Separator>
      </Col>
      {/* inicio accordion */}
      <div
        className="accordion"
        id="accordionExample"
        style={{ marginBottom: '15px' }}
      >
        <div className="accordion-item">
          <div
            id="collapseOne"
            className="accordion-collapse collapse"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body" style={{ padding: '0px' }}>
              <div className="row">
                <Col sm={12} md={4} className="ContainerFinalizadora">
                  <InputSelect
                    label="Finalizadora"
                    placeholder="Selecione"
                    name="finalizadora"
                    register={limiteRegister}
                    isDisabled={updateLimite}
                    isError={
                      initFinalizadora && !!formStateLimite.errors.finalizadora
                    }
                    control={limiteControl}
                    options={finalizadoras}
                    changeSelected={(selected) => {
                      setInitFinalizadora(false);
                      setValueLimite('finalizadora', selected);
                    }}
                  />
                </Col>
                <Col sm={12} md={2}>
                  <InputMoney
                    label="Limite"
                    placeholder="0,00"
                    maxLength={18}
                    min={0.0}
                    name="limiteDeCredito"
                    register={limiteRegister}
                    isError={!!formStateLimite.errors.limiteDeCredito}
                    showIcon
                  />
                </Col>
                <Col sm={12} md={5} className="ContainerStatusPdv">
                  <InputSelect
                    label="Status no PDV"
                    placeholder="Selecione"
                    name="statusPdv"
                    register={limiteRegister}
                    isError={
                      initStatusPdv && !!formStateLimite.errors.statusPdv
                    }
                    control={limiteControl}
                    options={statusDoPdv}
                    changeSelected={(selected) => {
                      setInitStatusPdv(false);
                      setValueLimite('statusPdv', selected);
                    }}
                  />
                </Col>
                <Col sm={12} md={3} className="ContainerLimiteCredito">
                  <div className="actButtonsCredito">
                    <ConfirmButton
                      onClick={() => {
                        setInitFinalizadora(true);
                        setUpdateLimite(false);
                        setInitStatusPdv(true);
                        handleLimite();
                      }}
                    >
                      Adicionar
                    </ConfirmButton>
                    <CancelButton
                      onClick={() => {
                        setLimiteAEditar(undefined);
                        setUpdateLimite(false);
                        resetLimite(limitDefaults);
                      }}
                    >
                      Cancelar
                    </CancelButton>
                  </div>
                </Col>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Fim accordion */}
      <div
        className="col-sm-12 col-md-12 dataTable"
        style={{
          width: '100%',
        }}
      >
        <Col
          style={{
            display: 'flex',
            height: '100%',
            width: '100%',
          }}
        >
          <div style={{ flexGrow: 1 }}>
            <DataGrid
              className="myDataGrid"
              rows={limitesDeCredito}
              columns={colunasLimiteDeCredito}
              pageSize={4}
              autoHeight
              columnBuffer={8}
              disableColumnFilter
              disableColumnSelector
              disableColumnMenu
              disableSelectionOnClick
              localeText={{
                noRowsLabel: 'Nenhum registro encontrado',
                columnMenuLabel: 'Menu',
                columnMenuFilter: 'Filtrar',
                columnMenuHideColumn: 'Esconder',
                columnMenuUnsort: 'Não ordenar',
                columnMenuSortAsc: 'Ordernar ASC',
                columnMenuSortDesc: 'Ordernar DESC',
                columnMenuShowColumns: 'Mostrar colunas',
              }}
            />
          </div>
        </Col>
      </div>
    </div>
  );
};
