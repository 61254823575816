import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  .isDisabled {
    cursor: no-drop;
  }
`;
interface inValid {
  IsInvalid: boolean;
}

export const InputLojas = styled.div<inValid>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  border: 2px solid #dfe1e6;
  border-radius: 3px;
  .ButtonLoja {
    min-width: 300px;
    background-color: #8850bf;
    color: #fff;
    border-radius: 5px;
    padding: 4px 25px 5px 30px;
    cursor: pointer;
    transition: 0.3s;
  }
  .form-check-input:focus {
    border-color: transparent !important;
    box-shadow: transparent !important;
  }
  .form-check-input:checked {
    background-color: #8850bf; //57069e
    border-color: transparent !important;
    transition: 0.3s;
  }
  ${(props) => props.IsInvalid && 'border-bottom: 2px solid #cf1919'}
`;

export const BootstrapModal = styled(Modal)`
  .custom-modal {
    max-width: 68%;
    margin: auto;
    margin-top: 20px;
    border-radius: 10px;
    background-color: #f7f7f7;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

    @media (max-width: 1280px) {
      max-width: 98%;
    }
  }

  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 16px 24px;
    border-bottom: 1px solid #dcdcdc;

    .modal-title {
      font-size: 18px;
      font-weight: bold;
      color: #333;
    }

    button.close {
      font-size: 1.5rem;
      color: #333;
      opacity: 0.6;

      &:hover {
        opacity: 1;
      }
    }
  }

  .modal-body {
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .form-group {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      label {
        font-size: 14px;
        color: #333;
      }

      input[type='text'] {
        width: 100%;
        padding: 8px;
        border: 1px solid #dcdcdc;
        border-radius: 4px;
        font-size: 14px;
        color: #333;

        &:focus {
          border-color: #8850bf;
          outline: none;
        }
      }
    }

    .checkbox-group {
      display: flex;
      gap: 12px;

      .form-check {
        display: flex;
        align-items: center;
        gap: 6px;

        input[type='checkbox'] {
          accent-color: #8850bf; /* Cor da checkbox */
          transform: scale(1.2);
        }

        label {
          font-size: 14px;
          color: #333;
        }
      }
    }

    .action-buttons {
      display: flex;
      justify-content: flex-end;
      gap: 12px;

      button {
        padding: 8px 16px;
        font-size: 14px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &.btn-confirm {
          background-color: #8850bf;
          color: #fff;

          &:hover {
            background-color: #57069e;
          }
        }

        &.btn-cancel {
          background-color: #dcdcdc;
          color: #333;

          &:hover {
            background-color: #bfbfbf;
          }
        }
      }
    }
  }
`;

export const ButtonSeparator = styled.button`
  border: none;
  background: none;
  color: #8850bf;
`;

export const ContentSeparator = styled.div`
  display: flex;
  text-align: center;
  justify-content: flex-end;
  color: #8850bf;
  gap: 10px;
`;
export const ColumnSeparator = styled.div`
  color: rgba(224, 224, 224, 1);
  right: -12px;
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  min-height: 56px;
  opacity: 1;
  top: 0;

  .MuiSvgIcon-root {
    width: 1.1em;
  }
`;

export const ClonarButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: #8850bf;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1px;
  transition: all 0.3s ease;
  widht: 120px;
  height: 40px;
  &:hover {
    background-color: #57069e;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }

  &:disabled {
    background-color: #d3d3d3;
    color: #a1a1a1;
    cursor: not-allowed;
  }
`;
