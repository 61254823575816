import { formatCurrencyAsText, transformAsCurrency } from '~/utils/functions';
import { FormData, Critica } from '../protocols';
import { createCritica } from './CreateCritica';

type Totais = {
  totalBcIcms: number;
  totalBcSt: number;
  totalVendaVarejo: number;
  totalNf: number;
  totalIcmsOp: number;
  totalIcmsSt: number;
  totalStFcp: number;
  // totalCreditoIcmsItens: number;
};

export function icmsValidation(formData: FormData): Critica[] {
  const criticas: Critica[] = [];

  const calculaTotaisItens = (): Totais => {
    let totalBcIcms = 0;
    let totalBcSt = 0;
    let totalVendaVarejo = 0;
    let totalNf = 0;
    let totalIcmsOp = 0;
    let totalIcmsSt = 0;
    let totalStFcp = 0;

    formData.itens.map((item) => {
      let { val_bc_st } = item;
      if (Number(val_bc_st) <= 0) {
        val_bc_st = Number(item.val_bc_st_xml) || 0;
      }

      totalBcIcms += item.val_bc_icms || 0;
      totalBcSt += Number(val_bc_st);
      totalVendaVarejo += item.val_venda_varejo * item.qtd_entrada || 0;
      totalNf += item.val_nf || 0;
      totalIcmsOp += item.val_icms || 0;
      totalIcmsSt += item.val_icms_st * item.qtd_entrada || 0;
      totalStFcp += item.val_fcp_st || 0;
      return item;
    });

    totalNf = Math.round(totalNf * 100) / 100;

    return {
      totalBcIcms,
      totalBcSt,
      totalVendaVarejo,
      totalNf,
      totalIcmsOp,
      totalIcmsSt,
      totalStFcp,
    };
  };

  const totaisItens = calculaTotaisItens();

  const validaTotalBCIcms = () => {
    // val_bc_icms
    const valTotBCIcms = formData.val_total_bc || 0;
    const { totalBcIcms } = totaisItens;

    if (
      transformAsCurrency(valTotBCIcms).toFixed(2) !==
      transformAsCurrency(totalBcIcms).toFixed(2)
    ) {
      const critica = createCritica(
        4,
        'Total BC ICMS OP informado difere da BC ICMS OP calculada',
        `
        BC informada: ${formatCurrencyAsText(valTotBCIcms)} \n
        BC calculada: ${formatCurrencyAsText(totalBcIcms)}
        `,
        totalBcIcms,
        'val_total_bc',
      );
      criticas.push(critica);
    }
  };

  const validaTotalIcmsOp = () => {
    const valTotIcmsOp = formData.val_total_icms || 0;
    const { totalIcmsOp } = totaisItens;

    if (
      transformAsCurrency(valTotIcmsOp).toFixed(2) !==
      transformAsCurrency(totalIcmsOp).toFixed(2)
    ) {
      const critica = createCritica(
        4,
        'Total ICMS OP informado difere do ICMS calculado',
        `
        ICMS informado: ${formatCurrencyAsText(valTotIcmsOp)} \n
        ICMS calculado: ${formatCurrencyAsText(totalIcmsOp)}
        `,
        totalIcmsOp,
        'val_total_icms',
      );
      criticas.push(critica);
    }
  };

  const validaTotalBcSt = () => {
    const valTotBCST = formData.val_total_bc_st || 0;
    const { totalBcSt } = totaisItens;

    if (
      transformAsCurrency(valTotBCST).toFixed(2) !==
      transformAsCurrency(totalBcSt).toFixed(2)
    ) {
      const critica = createCritica(
        4,
        'Total BC ST informado difere da BC ST calculada',
        `
        BC ST informada: ${formatCurrencyAsText(valTotBCST)} \n
        BC ST calculada: ${formatCurrencyAsText(totalBcSt)}
        `,
        totalBcSt,
        'val_total_bc_st',
      );
      criticas.push(critica);
    }
  };

  const validaIcmsSt = () => {
    const valTotIcmsSt = formData.val_total_icms_st || 0;
    const { totalIcmsSt } = totaisItens;

    if (
      transformAsCurrency(valTotIcmsSt).toFixed(2) !==
      transformAsCurrency(totalIcmsSt).toFixed(2)
    ) {
      const critica = createCritica(
        4,
        'Total ICMS ST informado difere do calculado',
        `
        ICMS ST informado: ${formatCurrencyAsText(valTotIcmsSt)} \n
        ICMS ST calculado: ${formatCurrencyAsText(totalIcmsSt)}
        `,
        totalIcmsSt,
        'val_total_icms_st',
      );
      criticas.push(critica);
    }
  };

  const validaTotalVendaVarejo = () => {
    const valTotVendaVArejo = formData.val_total_venda_varejo || 0;
    const { totalVendaVarejo } = totaisItens;

    if (valTotVendaVArejo.toFixed(2) !== totalVendaVarejo.toFixed(2)) {
      const critica = createCritica(
        4,
        'Total de venda varejo informado difere do calculado.',
        `
        Venda varejo informado: ${formatCurrencyAsText(valTotVendaVArejo)} \n
        Venda varejo calculado: ${formatCurrencyAsText(totalVendaVarejo)}
        `,
        totalVendaVarejo,
        'val_total_venda_varejo',
      );
      criticas.push(critica);
    }
  };

  const validaTotalFcp = () => {
    const valTotStFcp = formData.val_total_st_fcp || 0;
    const { totalStFcp } = totaisItens;

    if (
      transformAsCurrency(valTotStFcp).toFixed(2) !==
      transformAsCurrency(totalStFcp).toFixed(2)
    ) {
      const critica = createCritica(
        4,
        'Total FCP ST informado difere do calculado.',
        `
        FCP ST informado: ${formatCurrencyAsText(valTotStFcp)} \n
        FCP ST calculado: ${formatCurrencyAsText(totalStFcp)}
        `,
        totalStFcp,
        'val_total_st_fcp',
      );
      criticas.push(critica);
    }
  };

  const validaTotalNf = () => {
    const valTotNf = formData.val_total_nf || 0;
    const { totalNf } = totaisItens;
    if (
      transformAsCurrency(valTotNf).toFixed(2) !==
      transformAsCurrency(totalNf).toFixed(2)
    ) {
      const critica = createCritica(
        4,
        'Total da NF informado difere do calculado.',
        `
        Valor total informado: ${formatCurrencyAsText(valTotNf)} \n
        Valor total calculado: ${formatCurrencyAsText(totalNf)}
        `,
        totalNf,
        'val_total_nf',
      );
      criticas.push(critica);
    }
  };

  validaTotalBCIcms();
  validaTotalIcmsOp();
  validaTotalBcSt();
  validaIcmsSt();
  validaTotalVendaVarejo();
  validaTotalFcp();
  validaTotalNf();

  return criticas;
}
