import React from 'react';

interface SwitchProps {
  isOn: boolean;
  onToggle: (isOn: boolean) => void;
  onColor?: string;
  offColor?: string;
}

export const SwitchPlans: React.FC<SwitchProps> = ({
  isOn,
  onToggle,
  onColor = '#4caf50',
  offColor = '#ccc',
}) => {
  const handleToggle = () => onToggle(!isOn);

  return (
    <div
      onClick={handleToggle}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: isOn ? 'flex-end' : 'flex-start',
        width: '50px',
        height: '25px',
        borderRadius: '25px',
        backgroundColor: isOn ? onColor : offColor,
        cursor: 'pointer',
        padding: '5px',
        transition: 'background-color 0.3s, justify-content 0.3s',
      }}
    >
      <div
        style={{
          width: '20px',
          height: '20px',
          borderRadius: '50%',
          backgroundColor: '#fff',
          boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
          transition: 'transform 0.3s',
        }}
      />
    </div>
  );
};
