import styled from 'styled-components';
import { Button } from '@material-ui/core';



export const CloseButton = styled(Button)`
background-color: transparent;
color: #000;
border: none;
opacity: 0.3;

&:hover,
&:active,
&:focus {
  background-color: transparent;
  color: #504e4e;
  box-shadow: none !important;
}
`;

export const Option = styled.div<{ isSelected: boolean; disabled: boolean }>`
  border: 1px solid ${(props) => props.isSelected ? '#9C60FF' : props.disabled ? '#e0e0e0' : '#e0e0e0'};
  background-color: ${(props) => (props.disabled ? '#f5f5f5' : 'transparent')};
  color: ${(props) => (props.disabled ? '#aaa' : 'inherit')};
  border-radius: 8px;
  width: 180px;
  padding: 16px;
  text-align: center;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: border-color 0.3s;

  &:hover {
    border-color: ${(props) => (props.disabled ? '#e0e0e0' : '#9C60FF')};
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: block;
`;

export const ContainerOptions = styled.div`
display: flex;
justify-content: center;
gap: 16px;
margin-top: 16px;
margin-bottom: 16px;`;
