import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { InputText } from '~/components/NovosInputs';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { GridRowParams } from '@material-ui/data-grid';
import { toast } from 'react-toastify';
import { schema } from './Validations';
import Search from '~/components/Search';
import api from '~/services/api';
import FormDefault from '~/components/FormDefault';
import DefaultLoader from '~/components/DefaultLoader';
import { Container } from './styles';

const Unidades: React.FC = () => {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });
  const [loader, setLoader] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);

  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;
    const { des_medida, des_unidade } = row;

    setValue('des_medida', des_medida);
    setValue('des_unidade', des_unidade);

    setUpdate(true);
    setShowSearch(false);
  };

  const resetFormData = () => {
    reset();
    setValue('des_medida', '');
    setValue('des_unidade', '');
    setUpdate(false);
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      if (isUpdate) {
        setLoader(true);
        const res = await api.put('/unidade', data);
        const { success, message } = res.data;

        if (!success) {
          return toast.warning(message);
        }

        if (success) {
          resetFormData();
          setShowSearch(true);
          return toast.success(message);
        }
      }
      const res = await api.post('/unidade', data);

      const { success, message } = res.data;
      if (success) {
        resetFormData();
        setLoader(false);
        return toast.success(message);
      }
    } finally {
      setLoader(false);
    }
  });

  const handleDelete = async () => {
    const { des_unidade } = getValues();
    const res = await api.delete(`/unidade/${des_unidade}`);
    const { success, message } = res.data;
    if (!success) {
      return toast.warning(message);
    }
    toast.success(message);
    resetFormData();
    setShowSearch(true);
  };

  const onNewData = () => {
    resetFormData();
    setUpdate(false);
    setShowSearch(false);
  };

  if (loader) {
    return <DefaultLoader />;
  }

  return (
    <Container>
      {showSearch && (
        <Search
          codTela={287}
          newData={() => setShowSearch(false)}
          onRowClick={onRowClick}
        />
      )}

      {!showSearch && (
        <FormDefault
          codTela={287}
          title="Cadastro de Unidade"
          codigoRegistro={[
            {
              des_campo: 'Unidade',
              value: watch('des_unidade'),
            },
          ]}
          onSave={async () => {
            await onSubmit();
          }}
          onCancel={() => {
            resetFormData();
            setUpdate(false);
            setShowSearch(true);
          }}
          isUpdate={isUpdate}
          onNew={onNewData}
          onDelete={handleDelete}
          onClearFields={resetFormData}
          onReturnSearch={() => {
            resetFormData();
            setShowSearch(true);
          }}
        >
          <Row>
            <Col sm={12} md={4} className="mt-4">
              <InputText
                label="Unidade: "
                name="des_unidade"
                placeholder="Informe a Descrição"
                maxLength={3}
                disabled={isUpdate}
                register={register}
                control={control}
                caseInput="upper"
                isError={!!errors.des_unidade}
              />
            </Col>
            <Col sm={12} md={8} className="mt-4">
              <InputText
                label="Descrição da Medida: "
                name="des_medida"
                placeholder="Informe a Descrição"
                maxLength={50}
                control={control}
                register={register}
                isError={!!errors.des_medida}
              />
            </Col>
          </Row>
        </FormDefault>
      )}
    </Container>
  );
};
export default Unidades;
