import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;

  .table-actions-buttons {
    margin-top: 32px;
    float: right;

    button {
      padding: 6px 16px;
      margin-left: 8px;
      border-radius: 6px;
    }
  }
  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .subtotal {
    margin-bottom: 0;
    background-color: #b3f7b3;
    display: block;
    padding: 7px;
    font-size: 1.15rem;
    margin-right: 1rem;
    border-radius: 4px;
  }
  .total-itens {
    font-size: 1.15rem;
  }
  .field-error {
    border-radius: 4px;
    border-bottom: 2px solid #cf1919;
    transition: 0.5s;
  }
`;

export const ButtonForm = styled.button`
  padding: 3px 12px;
  height: 40px;
  border-radius: 4px;
  border: none;
  width: 160px;
  background-color: #28a745;
  color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
  line-height: 16px;

  svg {
    font-size: 20px;
    margin-right: 10px;
  }

  margin-right: 8px;
  margin-left: 8px;

  animation: all 0.25s ease-in;

  &.reprocess {
    background: #9900ff;
  }

  &.imprimir {
    background: #07289e;
  }
  &.transmitir {
    background: #28a745;
  }

  &.disabled {
    cursor: not-allowed;
    pointer-events: all !important;
  }

  &.disabled:hover {
    opacity: 1;
  }

  &:hover {
    opacity: 0.7;
  }

  @media (max-width: 992px) {
    width: 100% !important;
  }
`;


