import styled from 'styled-components';

export const DadosConcluirContainer = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  .content-boleto {
    display: flex;
    flex-direction: column;
    border: 0.0625rem solid rgb(209, 209, 209);
    margin-top: 0.9375rem;
    padding: 2.5rem;
    width: 60%;
    border-radius: 0.5rem;

    .copiar-codigo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #f9f9f9;
      transition: background 0.3s;
      padding: 8px;
      margin-top: 10px;
      border-radius: 6px;
      border: 1px solid #ececec;

      svg {
        cursor: pointer;
      }

      .codigo-boleto {
        display: flex;
        flex-direction: column;
        user-select: none;
      }

      &:hover {
        background-color: #ececec;
        border: 1px solid #f9f9f9;
      }
    }

    .info-uteis {
      display: flex;
      flex-direction: column;
      margin-top: 15px;
      gap: 10px;

      .info {
        display: flex;
        gap: 10px;
        align-items: center;

        span {
          background-color: #262548;
          border-radius: 50%;
          color: white;
          height: 25px;
          width: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
        }

        p {
          flex: 1;
          margin: 0;
        }
      }
    }

    .buttons-boleto {
      display: flex;
      margin-top: 0.9375rem;
      align-items: center;
      justify-content: center;
      gap: 1.25rem;

      button {
        padding: 10px;
        border: none;
        color: #1d519f;
        border-radius: 8px;
        font-size: 1.0313rem;
        line-height: 1.6094rem;

        &:hover {
          opacity: 90%;
        }
      }
      .print-boleto {
        background-color: #1d519f;
        color: white;
      }
    }
  }
`;
