import { Boleto } from '../components/Boleto';
import { CreditCard } from '../components/CreditCard';
import { ComponentPaymentProps, MethodFaturamento } from '../types/context';

interface PaymentMethodsProps {
  id: MethodFaturamento;
  label: string;
  Component: React.FC<ComponentPaymentProps>;
}

export const paymentMethods: PaymentMethodsProps[] = [
  { id: 'cartao-credito', label: 'Cartão de Crédito', Component: CreditCard },
  { id: 'boleto', label: 'Boleto', Component: Boleto },
];
