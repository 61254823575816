import React, { useCallback, useEffect, useState } from 'react';
import { GridRowParams } from '@material-ui/data-grid';
import Spinner from '@atlaskit/spinner';
import { toast } from 'react-toastify';
import Search from '~/components/Search';
import api from '~/services/api';
import useAuth from '~/hooks/useAuth';
import { Container } from './styles';
import { IStatus } from './types';
import FormDefault from '~/components/FormDefault';
import InputNumber from '~/components/Inputs/InputNumber';
import InputSelect from '~/components/Inputs/InputSelect';
import InputText from '~/components/Inputs/InputText';
import ToggleDefault from '~/components/ToggleDefault';

const options = [
  { value: 1, label: 'Aceitar normalmente' },
  { value: 2, label: 'Aceitar somente com autorização do supervisor' },
  { value: 3, label: 'Não pode ser aceito' },
];

const options_finalizadora = [
  { value: 1, label: 'Todos' },
  { value: 2, label: 'Cheque' },
  { value: 3, label: 'Convênio' },
];

const CadastroStatusPdv: React.FC = () => {
  const user = useAuth();
  const [loader, setLoader] = useState<boolean>(false);
  const [iniInicializado, setInitInicializado] = useState(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);
  const [formData, setFormData] = useState<IStatus>({
    cod_status: { value: '', isInvalid: false, isRequired: false },
    des_status: { value: '', isInvalid: true, isRequired: true },
    val_status: { value: 1, isInvalid: true, isRequired: true },
    negativar_status: { value: false, isInvalid: true, isRequired: true },
    indicador_status: {
      value: options[0],
      isInvalid: true,
      isRequired: false,
    },
    tipo_status: {
      value: options_finalizadora[0],
      isInvalid: true,
      isRequired: false,
    },
  });
  const resetFormData = useCallback(() => {
    setFormData({
      ...formData,
      des_status: { value: '', isInvalid: true, isRequired: true },
      val_status: { value: 1, isInvalid: false, isRequired: true },
      negativar_status: { value: false, isInvalid: true, isRequired: true },
      indicador_status: {
        value: options[0],
        isInvalid: true,
        isRequired: false,
      },
      tipo_status: {
        value: options_finalizadora[0],
        isInvalid: true,
        isRequired: false,
      },
    });
    setInitInicializado(false);
  }, [formData]);
  useEffect(() => {
    if (iniInicializado) setInitInicializado(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);
  const handleChangeAtivo = () => {
    setFormData({
      ...formData,
      negativar_status: {
        ...formData.negativar_status,
        value: !formData.negativar_status.value,
        isInvalid: false,
        isRequired: false,
      },
    });
  };
  const handleChangeDescricao = useCallback(
    (val: string, isInvalid: boolean) => {
      setFormData({
        ...formData,
        des_status: {
          ...formData.des_status,
          value: val.trimStart().toUpperCase(),
          isInvalid,
        },
      });
    },
    [formData],
  );
  const handleChangeStatusPdv = useCallback(
    (val: number, isInvalid: boolean) => {
      if (Number.isNaN(val)) {
        setFormData({
          ...formData,
          val_status: {
            ...formData.val_status,
            isInvalid: true,
          },
        });
      }
      setFormData({
        ...formData,
        val_status: {
          ...formData.val_status,
          value: val,
          isInvalid: false,
        },
      });
    },
    [formData],
  );
  const validaInputsParaSubmit = useCallback(
    (inputs: IStatus): boolean => {
      for (let i = 0; i < Object.keys(inputs).length; i++) {
        const input = formData[Object.keys(inputs)[i] as keyof IStatus];
        if (input.isRequired) {
          if (
            input.value === undefined ||
            input.value === '' ||
            Number.isNaN(input.value)
          ) {
            formData[Object.keys(inputs)[i] as keyof IStatus].isInvalid = true;
            return true;
          }
          if (input.value !== '' && typeof input.value === 'string') {
            formData[Object.keys(inputs)[i] as keyof IStatus].isInvalid = false;
          }
          if (Number(input.value) >= 0 && typeof input.value === 'number') {
            formData[Object.keys(inputs)[i] as keyof IStatus].isInvalid = false;
          }
        }
      }
      return false;
    },
    [formData],
  );
  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;
    const {
      cod_status_pdv,
      des_status_pdv,
      status_pdv,
      flg_negativar,
      tipo_condicao,
      tipo_finalizadora,
    } = row;
    setFormData({
      ...formData,
      cod_status: {
        value: cod_status_pdv,
        isInvalid: false,
        isRequired: true,
      },
      des_status: {
        value: des_status_pdv,
        isInvalid: false,
        isRequired: true,
      },
      val_status: {
        value: status_pdv,
        isInvalid: false,
        isRequired: true,
      },
      negativar_status: {
        value: flg_negativar,
        isInvalid: false,
        isRequired: true,
      },
      indicador_status: {
        value: options.filter((item) => item.value === tipo_condicao)[0],
        isInvalid: false,
        isRequired: true,
      },
      tipo_status: {
        value: options_finalizadora.filter(
          (item) => item.value === tipo_finalizadora,
        )[0],
        isInvalid: false,
        isRequired: true,
      },
    });
    setUpdate(true);
    setLoader(false);
    setShowSearch(false);
  };
  const handleSubmit = useCallback(async () => {
    if (validaInputsParaSubmit(formData)) {
      setInitInicializado(true);
      toast.warning('Existem informações faltando');
      return;
    }
    try {
      if (isUpdate) {
        const { data } = await api.put(
          `/status-pdv/${formData?.cod_status.value}`,
          {
            des_status: formData.des_status.value,
            val_status: formData.val_status.value,
            negativar_status: formData.negativar_status.value,
            indicador_status: formData.indicador_status.value,
            tipo_status: formData.tipo_status.value,
            cod_usuario_alteracao: user.user && user.user.id,
          },
        );
        toast.success(data.message);
        resetFormData();
        setShowSearch(true);
        return;
      }
      const { data } = await api.post('/status-pdv', {
        des_status: formData.des_status.value,
        val_status: formData.val_status.value,
        negativar_status: formData.negativar_status.value,
        indicador_status: formData.indicador_status.value,
        tipo_status: formData.tipo_status.value,
        cod_usuario_cadastro: user.user && user.user.id,
      });
      toast.success(data.message);
      resetFormData();
      setShowSearch(false);
    } finally {
      setLoader(false);
    }
  }, [formData, isUpdate, resetFormData, validaInputsParaSubmit]);
  const handleDelete = async () => {
    const res = await api.delete(`/status-pdv/${formData?.cod_status.value}`);
    const { success, message } = res.data;
    if (!success) throw new Error(message);
    resetFormData();
    setShowSearch(true);
    toast.success(message);
  };
  const onNew = () => {
    resetFormData();
    setUpdate(false);
  };
  const handleIndicadorStatus = (
    val: { value: number; label: string },
    isInvalid: boolean,
  ) => {
    setFormData({
      ...formData,
      indicador_status: {
        ...formData.indicador_status,
        value: val,
        isInvalid,
      },
    });
  };
  const handleTipoStatus = (
    val: { value: number; label: string },
    isInvalid: boolean,
  ) => {
    setFormData({
      ...formData,
      tipo_status: {
        ...formData.tipo_status,
        value: val,
        isInvalid,
      },
    });
  };
  if (loader) {
    return (
      <Container>
        <div className="w-100 h-100 d-flex justify-conten-center align-items-center">
          <Spinner />
        </div>
      </Container>
    );
  }
  return (
    <Container>
      {showSearch && (
        <Search
          newData={() => setShowSearch(false)}
          onRowClick={onRowClick}
          codTela={82}
        />
      )}
      {!showSearch && (
        <FormDefault
          codTela={82}
          title="Cadastro de Status PDV"
          codigoRegistro={[
            {
              value: formData.val_status.value,
              des_campo: 'Status',
            },
          ]}
          onSave={async () => {
            await handleSubmit();
          }}
          onCancel={() => {
            resetFormData();
            setShowSearch(true);
          }}
          isUpdate={isUpdate}
          onNew={() => onNew()}
          onDelete={handleDelete}
          onClearFields={() => resetFormData()}
          onReturnSearch={() => {
            setShowSearch(true);
            setUpdate(false);
            resetFormData();
          }}
          showSwitch={false}
        >
          <div className="row">
            <div className="col-sm-12 col-md-10">
              <InputText
                label="Descrição"
                value={formData.des_status.value}
                maxLength={50}
                placeholder="Informe a Descrição"
                isEmpty
                isNull
                isUndefined
                isRequired={formData.des_status.isRequired}
                setInvalid={formData.des_status.isInvalid}
                iniInicializado={iniInicializado}
                onChange={handleChangeDescricao}
              />
            </div>
            <div className="col-sm-12 col-md-2">
              <InputNumber
                label="Status PDV"
                min={0}
                max={99}
                isRequired={formData.val_status.isRequired}
                setInvalid={formData.val_status.isInvalid}
                placeholder=""
                isNull
                isUndefined
                value={formData.val_status.value}
                iniInicializado={iniInicializado}
                onChange={handleChangeStatusPdv}
              />
            </div>
            <div className="col-sm-12 col-md-2 mt-2">
              <ToggleDefault
                size="large"
                labelText="Negativar Cliente?"
                setChecked={formData.negativar_status.value}
                onSwitch={() => handleChangeAtivo()}
              />
            </div>
            <div className="col-sm-12 col-md-4 mt-1">
              <InputSelect
                options={options}
                label="Aceitar somente com autorização do supervisor"
                value={formData.indicador_status.value}
                isRequired={formData.indicador_status.isRequired}
                setInvalid={formData.indicador_status.isInvalid}
                iniInicializado={iniInicializado}
                placeholder="Informe a Condição"
                onChange={handleIndicadorStatus}
              />
            </div>
            <div className="col-sm-12 col-md-2 mt-1">
              <InputSelect
                options={options_finalizadora}
                label="Tipo de Finalizadora"
                value={formData.tipo_status.value}
                isRequired={formData.tipo_status.isRequired}
                setInvalid={formData.tipo_status.isInvalid}
                iniInicializado={iniInicializado}
                placeholder="Informe o Tipo de Finalizadora"
                onChange={handleTipoStatus}
              />
            </div>
          </div>
        </FormDefault>
      )}
    </Container>
  );
};

export default CadastroStatusPdv;
