import styled from 'styled-components';

export const ContainerAssineJa = styled.div`
  background-color: #f7f7f8;
  color: #5b17a2;
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  margin: 0;
  z-index: 0;
  width: 100%;
  border-bottom: 0.1px solid #5b17a2;
`;

export const SpanMessage = styled.span`
  font-size: 15px;
  font-weight: 500;
`;

export const ButtonAssineJa = styled.button`
  background-color: #f7f7f8;
  color: #5b17a2;
  padding: 6px 8px;
  border: 0.2px solid #5b17a2;
  border-radius: 3px;
  margin-left: 10px;
  font-size: 14px;
  cursor: pointer;

  &: hover {
    background-color: rgb(110, 31, 196);
    color: white;
  }
`;
