import * as yup from 'yup';

export const schema = yup
  .object()
  .shape({
    dfe: yup.string().notRequired(),
    serie: yup.string().test({
      name: 'Valida dfe',
      test: (value: any, context) => {
        const { parent } = context;
        if (parent.dfe === 'nfe' && (!parent.serie || value === ''))
          return false;

        if (parent.dfe === 'nfce') {
          if (!parent.num_pedido || parent.num_pedido === '') {
            if (!value || value === '') return false;
          }
          return true;
        }

        return true;
      },
    }),
    cod_pessoa: yup.object().when('dfe', {
      is: (value: any) => {
        if (value !== 'nfce') return true;
        return false;
      },
      then: yup
        .object()
        .shape({
          label: yup.string(),
          value: yup.number(),
        })
        .required(),
      otherwise: yup.object().notRequired().nullable(true),
    }),
    num_nf: yup.string().test({
      name: 'Valida dfe',
      test: (value: any, context) => {
        const { parent } = context;

        if (
          parent.dfe === 'nfe' &&
          (!parent.num_nf || value === '' || value <= 0)
        )
          return false;
        if (parent.dfe === 'nfce') {
          if (!parent.num_pedido || parent.num_pedido === '') {
            if (!value || value === '' || value === '0') return false;
          }
          return true;
        }
        return true;
      },
    }),
  })
  .required();

export const motivoSchema = yup.object().shape({
  motivo: yup.string().min(15).required(),
});
