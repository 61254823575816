import { Box } from '@material-ui/core';
import { DataGrid, GridColDef, GridColumns } from '@material-ui/data-grid';
import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { usePdvOnline } from '~/pages/PdvOnline/hooks/usePdvOnline';
import { moneyFormat } from '~/utils/functions';

export const GridPedidoPendente: React.FC<{ pedidoGrid: any[] }> = ({
  pedidoGrid,
}) => {
  const [selectedRowId, setSelectedRowId] = useState<number | null>(null);

  const {
    pedidosPendentes,
    handleShowTablePedidosPendentes,
    handleItensPedidosPendentes,
    handlePedidoPendenteSelected,
  } = usePdvOnline();

  const createColumn = useCallback(
    (
      field: string,
      headerName: string,
      options: Partial<GridColDef> = {},
    ): GridColDef => ({
      field,
      headerName,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      ...options,
    }),
    [],
  );

  const handleRowClick = (params: any) => {
    setSelectedRowId(params.id);

    const { num_pedido, cod_loja } = params.row;

    const pedidoPendente = pedidosPendentes.find(
      (p) =>
        Number(p.num_pedido) === Number(num_pedido) &&
        Number(p.cod_loja) === Number(cod_loja),
    );

    if (pedidoPendente) {
      handleShowTablePedidosPendentes(true, true);

      const pedidoItem = pedidoPendente.des_json_pedido_item.map((item) => ({
        id: item.cod_seq_pedido_item,
        cod_gtin: item.cod_gtin,
        des_produto: item.des_produto,
        des_unidade: item.des_unidade,
        qtd_pedido: item.qtd_pedido,
        val_pedido: moneyFormat(item.val_pedido.toFixed(2)),
        val_total: moneyFormat(item.val_total.toFixed(2)),
        val_custo_rep: moneyFormat(item.val_custo_rep.toFixed(2)),
        qtd_embalagem: item.qtd_embalagem,
      }));

      handleItensPedidosPendentes(pedidoItem);
      handlePedidoPendenteSelected(pedidoPendente);
    } else {
      toast.warning(`Nenhum item encontrado para o pedido: ${num_pedido}`);
    }
  };

  const renderActionCell = (params: { row: any }) => {
    return (
      <input type="radio" checked={params.row.id === selectedRowId} readOnly />
    );
  };

  const columns: GridColumns = [
    createColumn('id', 'Id', {
      hide: true,
      width: 100,
      disableColumnMenu: true,
    }),
    createColumn('', 'Sel?', {
      cellClassName: 'super-app-theme--cell',
      headerClassName: 'super-app-theme--header',
      disableColumnMenu: true,
      width: 70,
      renderCell: renderActionCell,
    }),
    createColumn('num_pedido', 'Núm. Pedido', {
      width: 115,
      disableColumnMenu: true,
      headerAlign: 'left',
      align: 'left',
    }),
    createColumn('qtd_pedido_item', 'Qtd Itens', {
      width: 95,
      disableColumnMenu: true,
      headerAlign: 'left',
      align: 'left',
    }),
    createColumn('nome_pessoa', 'Parceiro', {
      width: 250,
      disableColumnMenu: true,
      headerAlign: 'left',
      align: 'left',
    }),
    createColumn('val_pedido', 'Valor', {
      width: 100,
      disableColumnMenu: true,
      headerAlign: 'right',
      align: 'right',
    }),
    createColumn('dta_emissao', 'Emissão', {
      width: 100,
      disableColumnMenu: true,
    }),
  ];

  return (
    <Box sx={{ height: 230, width: '100%' }}>
      <DataGrid
        rows={pedidoGrid}
        columns={columns}
        disableColumnFilter
        disableColumnSelector
        disableSelectionOnClick
        disableColumnMenu
        hideFooterPagination
        hideFooterRowCount
        hideFooterSelectedRowCount
        hideFooter
        onRowClick={handleRowClick}
        getRowClassName={(params) =>
          params.id === selectedRowId ? 'selected-row' : ''
        }
        localeText={{
          noRowsLabel: 'Nenhum pedido encontrado',
          columnMenuLabel: 'Menu',
          columnMenuFilter: 'Filtrar',
          columnMenuHideColumn: 'Esconder',
          columnMenuUnsort: 'Não ordenar',
          columnMenuSortAsc: 'Ordernar ASC',
          columnMenuSortDesc: 'Ordernar DESC',
          columnMenuShowColumns: 'Mostrar colunas',
        }}
      />
      <style>{`
        .selected-row {
          background-color: #B3DAB6 !important;
        }
      `}</style>
    </Box>
  );
};
