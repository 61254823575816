/* eslint-disable no-console */
import React, { HTMLProps, useCallback } from 'react';
import { Form } from 'react-bootstrap';
import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import { InputContainer } from '../styles';

interface InputProps extends HTMLProps<HTMLInputElement> {
  label?: string;
  placeholder?: string;
  isDisabled?: boolean;
  name: string;
  control: Control<FieldValues, any>;
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  isError: boolean;
}

export const InputCpfCnpj: React.FC<InputProps> = ({
  label = '',
  placeholder = '',
  isDisabled = false,
  name,
  control,
  register,
  setValue,
  isError,
  ...rest
}) => {
  const formatCpfCnpj = useCallback((value: string) => {
    const cleanedValue = (value || '').replace(/\D/g, '');
    const isCpf = cleanedValue.length <= 11;

    let formattedValue = '';

    if (isCpf) {
      formattedValue = cleanedValue.replace(
        /(\d{3})(\d{3})(\d{3})(\d{2})/,
        (_, p1, p2, p3, p4) => `${p1}.${p2}.${p3}-${p4}`,
      );
    } else {
      formattedValue = cleanedValue.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        (_, p1, p2, p3, p4, p5) => `${p1}.${p2}.${p3}/${p4}-${p5}`,
      );
    }

    return formattedValue.substring(0, isCpf ? 14 : 18);
  }, []);

  return (
    <InputContainer>
      <Form.Group>
        {label.length > 0 && <Form.Label htmlFor={name}>{label}</Form.Label>}
        <Controller
          name={name}
          control={control}
          defaultValue=""
          render={({ field: { onChange, onBlur, value } }) => (
            <input
              {...register(name)}
              id={name}
              name={name}
              value={formatCpfCnpj(value || '')}
              onBlur={onBlur}
              onChange={(e) => {
                const formattedValue = formatCpfCnpj(e.target.value);
                setValue(name, formattedValue);
                onChange(e);
              }}
              autoComplete="off"
              minLength={14}
              maxLength={18}
              type="text"
              disabled={isDisabled}
              placeholder={placeholder || '999.999.999-99'}
              className={isError ? 'form-control is-invalid' : 'form-control'}
              {...rest}
            />
          )}
        />
      </Form.Group>
    </InputContainer>
  );
};
