import React, { useCallback, useEffect, useRef } from 'react';
import { Spinner } from 'react-bootstrap';
import { ListProps } from '../protocols';

import { Content, Option } from '../styles';
import { nanoid } from 'nanoid';

export const Lista: React.FC<ListProps> = ({
  inputRef,
  setIsOpen,
  setFilter,
  hasNextPage,
  fetchNextPage,
  listWidth,
  data,
  isFetching,
  changeSelected,
  messageForDependsOf,
  queryName,
  queryClient,
  selectedRef,
  useNanoIdForKey,
}) => {
  /**
   * wrapperRef
   * Ref da lista de opções utilizado para saber quando o final da lista foi atingido
   */
  const wrapperRef = useRef(null);

  /**
   * useCloseOptions
   * Fecha lista de opções ao clicar fora
   */
  function useCloseOptions(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (inputRef) inputRef.current.value = '';
          setFilter('');
          queryClient.removeQueries(`input_select_${queryName}`);
          setIsOpen(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }
  useCloseOptions(wrapperRef);

  /**
   * onScroll
   * Verifica se o scroll chegou ao final da lista de opções
   */
  const onScroll = async () => {
    if (wrapperRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = wrapperRef.current;
      const zoomLevel = Math.round(window.devicePixelRatio * 100);
      const offset = Math.abs(100 - zoomLevel); // essa é a margem que mencionei
      if (scrollTop + clientHeight >= scrollHeight - offset) {
        if (hasNextPage) {
          await fetchNextPage();
        }
      }
    }
  };

  /**
   * handleClick
   * Seleciona item da lista
   */
  const handleClick = useCallback(
    (option: { label: string; value: number }) => {
      selectedRef.current = option;
      changeSelected(option);
      setIsOpen(false);
    },
    [changeSelected, selectedRef, setIsOpen],
  );

  /**
   * renderOptions
   * Renderiza lista de opções
   */
  const renderOptions = useCallback(() => {
    /**
     * Caso a variavel data não seja undefined
     */
    if (data !== undefined) {
      /**
       * Caso existam dados de acordo com a busca efetuada
       * os mesmos serão renderizados na lista de opções.
       * Caso contrario entrarão na condicional abaixo exebindo
       * "Nenhum registro encontrado."
       */
      if (!data.pages[0].message) {
        return (
          <Option className="not-found">
            Digite ao menos 3 caracteres para pesquisar.
          </Option>
        );
      }
      if (data.pages[0].message === 'dependencies have not been met.') {
        return (
          <Option className="not-found">
            {messageForDependsOf !== undefined
              ? messageForDependsOf
              : 'É necessário preencher outros campos do formulário para pesquisar.'}
          </Option>
        );
      }
      if (data.pages[0].message === 'url not found') {
        return (
          <Option className="not-found">Nenhum registro encontrado.</Option>
        );
      }
      if (data.pages.length <= 0) {
        return (
          <Option className="not-found">Nenhum registro encontrado.</Option>
        );
      }
      if (
        data.pages[0].message === 'Registros encontrados' &&
        data.pages[0].data.length <= 0
      ) {
        return (
          <Option className="not-found">Nenhum registro encontrado.</Option>
        );
      }
      return data.pages.map((page) => {
        return page.data.map((item: any) => {
          return (
            <Option
              key={useNanoIdForKey ? nanoid() : item.value}
              className={
                selectedRef.current.value === item.value ? 'selected' : ''
              }
              title={item.label}
              onClick={() => {
                handleClick(item);
                if (inputRef) {
                  inputRef.current.placeholder = item.label;
                  inputRef.current.value = '';
                }
                queryClient.removeQueries(`input_select_${queryName}`);
                setFilter('');
              }}
              onKeyPress={() => {
                handleClick(item);
                if (inputRef) {
                  inputRef.current.placeholder = item.label;
                  inputRef.current.value = '';
                }
                queryClient.removeQueries(`input_select_${queryName}`);
                setFilter('');
              }}
            >
              {item.label}
            </Option>
          );
        });
      });
    }
    /**
     * Caso não existam dados de acordo com a busca efetuada
     * será listada a opção abaixo
     */
    return <Option className="not-found">Nenhum registro encontrado.</Option>;
  }, [
    data,
    handleClick,
    inputRef,
    messageForDependsOf,
    queryClient,
    queryName,
    selectedRef,
    setFilter,
    useNanoIdForKey,
  ]);

  return (
    <Content listWidth={listWidth}>
      <ul ref={wrapperRef} onScroll={onScroll}>
        {data
          ? renderOptions()
          : !isFetching && (
              <Option className="not-found">
                Digite ao menos 3 caracteres para pesquisar.
              </Option>
            )}
        {isFetching && (
          <Option className="not-found">
            <Spinner animation="border" size="sm" className="spinner" />
          </Option>
        )}
      </ul>
    </Content>
  );
};
