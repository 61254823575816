import api from '~/services/api';

export const getTransmissaoPendente = async (
  cod_loja: string | number,
  cod_operador: string | number,
  num_pdv: string | number,
): Promise<{ message: string; success: boolean }> => {
  const { data } = await api.get<{ message: string; success: boolean }>(
    `/pdv-online/transmitir-nfce/transmissao-pendente/${cod_loja}/${cod_operador}/${num_pdv}`,
  );
  return data;
};
