import { Field } from '@atlaskit/form';
import Select from '@atlaskit/select';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import FooterDefault from '~/components/FooterDefault';
import Loja from '~/components/Loja';
import { BuscaProduto } from '~/components/NovosInputs';
import Separator from '~/components/Separator';
import ToggleDefault from '~/components/ToggleDefault';
import useDebounce from '~/hooks/useDebounce';
import api from '~/services/api';
import {
  fltRound,
  formatCurrencyAsText,
  moneyFormat,
  transformAsCurrency,
} from '~/utils/functions';

import {
  ButtonCancel,
  ButtonForm,
  Container,
  ContainerLoader,
  SeparatorLine,
  Title,
} from './styles';

import DefaultLoader from '~/components/DefaultLoader';
import { DataGridComponent } from './components/DataGridComponent';
import { CalculoMargem } from '~/utils/classes/CalculoMargem';
import useAuth from '~/hooks/useAuth';
import Tooltip from '@atlaskit/tooltip';
import { HotKeys, configure } from 'react-hotkeys';
import { useQueryClient } from 'react-query';
import { formatCurrency } from './functions/FormatCurrency';
import { createDateWithoutTimezone } from '~/utils/createDateWithoutTimezone ';

const MySwal = withReactContent(Swal);

const keyMap = {
  hkSave: 'ctrl+alt+s',
};

const AlteracaoDePreco: React.FC = () => {
  const queryClient = useQueryClient();
  const { debouncedFn } = useDebounce();
  const { user } = useAuth();
  const [loader, setLoader] = useState<boolean>(false);
  const [loadingItens, setLoadingItens] = useState<boolean>(false);
  const [resetLoja, setResetLoja] = useState<boolean>(false);
  const [optionsPrecos, setOptionsPrecos] = useState<
    Array<{
      label: string;
      value: number;
    }>
  >([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = useState<number>(5);
  const [precos, setPrecos] = useState<any>([
    { label: 'PADRÃO', tipo: 1, value: 1 },
  ]);
  const [reajusta, setReajusta] = useState<boolean>(false);
  const [disabledItems, setDisabledItems] = useState<boolean>(false);
  const [produtos, setProdutos] = useState<any>([]);
  const [produtosPrecos, setProdutosPreco] = useState<any>([]);
  const [produtoPrecosInicial, setProdutosPrecoInicial] = useState<any>([]);
  const [lojas, setLojas] = useState<number | Array<number>>([]);
  const [resetCampo, setResetCampo] = useState<boolean>(false);
  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const [precoArredondamento, setPrecoArredondamento] = useState<any[]>([]);

  const { register, setValue, control, reset, formState } = useForm({
    reValidateMode: 'onSubmit',
  });

  configure({
    ignoreTags: ['input', 'select', 'textarea'],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ignoreEventsCondition(keyEvent: KeyboardEvent) {
      return false;
    },
  });

  const validaTelaAtalho = () => {
    const cod_tela_active: any = queryClient.getQueryData(`cod_tela_active`);
    if (cod_tela_active) {
      if (cod_tela_active.cod_tela === 37) {
        return true;
      }
    }

    return false;
  };

  const keyHandlers = {
    hkSave: async (e: any) => {
      const validaAtalho = validaTelaAtalho();
      if (validaAtalho) {
        if (produtosPrecos.length !== 0) {
          onSave();
        }
      }
      return false;
    },
  };

  const { errors } = formState;

  useEffect(() => {
    if (user) {
      setPrecoArredondamento(user.precos_arredondamentos);
    }
  }, [user]);

  const modalOpening = async (filterProdSimilar: any) => {
    const filterPrecos = precos.filter((prec: any) => prec.tipo === 4);
    let validated;
    if (filterProdSimilar.length > 0 && filterPrecos.length > 0) {
      setLoader(false);
      await MySwal.fire({
        text: `Deseja alterar o valor de OFERTA de todos os similares vinculados a esse produto?`,
        showCancelButton: true,
        showDenyButton: true,
        confirmButtonColor: '#8850BF',
        cancelButtonColor: '#DE350B',
        denyButtonColor: '#0065FF',
        confirmButtonText: 'Sim',
        denyButtonText: 'Alterar apenas este',
        cancelButtonText: 'Abortar',
      }).then((result) => {
        if (result.isConfirmed) {
          validated = true;
          return validated;
        }
        if (result.isDenied) {
          validated = false;
          return validated;
        }
        validated = null;
        return validated;
      });
      return validated;
    }
    // if (filterProdSimilar.length > 0) {
    //   setLoader(false);
    //   await MySwal.fire({
    //     title: 'Produto está vinculado a similares',
    //     text: `O produto está vinculado a similares. Todos os similares vinculados também terão seu custo e/ou preço alterado!`,
    //     confirmButtonColor: '#8850BF',
    //     confirmButtonText: 'OK',
    //   }).then(() => {
    //     validated = true;
    //     return validated;
    //   });
    //   return validated;
    // }
    return validated;
  };

  const verificaDataOfertaVálida = (): boolean => {
    const dataValida = produtosPrecos.some((prod: any) => {
      const preco = prod.precos.find((pre: any) => pre.r_cod_preco === 2);
      if (!preco) {
        return false;
      }
      if (!preco.r_dta_validade || preco.r_dta_validade === '') {
        return true;
      }
      const dataValidade = createDateWithoutTimezone(preco.r_dta_validade);

      const today = new Date();
      today.setUTCHours(0, 0, 0, 0);

      return dataValidade <= today;
    });
    return dataValida;
  };

  async function createMessageCusto(
    produtosToTable: any,
    tipoTolerancia: number,
  ): Promise<boolean> {
    const TableHeader = `<tr><th>Loja</th><th>Descrição</th><th>PLU</th><th>% Tol</th><th>% Variação</th></tr>`;
    const TableBody = produtosToTable.map((item: any): string => {
      const spaco = '&nbsp;&nbsp;';
      return `<tr><td>${item.loja}${spaco}</td><td>${item.descricao}${spaco}</td><td>${item.plu}${spaco}</td><td>${item.tol}%${spaco}</td><td>${item.variacao}%${spaco}</td></tr>`;
    });

    const Table = `
            <div style='max-height: 200px; border: solid 1px #dcdcdc; overflow: auto'>
              <table style='width:100%; min-width: 800px'>
                <thead style='text-align: center;'>${TableHeader}</thead>
                <tbody style='text-align: center;'>${TableBody.join(
                  ' ',
                )}</tbody>
              </table>
            </div>
           ${
             tipoTolerancia === 0
               ? `<p style='text-align: center; padding: 20px'>
                    Regularize a situação para prosseguir
                  </p>`
               : ''
           }
            `;
    if (produtosToTable.length > 0) {
      const { isConfirmed } = await MySwal.fire({
        icon: tipoTolerancia === 0 ? 'error' : 'warning',
        width: 960,
        title:
          tipoTolerancia === 0
            ? 'Custo excedeu o percentual de bloqueio'
            : 'Custo excedeu o percentual de tolerância',
        html: String(Table),
        confirmButtonText: tipoTolerancia === 0 ? 'OK' : 'Continuar',
      });
      return tipoTolerancia === 0 ? !isConfirmed : isConfirmed;
    }
    return true;
  }

  async function createMessageValor(
    produtosToTable: any,
    tipoTolerancia: number,
  ): Promise<boolean> {
    const TableHeader = `<tr><th>Loja</th><th>Descrição</th><th>PLU</th><th>% Tol</th><th>% Variação</th></tr>`;
    const TableBody = produtosToTable.map((item: any): string => {
      const spaco = '&nbsp;&nbsp;';
      return `<tr><td>${item.loja}${spaco}</td><td>${item.descricao}${spaco}</td><td>${item.plu}${spaco}</td><td>${item.tol}%${spaco}</td><td>${item.variacao}%${spaco}</td></tr>`;
    });

    const Table = `
            <div style='max-height: 200px; border: solid 1px #dcdcdc; overflow: auto'>
              <table style='width:100%; min-width: 800px'>
                <thead style='text-align: center;'>${TableHeader}</thead>
                <tbody style='text-align: center;'>${TableBody.join(
                  ' ',
                )}</tbody>
              </table>
            </div>
           ${
             tipoTolerancia === 0
               ? `<p style='text-align: center; padding: 20px'>
                    Regularize a situação para prosseguir
                  </p>`
               : ''
           }
            `;
    if (produtosToTable.length > 0) {
      const continua = await MySwal.fire({
        icon: tipoTolerancia === 0 ? 'error' : 'warning',
        width: 960,
        title:
          tipoTolerancia === 0
            ? 'Preço excedeu o percentual de bloqueio'
            : 'Preço excedeu o percentual de tolerância',
        html: String(Table),
        confirmButtonText: tipoTolerancia === 0 ? 'OK' : 'Continuar',
      });
      return tipoTolerancia === 0
        ? !continua.isConfirmed
        : continua.isConfirmed;
    }
    return true;
  }

  const verificaCustoExcedeBloqueio = () => {
    const produtosToTable: any[] = [];
    produtosPrecos.forEach((produto: any) => {
      const valorCustoAnterior = parseFloat(
        produto.val_custo_rep_ant.toFixed(2),
      );
      const valorCustoNovo = Number(produto.val_custo_rep);
      if (valorCustoAnterior !== valorCustoNovo) {
        if (produto.per_bloq_custo > 0) {
          const resultPercAviso = toleraValor(
            transformAsCurrency(valorCustoNovo),
            transformAsCurrency(valorCustoAnterior),
            produto.per_bloq_custo,
          );
          if (!resultPercAviso) {
            const variacaoProduto = variacao(
              valorCustoNovo,
              valorCustoAnterior,
            );
            const produtoFormattedToTable = {
              loja: produto.cod_loja,
              descricao: produto.des_produto,
              plu: produto.cod_produto,
              tol: moneyFormat(produto.per_tol_custo),
              variacao: moneyFormat(variacaoProduto.toString()),
            };
            produtosToTable.push(produtoFormattedToTable);
          }
        }
      }
    });
    return produtosToTable;
  };

  const verificaCustoExcedeTolerancia = () => {
    const produtosToTable: any[] = [];
    produtosPrecos.forEach((produto: any) => {
      const valorCustoAnterior = parseFloat(
        produto.val_custo_rep_ant.toFixed(2),
      );
      const valorCustoNovo = Number(produto.val_custo_rep);
      if (valorCustoAnterior !== valorCustoNovo) {
        if (produto.per_tol_custo > 0) {
          const resultPercAviso = toleraValor(
            transformAsCurrency(valorCustoNovo),
            transformAsCurrency(valorCustoAnterior),
            produto.per_tol_custo,
          );
          if (!resultPercAviso) {
            const variacaoProduto = variacao(
              valorCustoNovo,
              valorCustoAnterior,
            );
            const produtoFormattedToTable = {
              loja: produto.cod_loja,
              descricao: produto.des_produto,
              plu: produto.cod_produto,
              tol: moneyFormat(produto.per_tol_custo),
              variacao: moneyFormat(variacaoProduto.toString()),
            };
            produtosToTable.push(produtoFormattedToTable);
          }
        }
      }
    });
    return produtosToTable;
  };

  const verificaPrecoExcedeTolerancia = () => {
    const produtosToTable: any[] = [];
    const verificaTabela = precos.some((preco: any) => preco.value === 1);
    if (!verificaTabela) return produtosToTable;
    produtosPrecos.forEach((produto: any) => {
      const indexPrecoAnterior = produto.val_preco_base_ant.findIndex(
        (preco: any) => preco.r_cod_preco === 1,
      );
      const indexPrecoAtual = produto.precos.findIndex(
        (preco: any) => preco.r_cod_preco === 1,
      );
      const valorPrecoAnterior = fltRound(
        produto.val_preco_base_ant[indexPrecoAnterior].r_val_preco,
        2,
      );
      const valorPrecoNovo = fltRound(
        produto.precos[indexPrecoAtual].r_val_preco,
        2,
      );
      if (valorPrecoAnterior !== valorPrecoNovo) {
        if (produto.per_tol_venda > 0) {
          const resultPercAviso = toleraValor(
            transformAsCurrency(valorPrecoNovo),
            transformAsCurrency(valorPrecoAnterior),
            produto.per_tol_venda,
          );
          if (!resultPercAviso) {
            const variacaoProduto = variacao(
              valorPrecoNovo,
              valorPrecoAnterior,
            );
            const produtoFormattedToTable = {
              loja: produto.cod_loja,
              descricao: produto.des_produto,
              plu: produto.cod_produto,
              tol: moneyFormat(produto.per_tol_venda),
              variacao: moneyFormat(variacaoProduto.toString()),
            };
            produtosToTable.push(produtoFormattedToTable);
          }
        }
      }
    });
    return produtosToTable;
  };

  const verificaPrecoExcedeBloqueio = () => {
    const produtosToTable: any[] = [];
    const verificaTabela = precos.some((preco: any) => preco.value === 1);
    if (!verificaTabela) return produtosToTable;
    produtosPrecos.forEach((produto: any) => {
      const indexPrecoAnterior = produto.val_preco_base_ant.findIndex(
        (preco: any) => preco.r_cod_preco === 1,
      );
      const indexPrecoAtual = produto.precos.findIndex(
        (preco: any) => preco.r_cod_preco === 1,
      );
      const valorPrecoAnterior = fltRound(
        produto.val_preco_base_ant[indexPrecoAnterior].r_val_preco,
        2,
      );
      const valorPrecoNovo = fltRound(
        produto.precos[indexPrecoAtual].r_val_preco,
        2,
      );
      if (valorPrecoAnterior !== valorPrecoNovo) {
        if (produto.per_bloq_venda > 0) {
          const resultPercAviso = toleraValor(
            transformAsCurrency(valorPrecoNovo),
            transformAsCurrency(valorPrecoAnterior),
            produto.per_bloq_venda,
          );
          if (!resultPercAviso) {
            const variacaoProduto = variacao(
              valorPrecoNovo,
              valorPrecoAnterior,
            );
            const produtoFormattedToTable = {
              loja: produto.cod_loja,
              descricao: produto.des_produto,
              plu: produto.cod_produto,
              tol: moneyFormat(produto.per_bloq_venda),
              variacao: moneyFormat(variacaoProduto.toString()),
            };
            produtosToTable.push(produtoFormattedToTable);
          }
        }
      }
    });
    return produtosToTable;
  };

  const onSave = async () => {
    const dta_valida = verificaDataOfertaVálida();
    if (dta_valida) {
      setLoader(false);
      toast.warn('Selecione uma data posterior à data atual.');
      return;
    }
    const produtosExcedentesBloqueio = verificaCustoExcedeBloqueio();
    if (produtosExcedentesBloqueio.length > 0) {
      await createMessageCusto(produtosExcedentesBloqueio, 0);
      return;
    }
    const produtosExcedentesTolerancia = verificaCustoExcedeTolerancia();
    if (produtosExcedentesTolerancia.length > 0) {
      await createMessageCusto(produtosExcedentesTolerancia, 1);
    }

    const produtosPrecoExcedeBloqueio = verificaPrecoExcedeBloqueio();

    if (produtosPrecoExcedeBloqueio.length > 0) {
      await createMessageValor(produtosPrecoExcedeBloqueio, 0);
      return;
    }
    const produtosPrecoExcedeTolerancia = verificaPrecoExcedeTolerancia();

    if (produtosPrecoExcedeTolerancia.length > 0) {
      await createMessageValor(produtosPrecoExcedeTolerancia, 1);
    }

    setLoader(true);
    const filterProdSimilar = produtosPrecos.filter((prod: any) => {
      return prod.cod_similar > 0;
    });
    const continua = await modalOpening(filterProdSimilar);
    if (continua === null) return;
    setLoader(true);
    try {
      const { data } = await api.post('/alteraPrecoLote', {
        embalagens: produtosPrecos,
        flg_cad_similar: !!continua,
      });
      if (data.success) {
        toast.success(data.message);
        setPrecos([]);

        if (reajusta) setReajusta(true);
        else setReajusta(false);

        onCancelaPesquisa();
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
    } finally {
      setLoader(false);
    }
  };

  const handleBlurCustoRep = useCallback(
    async (newsValues: any[], value: string, i: number) => {
      const item = newsValues[i];

      for (let ii = 0; ii < item.precos.length; ii++) {
        const precF = item.precos[ii];

        const sugVda = await CalculoMargem.getValSugestaoVenda(
          parseFloat(value.replace('.', '').replace(',', '.')),
          item.val_custo_sem_imposto,
          precF.r_val_margem,
          item.per_desp_op,
          item.per_icms_saida,
          item.tipo_margem,
          precF.r_cod_preco,
          item.per_fcp_icms,
          item.per_pis_loja,
          item.per_cofins_loja,
          item.flg_nao_pis_cofins,
          precoArredondamento,
        );

        item.precos[ii].preco_sugestao =
          Math.sign(sugVda) !== -1 ? sugVda : 0.0;
      }
      newsValues[i] = item;
      setProdutosPreco([...newsValues]);
    },
    [precoArredondamento],
  );

  const toleraValor = useCallback(
    (value: number, valueAnt: number, perTolerancia: any) => {
      if (value > 0 && valueAnt > 0) {
        const sum = variacao(value, valueAnt);
        if (sum > perTolerancia) {
          return false;
        }
        return true;
      }
      return true;
    },
    [],
  );

  const variacao = (value_1: any, value_2: any) => {
    const sub = transformAsCurrency(value_1) - value_2;
    const result = (sub * 100) / value_2;
    return result;
  };

  const handleChangeCustoRep = useCallback(
    async (value, cod_produto) => {
      const idx = produtosPrecos.findIndex(
        (item: any) => item.cod_produto === cod_produto,
      );
      const newsValues = [...produtosPrecos];
      const item = { ...newsValues[idx] };

      item.val_custo_rep = value;
      newsValues[idx] = item;

      debouncedFn(() => handleBlurCustoRep(newsValues, value, idx), 500);

      item.precos.forEach((preco: any, precoIdx: number) => {
        const margem_calculada = calcMargVda(
          item,
          item.precos[precoIdx].r_val_preco,
        );
        item.precos[precoIdx].margem_calculada = moneyFormat(margem_calculada);
      });

      item.val_custo_rep = transformAsCurrency(value);
      newsValues[idx] = item;

      setProdutosPreco(newsValues);
    },
    [debouncedFn, handleBlurCustoRep, produtosPrecos],
  );
  const handleBlurMargemCalculada = useCallback(
    async (newsValues, margem, i) => {
      const item = newsValues[i];

      const sugVda = await CalculoMargem.getValSugestaoVenda(
        transformAsCurrency(item.val_custo_rep),
        item.val_custo_sem_imposto,
        transformAsCurrency(margem),
        item.per_desp_op,
        item.per_icms_saida,
        item.tipo_margem,
        item.cod_preco,
        item.per_fcp,
        item.per_pis_lj,
        item.per_cofins_lj,
        false,
        precoArredondamento,
      );

      item.precos[i].r_val_preco = fltRound(sugVda, 2);
      newsValues[i] = item;
      setProdutosPreco([...newsValues]);
    },
    [precoArredondamento],
  );

  const handleChangeDate = useCallback(
    (value, cod_preco, cod_produto) => {
      const idx = produtosPrecos.findIndex(
        (item: any) => item.cod_produto === cod_produto,
      );
      const newsValues = [...produtosPrecos];
      const item = { ...newsValues[idx] };

      const precoIdx = item.precos.findIndex(
        (p: any) => p.r_cod_preco === cod_preco,
      );

      item.precos[precoIdx].r_dta_validade = value;

      newsValues[idx] = item;

      setProdutosPreco(newsValues);
    },
    [produtosPrecos],
  );

  const handleChangeMargemCalculada = useCallback(
    async (margem, cod_preco, cod_produto) => {
      if (margem === '') {
        margem = '0,00';
      }

      const idx = produtosPrecos.findIndex(
        (item: any) => item.cod_produto === cod_produto,
      );
      const newsValues = [...produtosPrecos];
      const item = { ...newsValues[idx] };
      const precoIdx = item.precos.findIndex(
        (p: any) => p.r_cod_preco === cod_preco,
      );
      // calculo do preco
      let calc =
        (parseFloat(margem.replace(',', '.')) / 100) *
        parseFloat(item.precos[precoIdx].preco_sugestao);
      calc += parseFloat(item.precos[precoIdx].preco_sugestao);
      calc = Number(calc.toFixed(2));
      item.precos[precoIdx].margem_calculada = margem;
      item.precos[precoIdx].r_val_preco = fltRound(calc, 2);
      newsValues[idx] = item;
      setProdutosPreco(newsValues);
      handleBlurMargemCalculada(newsValues, margem, idx);

      setValue(`val_preco_base_${cod_produto}_1`, formatCurrency(calc));
    },
    [handleBlurMargemCalculada, produtosPrecos, setValue],
  );

  const handleChangePrecoBase = useCallback(
    async (value: any, cod_preco: any, cod_produto: any) => {
      const idx = produtosPrecos.findIndex(
        (item: any) => item.cod_produto === cod_produto,
      );
      const newsValues = [...produtosPrecos];
      const item = { ...newsValues[idx] };

      const precoIdx = item.precos.findIndex(
        (p: any) => p.r_cod_preco === cod_preco,
      );
      item.precos[precoIdx].r_val_preco = Number(value.replace(',', '.'));
      newsValues[idx] = item;
      const margem_calculada = calcMargVda(item, value);
      item.precos[precoIdx].margem_calculada = moneyFormat(margem_calculada);
      item.precos[precoIdx].r_val_preco = transformAsCurrency(value);
      newsValues[idx] = item;
      setProdutosPreco(newsValues);
    },
    [produtosPrecos],
  );

  const calcMargVda = (item: any, valor: string) => {
    const {
      tipo_margem,
      val_custo_rep,
      val_custo_sem_imposto,
      val_imposto_debito,
      per_desp_op,
    } = item;

    const margVda = CalculoMargem.getValMargemVenda(
      tipo_margem,
      transformAsCurrency(val_custo_rep),
      transformAsCurrency(val_custo_sem_imposto),
      transformAsCurrency(valor),
      val_imposto_debito,
      per_desp_op,
    );

    return margVda;
  };

  const onCancelaPesquisa = async () => {
    setLoadingItens(true);
    setDisabledItems(false);
    setProdutos([]);
    setProdutosPreco([]);
    setPage(0);
    setProdutosPrecoInicial([]);
    setTimeout(() => {
      setLoadingItens(false);
    }, 1500);
    setResetCampo(true);
    reset();
    setPrecos([{ label: 'PADRÃO', tipo: 1, value: 1 }]);
    const inputTag = document.getElementsByName('busca_produto');
    if (inputTag.length > 0) {
      inputTag[0].focus();
    }
  };

  useEffect(() => {
    (async () => {
      const { data } = await api.get('/preco');
      if (data.success) {
        const newPrecos = data.data.map((p: any) => ({
          label: p.des_preco,
          value: p.cod_preco,
          tipo: p.tipo_preco,
        }));
        newPrecos.sort(function (a: any, b: any) {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });
        setOptionsPrecos(newPrecos);
      }
    })();
  }, []);

  const verificaTipoMargem = (item: any) => {
    if (item === null) return false;

    return true;
  };

  const formataDataOferta = (item: any) => {
    const dataOferta = item.map((dta: any) => {
      if (dta.r_cod_preco !== 2) {
        return null;
      }
      if (dta.r_dta_validade !== null) {
        return moment(dta.r_dta_validade).format('YYYY-MM-DD');
      }
      return null;
    });
    const dataOfertaValida = verificaValidadeDataOferta(dataOferta);
    return { dataOferta, dataOfertaValida };
  };

  const verificaValidadeDataOferta = (data: any) => {
    const today = new Date();
    today.setUTCHours(0, 0, 0, 0);
    const condicao = moment(today).format('YYYY-MM-DD') > data[0];
    return condicao;
  };

  const formataInfosPreco = (item: any) => {
    const precoSugestao = item.map((prec: any) => {
      return prec.preco_sugestao;
    });
    return precoSugestao;
  };

  const formataPrecoProduto = (itens: any) => {
    let contemTipoMargem;
    const produtoFormatado = itens.map((item: any) => {
      contemTipoMargem = verificaTipoMargem(item.tipo_margem);
      const precoSugestao = formataInfosPreco(item.precos);
      const { dataOferta, dataOfertaValida } = formataDataOferta(item.precos);
      return {
        ...item,
        val_custo_rep: item.val_custo_rep,
        val_preco_base_ant: item.precos,
        precos: item.precos.map((un: any, index: any) => ({
          ...un,
          margem_calculada: moneyFormat(un.margem_calculada),
          r_val_preco: dataOfertaValida ? '0,00' : un.r_val_preco,
          r_dta_validade:
            un.r_cod_preco === 2
              ? dataOfertaValida
                ? 'null'
                : dataOferta[index]
              : 'null',
        })),
        preco_sugestao: precoSugestao[0],
      };
    });
    if (!contemTipoMargem) {
      return MySwal.fire({
        text: 'Regra para cálculo da margem ainda não foi especificado. \n Vá até a tela de Regra de Empresa e determine o critério para o cálculo de margem, ou consulte o seu supervisor.',
        showConfirmButton: true,
      });
    }
    return produtoFormatado;
  };

  const handleGetPrecos = useCallback(
    async (prods = produtos) => {
      if (prods.length > 0) {
        const idProdutos = prods.map((p: any) => p.id);
        const precosId = precos.map((p: any) => p.value);

        const { data } = await api.post('/preco/precoMargens', {
          lojas,
          produtos: idProdutos,
          precos: precosId,
        });
        if (data.success && data.data !== undefined) {
          const produtoFormatado = formataPrecoProduto(data.data);
          return produtoFormatado;
        }
      }
    },
    [lojas, precos, produtos],
  );

  const existeProdutoNaTabela = (val: any): boolean => {
    return val.some((item: any) =>
      produtos.some((elem: any) => elem.id === item.cod_produto),
    );
  };

  const formataProdutoValueLabel = (val: any) => {
    const requestDto = val.map((item: any) => {
      return {
        descricao: item.des_produto,
        id: item.cod_produto,
      };
    });

    return requestDto;
  };

  const setEstadosProdutoPreco = (produtosVal: any, response: any) => {
    setDisabledItems(true);
    const requestDto = formataProdutoValueLabel(produtosVal);

    produtosVal.forEach((produto: any) => {
      const custoRep = formatCurrencyAsText(produto.val_custo_rep);

      const precoBase = formatCurrencyAsText(produto.val_preco);
      const precosProduto = response.find(
        (produtoPreco: any) => produto.cod_produto === produtoPreco.cod_produto,
      );

      precosProduto.precos.map((preco: any) => {
        setValue(
          `val_preco_base_${produto.cod_produto}_${preco.r_cod_preco}`,
          formatCurrencyAsText(preco.r_val_preco),
        );
        return preco;
      });
      setValue(`val_custo_rep_${produto.cod_produto}`, custoRep);
    });

    const filteredNewProds = requestDto.filter((newProd: any) => {
      return response.some(
        (resItem: any) => resItem.cod_produto === newProd.id,
      );
    });
    const newProds = [...produtos, ...filteredNewProds];
    setProdutos(newProds);
    setProdutosPrecoInicial((prevProdutoPrecosInicial: any) => [
      ...prevProdutoPrecosInicial,
      ...response,
    ]);
    setProdutosPreco((prevProdutosPrecos: any) => [
      ...prevProdutosPrecos,
      ...response,
    ]);
  };

  const handleAddProduto = useCallback(
    async (val: any) => {
      if (val.length === 0) {
        setLoadingItens(false);
        return toast.warn(
          'Nenhum registro encontrado com o critério informado',
        );
      }
      setLoadingItens(true);
      const requestDto = formataProdutoValueLabel(val);
      const existeProduto = existeProdutoNaTabela(val);
      // const prod = val.some((item: any) => {
      //   const prodResult = produtos.some(
      //     (elem: any) => elem.id === item.cod_produto,
      //   );
      //   return prodResult;
      // });
      if (existeProduto) {
        setLoadingItens(false);
        toast.warn(
          ' O Produto a ser incluso já se encontra relacionado ao lote, pesquise outro ou edite ele',
        );
        return;
      }
      const response = await handleGetPrecos(requestDto);
      if (response) {
        setEstadosProdutoPreco(val, response);
        // setDisabledItems(true);
        // const filteredNewProds = requestDto.filter((newProd: any) => {
        //   return response.some(
        //     (resItem: any) => resItem.cod_produto === newProd.id,
        //   );
        // });
        // const newProds = [...produtos, ...filteredNewProds];
        // setProdutos(newProds);
        // setProdutosPrecoInicial((prevProdutoPrecosInicial: any) => [
        //   ...prevProdutoPrecosInicial,
        //   ...response,
        // ]);
        // setProdutosPreco((prevProdutosPrecos: any) => [
        //   ...prevProdutosPrecos,
        //   ...response,
        // ]);
      }
      setTimeout(() => {
        setLoadingItens(false);
      }, 1500);
    },
    [produtos, handleGetPrecos],
  );

  if (loader) {
    return (
      <ContainerLoader>
        <DefaultLoader />
      </ContainerLoader>
    );
  }
  return (
    <HotKeys id="hotkeys" keyMap={keyMap} handlers={keyHandlers} tabIndex={-1}>
      <Container>
        <Title>Alteração de Preços por Lote</Title>

        <form>
          <SeparatorLine />
          <div className="row">
            <div className="col-sm-12 col-md-7 mt-2">
              <Loja
                isMulti
                onChange={(val: any) => {
                  setLojas(val);
                }}
                resetLojas={resetLoja}
                selectedLoja={lojas}
              />
            </div>

            <div
              className="col-sm-12 col-md-5 tabelaPreco"
              style={{ zIndex: 1 }}
            >
              <Field
                name="buscar_por"
                defaultValue=""
                label="Tabela de Preços a Alterar"
              >
                {() => (
                  <>
                    <Select
                      placeholder="Selecione.."
                      options={optionsPrecos}
                      noOptionsMessage={() => 'Nenhum registro encontrado'}
                      className="single-select"
                      classNamePrefix="react-select"
                      onChange={(e) => {
                        setValue('precos', e);
                        setPrecos(e);
                      }}
                      value={precos}
                      isMulti
                      isDisabled={disabledItems}
                    />
                  </>
                )}
              </Field>
            </div>
            <div className="col-12">
              <Separator
                labelText="Relação dos Produtos"
                childrenWidth="270px"
              />
            </div>
            <div
              className="col-12"
              style={{
                display: 'flex',
                marginRight: '2.5px',
                justifyContent: 'flex-end',
              }}
            >
              <ToggleDefault
                labelText="Reajustar Venda Pela Margem Calculada?"
                setChecked={reajusta}
                onSwitch={() => {
                  setValue('reajusta', !reajusta);
                  setReajusta(!reajusta);
                }}
                disabled={disabledItems}
                inLine
                keepInLine
              />
            </div>
            {precos.length > 0 && (
              <>
                <div
                  className="col-sm-12 col-md-12 col-lg-12"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div style={{ width: '100%' }}>
                    <BuscaProduto
                      label="Buscar por"
                      placeholder="Selecione..."
                      name="busca_produto"
                      register={register}
                      isError={!!errors.busca_produto}
                      shouldApplyFocus
                      buscaItensInativos={false}
                      control={control}
                      buscaNasLojas={lojas as number[]}
                      customOptions={{
                        hideBuscarPor: true,
                        buscarPor: [
                          'Produto',
                          'Departamento',
                          'Fornecedor',
                          'Check-List',
                        ],
                        buscaPadrao: 'Produto',
                        showSelecionarItensContendo: true,
                      }}
                      getProduct={(selected: any) => {
                        setResetCampo(false);
                        handleAddProduto(selected);
                      }}
                      setValue={setValue}
                      clearCampoBusca={resetCampo}
                      style={{ width: '100%' }}
                    />
                  </div>
                  <div style={{ marginTop: '1.875rem' }}>
                    <ButtonCancel
                      type="button"
                      disabled={!disabledItems}
                      onClick={() => onCancelaPesquisa()}
                    >
                      Cancelar
                    </ButtonCancel>
                  </div>
                </div>
                <br />
                <br />
                <DataGridComponent
                  loadingItens={loadingItens}
                  precos={precos}
                  produtosPrecos={produtosPrecos}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  reajusta={reajusta}
                  handleChangeCustoRep={handleChangeCustoRep}
                  handleChangeMargemCalculada={handleChangeMargemCalculada}
                  handleChangePrecoBase={handleChangePrecoBase}
                  handleChangeDate={handleChangeDate}
                  handleChangePage={handleChangePage}
                  register={register}
                  control={control}
                  formState={formState}
                  setValue={setValue}
                />
              </>
            )}
            <SeparatorLine />
            <FooterDefault codTela={37}>
              <div className="right">
                <Tooltip position="bottom" content="CTRL + ALT + S">
                  <ButtonForm
                    disabled={produtosPrecos.length === 0}
                    onClick={onSave}
                    type="button"
                  >
                    Aplicar Alterações
                  </ButtonForm>
                </Tooltip>
              </div>
            </FooterDefault>
          </div>
        </form>
      </Container>
    </HotKeys>
  );
};

export default AlteracaoDePreco;
