import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useQuery } from 'react-query';

import { serviceMeuPlano } from '../services/meu-plano';
import {
  MethodFaturamento,
  MeuPlanoContextData,
  ScreenMeuPlano,
  SubScreenMeuPlano,
} from '../types/context';
import { useForm } from 'react-hook-form';

// import dataJson from '../mock/plan.json';
import {
  Ciclo,
  Contratacao,
  ContratacaoPlano,
  FormaPagamento,
} from '../types/Plan';
import { yupResolver } from '@hookform/resolvers/yup';
import creditCardSchema from '../components/CreditCard/validation';
import customerDataSchema from '../components/DadosCliente/validations';
import resetClientData from '../components/DadosCliente/reset.json';
import resetCreditCard from '../components/CreditCard/reset.json';
import api from '~/services/api';
import { CustomerData } from '../types/Customer';

export const MeuPlanoContext = createContext({} as MeuPlanoContextData);

export const MeuPlanoProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isDemonstracao, setIsDemonstracao] = useState(true);
  const [screen, setScreen] = useState<ScreenMeuPlano>('HOME');
  const [subScreen, setSubScreen] = useState<SubScreenMeuPlano>('NENHUMA');
  const [planoPagamento, setPlanoPagamento] = useState<'MENSAL' | 'ANUAL'>(
    'MENSAL',
  );
  const [contratacaoPlano, setContratacaoPlano] =
    useState<ContratacaoPlano | null>(null);
  const [contratacaoCliente, setContratacaoCliente] =
    useState<Contratacao | null>(null);
  const [methodFaturamento, setMethodFaturamento] =
    useState<MethodFaturamento | null>(null);
  const [contratacaoClienteFuturo, setContratacaoClienteFuturo] =
    useState<Contratacao>({} as Contratacao);
  const [cicloAtual, setCicloAtual] = useState<Ciclo>({} as Ciclo);
  const [cicloFuturo, setCicloFuturo] = useState<Ciclo>({} as Ciclo);
  const [cicloFuturoFormaPagamento, setCicloFuturoFormaPagamento] =
    useState<FormaPagamento>({} as FormaPagamento);
  const [contratacaoPlanoFuturo, setContratacaoPlanoFuturo] =
    useState<ContratacaoPlano>({} as ContratacaoPlano);

  const [tipoStatus, setTipoStatus] = useState<{
    contratacaoAtiva: boolean;
    contratacaoTransitoria: boolean;
    tipoStatus: number;
  }>({ contratacaoAtiva: false, contratacaoTransitoria: false, tipoStatus: 0 });
  const [isContratacaoCancelada, setIsContratacaoCancelada] = useState(false);
  const [isPagamentoPendente, setIsPagamentoPendente] = useState(false);
  const [dtaDemonstracaoFim, setDtaDemonstracaoFim] = useState<string | null>(
    null,
  );
  const [codSeqPlano, setCodSeqPlano] = useState(0);
  const [codAplicacao, setCodAplicacao] = useState(0);
  const [desPlano, setDesPlano] = useState('');
  const [selectedMethod, setSelectedMethod] = useState<string | null>(null);
  const [chaveBoleto, setChaveBoleto] = useState<string>('');
  const [tipoMudancaPlano, setTipoMudancaPlano] = useState<string>('');
  const [codCliente, setCodCliente] = useState<number>();
  const [valSelectedPlan, setValSelectedPlan] = useState<number>(0);
  const [tipoStatusLicenca, setTipoStatusLicenca] = useState<number>(0);

  /**
   * Form Contratacao de Planos
   */
  const {
    register: registerClienteContratacao,
    control: controlClienteContratacao,
    setValue: setValueClienteContratacao,
    formState: formStateClienteContratacao,
    getValues: getValuesClienteContratacao,
    trigger: triggerClienteContratacao,
    reset: resetClienteContratacao,
    clearErrors: clearErrorsClienteContratacao,
  } = useForm({
    resolver: yupResolver(customerDataSchema), // Conecta o esquema ao formulário
    mode: 'onChange', // Validação reativa (opcional)
  });

  const {
    register: registerCard,
    control: controlCard,
    setValue: setValueCard,
    formState: formStateCard,
    reset: resetFormCard,
    getValues: getValuesCard,
    resetField: resetFieldCard,
    trigger: triggerCard,
    watch: watchCard,
  } = useForm({
    resolver: yupResolver(creditCardSchema),
    mode: 'onChange',
  });

  const formCard = {
    registerCard,
    controlCard,
    setValueCard,
    formStateCard,
    resetFormCard,
    getValuesCard,
    resetFieldCard,
    triggerCard,
    watchCard,
  };

  const formClienteContratacao = {
    registerClienteContratacao,
    controlClienteContratacao,
    setValueClienteContratacao,
    formStateClienteContratacao,
    getValuesClienteContratacao,
    triggerClienteContratacao,
    resetClienteContratacao,
    clearErrorsClienteContratacao,
  };

  const {
    isFetching: isFetchingContratacaoAtual,
    refetch: refetchContratacaoAtual,
  } = useQuery(
    'contratacao-atual',
    async () => {
      const currentHiring = await serviceMeuPlano.index('/contratacao-atual');

      // Descomente para usar o mock
      // const currentHiring = {
      //   success: true,
      //   data: [dataJson],
      // };

      if (currentHiring.success) {
        const {
          cod_seq_plano,
          cod_aplicacao,

          ciclo_atual,
          ciclo_futuro,
          ciclo_futuro_forma_pagamento,

          contratacao_cliente,
          contratacao_cliente_futuro,
          contratacao_plano,
          contratacao_plano_futuro,

          flg_demonstracao,
          flg_ativo,

          tipo_status,

          dta_demonstracao_fim,

          cod_cliente,

          des_plano,
        } = currentHiring.data[0];

        setTipoStatusLicenca(tipo_status);

        setCodSeqPlano(cod_seq_plano);
        setCodAplicacao(cod_aplicacao);
        setCodCliente(cod_cliente);
        setDesPlano(des_plano);

        setCicloFuturo(ciclo_futuro);
        setCicloFuturoFormaPagamento(ciclo_futuro_forma_pagamento);

        setContratacaoPlano(contratacao_plano);
        setContratacaoPlanoFuturo(contratacao_plano_futuro);

        setContratacaoCliente(contratacao_cliente);
        setContratacaoClienteFuturo(contratacao_cliente_futuro);

        setCicloAtual(ciclo_atual);

        if (ciclo_atual.flg_atual) {
          setIsDemonstracao(flg_demonstracao);

          const ativa = Number(contratacao_cliente.tipo_status) === 0;
          const transitoria = Number(contratacao_cliente.tipo_status) === 1;

          setTipoStatus({
            contratacaoAtiva: ativa,
            contratacaoTransitoria: transitoria,
            tipoStatus: contratacao_cliente.tipo_status,
          });

          // setIsContratacaoCancelada(false);
          setIsContratacaoCancelada(
            transitoria &&
              contratacao_cliente &&
              contratacao_cliente.dta_fim !== null,
          );
          setIsPagamentoPendente(!flg_ativo);
          if (flg_demonstracao) {
            setDtaDemonstracaoFim(dta_demonstracao_fim);
            // console.log('Efetivacao em processo');
          } else if (!contratacao_cliente_futuro) {
            // console.log('Contratacao Atual pode fazer alteracoes');
          } else {
            // console.log('Contratacao Atual nao pode fazer alteracoes');
          }
        } else {
          // console.log('Regularizacao');
          setIsDemonstracao(false);
          setTipoStatus({
            contratacaoAtiva: false,
            contratacaoTransitoria: false,
            tipoStatus: contratacao_cliente.tipo_status,
          });

          setIsContratacaoCancelada(false);
          setIsPagamentoPendente(false);
        }
      }
    },
    // {
    //   refetchOnWindowFocus: false,
    // },
  );

  const handlePlanoPagamento = useCallback((plano: 'MENSAL' | 'ANUAL') => {
    setPlanoPagamento(plano);
  }, []);

  const handleSubScreen = useCallback((subscreen: SubScreenMeuPlano) => {
    setSubScreen(subscreen);
  }, []);

  const handleMethodFaturamento = useCallback(
    (method: MethodFaturamento | null) => {
      setMethodFaturamento(method);
    },
    [],
  );

  const fetchCustomerData = useCallback(async () => {
    const response = await api.get(`/meu-plano/customer-data/${codCliente}`);
    const { success, data } = response.data;

    if (success && data.length > 0) {
      return data[0]; // Retorna o primeiro cliente
    }
  }, [codCliente]);

  const {
    data: clienteData,
    refetch,
    // isLoading,
    // isError,
  } = useQuery<CustomerData>(['customerData', codCliente], fetchCustomerData, {
    staleTime: 5 * 60 * 1000,
    cacheTime: 10 * 60 * 1000,
    enabled: !!codCliente,
    refetchOnWindowFocus: false,
  });

  const setCustomerDataToForm = useCallback(() => {
    if (clienteData) {
      // Preenche form de cliente
      setValueClienteContratacao('des_nome', clienteData.nome_pessoa);
      setValueClienteContratacao('des_email', clienteData.email);
      setValueClienteContratacao('num_cpf_cnpj', clienteData.num_cpf_cnpj);
      setValueClienteContratacao('num_telefone', clienteData.num_celular);
      setValueClienteContratacao('num_cep', clienteData.num_cep);
      setValueClienteContratacao('des_rua', clienteData.des_logradouro);
      setValueClienteContratacao('des_numero', clienteData.num_endereco);
      setValueClienteContratacao(
        'des_complemento',
        clienteData.des_complemento,
      );
      setValueClienteContratacao('des_bairro', clienteData.des_bairro);
      setValueClienteContratacao('des_cidade', {
        value: clienteData.cod_cidade,
        ufValue: clienteData.des_sigla,
        label: `${clienteData.des_cidade} - ${clienteData.des_sigla}`,
        cidade: clienteData.des_cidade,
      });

      // preenche form de cartão
      setValueCard('des_nome', clienteData.nome_pessoa);
      setValueCard('des_email', clienteData.email);
      setValueCard('num_cpf_cnpj', clienteData.num_cpf_cnpj);
      setValueCard('num_cep', clienteData.num_cep);
      setValueCard('num_endereco', clienteData.num_endereco);
      setValueCard('des_complemento', clienteData.des_complemento);
      setValueCard('num_celular', clienteData.num_celular);
    }
  }, [clienteData, setValueCard, setValueClienteContratacao]);

  const handleScreen = useCallback(
    (newScreen: ScreenMeuPlano) => {
      if (newScreen === 'HOME') {
        resetClienteContratacao(resetClientData);
        resetFormCard(resetCreditCard);
        setCustomerDataToForm();
      }

      if (newScreen !== 'MUDAR-FORMA-PAGAMENTO') {
        setValueCard('tela', '');
      }
      setScreen(newScreen);
    },
    [
      resetClienteContratacao,
      resetFormCard,
      setCustomerDataToForm,
      setValueCard,
    ],
  );

  useEffect(() => {
    setCustomerDataToForm();
  }, [clienteData, setCustomerDataToForm]);

  return (
    <MeuPlanoContext.Provider
      value={{
        isDemonstracao,

        screen,
        handleScreen,

        planoPagamento,
        handlePlanoPagamento,

        contratacaoPlano,
        contratacaoPlanoFuturo,

        isFetchingContratacaoAtual,

        subScreen,
        handleSubScreen,

        methodFaturamento,
        handleMethodFaturamento,

        contratacaoCliente,
        contratacaoClienteFuturo,

        cicloAtual,
        cicloFuturo,
        cicloFuturoFormaPagamento,

        tipoStatus,
        isContratacaoCancelada,
        isPagamentoPendente,

        dtaDemonstracaoFim,

        formCard,

        codSeqPlano,

        desPlano,
        codAplicacao,

        formClienteContratacao,

        selectedMethod,
        setSelectedMethod,
        chaveBoleto,
        setChaveBoleto,

        tipoMudancaPlano,
        setTipoMudancaPlano,
        codCliente,
        clienteData,
        refetch,
        setCustomerDataToForm,
        valSelectedPlan,
        setValSelectedPlan,
        tipoStatusLicenca,
        setTipoStatusLicenca,
        refetchContratacaoAtual,
      }}
    >
      {children}
    </MeuPlanoContext.Provider>
  );
};
