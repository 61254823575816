import api from '~/services/api';
import { ResponsePdvProps, ResponseSerieNfceProps } from '../types';

export const getSerieNFCe = async (
  cod_loja: number,
): Promise<ResponsePdvProps<ResponseSerieNfceProps[]>> => {
  const { data } = await api.get<ResponsePdvProps<ResponseSerieNfceProps[]>>(
    `/pdvs/serie-nfce/${cod_loja}`,
  );

  return data;
};
