import React, { useCallback, useEffect, useState } from 'react';
import ToggleDefault from '~/components/ToggleDefault';
import { useRegraLoja } from '~/pages/RegraDeLoja/RegraLojaContext';
import { InputSelect } from '~/components/NovosInputs';
import api from '~/services/api';

interface IConta {
  cod_cc: number;
  des_cc: string;
  cod_banco: number;
  descricao: string;
}

const Pdvfinanceiro = () => {
  const {
    formRegraLoja,
    changeFormRegraLoja,
    formTela: {
      register,
      control,
      setValue,
      clearErrors,
      getValues,
      formState: { errors },
    },
  } = useRegraLoja();
  const [optionsContaBanco, setOptionsContaBanco] = useState([]);

  useEffect(() => {
    (async () => {
      const { cod_cc_abertura } = getValues();
      const { data } = await api.get(
        `/contasAbertura/loja/${formRegraLoja.cod_loja.value.value}`,
      );
      if (data.success) {
        if (data.message !== 'Nenhum registro encontrado.') {
          const options = data.data
            .filter((f: any) => f.flg_inativa !== true && f.descricao !== null)
            .map((conta: IConta) => ({
              label: conta.descricao,
              value: conta.cod_cc,
            }));

          if (cod_cc_abertura?.value) {
            const selectedConta = data.data.find(
              (conta: any) => cod_cc_abertura.value === conta.cod_cc,
            );
            setValue('cod_cc_abertura', {
              value: selectedConta.cod_cc,
              label: selectedConta.descricao,
            });
          }
          setOptionsContaBanco(options);
        }
      }
    })();
  }, [formRegraLoja.cod_loja.value.value]);

  const handleChangeContaBanco = useCallback(
    (val: { label: string; value: number }, isInvalid: boolean) => {
      setValue('cod_cc_abertura', val);
      changeFormRegraLoja({
        ...formRegraLoja,
        cod_cc_abertura: {
          ...formRegraLoja.cod_cc_abertura,
          value: val,
          isInvalid,
        },
      });
    },
    [changeFormRegraLoja, setValue],
  );
  return (
    <>
      <div className="row mt-5">
        <div className="col-sm-8 col-md-4">
          <ToggleDefault
            labelText="Não Confere Tesouraria?"
            setChecked={formRegraLoja.flg_ignora_tes.value}
            onSwitch={() => {
              setValue('flg_ignora_tes', !formRegraLoja.flg_ignora_tes.value);
              changeFormRegraLoja({
                ...formRegraLoja,
                flg_ignora_tes: {
                  ...formRegraLoja.flg_ignora_tes,
                  value: !formRegraLoja.flg_ignora_tes.value,
                },
              });
            }}
          />
        </div>
        <div className="col-sm-8 col-md-4">
          <ToggleDefault
            labelText="Tesouraria sem Turnos?"
            setChecked={formRegraLoja.flg_tes_sem_turno.value}
            onSwitch={() => {
              setValue(
                'flg_tes_sem_turno',
                !formRegraLoja.flg_tes_sem_turno.value,
              );
              changeFormRegraLoja({
                ...formRegraLoja,
                flg_tes_sem_turno: {
                  ...formRegraLoja.flg_tes_sem_turno,
                  value: !formRegraLoja.flg_tes_sem_turno.value,
                },
              });
            }}
          />
        </div>
        <div className="col-sm-8 col-md-4">
          <ToggleDefault
            labelText="Tesousaria sem Bloqueio por Caixas não Liberados?"
            setChecked={formRegraLoja.flg_tes_sem_bloq.value}
            onSwitch={() => {
              setValue(
                'flg_tes_sem_bloq',
                !formRegraLoja.flg_tes_sem_bloq.value,
              );
              changeFormRegraLoja({
                ...formRegraLoja,
                flg_tes_sem_bloq: {
                  ...formRegraLoja.flg_tes_sem_bloq,
                  value: !formRegraLoja.flg_tes_sem_bloq.value,
                },
              });
            }}
          />
        </div>
      </div>
      <div className="col-sm-8 col-md-4 mt-5">
        <InputSelect
          label="Conta Fundo de Abertura"
          placeholder="Selecione"
          name="cod_cc_abertura"
          register={register}
          isError={!!errors.cod_cc_abertura}
          control={control}
          options={optionsContaBanco}
          changeSelected={(selected: any) => {
            clearErrors('cod_cc_abertura');
            handleChangeContaBanco(
              selected,
              typeof selected.value !== 'number',
            );
          }}
        />
      </div>
    </>
  );
};

export default Pdvfinanceiro;
