import React, { useContext } from 'react';
import useAuth from '~/hooks/useAuth';
import { ButtonAssineJa, ContainerAssineJa, SpanMessage } from './style';
import { useHistory } from 'react-router-dom';
import { InformacaoTelasAcessadasContext } from '~/context/InformacaoTelasAcessadas';
import { Context } from '~/context/auth';
import api from '~/services/api';
import { toast } from 'react-toastify';

const BarraAssineJa: React.FC = () => {
  const { user } = useContext(Context);
  const { isFreeTrial, message } = useAuth();
  const history = useHistory();
  const { addScreenInfo } = useContext(InformacaoTelasAcessadasContext);

  if (!isFreeTrial) return null;

  const openPageOnClick = async () => {
    let meuPlano;
    try {
      const { data: dataUserPages } = await api.get(
        `/telasModulo/${user?.cod_controle}`,
      );

      if (dataUserPages.data) {
        meuPlano = dataUserPages.data
          .flatMap((modulo: any) => modulo.telas)
          .find((tela: any) => tela.cod_tela === 305);
      }
    } catch (error) {
      toast.warn(error);
    }
    if (meuPlano) {
      const telaAdd = {
        label: meuPlano.label_menu,
        active: true,
        lib_icon: meuPlano.lib_icon,
        des_icon: meuPlano.des_icon,
        cod_tela: meuPlano.cod_tela,
        url: meuPlano.url,
        linkMaterial: meuPlano.link_material,
        flg_abre_pesquisa: meuPlano.flg_abre_pesquisa,
      };

      addScreenInfo(telaAdd);

      history.push('meu-plano');
    }
  };

  return (
    <ContainerAssineJa className="barra-assine-ja">
      <SpanMessage>{message}</SpanMessage>
      <ButtonAssineJa
        className="botao-assine-ja"
        type="button"
        onClick={() => {
          openPageOnClick();
        }}
      >
        ASSINE AGORA
      </ButtonAssineJa>
    </ContainerAssineJa>
  );
};

export default BarraAssineJa;
