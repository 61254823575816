import React, { useCallback, useState, ChangeEvent } from 'react';
import { GridRowParams } from '@material-ui/data-grid';

import Spinner from '@atlaskit/spinner';
import { toast } from 'react-toastify';
import Search from '~/components/Search';

import { useForm } from 'react-hook-form';
import { schema } from './Validations';
import { yupResolver } from '@hookform/resolvers/yup';
import { Container } from './styles';
import FormDefault from '~/components/FormDefault';
import api from '~/services/api';
import { InputText, InputNumber2 } from '~/components/NovosInputs';

const CadastroDeBanco: React.FC = () => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });
  const [loader, setLoader] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);

  /**
   * Reseta form para criação de novo registro
   */
  const resetFormData = useCallback(() => {
    setUpdate(false);
    reset();
    setValue('cod_banco', '');
    setValue('des_banco', '');
  }, [reset, setValue]);

  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;

    const { cod_banco, des_banco } = row;

    setValue('des_banco', des_banco);
    setValue('cod_banco', cod_banco);

    setUpdate(true);
    setLoader(false);
    setShowSearch(false);
  };

  const onSave = handleSubmit(async (data1) => {
    try {
      if (isUpdate) {
        const { data } = await api.put(
          `/cadastro-de-banco/${data1.cod_banco}`,
          {
            des_banco: data1.des_banco.trim(),
          },
        );
        toast.success(data.message);
        resetFormData();
        setShowSearch(true);
        return;
      }
      const { data } = await api.post('/cadastro-de-banco', {
        des_banco: data1.des_banco.trim(),
        cod_banco: data1.cod_banco,
      });
      if (data.success) {
        toast.success(data.message);
        resetFormData();
        setShowSearch(false);
      }
    } finally {
      setLoader(false);
    }
  });

  const handleDelete = async () => {
    const { cod_banco } = getValues();
    const res = await api.delete(`/cadastro-de-banco/${cod_banco}`);
    const { success, message } = res.data;
    if (!success) throw new Error(message);
    resetFormData();
    setShowSearch(true);
    toast.success(message);
  };

  const onNew = () => {
    reset();
    resetFormData();
  };

  if (loader) {
    return (
      <Container>
        <div className="w-100 h-100 d-flex justify-conten-center align-items-center">
          <Spinner />
        </div>
      </Container>
    );
  }

  return (
    <Container>
      {showSearch && (
        <Search
          newData={() => setShowSearch(false)}
          onRowClick={onRowClick}
          codTela={180}
        />
      )}

      {!showSearch && (
        <FormDefault
          codTela={180}
          title="Banco"
          codigoRegistro={[
            { value: getValues('cod_banco'), des_campo: 'Código' },
          ]}
          onSave={async () => {
            await onSave();
          }}
          onCancel={() => {
            resetFormData();
            setShowSearch(true);
          }}
          isUpdate={isUpdate}
          onNew={() => onNew()}
          onDelete={handleDelete}
          onClearFields={() => {
            resetFormData();
          }}
          onReturnSearch={() => {
            setShowSearch(true);
            setUpdate(false);
            resetFormData();
          }}
          showSwitch={false}
        >
          <div className="row">
            {/* Number */}
            <div className="col-sm-12 col-md-2">
              <InputNumber2
                label="Código"
                name="cod_banco"
                maxLength={9}
                min={0.0}
                control={control}
                max={99999}
                placeholder="0"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setValue('cod_banco', Math.abs(Number(event.target.value)));
                }}
                register={register}
                disabled={isUpdate}
                isError={!!errors.cod_banco}
              />
            </div>
            <div className="col-sm-12 col-md-10">
              <InputText
                label="Descrição"
                maxLength={100}
                placeholder="Informe a Descrição"
                name="des_banco"
                control={control}
                register={register}
                disabled={false}
                isError={!!errors.des_banco}
                caseInput="upper"
              />
            </div>
          </div>
        </FormDefault>
      )}
    </Container>
  );
};

export default CadastroDeBanco;
