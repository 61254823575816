import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { GridRowParams } from '@material-ui/data-grid';

import Spinner from '@atlaskit/spinner';
import { toast } from 'react-toastify';
import Search from '~/components/Search';

import { Container } from './styles';
import FormDefault from '~/components/FormDefault';
import api from '~/services/api';
import { IPais } from './types';
import { transformAsCurrency } from '~/utils/functions';
import { InputNumber2, InputText } from '~/components/NovosInputs';
import { useForm } from 'react-hook-form';
import { schemaValidation } from './validations';
import { yupResolver } from '@hookform/resolvers/yup';
import defaultData from './defaultData/defaultData.json';

const CadastroPais: React.FC = () => {
  const {
    register,
    control,
    reset,
    getValues,
    watch,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaValidation),
  });
  const [loader, setLoader] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);
  /**
   * Reseta form para criação de novo registro
   */
  const resetFormData = () => {
    reset(defaultData);
  };

  const handleKeyDown = (event: any) => {
    if ([69, 109, 107, 194, 110].includes(event.keyCode)) {
      event.preventDefault();
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const { clipboardData } = event;
    const pastedText = clipboardData.getData('text');
    if (/[a-zA-Z]/.test(pastedText)) {
      const numericText = pastedText.replace(/[^0-9]/g, '');
      setValue('cod_pais', numericText);
      event.preventDefault();
    }
  };

  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;
    const { cod_pais, des_pais, val_pais } = row;

    setValue('cod_pais', cod_pais);
    setValue('des_pais', des_pais);
    setUpdate(true);
    setLoader(false);
    setShowSearch(false);
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      if (isUpdate) {
        const reqData = await api.put(`/pais/${data.cod_pais}`, {
          des_pais: data.des_pais,
        });
        if (reqData.data.success) {
          toast.success(reqData.data.message);
          resetFormData();
          setShowSearch(true);
          return;
        }
      }
      const reqData = await api.post('/pais', {
        cod_pais: data.cod_pais,
        des_pais: data.des_pais,
      });
      toast.success(reqData.data.message);
      resetFormData();
      setShowSearch(false);
    } finally {
      setLoader(false);
    }
  });

  const handleDelete = async () => {
    const codPais = getValues('cod_pais');
    const res = await api.delete(`/pais/${codPais}`);
    const { message } = res.data;
    resetFormData();
    setShowSearch(true);
    toast.success(message);
  };

  const onNew = () => {
    resetFormData();
    setUpdate(false);
  };

  if (loader) {
    return (
      <Container>
        <div className="w-100 h-100 d-flex justify-conten-center align-items-center">
          <Spinner />
        </div>
      </Container>
    );
  }

  return (
    <Container>
      {showSearch && (
        <Search
          newData={() => setShowSearch(false)}
          onRowClick={onRowClick}
          codTela={93}
        />
      )}

      {!showSearch && (
        <FormDefault
          codTela={93}
          title="Cadastro de País"
          onSave={async () => {
            await onSubmit();
          }}
          codigoRegistro={[
            {
              value: watch('cod_pais'),
              des_campo: 'Código',
            },
          ]}
          onCancel={() => {
            resetFormData();
            setShowSearch(true);
          }}
          isUpdate={isUpdate}
          onNew={() => onNew()}
          onDelete={handleDelete}
          onClearFields={() => resetFormData()}
          onReturnSearch={() => {
            setShowSearch(true);
            setUpdate(false);
            resetFormData();
          }}
          showSwitch={false}
        >
          <div className="row">
            {/* Number */}
            <div className="col-sm-12 col-md-2">
              <InputNumber2
                register={register}
                name="cod_pais"
                label="Código"
                control={control}
                placeholder="0"
                isError={!!errors.cod_pais}
                type="number"
                min={0}
                maxLength={4}
                max={9999}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  clearErrors('cod_pais');
                  if (event.target.value.length >= 4) {
                    event.target.value = event.target.value.slice(0, 4);
                  }
                  setValue('cod_pais', event.target.value);
                }}
                onPaste={handlePaste}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div className="col-sm-12 col-md-10">
              <InputText
                register={register}
                label="Descrição"
                placeholder="Informe a Descrição de País"
                control={control}
                isError={!!errors.des_pais}
                name="des_pais"
                minLength={1}
                caseInput="upper"
                maxLength={50}
              />
            </div>
          </div>
        </FormDefault>
      )}
    </Container>
  );
};

export default CadastroPais;
