import React from 'react';
import { GradeContainer } from './styles';
import { SkeletonItem } from '@atlaskit/menu';
import { Row, Col } from 'react-bootstrap';

export const AbaGrade: React.FC = () => {
  const RowSkeleton = () => {
    return (
      <>
        <Row>
          <Col sm={12} md={3}>
            <SkeletonItem width="100%" isShimmering />
          </Col>
          <Col sm={12} md={6}>
            <SkeletonItem width="100%" isShimmering />
          </Col>
          <Col sm={12} md={3}>
            <SkeletonItem width="100%" isShimmering />
          </Col>
        </Row>
      </>
    );
  };
  return (
    <GradeContainer>
      <Row>
        <Col sm={12} md={3}>
          <SkeletonItem width="60%" isShimmering />
        </Col>
        <Col sm={12} md={6}>
          <SkeletonItem width="60%" isShimmering />
        </Col>
        <Col sm={12} md={3}>
          <SkeletonItem width="100%" isShimmering />
        </Col>
      </Row>
      <RowSkeleton />
      <RowSkeleton />
      <RowSkeleton />
      <RowSkeleton />
      <RowSkeleton />
      <RowSkeleton />
      <RowSkeleton />
    </GradeContainer>
  );
};
