import React, { useCallback, useMemo, useState } from 'react';
import { IoDiamondOutline } from 'react-icons/io5';
import { useMeuPlano } from '../../hooks/useMeuPlano';
import { HeaderScreen } from '../HeaderScreen';
import { ContainerPlanosDisponiveis, ContentCard } from './styles';
import { DadosFaturamento } from './components/DadosFaturamento';
import { DadosConcluir } from '../DadosConcluir';
import { DadosCliente } from '../DadosCliente';
import { CustomButtonNovo } from '~/components/Buttons/CustomButtonNovo';
import { toast } from 'react-toastify';
import api from '~/services/api';
import { getYear } from 'date-fns';
import confetti from 'canvas-confetti';
import { formatCurrencyAsText } from '~/utils/functions';

type Steps = 'DADOS' | 'FATURAMENTO' | 'CONCLUIR' | 'FINALIZADO';
const stepsOrder: Steps[] = ['DADOS', 'FATURAMENTO', 'CONCLUIR', 'FINALIZADO'];

export const AvailablePlans: React.FC = () => {
  const {
    screen,
    tipoStatus,
    codSeqPlano,
    planoPagamento,
    handleScreen,
    methodFaturamento,
    formClienteContratacao,
    formCard,
    selectedMethod,
    setChaveBoleto,
    setTipoMudancaPlano,
    isDemonstracao,
    contratacaoCliente,
    setValSelectedPlan,
  } = useMeuPlano();

  const { getValuesClienteContratacao, triggerClienteContratacao } =
    formClienteContratacao;
  const { triggerCard, getValuesCard } = formCard;
  const [planoSelecionado, setPlanoSelecionado] = useState('');
  const [step, setStep] = useState<Steps>('DADOS');
  const [showLoading, setShowLoading] = useState(false);
  // const [planos, setPlanos] = useState<Plano[]>([]);

  const planos = useMemo(
    () => [
      {
        plano: 'Basic',
        content: ['PDV ONLINE', 'EMISSÃO DE NFE E NFCE', 'SUPORTE ATÉ 48H'],
        val_plano_mensal: 89.9,
        val_plano_anual: 899,
        economize: 0,
        cod_seq_plano: 1,
        // oservation: '',
      },
      {
        plano: 'Pro',
        content: [
          'TUDO DO BASIC',
          'INTEGRAÇÃO COM PERIFÉRICOS',
          'RELATÓRIOS POR I.A.',
          'SUPORTE POR CHAT',
        ],
        val_plano_mensal: 129.9,
        val_plano_anual: 1299,
        economize: 0,
        cod_seq_plano: 2,
      },
      {
        plano: 'Premium',
        content: [
          'TUDO DO PRO',
          'RECEPÇÃO AUTOMÁTICA DE XML',
          'INTEGRAÇÃO COM PDV SYG',
          'SUPORTE PRIORITÁRIO',
        ],
        val_plano_mensal: 175.9,
        val_plano_anual: 1759,
        economize: 0,
        cod_seq_plano: 3,
      },
    ],
    [],
  );

  const handlePlanSelected = useCallback(
    (plan: string) => {
      if (tipoStatus.contratacaoTransitoria) {
        toast.warning(
          'Mudança de plano aguardando vigência. O novo plano poderá ser selecionado quando o contrato futuro entrar em vigor.',
        );
        return;
      }
      if (!tipoStatus.contratacaoAtiva) {
        toast.warning(
          'Mudança de plano indisponível. Apenas contratos ativos podem ser alterados.',
        );
        return;
      }
      const selectedPlan = planos.find((plano) => plano.plano === plan);

      if (!selectedPlan) {
        toast.warn('Não foi possível recuperar o plano selecionado');
        return;
      }

      const val_plano =
        planoPagamento === 'ANUAL'
          ? selectedPlan?.val_plano_anual
          : selectedPlan.val_plano_mensal;

      setValSelectedPlan(val_plano);

      setPlanoSelecionado(plan);
      handleScreen('PLANOS-DISPONIVEIS-DADOS');
    },
    [handleScreen, planoPagamento, planos, setValSelectedPlan, tipoStatus],
  );

  const getStepIndex = (currentStep: Steps): number => {
    return stepsOrder.indexOf(currentStep);
  };

  const currentStepIndex = getStepIndex(step);

  const validaDadosCliente = useCallback(
    async (stp: string): Promise<boolean> => {
      if (stp === 'FATURAMENTO') {
        // Dispara a validação de todo o formulário de cliente
        const isValid = await triggerClienteContratacao();

        if (!isValid) {
          return false;
        }
      }
      return true;
    },
    [triggerClienteContratacao],
  );

  const validaDadosCartao = useCallback(
    async (stp: string): Promise<boolean> => {
      if (stp === 'CONCLUIR') {
        if (selectedMethod === 'cartao-credito') {
          const isValid = await triggerCard();

          if (!isValid) {
            return false;
          }
        }
      }
      return true;
    },
    [selectedMethod, triggerCard],
  );

  const getTipoCiclo = useCallback(() => {
    switch (planoPagamento) {
      case 'ANUAL':
        return 1;
      case 'MENSAL':
        return 2;
      default:
        return 2;
    }
  }, [planoPagamento]);

  const getTipoFormaPagamento = useCallback(() => {
    switch (selectedMethod) {
      case 'cartao-credito':
        return 1;
      default:
        return 0; // boleto
    }
  }, [selectedMethod]);

  const congrats = () => {
    const count = 200;
    const defaults = {
      origin: { y: 0.7 },
    };

    const fire = (particleRatio: any, opts: any) => {
      confetti({
        ...defaults,
        ...opts,
        particleCount: Math.floor(count * particleRatio),
      });
    };
    fire(0.25, {
      spread: 26,
      startVelocity: 55,
    });
    fire(0.2, {
      spread: 60,
    });
    fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  };

  /** @TODO - Abstrair e refatorar */
  const handleStep = useCallback(
    async (tipo: 'next' | 'prev') => {
      try {
        const nextNext =
          tipo === 'next' ? currentStepIndex + 1 : currentStepIndex - 1;
        const stp = stepsOrder[nextNext];

        // Valida Dados do Cliente
        if (!(await validaDadosCliente(stp))) {
          return;
        }

        // Valida Faturamento
        if (!(await validaDadosCartao(stp))) {
          return;
        }

        const selectedPlan = planos.find(
          (plan) => plan.plano === planoSelecionado,
        );

        if (!selectedPlan) {
          toast.warn('Não foi possível recuperar o plano selecionado');
          return;
        }

        const val_plano =
          planoPagamento === 'ANUAL'
            ? selectedPlan?.val_plano_anual
            : selectedPlan.val_plano_mensal;

        let operationType =
          selectedPlan.cod_seq_plano > codSeqPlano ? 'upgrade' : 'downgrade';

        if (isDemonstracao) {
          operationType = 'subscribe';
        }

        // Conclui contratacao via boleto
        if (stp === 'CONCLUIR') {
          if (selectedMethod === 'boleto') {
            setShowLoading(true);

            const tipo_forma_pagamento = getTipoFormaPagamento();

            setTipoMudancaPlano(operationType);

            const payload: any = {
              cod_seq_plano: selectedPlan?.cod_seq_plano,
              tipo_ciclo: getTipoCiclo(),
              val_plano,
              tipo_forma_pagamento,
              operationType,
            };

            const response = await api.post('/meu-plano/subscription', payload);

            if (!response.data.success || !response.data.data) {
              toast.error('Falha na criação da assinatura.');
              return;
            }

            if (response.data.data.des_boleto_codigo_barras)
              setChaveBoleto(response.data.data.des_boleto_codigo_barras);

            if (operationType === 'downgrade') {
              toast.success('Seu plano foi atualizado com sucesso.');
              congrats();
              handleScreen('HOME');
              return;
            }

            congrats();
            setStep(stp);
            return;
          }
        }

        // Conclui contratacao via cartão
        if (stp === 'FINALIZADO') {
          if (selectedMethod !== 'boleto') {
            setShowLoading(true);

            if (!selectedPlan) {
              toast.warn('Não foi possível recuperar o plano selecionado');
              return;
            }
            const tipo_forma_pagamento = getTipoFormaPagamento();

            setTipoMudancaPlano(operationType);

            const payload: any = {
              cod_seq_plano: selectedPlan?.cod_seq_plano,
              tipo_ciclo: getTipoCiclo(),
              val_plano,
              tipo_forma_pagamento,
              operationType,
            };

            if (tipo_forma_pagamento === 1) {
              const creditCard = getValuesCard();
              const clienteData = getValuesClienteContratacao();

              const { name, expiry, number, cvc } = creditCard;

              const {
                des_nome,
                des_email,
                num_cpf_cnpj,
                num_cep,
                des_numero,
                des_complemento,
                num_telefone,
                // num_celular,
              } = clienteData;

              const expiryMonth = expiry.split('/')[0];
              const expiryYear = expiry.split('/')[1];
              const anoAtual = getYear(new Date());
              const preYear = String(anoAtual).substring(0, 2);

              payload.creditCard = {
                holderName: name,
                number,
                expiryMonth,
                expiryYear: `${preYear}${expiryYear}`,
                ccv: cvc,
              };
              payload.creditCardHolderInfo = {
                name: des_nome,
                email: des_email,
                cpfCnpj: num_cpf_cnpj,
                postalCode: num_cep,
                addressNumber: des_numero,
                addressComplement: des_complemento,
                phone: num_telefone,
                mobilePhone: num_telefone,
              };
            }

            await api.post('/meu-plano/subscription', payload);

            if (operationType === 'downgrade') {
              toast.success('Seu plano foi atualizado com sucesso.');
              congrats();
              handleScreen('HOME');
              return;
            }
            congrats();
          }
        }

        setStep(stp);
      } catch (error) {
        console.error(error);
      } finally {
        setShowLoading(false);
      }
    },
    [
      codSeqPlano,
      currentStepIndex,
      getTipoCiclo,
      getTipoFormaPagamento,
      getValuesCard,
      getValuesClienteContratacao,
      handleScreen,
      isDemonstracao,
      planoPagamento,
      planoSelecionado,
      planos,
      selectedMethod,
      setChaveBoleto,
      setTipoMudancaPlano,
      validaDadosCartao,
      validaDadosCliente,
    ],
  );

  const onBack = useCallback(() => {
    // handleMethodFaturamento(null);
    if (step === 'DADOS') {
      setPlanoSelecionado('');
      setStep('DADOS');
      handleScreen('PLANOS-DISPONIVEIS');
    } else {
      const stp = stepsOrder[currentStepIndex - 1];
      setStep(stp);
    }
  }, [currentStepIndex, handleScreen, step]);

  /** Descomente para buscar os planos diretamente do banco de dados */
  // useEffect(() => {
  //   (async () => {
  //     const response = await api.get('/meu-plano/available-plans');

  //     const { success, data } = response.data;

  //     if (success && data.length > 0) {
  //       const plans = data.map((plano: PlanoResponse) => {
  //         let content = [''];
  //         if (plano.cod_seq_plano === 1)
  //           content = [
  //             'PDV ONLINE',
  //             'EMISSÃO DE NFE E NFCE',
  //             'SUPORTE ATÉ 48H',
  //           ];
  //         if (plano.cod_seq_plano === 2)
  //           content = [
  //             'TUDO DO BASIC',
  //             'INTEGRAÇÃO COM PERIFÉRICOS',
  //             'RELATÓRIOS POR I.A.',
  //             'SUPORTE POR CHAT',
  //           ];
  //         if (plano.cod_seq_plano === 3)
  //           content = [
  //             'TUDO DO PRO',
  //             'RECEPÇÃO AUTOMÁTICA DE XML',
  //             'INTEGRAÇÃO COM PDV SYG',
  //             'SUPORTE PRIORITÁRIO',
  //           ];

  //         return {
  //           plano: plano.des_nome,
  //           content,
  //           val_plano_mensal: plano.val_plano_mensal,
  //           val_plano_anual: plano.val_plano_anual,
  //           economize: 0,
  //           cod_seq_plano: plano.cod_seq_plano,
  //         };
  //       });
  //       plans.sort(
  //         (a: PlanoResponse, b: PlanoResponse) =>
  //           a.cod_seq_plano - b.cod_seq_plano,
  //       );
  //       console.log(plans);
  //       setPlanos(plans);
  //       return;
  //     }

  //     toast.warn('Planos não encontrados');
  //     handleScreen('HOME');
  //   })();
  // }, [handleScreen]);

  if (screen === 'HOME') {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <IoDiamondOutline size={130} color="#FFC107" />
        <p
          style={{
            textAlign: 'center',
            fontSize: '1.25rem',
            lineHeight: '1.875rem',
          }}
        >
          Planos disponíveis
        </p>
      </div>
    );
  }

  return (
    <ContainerPlanosDisponiveis>
      <HeaderScreen
        showButtonVoltar={screen === 'PLANOS-DISPONIVEIS'}
        showMensalAnual={screen === 'PLANOS-DISPONIVEIS'}
        showTrilha={screen === 'PLANOS-DISPONIVEIS-DADOS'}
        stepTrilha={currentStepIndex}
      />
      {screen === 'PLANOS-DISPONIVEIS' && (
        <ContentCard planoPagamento={planoPagamento}>
          {planos.map((plan) => {
            const isPlanoContratado =
              plan.cod_seq_plano === codSeqPlano &&
              contratacaoCliente?.tipo_ciclo ===
                (planoPagamento === 'ANUAL' ? 6 : 2);

            return (
              <div key={plan.plano} className="card-content">
                {/* Cabeçalho com o nome do plano */}
                <h2 className="plan-title">{plan.plano}</h2>

                {/* Preço */}
                <div className="plan-price">
                  <strong>
                    R${' '}
                    {planoPagamento === 'ANUAL'
                      ? formatCurrencyAsText(plan.val_plano_anual)
                      : formatCurrencyAsText(plan.val_plano_mensal)}
                  </strong>
                  <small>
                    {planoPagamento === 'ANUAL' ? 'no plano anual' : 'mês'}
                  </small>
                </div>

                {/* Informação do desconto */}
                {plan.economize > 0 && (
                  <div className="discount-info">25% OFF no plano anual</div>
                )}

                {/* Benefícios */}
                <ul className="plan-features">
                  {plan.content.map((cont) => (
                    <li key={cont}>{cont}</li>
                  ))}
                </ul>

                {/* Botão */}
                <button
                  type="button"
                  className="select-plan-button"
                  style={{
                    background: isPlanoContratado ? '#57069e' : 'white',
                    color: isPlanoContratado ? 'white' : '#57069e',
                    cursor:
                      isPlanoContratado && !isDemonstracao
                        ? 'not-allowed'
                        : 'pointer',
                  }}
                  onClick={() => {
                    if (isPlanoContratado) {
                      if (!isDemonstracao) {
                        toast.success(
                          `Você já possui o plano ${
                            plan.plano
                          } contratado no formato ${planoPagamento.toLowerCase()}.`,
                        );
                        return;
                      }
                    }
                    handlePlanSelected(plan.plano);
                  }}
                >
                  {isDemonstracao
                    ? isPlanoContratado
                      ? 'Efetivar Plano'
                      : 'Selecionar Plano'
                    : isPlanoContratado
                    ? 'Plano Contratado'
                    : 'Selecionar Plano'}
                </button>
              </div>
            );
          })}
        </ContentCard>
      )}
      {screen === 'PLANOS-DISPONIVEIS-DADOS' && (
        <>
          {step === 'DADOS' && (
            <DadosCliente plano={planoSelecionado} tipo={planoPagamento} />
          )}
          {step === 'FATURAMENTO' && (
            <DadosFaturamento plano={planoSelecionado} tipo={planoPagamento} />
          )}
          {step === 'CONCLUIR' && <DadosConcluir />}
          <section className="content-steps">
            {(step === 'CONCLUIR' && methodFaturamento === 'boleto') ||
            (step === 'FINALIZADO' &&
              methodFaturamento === 'cartao-credito') ? (
              <CustomButtonNovo
                disabled={false}
                onClick={() => handleScreen('HOME')}
                label="ACESSAR O SISTEMA"
                width="11.875rem"
              />
            ) : (
              <div className="footer-buttons">
                <CustomButtonNovo
                  disabled={showLoading}
                  onClick={onBack}
                  label={step === 'DADOS' ? 'Planos' : 'Voltar'}
                  width="11.875rem"
                  style={{ backgroundColor: '#BF5050' }}
                  outline
                />
                <CustomButtonNovo
                  disabled={
                    (step === 'FATURAMENTO' && !methodFaturamento) ||
                    showLoading
                  }
                  onClick={async () => handleStep('next')}
                  label={
                    stepsOrder[currentStepIndex + 1] === 'FINALIZADO'
                      ? 'PAGAR FATURA'
                      : stepsOrder[currentStepIndex + 1]
                  }
                  width="11.875rem"
                  showLoadingIcon={showLoading}
                />
              </div>
            )}
          </section>
        </>
      )}
    </ContainerPlanosDisponiveis>
  );
};
