/* eslint-disable no-lonely-if */
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  FieldErrorsImpl,
  FormState,
  useForm,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues, UseFormRegister, Control } from 'react-hook-form';
import { toast } from 'react-toastify';

import api from '~/services/api';
import { UfProps } from './protocols/UFProps';
import { ItemNFProps } from './protocols/ItemNFProps';
import { getItensFiscal } from './services/getItensFiscal';
import { getGTINs } from './services/getGTINs';
import { getCFOPs } from './services/getCFOPs';
import { getTributacoes } from './services/getTributacoes';
import { getNCM } from './services/getNCM';
import { getNfe } from './services/getNfe';
import { schema, schemaModal } from './validations';
import { RecalculaValores } from './function/RecalculaValores';
import { CorrigirCfopItens } from './function/CorrigirCFOP';
import { SelectModalProps, SelectProps } from './protocols/SelectProps';
import { TributacoesProps } from './protocols/TributacoesProps';
import { getEspecies } from './services/getEspecie';
import { ICEP } from '~/components/Pessoa/types';
import { CapaNFProps, NfeProps } from './protocols/NfeProps';
import {
  delZeroLeft,
  formatCurrencyAsText,
  strPadZeroL,
  transformAsCurrency,
  validarChaveNFe,
  validarCNPJ,
} from '~/utils/functions';
import { LojaContext } from '~/context/loja';
import { checkEstadualInterestadual } from './function/checkEstadualInterestadual';
import { verificarCFOPs } from './function/verificarCFOPs';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { cfopEnergia } from './function/cfopEnergia';
import { isBefore, parseISO } from 'date-fns';
import { verificarDescontosNegativos } from './function/verificarDescontosNegativos';

interface ManutencaoFiscalContextData {
  cfops: SelectProps[];
  especie: SelectProps[];
  tributacoesSelect: SelectProps[];
  nfes: NfeProps[];

  onClearForm: () => void;
  handleConferido: () => void;
  handleAplicarItens: () => void;
  handleRecalcularValores: () => void;
  handleCorrigirCFOPItens: () => void;
  handleCFOPS: (
    flg_interestadual: boolean,
    tipo_operacao: any,
    flg_cliente: boolean,
  ) => void;

  handleEspecie: () => void;
  handleTributacoes: () => void;
  searchCep: () => void;
  handleItemFiscalEdit: (value: ItemNFProps) => void;

  setValue: UseFormSetValue<FieldValues>;
  errors: FieldErrorsImpl<{ [x: string]: any }>;
  control: Control<FieldValues, any>;
  register: UseFormRegister<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;

  registerFiscal: UseFormRegister<FieldValues>;
  controlFiscal: Control<FieldValues, any>;
  setValueFiscal: UseFormSetValue<FieldValues>;
  formStateFiscal: FormState<FieldValues>;

  showSearch: boolean;
  setShowSearch: (showSearch: boolean) => void;

  update: boolean;
  setUpdate: (update: boolean) => void;

  currentTab: string;
  setCurrentTab: (item: string) => void;

  getFiscal: () => void;
  itensFiscal: ItemNFProps[];

  handleOpenModal: (value: boolean) => void;
  openModal: boolean;

  gtins: any;
  gtinsAtivos: any;

  ncms: SelectProps[];
  handleNfe: (value: NfeProps[]) => void;

  handleFlgProdutor: (value: boolean) => void;
  handleFlgLivro: () => void;
  flgProdutor: boolean;

  loadingFiscal: boolean;

  estados: SelectModalProps[];
  conferida: boolean;
  setConferida: (item: boolean) => void;
  flgLivro: boolean;
  setFlgLivro: (item: boolean) => void;
  setInitEspecie: (item: boolean) => void;
  initEspecie: boolean;
  setInitEstado: (item: boolean) => void;
  initEstado: boolean;
  handleNfAnt: (numNfAnt: number, numSerieAnt: number) => void;
  handleTipoEmitente: (tp: number | null) => void;
  handleTipoEmissao: (tp: number | null) => void;
  loadingIcon: string;
  onSave: () => void;
  getValuesFiscal: UseFormGetValues<FieldValues>;
  ncmFiscal: any;
  handleNcm: () => void;
  loader: boolean;
  setLoader: (val: boolean) => void;
  cidade: any;
  setCidade: React.Dispatch<React.SetStateAction<any>>;
  clearCidade: boolean;
  setClearCidade: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ProviderManutencaoFiscalProps {
  children: ReactNode;
}

export const ManutencaoFiscalContext = createContext(
  {} as ManutencaoFiscalContextData,
);

const MySwal = withReactContent(Swal);

export function ManutencaoFiscalContextProvider({
  children,
}: ProviderManutencaoFiscalProps) {
  const [update, setUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState<boolean>(true);
  const [currentTab, setCurrentTab] = useState<string>('documento');
  const [loadingIcon, setLoadingIcon] = useState<string>('');
  const [loadingFiscal, setLoadingFiscal] = useState<boolean>(false);
  const [estados, setEstados] = useState<SelectModalProps[]>([
    {
      value: undefined,
      label: '',
    },
  ]);

  const [itensFiscal, setItensFiscal] = useState<ItemNFProps[]>([]);
  const [ncmFiscal, setNcmFiscal] = useState<SelectModalProps>({
    value: undefined,
    label: '',
  });
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [gtins, setGtins] = useState<SelectProps[]>([]);
  const [gtinsAtivos, setGtinsAtivos] = useState<SelectProps[]>([]);
  const [cfops, setCfops] = useState<SelectProps[]>([]);
  const [especie, setEspecie] = useState<SelectProps[]>([]);
  const [tributacoes, setTributacoes] = useState<TributacoesProps[]>([]);
  const [tributacoesSelect, setTributacoesSelect] = useState<SelectProps[]>([]);
  const [ncms, setNcms] = useState<SelectProps[]>([]);
  const [nfes, setNfes] = useState<NfeProps[]>([]);
  const [flgProdutor, setFlgProdutor] = useState<boolean>(false);
  const [fiscalItenEdit, setFiscalItenEdit] = useState<any>(undefined);
  const [conferida, setConferida] = useState<boolean>(false);
  const [flgLivro, setFlgLivro] = useState<boolean>(false);
  const [initEspecie, setInitEspecie] = useState<boolean>(false);
  const [initEstado, setInitEstado] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [itensFiscalNFAnt, setItensFiscalNFAnt] = useState<ItemNFProps[]>([]);
  const [nfAnt, setNfAnt] = useState({ numNfAnt: 0, numSerieAnt: 0 });
  const [tipoEmitente, setTipoEmitente] = useState<number | null>(null);
  const [tipoEmissao, setTipoEmissao] = useState<number | null>(null);
  const [cidade, setCidade] = useState<any>({
    cidade: '',
    uf: '',
  });
  const [clearCidade, setClearCidade] = useState<boolean>(false);
  const { loja } = useContext(LojaContext);

  useEffect(() => {
    (async () => {
      const { data } = await api.get('/uf');

      if (data.success) {
        const options = data.data.map((ufData: UfProps) => ({
          label: ufData.des_sigla?.toUpperCase(),
          value: Number(ufData.cod_uf_ibge),
        }));
        setEstados(options);
      }
    })();

    setCurrentTab('documento');
  }, []);

  const {
    handleSubmit,
    register,
    control,
    reset,
    setValue,
    setError,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  const {
    register: registerFiscal,
    handleSubmit: handleSubmitFiscal,
    control: controlFiscal,
    getValues: getValuesFiscal,
    setValue: setValueFiscal,
    formState: formStateFiscal,
    reset: resetFiscal,
  } = useForm({
    resolver: yupResolver(schemaModal),
    reValidateMode: 'onBlur',
  });

  const formatToThreeDecimals = useCallback(
    (value: string | number): string => {
      const stringValue = value.toString();

      if (stringValue.includes(',')) {
        const [integerPart, decimalPart = ''] = stringValue.split(',');
        return `${integerPart},${decimalPart.padEnd(3, '0')}`;
      }
      if (stringValue.includes('.')) {
        const parts = stringValue.split('.');
        const integerPart = parts.slice(0, parts.length - 1).join('.');
        const decimalPart = parts[parts.length - 1];
        return `${integerPart},${decimalPart.padEnd(3, '0')}`;
      }

      return `${stringValue},000`;
    },
    [],
  );

  const onClearForm = async () => {
    reset();
    setFlgLivro(false);
    setNfes([]);
    setItensFiscal([]);
    setCidade({
      cidade: '',
      uf: '',
    });
    setClearCidade(false);
    setCurrentTab('documento');
  };

  const handleOpenModal = useCallback(
    (value: boolean) => {
      resetFiscal();
      setOpenModal(value);
    },
    [resetFiscal],
  );

  const handleNfAnt = useCallback((numNfAnt: number, numSerieAnt: number) => {
    setNfAnt({ numNfAnt, numSerieAnt });
  }, []);

  const handleTipoEmitente = useCallback((tp: number | null) => {
    setTipoEmitente(tp);
  }, []);

  const handleTipoEmissao = useCallback((tp: number | null) => {
    setTipoEmissao(tp);
  }, []);

  const onSave = handleSubmit(async (value) => {
    const cod_seq_nf = getValues('cod_seq_nf');
    const formData = value as CapaNFProps;
    const parsedDtaEntrada = parseISO(formData.dtaEntrada);
    const parsedDtaEmissao = parseISO(formData.dtaEmissao);

    const especieNF = value?.especie?.des_especie;

    if (!validarCNPJ(formData.num_documento))
      return toast.warning('CNPJ do parceiro deve ser válido');

    if (isBefore(parsedDtaEntrada, parsedDtaEmissao)) {
      await MySwal.fire({
        icon: 'warning',
        text: 'Data Entrada não deve ser anterior a Data Emissão.',
      });
      setError('dtaEntrada', { type: 'validate' });
      return;
    }

    if (especieNF === 'NFE' && formData.chave_nfe?.length <= 0) {
      const checkChave = await MySwal.fire({
        title: 'Chave NFe não informada, deseja prosseguir?',
        showCancelButton: true,
        confirmButtonColor: '#8850BF',
        cancelButtonColor: '#DE350B',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then((res) => {
        if (res.isDismissed) return true;
        return false;
      });

      if (checkChave) {
        setError('chave_nfe', { type: 'required' });
        return;
      }
    }

    if (formData.chave_nfe.length > 0 && especieNF === 'NFE') {
      if (formData.chave_nfe.length !== 44) {
        await MySwal.fire({
          icon: 'warning',
          html: `
            <p>Chave de Acesso deve possuir 44 dígitos.</p>
            <p>Chave de Acesso informada possui: ${formData.chave_nfe.length} dígitos.</p>
          `,
        });
        setError('chave_nfe', { type: 'validate' });
        return;
      }

      if (!validarChaveNFe(formData.chave_nfe)) {
        await MySwal.fire({
          icon: 'warning',
          text: 'Chave de Acesso informada inválida.',
        });
        setError('chave_nfe', { type: 'validate' });
        return;
      }

      const cnpjChaveNfe = formData.chave_nfe.slice(6, 6 + 14);

      if (
        cnpjChaveNfe !==
          strPadZeroL(formData.num_documento, 14).replace(/\D/g, '') &&
        tipoEmissao === 0
      ) {
        await MySwal.fire({
          icon: 'warning',
          html: `
            <p>CNPJ informado na Chave de Acesso é divergente do informado na Nota Fiscal.</p>
            <p>CNPJ da Chave de Acesso: ${cnpjChaveNfe}</p>
          `,
        });
        setError('chave_nfe', { type: 'validate' });
        return;
      }

      const numNfChaveNfe = formData.chave_nfe.slice(25, 25 + 9);

      if (
        parseInt(delZeroLeft(numNfChaveNfe), 10) !== Math.trunc(formData.num_nf)
      ) {
        await MySwal.fire({
          icon: 'warning',
          html: `
            <p>Número da NF da Chave de Acesso é divergente do informado na Nota Fiscal.</p>
            <p>Número da Nota Fiscal da Chave de Acesso: ${delZeroLeft(
              numNfChaveNfe,
            )}</p>
          `,
        });
        setError('chave_nfe', { type: 'validate' });
        return;
      }
    }

    const operacao = checkEstadualInterestadual({
      ufFornecedor: formData.cidades.ufValue,
      ufLoja: loja.des_uf,
    });

    const hasIncompatibleCFOP = verificarCFOPs(itensFiscal, operacao);

    if (hasIncompatibleCFOP) {
      await MySwal.fire({
        icon: 'info',
        html: `
          <p>Existem itens com CFOP's incompatível com a operação (estadual/interestadual).</p>
          <p>Regularize a situação para prosseguir.</p>
          `,
      });
      return;
    }

    const isCfopEnergia = cfopEnergia(formData, itensFiscal);

    if (isCfopEnergia) {
      const message =
        especieNF === 'NFCEE'
          ? 'Existem produtos com CFOP diferente do permitido para Energia Elétrica!'
          : 'Para CFOP de Energia Elétrica a espécie da nota deve ser NFCEE ou NFE!';

      await MySwal.fire({
        icon: 'warning',
        title: 'Regularize antes de prosseguir.',
        text: message,
      });
      return;
    }

    if (
      tipoEmitente === 0 &&
      Number(formData.num_nf) !== Number(nfAnt.numNfAnt)
    ) {
      await MySwal.fire({
        icon: 'warning',
        text: 'Não é possível alterar o número da nota para Notas Fiscais emitidas pela própria loja.',
      });
      setError('num_nf', { type: 'validate' });
      return;
    }
    if (
      tipoEmitente === 0 &&
      formData.numSerieNF.toString() !== nfAnt.numSerieAnt.toString()
    ) {
      await MySwal.fire({
        icon: 'warning',
        text: 'Não é possível alterar a série para Notas Fiscais emitidas pela própria loja.',
      });
      setError('numSerieNF', { type: 'validate' });
      return;
    }

    if (nfAnt.numSerieAnt !== formData.numSerieNF && formData.flg_confirmar) {
      await MySwal.fire({
        icon: 'warning',
        text: 'Não é permitido alterar a Série de NF à Confirmar.',
      });
      return;
    }

    if (verificarDescontosNegativos(itensFiscal))
      return toast.warning('Valor de desconto nos itens não pode ser negativo');

    if (conferida) return setShowSearch(true);

    formData.telefone = formData.telefone.replace(/\D/g, '');
    formData.num_documento = strPadZeroL(formData.num_documento, 14).replace(
      /\D/g,
      '',
    );
    formData.ie_rg = formData.ie_rg.replace(/\D/g, '');
    formData.cep = formData.cep.replace(/\D/g, '');

    const itensFiscalValue = itensFiscal.map((item) => {
      item.val_bc_icms = transformAsCurrency(item.val_bc_icms);
      item.val_bc_st = transformAsCurrency(item.val_bc_st);
      item.val_desconto = transformAsCurrency(item.val_desconto);
      item.val_despesa_acessoria = transformAsCurrency(
        item.val_despesa_acessoria,
      );
      item.val_frete = transformAsCurrency(item.val_frete);
      item.val_icms = transformAsCurrency(item.val_icms);
      item.val_icms_st = transformAsCurrency(item.val_icms_st);
      item.val_isento = transformAsCurrency(item.val_isento);
      item.val_outros = transformAsCurrency(item.val_outros);
      item.val_total = transformAsCurrency(item.val_total);
      item.qtd_total = transformAsCurrency(item.qtd_total);
      item.qtd_entrada = transformAsCurrency(item.qtd_entrada);
      return item;
    });

    try {
      setLoader(true);

      const { data } = await api.put(`/manutencao-fiscal/${cod_seq_nf}`, {
        ...formData,
        itens_fiscal: itensFiscalValue,
      });

      if (data.success) {
        setShowSearch(true);
        toast.success(data.message);
        setLoader(false);
        await onClearForm();
      }
    } catch (error: any) {
      setLoader(false);
      if (error && error.data) return;
      return toast.warning('NF não encontrada');
    }
  });

  const handleGtins = useCallback(async (cod_produto: string) => {
    const gtinsValues = await getGTINs(cod_produto);
    const gtinsValuesAtivos = gtinsValues.filter((i) => i.flg_inativo !== true);

    setGtinsAtivos(gtinsValuesAtivos);
    setGtins(gtinsValues);
  }, []);

  const handleNcm = useCallback(async () => {
    const tributacoesValues = await getNCM();
    setNcms(tributacoesValues);
  }, []);

  const handleTributacoes = useCallback(async () => {
    const tributacoesValues = await getTributacoes();
    const tributacao = tributacoesValues.map((item: any) => {
      return { label: item.des_tributacao, value: item.cod_tributacao };
    });
    setTributacoes(tributacoesValues);
    setTributacoesSelect(tributacao);
  }, []);

  const handleItemFiscalEdit = useCallback(
    (value: ItemNFProps) => {
      handleGtins(value.cod_produto);

      setTimeout(() => {
        setValueFiscal('cod_gtin', {
          label: value.cod_gtin,
          value: Number(value.cod_gtin),
        });
      }, 200);

      setValueFiscal('cfop', { label: value.cfop, value: value.cfop });
      setValueFiscal('descricao', value.des_produto);
      if (value.cod_ncm) {
        setValueFiscal(
          'num_ncm',
          ncms.filter((item: any) => item.value === value.cod_ncm)[0],
        );
        setNcmFiscal(
          ncms.filter((item: any) => item.value === value.cod_ncm)[0],
        );
      }

      if (value.cod_tributacao && tributacoesSelect) {
        setValueFiscal(
          'tributacao',
          tributacoesSelect.filter(
            (item: any) => item.value === value.cod_tributacao,
          )[0],
        );
      } else {
        setValueFiscal('tributacao', 0);
      }

      setValueFiscal('qtd_embalagem', value.qtd_embalagem);
      setValueFiscal('qtd_entrada', value.qtd_entrada);
      setValueFiscal('qtd_total', value.qtd_total);
      setValueFiscal(
        'val_total',
        formatCurrencyAsText(String(value.val_total ?? 0)),
      );
      setValueFiscal('val_desconto', value.val_desconto);
      setValueFiscal(
        'val_bc_icms',
        formatCurrencyAsText(formatToThreeDecimals(value.val_bc_icms ?? 0)),
      );

      setValueFiscal('val_isento', value.val_isento);
      setValueFiscal(
        'val_bc_st',
        formatCurrencyAsText(formatToThreeDecimals(value.val_bc_st ?? 0)),
      );
      setValueFiscal('des_embalagem', value.des_unidade.trim());
      setValueFiscal('acrescimo', '0,00');
      setValueFiscal('val_icms_op', value.val_icms);
      setValueFiscal('val_outros', value.val_outros);
      setValueFiscal(
        'st',
        formatCurrencyAsText(formatToThreeDecimals(value.val_icms_st ?? 0)),
      );
      setFiscalItenEdit(value.cod_seq_nf_item);
    },
    [
      handleGtins,
      ncms,
      setValueFiscal,
      tributacoesSelect,
      formatToThreeDecimals,
    ],
  );
  const searchCep = useCallback(async () => {
    if (loadingIcon !== 'cep') {
      setLoadingIcon('cep');
      let cep = getValues('cep');
      if (!cep) {
        toast.warning('CEP inválido');
        setLoadingIcon('');
        return;
      }
      cep = cep.replace(/[^\d]/g, '');
      if (cep === '') {
        toast.warning('CEP inválido');
        setLoadingIcon('');
        return;
      }
      try {
        const data: ICEP = await api.get(
          `https://brasilapi.com.br/api/cep/v2/${cep}`,
        );
        if (data.data && !data.message) {
          const uf = estados.filter(
            (estado: any) => estado.label === data.data.state,
          );
          setValue(
            'endereco',
            data.data.street && data.data.street.toUpperCase(),
          );
          setValue(
            'bairro',
            data.data.neighborhood && data.data.neighborhood.toUpperCase(),
          );
          setValue('cidade', data.data.city && data.data.city.toUpperCase());
          setValue('latitude', data.data.location.coordinates.latitude);
          setValue('longitude', data.data.location.coordinates.longitude);
          setCidade({
            uf: data.data.state ? data.data.state : '',
            cidade: data.data.city ? data.data.city.toUpperCase() : '',
          });
          setValue('estado', uf.length > 0 && uf[0]);
          setLoadingIcon('');
          return;
        }
        if (data.message) {
          toast.warn(data.message);
          setLoadingIcon('');
          return;
        }
      } catch (error: any) {
        setLoadingIcon('');
        return toast.warning('CEP não encontrado');
      }
      setLoadingIcon('');
    }
  }, [estados, getValues, loadingIcon, setValue]);

  const formatItensFiscal = useCallback(
    (itensFiscalNF: any[], keysToFormat: any[]): ItemNFProps[] => {
      return itensFiscalNF.map((item) => {
        keysToFormat.forEach((key) => {
          if (
            (key in item && typeof item[key] === 'number') ||
            typeof item[key] === 'string'
          ) {
            item[key] = formatToThreeDecimals(item[key] as number);
          }
        });
        return item;
      });
    },
    [formatToThreeDecimals],
  );

  const getFiscal = useCallback(async () => {
    const cod_seq_nf = getValues('cod_seq_nf');
    const itensFiscalNF = await getItensFiscal(cod_seq_nf);
    const keys = ['val_bc_icms', 'val_bc_st', 'val_icms_st'];
    setItensFiscal(formatItensFiscal(itensFiscalNF, keys));
    setItensFiscalNFAnt(formatItensFiscal(itensFiscalNF, keys));
  }, [getValues, formatItensFiscal]);

  const handleCFOPS = useCallback(
    async (
      flg_interestadual: boolean,
      tipo_operacao: number,
      flg_cliente: boolean,
    ) => {
      const cfopsValues = await getCFOPs(
        flg_interestadual,
        tipo_operacao,
        flg_cliente,
      );

      setCfops(cfopsValues);
    },
    [],
  );
  const handleEspecie = useCallback(async () => {
    const especiesValues = await getEspecies();
    setEspecie(especiesValues);
  }, []);

  const handleNfe = useCallback(async (value: any) => {
    if (value) {
      const NFEValues = await getNfe(value);
      setNfes(NFEValues);
    }
  }, []);

  const handleFlgProdutor = useCallback(async (value: boolean) => {
    setFlgProdutor(value);
  }, []);

  const handleLoadingFiscal = useCallback((value: any) => {
    setLoadingFiscal(value);
  }, []);

  const handleConferido = useCallback(async () => {
    const { flg_cancelada, flg_confirmar, cod_seq_nf } = getValues();
    if (flg_confirmar) {
      return toast.warning('Alteração não permitida, NF a Confirmar!');
    }
    if (flg_cancelada) {
      return toast.warning('Alteração não permitida, NF Cancelada!');
    }
    try {
      const { data } = await api.put(`/manutencao-fiscal/nfe/${cod_seq_nf}`, {
        flg_conferida: !conferida,
      });

      if (data) {
        setConferida(!conferida);
        setValue('flg_conferida', !conferida);
        if (!conferida) {
          toast.success('NF marcada como conferida');
        } else {
          toast.success('NF desmarcada');
        }
      }
    } catch (error: any) {
      return toast.warning('NF não encontrada');
    }
  }, [getValues, setValue, conferida]);

  const handleFlgLivro = useCallback(async () => {
    const { flg_cancelada, flg_confirmar, cod_seq_nf } = getValues();
    if (flg_confirmar) {
      return toast.warning('Alteração não permitida, NF a Confirmar!');
    }
    if (flg_cancelada) {
      return toast.warning('Alteração não permitida, NF Cancelada!');
    }
    try {
      const { data } = await api.put(
        `/manutencao-fiscal/nfe/flg-livro/${cod_seq_nf}`,
        {
          flg_livro: !flgLivro,
        },
      );

      if (data) {
        setFlgLivro(!flgLivro);
        setValue('flg_livro', !flgLivro);
        if (!flgLivro) {
          toast.success('NF marcada para gerar o Livro Fiscal');
        } else {
          toast.success('NF desmarcada para gerar o Livro Fiscal');
        }
      }
    } catch (error: any) {
      return toast.warning('NF não encontrada');
    }
  }, [getValues, setValue, flgLivro]);

  const handleAplicarItens = handleSubmitFiscal((values: any) => {
    const itensFiscalValue = JSON.parse(JSON.stringify(itensFiscal));

    const itensFiscalIndex = itensFiscalValue.findIndex(
      (i: any) => i.cod_seq_nf_item === fiscalItenEdit,
    );

    if (itensFiscalIndex !== -1) {
      itensFiscalValue[itensFiscalIndex].cfop = values.cfop.value;
      itensFiscalValue[itensFiscalIndex].cod_gtin = values.cod_gtin.value;
      itensFiscalValue[itensFiscalIndex].cod_ncm = values.num_ncm.value;
      itensFiscalValue[itensFiscalIndex].cod_tributacao =
        values.tributacao.value;
      itensFiscalValue[itensFiscalIndex].des_embalagem = values.des_embalagem;
      itensFiscalValue[itensFiscalIndex].des_ncm = values.num_ncm.label;
      itensFiscalValue[itensFiscalIndex].des_produto = values.descricao;
      itensFiscalValue[itensFiscalIndex].des_tributacao =
        values.tributacao.label;
      itensFiscalValue[itensFiscalIndex].des_unidade = values.des_embalagem;
      itensFiscalValue[itensFiscalIndex].num_ncm = values.num_ncm.value;
      itensFiscalValue[itensFiscalIndex].qtd_embalagem = values.qtd_embalagem;
      itensFiscalValue[itensFiscalIndex].qtd_entrada = values.qtd_entrada;
      itensFiscalValue[itensFiscalIndex].qtd_total = values.qtd_total;
      itensFiscalValue[itensFiscalIndex].val_bc_icms = values.val_bc_icms;
      itensFiscalValue[itensFiscalIndex].val_bc_st = values.val_bc_st;
      itensFiscalValue[itensFiscalIndex].val_desconto = values.val_desconto;
      itensFiscalValue[itensFiscalIndex].val_icms_st = values.st;
      itensFiscalValue[itensFiscalIndex].val_icms = values.val_icms_op;
      itensFiscalValue[itensFiscalIndex].val_isento = values.val_isento;
      itensFiscalValue[itensFiscalIndex].val_outros = values.val_outros;
      itensFiscalValue[itensFiscalIndex].val_total = values.val_total;
    }

    setItensFiscal(itensFiscalValue);

    handleOpenModal(false);
  });

  const handleRecalcularValores = async () => {
    handleLoadingFiscal(true);

    const flg_complementar = getValues('flg_complementar');
    const estado = getValues('cidades').ufValue;
    if (itensFiscalNFAnt.length > 0) {
      const recalculaValoresValue = await RecalculaValores(
        itensFiscal,
        itensFiscalNFAnt,
        tributacoes,
        flg_complementar,
        estado,
      );

      const valueRecalculada = recalculaValoresValue.map((item: any) => {
        item.val_isento = formatCurrencyAsText(item.val_isento);
        item.val_total = formatCurrencyAsText(item.val_total);
        item.val_bc_icms = formatCurrencyAsText(item.val_bc_icms);
        item.val_outros = formatCurrencyAsText(item.val_outros);
        return item;
      });
      setItensFiscal(valueRecalculada);
    }
    setTimeout(() => {
      handleLoadingFiscal(false);
      toast.success('Processamento concluído');
    }, 1000);
  };

  const handleCorrigirCFOPItens = () => {
    handleLoadingFiscal(true);

    const cfopItens = CorrigirCfopItens(itensFiscal, tributacoes);
    setTimeout(() => {
      setItensFiscal(cfopItens);
      handleLoadingFiscal(false);
      toast.success('Processamento concluído');
    }, 1000);
  };

  return (
    <ManutencaoFiscalContext.Provider
      value={{
        update,
        setUpdate,
        showSearch,
        setShowSearch,
        onClearForm,
        currentTab,
        setCurrentTab,
        setValue,
        errors,
        control,
        register,
        getValues,

        registerFiscal,
        controlFiscal,
        setValueFiscal,
        formStateFiscal,

        searchCep,
        getFiscal,
        itensFiscal,
        handleOpenModal,
        openModal,
        handleItemFiscalEdit,
        gtins,
        gtinsAtivos,
        especie,
        cfops,
        tributacoesSelect,
        ncms,
        handleNfe,
        nfes,
        handleFlgProdutor,
        flgProdutor,
        handleConferido,
        handleAplicarItens,
        handleRecalcularValores,
        handleCorrigirCFOPItens,
        handleCFOPS,
        handleEspecie,
        handleTributacoes,
        loadingFiscal,
        handleNfAnt,
        handleTipoEmitente,
        handleTipoEmissao,
        estados,
        conferida,
        setConferida,
        setInitEspecie,
        initEspecie,
        setInitEstado,
        initEstado,

        loadingIcon,
        onSave,
        getValuesFiscal,
        ncmFiscal,
        handleNcm,
        loader,
        setLoader,
        flgLivro,
        setFlgLivro,
        handleFlgLivro,
        cidade,
        setCidade,
        clearCidade,
        setClearCidade,
      }}
    >
      {children}
    </ManutencaoFiscalContext.Provider>
  );
}

export const useManutencaoFiscal = (): ManutencaoFiscalContextData => {
  return useContext(ManutencaoFiscalContext);
};
