import styled from 'styled-components';

export const ContainerAccordion = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const MethodContainerAccordion = styled.div`
  border: 1px solid #ccc;
  border-radius: 8px;
  /* overflow: hidden; */
`;

export const HeaderAccordion = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  background: #f9f9f9;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: #ececec;
  }
`;

export const CircleAccordion = styled.div<{ selected: boolean }>`
  width: 16px;
  height: 16px;
  border: 2px solid #007bff;
  border-radius: 50%;
  margin-right: 0.75rem;
  background: ${({ selected }) => (selected ? '#007bff' : 'transparent')};
  transition: background 0.3s, border 0.3s;
`;

export const LabelAccordion = styled.span`
  font-size: 1rem;
  color: #333;
`;

export const ContentAccordion = styled.div<{ isOpen: boolean }>`
  /* overflow: hidden; */
  max-height: ${({ isOpen }) => (isOpen ? 'auto' : '0')};
  transition: max-height 0.5s ease, padding 0.3s ease;
  padding: ${({ isOpen }) => (isOpen ? '1rem' : '0')};
  background: #fff;
`;
