import { Box, Tooltip } from '@material-ui/core';
import { DataGrid, GridColDef, GridColumns } from '@material-ui/data-grid';
import React, { useCallback } from 'react';
import { usePdvOnline } from '~/pages/PdvOnline/hooks/usePdvOnline';

export const GridPedidoItemPendente: React.FC = () => {
  const { itensPedidoPendente } = usePdvOnline();

  const createColumn = useCallback(
    (
      field: string,
      headerName: string,
      options: Partial<GridColDef> = {},
    ): GridColDef => ({
      field,
      headerName,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      ...options,
    }),
    [],
  );

  const columns: GridColumns = [
    createColumn('id', 'Id', {
      hide: true,
      width: 100,
      disableColumnMenu: true,
    }),
    createColumn('cod_gtin', 'EAN', {
      width: 125,
      disableColumnMenu: true,
      headerAlign: 'left',
      align: 'left',
    }),
    createColumn('des_produto', 'Descrição', {
      width: 260,
      disableColumnMenu: true,
      headerAlign: 'left',
      align: 'left',
    }),
    createColumn('des_unidade', 'UN. Cpa', {
      width: 85,
      disableColumnMenu: true,
      headerAlign: 'left',
      align: 'left',
    }),
    createColumn('qtd_embalagem', 'Emb.', {
      width: 65,
      disableColumnMenu: true,
      headerAlign: 'right',
      align: 'right',
    }),
    createColumn('qtd_pedido', 'Qtd.', {
      width: 60,
      disableColumnMenu: true,
      headerAlign: 'right',
      align: 'right',
    }),
    createColumn('val_pedido', 'Valor', {
      width: 100,
      disableColumnMenu: true,
    }),
    createColumn('val_total', 'Total', {
      width: 100,
      disableColumnMenu: true,
    }),
    createColumn('val_custo_rep', 'Custo Rep', {
      width: 100,
      disableColumnMenu: true,
    }),
  ];

  const renderTooltipColumns = (params: any) => (
    <Tooltip title={params.row.des_produto} placement="bottom">
      <div style={{ width: '100%', height: '100%' }}>{params.value}</div>
    </Tooltip>
  );

  const columnsWithTooltips = columns.map((col) => ({
    ...col,
    renderCell: renderTooltipColumns,
  }));

  return (
    <Box sx={{ height: 230, width: '100%' }}>
      <DataGrid
        rows={itensPedidoPendente}
        columns={columnsWithTooltips}
        disableColumnFilter
        disableColumnSelector
        disableSelectionOnClick
        disableColumnMenu
        hideFooterPagination
        hideFooterRowCount
        hideFooterSelectedRowCount
        hideFooter
        localeText={{
          noRowsLabel: 'Nenhum item encontrado',
          columnMenuLabel: 'Menu',
          columnMenuFilter: 'Filtrar',
          columnMenuHideColumn: 'Esconder',
          columnMenuUnsort: 'Não ordenar',
          columnMenuSortAsc: 'Ordernar ASC',
          columnMenuSortDesc: 'Ordernar DESC',
          columnMenuShowColumns: 'Mostrar colunas',
        }}
      />
    </Box>
  );
};
