import React, { useEffect, useState } from 'react';
import { IconWrapper, Line, ProgressContainer, Step } from './styles';
import { FaRegUser } from 'react-icons/fa';
import { FaRegCreditCard, FaRegHourglassHalf } from 'react-icons/fa6';
import { GoTrophy } from 'react-icons/go';
import { nanoid } from 'nanoid';

const icons = [
  { icon: FaRegUser, label: 'Dados' },
  { icon: FaRegCreditCard, label: 'Faturamento' },
  { icon: FaRegHourglassHalf, label: 'Concluir' },
  { icon: GoTrophy, label: 'Finalizado' },
];

export const ProgressBar: React.FC<{ step: number }> = ({ step }) => {
  const [previousStep, setPreviousStep] = useState<number>(step);

  useEffect(() => {
    if (step !== previousStep) setPreviousStep(step);
  }, [step, previousStep]);

  return (
    <ProgressContainer>
      {icons.map(({ icon: Icon, label }, index) => (
        <React.Fragment key={nanoid()}>
          <Step active={index <= step}>
            <IconWrapper active={index <= step}>
              <Icon />
            </IconWrapper>
            {label && <span>{label}</span>}
          </Step>
          {index < icons.length - 1 && (
            <Line
              progress={step > index ? 100 : 0}
              animate={previousStep !== step}
            />
          )}
        </React.Fragment>
      ))}
    </ProgressContainer>
  );
};
