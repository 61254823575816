import styled from 'styled-components';

export const InputsContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 0.4375rem;
  width: 100%;

  #busca_parceiro {
    width: 21.875rem;
  }

  @media only screen and (max-width: 1550px) {
    flex-direction: column;
    input,
    #busca_parceiro {
      width: 27.8125rem;
    }
  }
`;

export const CustomButtonsContainer = styled.section`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.4375rem;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
`;
