import React, { useState, useEffect } from 'react';
import { GridRowParams } from '@material-ui/data-grid';
import { Button } from 'react-bootstrap';
import Spinner from '@atlaskit/spinner';
import { toast } from 'react-toastify';
import Search from '~/components/Search';
import { Row, Col } from 'react-bootstrap';
import { Container } from './styles';
import FormDefault from '~/components/FormDefault';
import api from '~/services/api';
import { InputText } from '~/components/NovosInputs';
import Select from 'react-select';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './validations';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import optionsData from './Data/grade';

const MySwal = withReactContent(Swal);
const GradeDeProduto: React.FC = () => {
  const {
    register,
    control,
    reset,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });
  const [loader, setLoader] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [newOption, setNewOption] = useState(false);
  const [grades, setGrades] = useState<any[]>([]);
  const [variacoes, setVariacoes] = useState<
    { label: string; value: string }[]
  >([]);

  useEffect(() => {
    const OptionsDatas = optionsData.map((item: any) => ({
      label: item.label,
      value: item.value.map((v: any) => ({ label: v, value: v })),
    }));
    setGrades(OptionsDatas);
  }, []);

  /**
   * Reseta form para criação de novo registro
   */
  const formBlank = {
    des_grade: '',
    des_variacao: [],
    cod_seq_grade: null,
  };
  const resetFormData = () => {
    reset(formBlank);
    setVariacoes([]);
    setOpenModal(false);
    setNewOption(false);
    setUpdate(false);
  };

  if (openModal) {
    MySwal.fire({
      title: 'Importar Modelo de Grade',
      width: 1000,
      heightAuto: true,
      showCloseButton: true,
      showConfirmButton: false,
      html: `
        <table style="width:100%; border-collapse:collapse; font-family:Arial, sans-serif; font-size:14px;">
          <thead>
            <tr style="background-color:#f4f4f4; border-bottom:1px solid #ddd;">
              <th style="padding:8px;">Grade</th>
              <th style="padding:8px, margin-left:100px;">Variações</th>
              <th style="padding:8px;"></th>
            </tr>
          </thead>
          <tbody>
            ${grades
              .map(
                (item, index) => `
                  <tr>
                    <td style="padding:8px;">${item.label}</td>
                    <td style="padding:8px;">${item.value
                      .map((v: any) => v.label)
                      .join(', ')}</td>
                     <td style="padding:8px; text-align:center;">
                    <button
                      class="add-button"
                      data-index="${index}"
                      style="background-color:#6a1b9a; color:white; border:none; border-radius:50%; width:30px; height:30px; font-size:20px; line-height:0; cursor:pointer;"
                    >+</button>
                  </td>
                  </tr>
                `,
              )
              .join('')}
          </tbody>
        </table>
      `,
      didOpen: () => {
        const buttons = document.querySelectorAll('.add-button');
        buttons.forEach((button) => {
          button.addEventListener('click', (event: any) => {
            const index = event.target.getAttribute('data-index');
            const selectedItem = grades[index];
            setVariacoes((prevVariacoes: any[]) => {
              const novasVariacoes = selectedItem.value.filter(
                (novoItem: any) =>
                  !prevVariacoes.some(
                    (variacao: any) =>
                      variacao.label === novoItem.label &&
                      variacao.value === novoItem.value,
                  ),
              );
              if (novasVariacoes.length <= 0) {
                toast.warn('Variação já informada');
              } else {
                toast.success('Modelo importado com sucesso');
                setOpenModal(false);
              }
              return [...prevVariacoes, ...novasVariacoes];
            });
          });
        });
        setOpenModal(false);
      },
    });
  }

  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;
    const { cod_seq_grade, des_grade, des_variacoes } = row;

    const formattedVariacoes = des_variacoes
      .split(', ')
      .map((variacao: any) => ({ label: variacao, value: variacao }));

    setValue('cod_seq_grade', cod_seq_grade);
    setValue('des_grade', des_grade);
    setVariacoes(formattedVariacoes);
    setUpdate(true);
    setLoader(false);
    setShowSearch(false);
  };

  const onSave = handleSubmit(async (dataForm) => {
    if (variacoes.length <= 0) {
      return toast.warning('Selecione pelo menos uma variação');
    }
    try {
      const variacoesValues = variacoes.map((item) => item.value);
      if (isUpdate) {
        const { data } = await api.put(
          `/grade-de-produto/${dataForm.cod_seq_grade}`,
          {
            des_grade: dataForm.des_grade,
            des_variacao: variacoesValues,
          },
        );
        if (data.success) {
          toast.success(data.message);
          resetFormData();
          setShowSearch(true);
          return;
        }
      }
      const { data } = await api.post('/grade-de-produto', {
        des_grade: dataForm.des_grade,
        des_variacao: variacoesValues,
      });
      if (data.success) {
        toast.success(data.message);
        resetFormData();
        setShowSearch(false);
      } else {
        toast.warning(data.message);
      }
    } finally {
      setLoader(false);
    }
  });

  if (newOption) {
    MySwal.fire({
      title: 'Gravar nova opção na grade',
      input: 'text',
      inputPlaceholder: 'Digite a nova opção',
      showCancelButton: true,
      width: 750,
      inputAutoTrim: true,
      heightAuto: true,
      showCloseButton: true,
      confirmButtonText: 'Gravar',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#3F48CC',
      cancelButtonColor: '#ED1C24',
      preConfirm: (newGrade) => {
        if (!newGrade || newGrade.trim() === '') {
          Swal.showValidationMessage('O campo não pode estar vazio');
          return false;
        }

        const novaOpcao = {
          label: newGrade.trim().toUpperCase(),
          value: newGrade.trim().replace(/\s+/g, '-').toUpperCase(),
        };

        if (variacoes.some((item) => item.label === novaOpcao.label)) {
          Swal.showValidationMessage('Opção já existente');
          return false;
        }

        return novaOpcao;
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const novaOpcao = result.value as { label: string; value: string };
        setNewOption(false);
        toast.success('Opção adicionada com sucesso');
        setVariacoes((prev) => [...prev, novaOpcao]);
      } else {
        setNewOption(false);
      }
    });
  }

  const onNew = () => {
    resetFormData();
    setUpdate(false);
  };

  if (loader) {
    return (
      <Container>
        <div className="w-100 h-100 d-flex justify-conten-center align-items-center">
          <Spinner />
        </div>
      </Container>
    );
  }

  return (
    <Container>
      {showSearch && (
        <Search
          newData={() => setShowSearch(false)}
          onRowClick={onRowClick}
          codTela={306}
        />
      )}

      {!showSearch && (
        <FormDefault
          codTela={306}
          title="Grade de Produto"
          codigoRegistro={[
            {
              value: watch('cod_seq_grade'),
              des_campo: 'Código',
            },
          ]}
          onSave={onSave}
          onCancel={() => {
            resetFormData();
            setShowSearch(true);
          }}
          isUpdate={isUpdate}
          onNew={() => onNew()}
          onDelete={() => []}
          isDelete={false}
          onClearFields={() => resetFormData()}
          onReturnSearch={() => {
            setShowSearch(true);
            setUpdate(false);
            resetFormData();
          }}
          showSwitch={false}
        >
          <Row>
            <Row className="align-items-center">
              <Col md={7} sm={12}>
                <InputText
                  label="Descrição"
                  maxLength={50}
                  caseInput="upper"
                  placeholder="Informe a Descrição da grade"
                  name="des_grade"
                  register={register}
                  control={control}
                  isError={!!errors.des_grade}
                />
              </Col>
              <Col md={3} sm={12}>
                <Button
                  className="btn-importar"
                  onClick={() => setOpenModal(true)}
                  title="Importar Modelo"
                >
                  Importar Modelo
                </Button>
              </Col>
            </Row>
            <Col md={12} sm={10} className="mt-4">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '15px',
                }}
              >
                <div style={{ flex: 1 }}>
                  Variações
                  <Select
                    closeMenuOnSelect={false}
                    value={variacoes}
                    isMulti
                    options={grades}
                    placeholder="Selecione..."
                    onChange={(selected: any) => {
                      if (variacoes.length === 0) {
                        setVariacoes(
                          (selected[0] || [])?.value.map((item: any) => ({
                            label: item.label,
                            value: item.value,
                          })),
                        );
                      } else if (selected.length < variacoes.length) {
                        return setVariacoes(selected);
                      } else {
                        const novas = selected[selected.length - 1];
                        setVariacoes((prevVariacoes: any[]) => {
                          const novasVariacoes = Array.isArray(novas.value)
                            ? novas.value
                            : [];
                          const variacoesAtualizadas = novasVariacoes.filter(
                            (novoItem: any) =>
                              !prevVariacoes.some(
                                (variacao: any) =>
                                  variacao.label === novoItem.label &&
                                  variacao.value === novoItem.value,
                              ),
                          );
                          if (variacoesAtualizadas.length <= 0) {
                            toast.warn('Variação já informada');
                          }
                          return [...prevVariacoes, ...variacoesAtualizadas];
                        });
                      }
                    }}
                  />
                </div>
                <div
                  style={{
                    marginTop: '20px',
                  }}
                >
                  <Button
                    onClick={() => {
                      setNewOption(true);
                    }}
                  >
                    +
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </FormDefault>
      )}
    </Container>
  );
};

export default GradeDeProduto;
