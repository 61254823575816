import React, { useContext, useEffect } from 'react';
import { Container, ContainerLoader } from './styles';
import { useAlteracaoDePrecoPorItem } from './hooks/useAlteracaoDePrecoPorItem';
import DefaultLoader from '~/components/DefaultLoader';
import Loja from '~/components/Loja';
import FormDefault from '~/components/FormDefault';
import { BuscaProduto } from '~/components/NovosInputs';
import { LojaContext } from '~/context/loja';
import { ItemTable } from './components/ItemTable';
import { ScreenFields } from './components/ScreenFields';
import { formatCurrencyAsText } from '~/utils/functions';
import Separator from '~/components/Separator';
import { DataGrid } from '@material-ui/data-grid';
import { colunasUltEntrada } from './utils/colunasUltEntrada';
import Search from '~/components/Search';

export const AlteracaoDePrecoPorItemContent: React.FC = () => {
  const {
    loader,
    register,
    control,
    formState,
    setValue,
    changeLoja,
    handleChangeProduct,
    productSelected,
    codProduto,
    sugestaoVendaPorMargemFixa,
    handleLimparCampos,
    handleDisableCancelButton,
    detalhesNfFornecedor,
    handleDisableContent,
    disableContent,
    onSave,
    showSearch,
    onNew,
    onCancel,
    isUpdate,
    onRowClick,
  } = useAlteracaoDePrecoPorItem();

  const isSimilar = !!(
    productSelected &&
    productSelected.cod_similar &&
    productSelected.cod_similar > 0
  );

  const { loja } = useContext(LojaContext);

  if (loader) {
    return (
      <ContainerLoader>
        <DefaultLoader />
      </ContainerLoader>
    );
  }

  return (
    <Container>
      {showSearch && (
        <Search codTela={290} newData={onNew} onRowClick={onRowClick} />
      )}
      {!showSearch && (
        <FormDefault
          codTela={290}
          codigoRegistro={[{ value: codProduto, des_campo: 'Código' }]}
          title="Alteração de Preço por Item"
          onSave={async () => {
            await onSave();
          }}
          onCancel={onCancel}
          isUpdate={isUpdate}
          onNew={onNew}
          onDelete={() => []}
          onClearFields={handleLimparCampos}
          onReturnSearch={onCancel}
          showSwitch={false}
          isDelete={false}
        >
          <Loja
            selectedLoja={loja.cod_loja}
            IsInvalid={false}
            onChange={(e) => {
              changeLoja(typeof e === 'number' ? e : loja.cod_loja);
            }}
            isDisabled={false}
          />

          <section className="content-buscaproduto">
            <div style={{ width: '100%' }}>
              <BuscaProduto
                label="Buscar Produto"
                placeholder="Código PLU, GTIN ou Descrição"
                name="busca_produto"
                register={register}
                isError={!!formState.errors.busca_produto}
                disabled={false}
                control={control}
                buscaNasLojas={[loja.cod_loja]}
                customOptions={{
                  hideBuscarPor: true,
                  buscarPor: ['Produto'],
                  showSelecionarItensContendo: false,
                }}
                getProduct={async (selected: any) => {
                  setValue('busca_produto', {
                    value: selected[0].cod_produto,
                    label: selected[0].des_produto,
                  });

                  handleDisableCancelButton(false);
                  handleDisableContent(false);

                  await handleChangeProduct(
                    selected[0].cod_produto,
                    loja.cod_loja,
                  );
                }}
                style={{ width: '100%' }}
                setValue={setValue}
              />
            </div>
          </section>

          <section className="table-container mt-4">
            <ItemTable
              item={productSelected}
              cod_loja={loja.cod_loja}
              cod_produto={codProduto}
              sugestaoVendaPorMargem={sugestaoVendaPorMargemFixa}
              disableContent={disableContent}
            />
            {isSimilar && (
              <span
                style={{
                  backgroundColor: '#E9F2FF',
                  display: 'inline-flex',
                  width: '100%',
                  padding: '0.3125rem',
                  borderBottomLeftRadius: '0.3125rem',
                  borderBottomRightRadius: '0.3125rem',
                  fontSize: '1rem',
                  justifyContent: 'center',
                  color: 'black',
                }}
              >
                O produto está vinculado a Similares. Todos os similares
                vinculados também terão seu custo e preço alterados!
              </span>
            )}
          </section>
          <article className={`grid-container ${isSimilar ? 'mt-0' : 'mt-3'}`}>
            <section>
              <ScreenFields />
            </section>
            <section>
              <Separator labelText="Últimas Entradas" marginTop="0.625rem" />
              <div>
                <DataGrid
                  className="myDataGrid"
                  rows={detalhesNfFornecedor}
                  columns={colunasUltEntrada}
                  disableColumnMenu
                  hideFooterRowCount
                  hideFooterPagination
                  hideFooter
                  autoHeight
                  localeText={{
                    noRowsLabel: 'Nenhum registro encontrado...',
                    columnMenuLabel: 'Menu',
                    columnMenuFilter: 'Filtrar',
                    columnMenuHideColumn: 'Esconder',
                    columnMenuUnsort: 'Não ordenar',
                    columnMenuSortAsc: 'Ordernar ASC',
                    columnMenuSortDesc: 'Ordernar DESC',
                    columnMenuShowColumns: 'Mostrar colunas',
                  }}
                />
              </div>
            </section>
          </article>
        </FormDefault>
      )}
    </Container>
  );
};
