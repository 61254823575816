import styled from 'styled-components';

export const CreditCardContent = styled.div`
  display: flex;
  flex-direction: column;

  .inputs-content {
    .grid-container {
      display: grid;
      grid-template-columns: 57% 20% 20%;
      gap: 10px;
    }

    .endereco-container {
      display: grid;
      grid-template-columns: 30% 68%;
      gap: 10px;
    }

    .dados-basicos {
      display: grid;
      grid-template-columns: 24% 36% 36%;
      gap: 10px;
    }
  }
`;
