import styled from 'styled-components';
import { Button } from 'react-bootstrap';

export const SeparatorLine = styled.p`
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
  margin-top: 40px;
  margin-bottom: 0px;
`;
export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px;
  .alignNumber {
    text-align: right;
  }
  .alignDate {
    text-align: center;
  }
  h3 {
    margin-top: 20px;
  }

  .button-action {
    min-width: 90px;
    height: 40px;
  }

  .button-aceite {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr;
    background: #57069e;
    color: #fff;
    width: 100%;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    font-size: 1rem;
    border-radius: 0.25rem;
    &[disabled] {
      cursor: not-allowed;
    }
  }

  .toogle-inline {
    .toggleButton {
      margin-top: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row-reverse;
    }
  }

  .tbody-margem tr td,
  .thead-margem tr th {
    min-width: 130px;
  }
  .precos {
    background-color: rgb(136, 80, 191);
  }
  .precos:nth-child(2n) {
    background-color: rgb(123, 66, 180) !important;
  }
  .MargemRef {
    border-left: 1px solid rgba(255, 255, 255, 0.1);
  }

  .table-input {
    th {
      text-align: right;
      font-weight: 400;
    }

    .css-13fq05f {
      border: none;
      border-right: 1px solid #ccc;
      border-radius: 0px;
    }
  }
  .table tbody {
    border: 1px solid #dee2e6 !important;
  }
  .table tbody tr td {
    border-left: 1px solid #dee2e6;
  }
  .table tbody tr td input {
    width: 100%;
    height: 100%;
    border: none;
    box-sizing: border-box;
    &:disabled {
      border: none !important;
      background-color: transparent !important;
    }
  }
  .table thead tr th {
    padding-left: 0px;
    border: none;
    font-size: 0.8571428571428571em;
    font-style: inherit;
    color: #6b778c;
    font-weight: 600 !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  }

  @media screen and (max-width: 767px) {
    .containerTitle {
      max-width: 200px;
    }
    .containerChild {
      max-width: 200px !important;
      padding-left: 0px !important;
    }
  }
`;
export const Subtotal = styled.div`
  display: flex;
  width: 100%;
  height: 60px;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
`;
export const GroupItem = styled.div`
  display: grid;
  width: 100%;
  gap: 5px;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  button {
    align-items: center;
  }
  .label {
    color: #6b778c;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  }
`;
export const TableEmpty = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  h3 {
    font-size: 10pt;
    color: var(--ds-text-lowEmphasis, var(--ds-text-lowEmphasis, #6b778c));
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  }
`;

export const BtnGroupContainer = styled.div`
  display: flex;
  align-items: center;
  z-index: 0;
  color: #706f6f;
  font-family: 'Jost', sans-serif !important;
  font-weight: 500;
  font-size: 16px;
  .btn-group {
    cursor: pointer;
    border-radius: 0px;
    background-color: #ecedf0;
    color: rgb(37, 56, 88);
    border: none;
    font-size: 0.9rem;
    &:active {
      outline: none;
      box-shadow: none !important;
    }
    &:focus {
      outline: none;
      box-shadow: none !important;
    }
    &:hover {
      background-color: #8850bf;
      color: #fff !important;
    }
  }

  .btn-group-active {
    background-color: #57069e;
    color: #fff !important;

    &:hover {
      background-color: #57069e;
      color: #fff;
    }
  }
`;

export const CloseButton = styled(Button)`
  background-color: transparent;
  color: #000;
  border: none;

  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    color: #504e4e;
    box-shadow: none !important;
  }
`;

export const ButtonValidar = styled(Button)`
  margin-top: 30px;
`;

export const ButtonDeafult = styled.button`
  padding: 3px 12px;
  height: 40px;
  min-width: 160px;
  border-radius: 4px;
  gap: 5px;
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
  line-height: 26.01px;
  animation: all 0.25s ease-in;

  &:active,
  &:focus {
    border: none;
    outline: none;
  }

  &:hover {
    opacity: 0.7;
  }

  &.confirm {
    background-color: #28a745;
    &:disabled {
      background-color: #b7e5c1;
      cursor: not-allowed;
      opacity: 0.7;
      &:hover {
        background-color: #b7e5c1;
        opacity: 1;
      }
    }
  }
`;

export const InpuTypeDate = styled.input`
  background-color: transparent;
  border: 1px solid;
  border-color: #ccc;
  box-sizing: border-box;
  height: 2.5rem;
  padding: 5px;
  color: inherit;
  cursor: inherit;
  font-size: 14px;
  min-width: 0;
  width: 100%;
  line-height: 1.4285714285714286;
`;

export const ButtonMarcarDesmarcar = styled.button`
  float: right;
  padding: 8px 8px 8px 8px;
  background-color: transparent;
  border: none;
  border-radius: 5px;
  transition: 0.4s;
  color: #424242;

  &:hover {
    background-color: #f4f5f7;
    color: #000;
    transition: 0.4s;
  }
  &:focus {
    outline: 0;
  }
  svg {
    color: green !important;
  }
`;