import React, { useState, useEffect, useCallback } from 'react';
import { Form, Modal } from 'react-bootstrap';
import Tooltip from '@atlaskit/tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ClonarButton, Container, InputLojas } from './styles';
import api from '~/services/api';
import useAuth from '~/hooks/useAuth';
import { toast } from 'react-toastify';
import { GrFormClose } from 'react-icons/gr';
import { BootstrapModal } from '../Card/ModalCard/styles';
import { ButtonForm } from '../FormDefault/styles';
import { LuCopyPlus } from 'react-icons/lu';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { MdOutlineWarning } from 'react-icons/md';

const MySwal = withReactContent(Swal);

interface ILoja {
  cod_loja: number;
  des_loja: string;
  des_cidade: string;
  flg_padrao: boolean;
  flg_checked: boolean;
  des_uf: string;
  num_cnpj: string;
  tipo_regime: number;
  flg_inativa: boolean;
  val_pis: number;
  val_cofins: number;
  cst_pis_cof_entrada: string;
  cst_pis_cof_saida: string;
}
interface ISelectLoja {
  value: number;
  label: string;
  uf?: string;
  selected?: boolean;
}
interface ResponseLoja {
  data: ILoja[];
  success: boolean;
}
interface LojaProps {
  disabled?: boolean;
  selectedLoja?: number | Array<number> | undefined;
  resetLojas?: boolean;
  // onChange(value: number | Array<number>): void;
  onClick(value: number | Array<number>): void;
  onChangedObject?(value: any): void;
  isMulti?: boolean;
  isDisabled?: boolean;
  IsInvalid?: boolean;
  ncm: any;
  tipoItem: any;
}
/**
 *
 * @param labelText Texto que aparecerá em frente a linha de separação
 * @returns Componente para separar áreas do formulário como dados pessoais, contato, endereço, etc.
 */
const CadastroEmMultiplasLojas: React.FC<LojaProps> = ({
  selectedLoja,
  disabled,
  resetLojas = false,
  isMulti = false,
  IsInvalid = false,
  isDisabled = false,
  onClick,
  onChangedObject,
  ncm,
  tipoItem,
}) => {
  const [loading, setLoading] = useState(true);
  const [all, setAll] = useState(false);
  const [lojas, setLojas] = useState<ILoja[]>([]);
  const [optionsSelect, setOptionsSelect] = useState<ISelectLoja[]>([]);
  const [optionsSelectActive, setOptionsSelectActive] = useState<ISelectLoja[]>(
    [],
  );
  const [selectedLojas, setSelectedLojas] = useState<any>([]);
  const [value, setValue] = useState<ISelectLoja | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [originalLojas, setOriginalLojas] = useState<ILoja[]>([]);
  const [originalSelectedLojas, setOriginalSelectedLojas] = useState<number[]>(
    [],
  );
  const { user } = useAuth();

  const validaTipificacaoUf = async (lojasADuplicar: ILoja[]) => {
    const res = await api.post('/produto/ncm-departamento/', {
      cod_ncm: ncm.value,
    });

    const { data } = res.data;
    const ncmData = data;

    const lojasSemTipificacao = lojasADuplicar.filter((loja) => {
      const hasTipificacao = ncmData.some(
        (item: any) =>
          item.des_sigla === loja.des_uf &&
          tipoItem.value === item.cod_tipo_item,
      );
      return !hasTipificacao;
    });

    const desUfSemTipificacao = lojasSemTipificacao.map((loja) => loja.des_uf);

    if (lojasSemTipificacao.length > 0) {
      const AlertTable = (
        <div>
          <div>
            <MdOutlineWarning style={{ fontSize: '50px', color: '#FDDF54' }} />
            <h5>
              NCM selecionado não tem tributação informada para a unidade
              federativa da loja.
            </h5>
          </div>
          <div>
            Vá até o cadastro de NCM e adicione a tributação referente ao estado
          </div>
          <div>{desUfSemTipificacao.map((loja) => loja)}</div>
        </div>
      );

      MySwal.fire({
        width: 650,
        html: AlertTable,
      });

      return true;
    }

    return false;
  };

  useEffect(() => {
    if (resetLojas) {
      if (lojas.length === 1) {
        return handleSetAllLojas();
      }
      const arrayLojas = lojas;

      lojas.map((loja, index) => {
        if (loja.flg_padrao === true) {
          arrayLojas[index].flg_checked = true;
          return;
        }

        arrayLojas[index].flg_checked = false;
        return arrayLojas;
      });

      setAll(false);
      setLojas(arrayLojas);
      const newValue = [];
      newValue.push(user?.loja);

      const arrayFiltrado: number[] = newValue.filter(
        (n: number | undefined): n is number => typeof n === 'number',
      );

      setSelectedLojas(arrayFiltrado);
    }
  }, [resetLojas]);

  const handleSetAllLojas = () => {
    if (!all) {
      setAll(true);
      const newLojas = lojas.map((l: ILoja) => ({
        ...l,
        flg_checked: true,
      }));
      setLojas(newLojas);
      const newValue = newLojas.map((l: ILoja) => l.cod_loja);
      setSelectedLojas(newValue);
    } else {
      setAll(false);
      const newLojas = lojas.map((l: ILoja) => ({
        ...l,
        flg_checked: l.cod_loja === Number(user?.loja),
      }));
      setLojas(newLojas);
      setSelectedLojas(isMulti ? [newLojas[0].cod_loja] : []);
    }
  };
  const onChangeValue = (id: number) => {
    const newLojas = lojas.map((l: ILoja) => {
      if (l.cod_loja === id) {
        return {
          ...l,
          flg_checked: !l.flg_checked,
        };
      }
      return l;
    });
    const existeLojaSelecionada = newLojas.filter(
      (loja) => loja.flg_checked === true,
    );

    if (existeLojaSelecionada.length > 0) {
      const isAnyLojaSelected = newLojas.find(
        (bool) => bool.flg_checked === true,
      );
      if (!isAnyLojaSelected) {
        setAll(false);
      }
      const isAnyLojaUnSelected = newLojas.find(
        (bool) => bool.flg_checked === false,
      );
      if (!isAnyLojaUnSelected) {
        setAll(true);
      } else {
        setAll(!true);
      }
      setLojas(newLojas);
      const filteredLojas = newLojas.filter((l: ILoja) => l.flg_checked);
      const newValue = filteredLojas.map((l: ILoja) => l.cod_loja);

      // Filtrar as lojas com base nos códigos fornecidos
      const lojasFiltradas = lojas.filter((loja: any) =>
        newValue.includes(loja),
      );
      if (onChangedObject) onChangedObject(lojasFiltradas);
      setSelectedLojas(filteredLojas);
    } else {
      toast.warning('Ao menos uma loja deve estar selecionada');
    }
  };

  const getLojas = useCallback(async () => {
    setLoading(true);
    try {
      if (user && user.id) {
        const { data } = await api.get<ResponseLoja>(`/lojas/usuario`);
        if (
          Array.isArray(selectedLoja) &&
          selectedLoja.length === data.data.length
        ) {
          setAll(true);
        }

        if (data.success) {
          const newLojas = (() => {
            // Verifica se selectedLoja corresponde a algum cod_loja das lojas
            const isSelectedLojaPresent = data.data.some((l) =>
              Array.isArray(selectedLoja)
                ? selectedLoja.includes(l.cod_loja)
                : selectedLoja === l.cod_loja,
            );

            return data.data
              .filter((l: ILoja) => l.flg_inativa !== true)
              .map((l: ILoja) => {
                let isSelected = false;

                if (isSelectedLojaPresent) {
                  // Se selectedLoja corresponder a algum cod_loja, marca apenas essas lojas
                  isSelected = Array.isArray(selectedLoja)
                    ? selectedLoja.includes(l.cod_loja)
                    : selectedLoja === l.cod_loja;
                } else {
                  // Se nenhum cod_loja corresponder a selectedLoja, marca a loja padrão
                  isSelected = l.flg_padrao;
                }

                return {
                  ...l,
                  flg_checked: isSelected,
                };
              });
          })();

          setLojas(newLojas);
          const filteredLojas = newLojas.filter((l: ILoja) => l.flg_checked);
          const newValue = filteredLojas.map((l: ILoja) => l.cod_loja);
          setSelectedLojas(newValue);
        } else {
          const createOption = (loja: ILoja, selected: boolean) => {
            return {
              label:
                loja.cod_loja < 10
                  ? `0${loja.cod_loja} - ${loja.des_loja} (${loja.des_cidade})`
                  : `${loja.cod_loja} - ${loja.des_loja} (${loja.des_cidade})`,
              value: loja.cod_loja,
              selected,
              des_loja: loja.des_loja,
              uf: loja.des_uf,
              cod_loja: loja.cod_loja,
              num_cnpj: loja.num_cnpj,
              tipo_regime: loja.tipo_regime,
            };
          };

          const options = [] as ISelectLoja[];
          const optAtivo = [] as ISelectLoja[];

          data.data.forEach((loja: ILoja) => {
            const selected = loja.cod_loja === selectedLoja;

            if (loja.flg_inativa !== true) {
              optAtivo.push(createOption(loja, selected));
            }
            options.push(createOption(loja, selected));

            if (selected) {
              setValue({ value: loja.cod_loja, label: loja.des_loja });
            }
          });

          setOptionsSelect(options);
          setOptionsSelectActive(optAtivo);

          const opt = options.find((o) => o.selected === true);
          if (opt) setSelectedLojas([opt.value]);
        }
      }
    } finally {
      setLoading(false);
    }
  }, [isMulti, user]);

  useEffect(() => {
    getLojas();
  }, [getLojas]);
  useEffect(() => {
    const loja = optionsSelect.find((lj) => lj.value === selectedLoja);
    if (loja) {
      setValue(loja);
    } else {
      setValue(null);
    }
  }, [optionsSelect, selectedLoja]);
  useEffect(() => {
    if (lojas.length === 1) {
      setAll(true);
    }
  }, [lojas]);

  const handleCloseModal = () => {
    setLojas(originalLojas);
    setSelectedLojas(originalSelectedLojas);
    setModalOpen(false);
  };

  const handleDelete = async () => {
    await MySwal.fire({
      title: `Ao clonar um produto para outra loja, os seguintes dados NÃO serão transportados:`,
      html: `
        <div style="max-height: 150px; border: solid 1px #dcdcdc; overflow: auto; text-align: center; padding: 10px; display: flex; align-items: center; justify-content: center;">
          <ul style="text-align: left; margin: 0; padding: 0;">
            <li>Associado</li>
            <li>Vasilhame</li>
            <li>Custos</li>
          </ul>
        </div>
      `,
      icon: 'info',
      confirmButtonText: 'Estou Ciente',
    });
  };

  const clearInfos = () => {
    setOriginalSelectedLojas([]);
    setOriginalLojas([]);
    setSelectedLojas([]);
    setOptionsSelectActive([]);
    setOptionsSelect([]);
    setModalOpen(false);
  };

  const handleClick = async () => {
    const valueFiltered = selectedLojas.filter((loja: any) =>
      Array.isArray(selectedLoja)
        ? !selectedLoja.some((selected) => selected === loja.cod_loja)
        : loja.cod_loja !== selectedLoja,
    );
    if (selectedLojas !== originalSelectedLojas) {
      const validaUF = await validaTipificacaoUf(valueFiltered);
      if (validaUF) {
        setLojas(originalLojas);
        clearInfos();
        return;
      }
      await handleDelete();
      const newValue = valueFiltered.map((item: any) => item);
      clearInfos();
      onClick(newValue);
    }
    clearInfos();
  };
  return (
    <>
      <ClonarButton
        type="button"
        onClick={() => {
          setModalOpen(true);
          setOriginalLojas([...lojas]);
          setOriginalSelectedLojas(selectedLojas);
        }}
      >
        <LuCopyPlus size={18} />
        Clonar em outra loja
      </ClonarButton>
      <Modal
        show={modalOpen}
        centered
        onHide={() => handleCloseModal()}
        dialogClassName="custom-modal"
      >
        <BootstrapModal.Header>
          <BootstrapModal.Title>Cadastrar em outras Lojas</BootstrapModal.Title>
          <GrFormClose
            size={20}
            style={{ cursor: 'pointer' }}
            onClick={() => handleCloseModal()}
          />
        </BootstrapModal.Header>
        <BootstrapModal.Body>
          {/* Lista de Lojas */}
          <div className="col-12">
            <label
              htmlFor="input-lojas"
              className="sc-iqAclL lflqij css-1bwberi-Label"
            >
              Lojas
            </label>
            <InputLojas id="input-lojas" IsInvalid={IsInvalid}>
              {loading && (
                <CircularProgress
                  size={15}
                  style={{ color: '#8850bf', margin: '10px 0px 0px 10px' }}
                />
              )}
              {lojas &&
                lojas.map((loja: any, index: any) => {
                  const isDisabledSelected =
                    (Array.isArray(selectedLoja) &&
                      selectedLoja.includes(loja.cod_loja)) ||
                    selectedLoja === loja.cod_loja;
                  return (
                    <Tooltip
                      position="top"
                      content={`LOJA ${loja.cod_loja} - ${loja.des_loja}`}
                      key={loja.cod_loja}
                    >
                      <div
                        onClick={() => {
                          if (!isDisabledSelected) onChangeValue(loja.cod_loja);
                        }}
                      >
                        <Form.Check
                          className="ButtonLoja"
                          type="checkbox"
                          disabled={isDisabledSelected}
                          label={`${loja.cod_loja} - ${loja.des_loja}`}
                          checked={loja.flg_checked}
                          onChange={() => null}
                          style={{
                            backgroundColor: loja.flg_checked
                              ? '#57069e'
                              : '#8850bf',
                            cursor: isDisabledSelected
                              ? 'not-allowed'
                              : 'pointer',
                          }}
                        />
                      </div>
                    </Tooltip>
                  );
                })}
            </InputLojas>
          </div>
        </BootstrapModal.Body>
        <div className="d-flex justify-content-center aliggn-center w-full mt-2 mb-4">
          <ButtonForm onClick={handleClick}>Cadastrar</ButtonForm>
        </div>
        <BootstrapModal.Footer />
      </Modal>
    </>
  );
};
export default CadastroEmMultiplasLojas;
