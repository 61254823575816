import React, { ReactNode, useCallback } from 'react';
import { ContainerCardMeuPlano } from './styles';
import { useMeuPlano } from '../../hooks/useMeuPlano';
import { ScreenMeuPlano } from '../../types/context';

interface CardMeuPlanoProps {
  content: ReactNode;
  screenOpen: ScreenMeuPlano;
}

export const CardMeuPlano: React.FC<CardMeuPlanoProps> = ({
  content,
  screenOpen,
}) => {
  const { isDemonstracao, handleScreen, tipoStatus } = useMeuPlano();

  const handleClick = useCallback(() => {
    if (
      screenOpen === 'PLANOS-DISPONIVEIS' ||
      screenOpen === 'GERENCIAR-ASSINATURA'
    ) {
      handleScreen(screenOpen);
    }

    if (
      screenOpen === 'MUDAR-FORMA-PAGAMENTO' &&
      isDemonstracao &&
      tipoStatus.tipoStatus === 2
    ) {
      handleScreen(screenOpen);
    }

    if (isDemonstracao) {
      return;
    }

    handleScreen(screenOpen);
  }, [handleScreen, isDemonstracao, screenOpen, tipoStatus]);

  return (
    <ContainerCardMeuPlano onClick={handleClick}>
      {content}
    </ContainerCardMeuPlano>
  );
};
