import Spinner from '@atlaskit/spinner';
import { GridRowParams } from '@material-ui/data-grid';
import React, {
  useCallback,
  useEffect,
  useState,
  ChangeEvent,
  useContext,
} from 'react';

import Swal from 'sweetalert2';

import withReactContent from 'sweetalert2-react-content';

import { toast } from 'react-toastify';
import Search from '~/components/Search';
import { Col, Row } from 'react-bootstrap';
import FormDefault from '~/components/FormDefault';
import api from '~/services/api';
import { Container } from './styles';
import { Especie } from './types';
import { InputNumber, InputSelect } from '~/components/NovosInputs';
import Loja from '~/components/Loja';
import { LojaContext } from '~/context/loja';
import ToggleDefault from '~/components/ToggleDefault';
import useAuth from '~/hooks/useAuth';
import { useForm } from 'react-hook-form';
import { schema } from './Validations';
import { yupResolver } from '@hookform/resolvers/yup';

const MySwal = withReactContent(Swal);

const Operador: React.FC = () => {
  const user = useAuth();
  const { loja } = useContext(LojaContext);
  const [loader, setLoader] = useState<boolean>(false);
  const [lojas, setLojas] = useState<number>(loja.cod_loja);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [flagInativo, setFlagInativo] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    setValue,
    getValues,
    watch,
    reset,
  } = useForm({
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const [optionsSelectEspecie, setOptionsSelectEspecie] = useState([]);
  const [manterDados, setManterDados] = useState(false);

  /**
   * Evento disparado ao clicar em uma linha da tabela
   * @param param
   */
  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;
    setUpdate(true);

    const {
      num_serie,
      cod_loja,
      cod_especie,
      des_especie,
      des_doc_fiscal,
      num_ult_nf_emitida,
      des_loja,
      flg_inativo,
    } = row;

    setLojas(cod_loja);
    setValue('cod_loja', {
      value: cod_loja,
      label: `${cod_loja}-${des_loja}`,
    });
    setValue('num_serie', num_serie);
    setValue('cod_especie', {
      label: `${des_especie} - ${des_doc_fiscal}`,
      value: cod_especie,
    });
    setFlagInativo(flg_inativo);
    setValue('num_ult_nf_emitida', num_ult_nf_emitida);
    setValue('flg_inativo', flg_inativo);

    setLoader(true);
    setLoader(false);

    setShowSearch(false);
  };

  /**
   * Reseta form para criação de novo registro
   */
  const formBlank = {
    num_serie: null,
    cod_especie: 0,
    num_ult_nf_emitida: 0,
    flg_inativo: false,
  };
  const resetFormData = () => {
    setUpdate(false);
    reset(formBlank);
    setFlagInativo(false);
    setLojas(loja.cod_loja);
  };

  /**
   * Listagem de especies no select
   */
  useEffect(() => {
    (async () => {
      const { data } = await api.get('/especies');

      if (data.success) {
        const options = data.data
          .filter(
            (especie: Especie) =>
              especie.cod_doc_fiscal === '65' ||
              especie.cod_doc_fiscal === '55',
          )
          .map((especie: Especie) => ({
            label: `${especie.des_especie} - ${especie.des_doc_fiscal}`,
            value: especie.cod_especie,
          }));

        setOptionsSelectEspecie(options);
      }
    })();
  }, []);

  /**
   * Evento reponsavel por cadastrar ou editar um Operador
   * @return
   */

  const onSave = handleSubmit(async (dataForm) => {
    try {
      if (isUpdate) {
        const { data } = await api.put(`/serienf/${dataForm.num_serie}`, {
          cod_loja: lojas,
          cod_especie: dataForm.cod_especie.value,
          num_ult_nf_emitida: dataForm.num_ult_nf_emitida,
          flg_inativo: flagInativo,
        });
        if (data.success) {
          toast.success(data.message);
          setLoader(false);
          setShowSearch(true);
          setUpdate(false);
        }
      } else {
        const { data } = await api.post('/serienf', {
          num_serie: Number(dataForm.num_serie),
          cod_loja: lojas,
          cod_especie: dataForm.cod_especie.value,
          num_ult_nf_emitida: dataForm.num_ult_nf_emitida,
          flg_inativo: flagInativo,
        });
        if (data.success) {
          toast.success(data.message);
          resetFormData();
        }
      }
    } catch (error) {
      console.log(error);
    }
  });

  const handleSwitch = useCallback(() => {
    setManterDados(!manterDados);
  }, [manterDados]);

  const onNew = () => {
    resetFormData();
    setUpdate(false);
  };

  if (loader) {
    return (
      <Container>
        <div className="w-100 h-100 d-flex justify-conten-center align-items-center">
          <Spinner />
        </div>
      </Container>
    );
  }

  return (
    <Container>
      {showSearch && (
        <Search
          codTela={15}
          newData={() => {
            resetFormData();
            setShowSearch(false);
          }}
          onRowClick={onRowClick}
        />
      )}

      {!showSearch && (
        <FormDefault
          codTela={15}
          title="Cadastro de Série de NF"
          codigoRegistro={[
            { value: getValues('num_serie'), des_campo: 'Código' },
          ]}
          onSave={async () => {
            await onSave();
          }}
          onCancel={() => {
            resetFormData();
            setShowSearch(true);
          }}
          isUpdate={isUpdate}
          onNew={() => onNew()}
          onDelete={() => []}
          onClearFields={() => resetFormData()}
          onReturnSearch={() => {
            setShowSearch(true);
            setUpdate(false);
            resetFormData();
          }}
          onSwitch={handleSwitch}
          isDelete={false}
        >
          <Row>
            <Col sm={12} md={10}>
              <Loja
                selectedLoja={lojas}
                IsInvalid={false}
                onChange={async (e: number) => {
                  setValue('cod_loja', e);
                  setLojas(e);
                }}
                isDisabled={isUpdate}
              />
            </Col>
            <Col sm={12} md={2} className="align">
              <InputNumber
                label="Série"
                name="num_serie"
                placeholder={watch('num_serie') || '0'}
                maxLength={999}
                min={0}
                max={9999999}
                isError={!!errors.num_serie}
                disabled={isUpdate}
                control={control}
                register={register}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (
                    e.target.value.toString().length <= 7 &&
                    !e.target.value.toString().includes('-')
                  ) {
                    setValue('num_serie', Number(e.target.value));
                  }
                }}
              />
            </Col>
            <Col sm={12} md={6}>
              <InputSelect
                label="Espécie"
                name="cod_especie"
                placeholder="Informe a espécie"
                register={register}
                options={optionsSelectEspecie}
                isError={!!errors.cod_especie}
                control={control}
                changeSelected={(selected) => {
                  setValue('cod_especie', selected);
                }}
              />
            </Col>

            <Col sm={12} md={3}>
              <InputNumber
                label="Última Emissão NF"
                name="num_ult_nf_emitida"
                placeholder="0"
                maxLength={999}
                min={0}
                max={9999999}
                control={control}
                register={register}
                isError={!!errors.num_ult_nf_emitida}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (
                    e.target.value.toString().length <= 5 &&
                    !e.target.value.toString().includes('-')
                  ) {
                    setValue('num_ult_nf_emitida', e.target.value);
                  }
                }}
              />
            </Col>

            <Col sm={12} md={2}>
              <ToggleDefault
                labelText="Inativo?"
                setChecked={flagInativo}
                onSwitch={() => {
                  setValue('flg_inativo', !flagInativo);
                  setFlagInativo(!flagInativo);
                }}
              />
            </Col>
          </Row>
        </FormDefault>
      )}
    </Container>
  );
};

export default Operador;
