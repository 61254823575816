import styled from 'styled-components';
import { AccountStatus } from '../../types/AccountStatus';

export const ContentCurrentPlan = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;

  .plano-atual {
    display: flex;
    align-items: center;

    gap: 0.625rem;

    h3 {
      padding-top: 0.5rem;
    }
  }

  .screen-plano-atual {
    display: flex;
    flex-direction: column;

    .info-plano {
      display: flex;
      flex-direction: column;
      margin-top: 1.25rem;

      border: 0.0625rem solid #57069e;
      padding: 1.25rem;
      border-radius: 0.5rem;

      .badges {
        div {
          margin-top: 0.625rem;
          display: flex;
          align-items: center;
          gap: 0.625rem;
        }
      }

      .info-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: end;

        span {
          font-size: large;
        }
      }
    }

    .buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 1.4375rem;
    }
  }
`;

export const AccountCard = styled.div<{
  status: AccountStatus;
  borderColor: string;
}>`
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 0.4375rem;
  padding-left: 0.625rem;
  background: ${({ status }) => {
    switch (status) {
      case 'TRIAL':
        return 'linear-gradient(45deg, #B6D7A8, #D9EAD3, #A1C181)';
      case 'ENTRARA-EM-VIGOR':
        return 'linear-gradient(45deg, #9FC5F8, #CFE2F3, #7BA9E1)';
      case 'AGUARDANDO-CONFIRMACAO-PAGAMENTO':
        return 'linear-gradient(45deg, #FFE599, #FFF2CC, #FCD77E)';
      case 'FALHA-PROCESSAMENTO-PAGAMENTO':
        return '#EA9999';
      case 'CONTINUAR-USANDO-SISTEMA':
        return '#B6D7A8';
      default:
        return '#ffffff';
    }
  }};

  border: ${({ borderColor }) => `1px solid ${borderColor}`};

  p {
    color: #333;
    font-size: 1.1563rem;
    line-height: 1.7344rem;
    padding-top: 0.625rem;
    font-weight: 500;
  }
`;
