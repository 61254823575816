import Payment from 'payment';

const clearNumber = (value: string) => value.replace(/\D+/g, '');

const formatCreditCardNumber = (value: string): string => {
  if (!value) return value;

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case 'amex':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10,
      )} ${clearValue.slice(10, 15)}`;
      break;
    case 'dinersclub':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10,
      )} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8,
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();
};

export const formatExpirationDate = (value: string): string => {
  let clearValue = clearNumber(value);

  if (clearValue.length > 4) clearValue = clearValue.slice(0, 4);

  if (clearValue.length > 2)
    clearValue = `${clearValue.slice(0, 2)}/${clearValue.slice(2)}`;

  return clearValue;
};

const formatCellphone = (value: string): string => {
  const onlyNums = clearNumber(value);

  if (onlyNums === '') return '';

  if (onlyNums.length <= 2) return `(${onlyNums}`;
  if (onlyNums.length <= 7)
    return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2)}`;
  if (onlyNums.length <= 11)
    return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 7)}-${onlyNums.slice(
      7,
      11,
    )}`;
  return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 7)}-${onlyNums.slice(
    7,
    11,
  )}`;
};

const formatPhone = (value: string): string => {
  const onlyNums = clearNumber(value);

  if (onlyNums === '') return '';

  if (onlyNums.length <= 2) return `(${onlyNums}`;
  if (onlyNums.length <= 6)
    return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2)}`;
  if (onlyNums.length <= 10)
    return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 6)}-${onlyNums.slice(
      6,
      10,
    )}`;
  return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 6)}-${onlyNums.slice(
    6,
    10,
  )}`;
};

const formatCEP = (value: string): string => {
  const onlyNums = clearNumber(value);

  if (onlyNums === '') return '';

  if (onlyNums.length <= 5) return onlyNums;
  if (onlyNums.length <= 8)
    return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5)}`;

  return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5, 8)}`;
};

export const formatUtils = {
  formatCreditCardNumber,
  formatExpirationDate,
  formatCellphone,
  formatPhone,
  formatCEP,
};
