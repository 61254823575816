import React from 'react';
import { ButtonPropsNovo } from './types';
import { IconWrapper, StyledButtonNovo } from './styles';
import { CircularProgress } from '@material-ui/core';
import { Spinner } from 'react-bootstrap';

/**
 * Componente de botão personalizado com suporte a ícones e estado de carregamento.
 *
 * @param {ButtonPropsNovo} props - Propriedades do botão.
 * @param {'button' | 'submit' | 'reset'} [props.type='button'] - Tipo do botão.
 * @param {'confirm' | 'cancel' | 'clear' | 'default'} [props.variant='default'] - Variante de estilo do botão.
 * @param {boolean} [props.outline=false] - Variante de estilo do botão.
 * @param {string} [props.width='9.375rem'] - Largura do botão.
 * @param {string} [props.height='2.5rem'] - Altura do botão.
 * @param {React.ReactNode} props.children - Conteúdo do botão.
 * @param {IconType} [props.icon] - Componente do ícone a ser exibido no botão.
 * @param {'left' | 'right'} [props.iconPosition='left'] - Posição do ícone no botão.
 * @param {boolean} [props.showLoading=false] - Indica se o estado de carregamento deve ser exibido.
 * @param {string | null} [props.label=null] - Texto a ser exibido no botão. Se `null`, o `children` será exibido.
 * @param {string} [props.className] - Classe CSS adicional para estilização personalizada.
 * @param {React.ButtonHTMLAttributes<HTMLButtonElement>} rest - Outras propriedades de `ButtonHTMLAttributes<HTMLButtonElement>`.
 * @returns {JSX.Element} O botão personalizado.
 */

export const CustomButtonNovo: React.FC<ButtonPropsNovo> = ({
  type = 'button',
  variant = 'default',
  width = '9.375rem',
  height = '2.5rem',
  children,
  icon,
  iconPosition = 'left',
  showLoading = false,
  label = null,
  className,
  outline = false,
  showLoadingIcon = false,
  ...rest
}) => {
  const Icon = icon;

  return (
    <StyledButtonNovo
      type={type}
      variant={variant}
      width={width}
      height={height}
      className={className}
      outline={outline}
      {...rest}
    >
      {showLoadingIcon && (
        <Spinner
          animation="border"
          size="sm"
          style={{ marginRight: '0.4375rem' }}
        />
      )}

      {Icon && iconPosition === 'left' && (
        <IconWrapper position={iconPosition}>
          {showLoading && (
            <CircularProgress
              size={15}
              style={{ marginTop: '-0.125rem', color: 'inherit' }}
            />
          )}
          {!showLoading && (
            <Icon
              focusable={false}
              aria-hidden="true"
              size={20}
              style={{ marginTop: '-0.125rem' }}
            />
          )}
        </IconWrapper>
      )}

      {label ?? children}

      {Icon && iconPosition === 'right' && (
        <IconWrapper position={iconPosition}>
          {showLoading && (
            <CircularProgress
              size={15}
              style={{ marginTop: '-0.125rem', color: 'inherit' }}
            />
          )}
          {!showLoading && (
            <Icon
              focusable={false}
              aria-hidden="true"
              size={20}
              style={{ marginTop: '-0.125rem', color: '#fff' }}
            />
          )}
        </IconWrapper>
      )}
    </StyledButtonNovo>
  );
};
