import React, { createContext, ReactNode, useContext } from 'react';

interface CancelamentoNFeContextProviderProps {
  children: ReactNode;
}

interface CancelamentoNFeContextData {
  teste: string;
}

export const CancelamentoNFeContext = createContext(
  {} as CancelamentoNFeContextData,
);

export function CancelamentoNFeContextProvider({
  children,
}: CancelamentoNFeContextProviderProps): JSX.Element {
  return (
    <CancelamentoNFeContext.Provider
      value={{
        teste: '',
      }}
    >
      {children}
    </CancelamentoNFeContext.Provider>
  );
}

export const useCancelamentoNFE = (): CancelamentoNFeContextData => {
  return useContext(CancelamentoNFeContext);
};
