import React, { useCallback, useEffect, useState, ChangeEvent } from 'react';
import { GridRowParams } from '@material-ui/data-grid';

import Spinner from '@atlaskit/spinner';
import { toast } from 'react-toastify';
import Search from '~/components/Search';

import { Container } from './styles';
import FormDefault from '~/components/FormDefault';
import api from '~/services/api';
import { InputNumber2, InputText } from '~/components/NovosInputs';
import { useForm } from 'react-hook-form';
import { schema } from './Validations';
import { yupResolver } from '@hookform/resolvers/yup';

const TipoDeDados: React.FC = () => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });
  const [loader, setLoader] = useState<boolean>(false);
  const [iniInicializado, setInitInicializado] = useState(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);

  /**
   * Reseta form para criação de novo registro
   */
  const resetFormData = useCallback(() => {
    setUpdate(false);
    reset();
    setValue('tamanho', '');
    setValue('des_tipo_dados', '');
  }, [reset, setValue]);

  useEffect(() => {
    if (iniInicializado) setInitInicializado(false);
  }, []);

  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;

    const { cod_tipo_dados, des_tipo_dados, tamanho } = row;

    setValue('cod_tipo_dados', cod_tipo_dados);
    setValue('des_tipo_dados', des_tipo_dados);
    setValue('tamanho', tamanho);

    setUpdate(true);
    setLoader(false);
    setShowSearch(false);
  };

  const onSave = handleSubmit(async (data1) => {
    try {
      if (isUpdate) {
        const { data } = await api.put(
          `/tipo-de-dados/${data1.cod_tipo_dados}`,
          {
            tamanho: data1.tamanho,
            des_tipo_dados: data1.des_tipo_dados,
          },
        );
        if (data.success) {
          toast.success(data.message);
          resetFormData();
          setShowSearch(true);
          return;
        }
      }
      const { data } = await api.post('/tipo-de-dados', {
        tamanho: data1.tamanho,
        des_tipo_dados: data1.des_tipo_dados.trimStart(),
      });
      toast.success(data.message);
      resetFormData();
      setShowSearch(false);
    } finally {
      setLoader(false);
    }
  });

  const handleDelete = async () => {
    const { cod_tipo_dados } = getValues();
    const res = await api.delete(`/tipo-de-dados/${cod_tipo_dados}`);
    const { success, message } = res.data;
    if (!success) throw new Error(message);
    resetFormData();
    setShowSearch(true);
    toast.success(message);
  };

  const onNew = () => {
    resetFormData();
    setUpdate(false);
  };

  if (loader) {
    return (
      <Container>
        <div className="w-100 h-100 d-flex justify-conten-center align-items-center">
          <Spinner />
        </div>
      </Container>
    );
  }

  return (
    <Container>
      {showSearch && (
        <Search
          newData={() => setShowSearch(false)}
          onRowClick={onRowClick}
          codTela={179}
        />
      )}

      {!showSearch && (
        <FormDefault
          codTela={179}
          title="Tipo de Dados"
          codigoRegistro={[
            {
              value: getValues('cod_tipo_dados'),
              des_campo: 'Código',
            },
          ]}
          onSave={async () => {
            await onSave();
          }}
          onCancel={() => {
            resetFormData();
            setShowSearch(true);
          }}
          isUpdate={isUpdate}
          onNew={() => onNew()}
          onDelete={handleDelete}
          onClearFields={() => resetFormData()}
          onReturnSearch={() => {
            setShowSearch(true);
            setUpdate(false);
            resetFormData();
          }}
          showSwitch={false}
        >
          <div className="row">
            <div className="col-sm-12 col-md-10">
              <InputText
                label="Descrição"
                maxLength={30}
                placeholder="Informe a Descrição"
                name="des_tipo_dados"
                control={control}
                register={register}
                disabled={false}
                isError={!!errors.des_tipo_dados}
                toLowerCase={false}
              />
            </div>
            <div className="col-sm-12 col-md-2">
              <InputNumber2
                label="Tamanho"
                name="tamanho"
                maxLength={9999}
                min={0.0}
                control={control}
                max={999}
                placeholder="0"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  const { value } = event.target;
                  if (value.length <= 5) {
                    setValue('tamanho', Number(value));
                  }
                }}
                register={register}
                isError={!!errors.tamanho}
              />
            </div>
          </div>
        </FormDefault>
      )}
    </Container>
  );
};

export default TipoDeDados;
