/* eslint-disable no-bitwise */
export const shadeColor = (color: string, percent: number): string => {
  const num = parseInt(color.replace('#', ''), 16);
  const amt = Math.round(2.55 * percent);

  const r = Math.min(255, Math.max(0, Math.floor(num / 0x10000) + amt));
  const g = Math.min(255, Math.max(0, Math.floor((num / 0x100) % 0x100) + amt));
  const b = Math.min(255, Math.max(0, Math.floor(num % 0x100) + amt));

  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
};
