import styled from 'styled-components';

export const FaturasContent = styled.section`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .grid {
      width: 70%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    table {
      width: 100%;
      border-collapse: collapse;
      margin: 20px 0;
      font-size: 16px;
      text-align: left;
    }

    th,
    td {
      border: 1px solid #ddd;
      padding: 12px;
    }

    thead tr {
      background-color: #6b46c1;
      color: white;
      text-transform: uppercase;
    }

    tr:nth-child(even) {
      background-color: #f9f9f9;
    }

    tbody tr:hover {
      background-color: #f1f1f1;
    }

    button {
      /* background-color: #6b46c1; */
      min-width: 111px;
      color: white;
      padding: 8px 16px;
      border: none;
      border-radius: 4px;
      cursor: pointer;

      &.action {
        background-color: #6b46c1;

        &:hover {
          background-color: #553c9a;
        }
      }

      &.vencido {
        background-color: #f87171;
        cursor: auto;
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
      &.a-vencer {
        background-color: #fbbf24;
        cursor: auto;
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
      &.pago {
        background-color: #4ade80;
        cursor: auto;
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }
  }
`;

export const BoletoInfoModal = styled.section`
  button {
    /* background-color: #6b46c1; */
    min-width: 111px;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &.action {
      background-color: #6b46c1;

      &:hover {
        background-color: #553c9a;
      }
    }
  }
`;
