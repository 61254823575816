import pdfMake from 'pdfmake/build/pdfmake';
import {
    LojaApuracaoProps,
} from '../types';
import { moneyFormat } from '~/utils/functions';
import { format } from 'date-fns';
import { createDateWithoutTimezone } from '~/utils/createDateWithoutTimezone ';

class PedidoVendaBobinaPDF {
    private docDefinition: any;

    constructor(
        private infos: any,
    ) {


        this.docDefinition = {
            pageSize: { width: 226, height: 'auto' },
            pageMargins: [5, 10, 5, 10],
            pageOrientation: 'portrait',
            defaultStyle: {
                fontSize: 6,
                color: '#3f4254'
            },
            content: this.createContent(),
        };
    }
    public static formatEndereco(infos: any) {
        const {
            des_logradouro_entrega,
            num_endereco_entrega,
            des_bairro_entrega,
            des_cidade_entrega,
            des_sigla_entrega,
        } = infos;
        if (
            !des_logradouro_entrega &&
            !num_endereco_entrega &&
            !des_bairro_entrega &&
            !des_cidade_entrega &&
            !des_sigla_entrega
        ) {
            return "Endereço não informado";
        }
        const endereco = `${des_logradouro_entrega || "Logradouro não informado"} - Nº: ${num_endereco_entrega || "Número de endereço não informado"} - ${des_bairro_entrega || "Bairro não informado"}, ${des_cidade_entrega || "Cidade não informada"} - ${des_sigla_entrega || "UF não informada"}`;
        return endereco;
    }

    private createHeader() {
        const { infos } = this;
        const endereco = PedidoVendaBobinaPDF.formatEndereco(infos);
        const des_negociacao = ['Orçamento', 'Pedido', 'PDV Online']
        const des_status = ['Pendente', 'Parcial', 'Total', 'Cancelado', 'Recusado']
        const negociacao = des_negociacao[infos.tipo_negociacao] || 'Não informado'
        const status = des_status[infos.tipo_status] || 'Não informado'
        return [
            {
                layout: 'noBorders',
                table: {
                    widths: ['100%'],
                    body: [
                        [
                            {
                                text: infos.des_loja, fontSize: 6, bold: true, alignment: 'left',
                                margin: [10, 5, 0, 5],
                            },
                        ],
                        [
                            {
                                text: `${endereco}\nTelefone: ${infos.num_telefone_loja}\nCNPJ: ${infos.num_cnpj_loja}\nNegociação: ${negociacao}\nStatus: ${status}`,
                                alignment: 'left',
                                fontSize: 6,
                                margin: [10, 5, 0, 5],
                            },
                        ],

                    ],
                },
            },
            PedidoVendaBobinaPDF.createDividerHeader(),
        ];
    }

    private createSubHeader() {
        const { infos } = this;

        const dta_emissao = infos.dta_emissao ? format(createDateWithoutTimezone(infos.dta_emissao), 'dd/MM/yyyy') : 'Não informada'
        const val_total_venda = infos.item_info.reduce((total: number, item: any) => total + ((item.val_pedido - item.val_desconto + item.val_acrescimo) * item.qtd_pedido), 0) || 0;
        const nome_vendedor = infos.des_vendedor ? infos.des_vendedor : 'Não informado'
        return [
            {

                text: 'Comprovante', fontSize: 10, bold: true, alignment: 'center',
                margin: [0, 5, 0, 5],

            },
            {
                layout: 'noBorders',
                table: {
                    widths: ['100%'],
                    body: [
                        [
                            {
                                columns: [
                                    { text: `Data: ${dta_emissao} \nVendedor: ${nome_vendedor} \nValor total: R$ ${moneyFormat(String(val_total_venda))}   `, alignment: 'left', fontSize: 6, },
                                    { text: `Pedido: ${infos.num_pedido} \n`, alignment: 'right', fontSize: 10, },

                                ],
                                margin: [10, 10, 10, 10]
                            },
                        ],
                    ],

                }
            },

        ]
    }
    private createTable() {
        const { infos } = this;
        const qtd_registrado = infos.item_info.length;
        const qtd_total = infos.item_info.reduce((total: number, item: any) => total + item.qtd_pedido, 0);

        const bodyRows = infos.item_info.map((item: any) => [
            { text: `${item.des_reduzida} [Cód.: ${item.cod_produto}]`, fontSize: 7, bold: true, margin: [10, 0, 10, 0] },
            {
                layout: 'noBorders',
                table: {
                    widths: ['100%'],
                    body: [
                        [
                            {
                                columns: [
                                    { text: `QTD:${item.qtd_pedido}`, fontSize: 6, },
                                    { text: `VLR UN:  ${moneyFormat(String(item.val_pedido))}`, fontSize: 6 },
                                    { text: `VLR DESC: ${moneyFormat(String(item.val_desconto))}`, fontSize: 6 },
                                    { text: `VLR ACRESC: ${moneyFormat(String(item.val_acrescimo))}`, fontSize: 6 },
                                    { text: `VLR TOTAL: ${moneyFormat(String((item.val_pedido - item.val_desconto + item.val_acrescimo) * item.qtd_pedido))}`, fontSize: 6 }

                                ],
                                margin: [10, 0, 10, 10]
                            },
                        ],
                    ],

                }
            },
        ]);


        return [
            {
                text: 'Itens', alignment: 'center', fontSize: 10
            },
            PedidoVendaBobinaPDF.createDividerHeader(),
            {
                layout: 'noBorders',
                table: {
                    widths: ['100%'],
                    body: [
                        [
                            {
                                columns: [
                                    { text: `Itens Registrados: ${qtd_registrado}`, alignment: 'left', fontSize: 6, },
                                    { text: `Quantidade Total: ${qtd_total}`, alignment: 'right', fontSize: 6, },

                                ],
                                margin: [10, 10, 10, 10]
                            },
                        ],
                    ],

                }
            }, ...bodyRows, PedidoVendaBobinaPDF.createDividerHeader()
        ]
    }
    private createFooterTable() {
        const { infos } = this;
        const val_total_produtos = infos.item_info.reduce((total: number, item: any) => total + (item.qtd_pedido * item.val_pedido), 0) || 0;
        const val_total_venda = infos.item_info.reduce((total: number, item: any) => total + ((item.val_pedido - item.val_desconto + item.val_acrescimo) * item.qtd_pedido), 0) || 0;
        return [
            {
                layout: 'noBorders',
                table: {
                    widths: ['100%'],
                    body: [
                        [
                            {
                                columns: [
                                    { text: `Valor total dos produtos: R$ ${moneyFormat(String(val_total_produtos))} \nTotal: R$ ${moneyFormat(String(val_total_venda))}`, bold: true, alignment: 'right', fontSize: 10 },

                                ],
                                margin: [10, 30, 10, 30]
                            },
                        ],
                    ],

                }
            },
        ]
    }
    private static createDividerHeader() {
        return {
            canvas: [
                {
                    type: 'line',
                    x1: 0,
                    y1: 0,
                    x2: 535,
                    y2: 0,
                    lineWidth: 1,
                    lineColor: '#cbccce',
                },
            ],
            margin: [0, 0, 0, 0],
        };
    }


    private createContent() {
        const { infos } = this;

        const content: any = []

        content.push(...this.createHeader())
        content.push(...this.createSubHeader())
        content.push(...this.createTable())


        content.push(...this.createFooterTable())
        return content;
    }


    public generatePDF(): void {
        const pdfDocGenerator = pdfMake.createPdf(this.docDefinition);
        pdfDocGenerator.open();
    }
}
export default PedidoVendaBobinaPDF;
