import {
  DataGrid,
  GridRowParams,
  GridColDef,
  GridCellParams,
} from '@material-ui/data-grid';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { nanoid } from 'nanoid';
import { yupResolver } from '@hookform/resolvers/yup';
import DefaultLoader from '~/components/DefaultLoader';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

import Select from '@atlaskit/select';
import { toast } from 'react-toastify';
import { MdDeleteForever } from 'react-icons/md';
import { GoPencil } from 'react-icons/go';

import { schema, schemaFinanceiro } from './Validation';
import { SelectProps } from './types';
import api from '~/services/api';

import { Tab, Tabs } from 'react-bootstrap';
import { InputPercent, InputText, InputSelect } from '~/components/NovosInputs';
import SelectInput from '~/components/Inputs/InputSelect';
import Search from '~/components/Search';
import Loja from '~/components/Loja';
import FormDefault from '~/components/FormDefault';
import Separator from '~/components/Separator';
import ContaBanco from '~/components/ContaBanco';
import Categoria from '~/components/Categoria';
import ToggleDefault from '~/components/ToggleDefault';
import { Container, ButtonRow } from './styles';
import { CancelButton, ConfirmButton } from '~/components/Buttons';
import Finalizadora from '~/components/Finalizadora';
import InputNumberDias from '~/components/Inputs/inputNumberDias';
import * as yup from 'yup';

import {
  FormPerfilDocumento,
  FormPerfilDocumentoLoja,
  PerfilDocumentoLoja,
  Cfop,
  ISelect,
  ICategoria,
  CfopRows,
} from './protocols';

import {
  addUpdate,
  insertOrUpdate,
  deleteItens,
  MasterDetailProps,
} from '~/utils/masterDetail';

import { tiposOperacao, tiposUnidade, tiposEmissaoNf, tiposNf } from './data';
import { errorHandler } from '~/utils/ErrorHandler';
import { Col } from 'react-bootstrap';
import { SelectType } from '~/components/NovosInputs/InputSelect/protocols';
import validationSchema from './financeiroValidationSchema';

const MySwal = withReactContent(Swal);

const PerfilDeDocumentos: React.FC = () => {
  const masterDetailDefault = [
    {
      obj_name: 'financeiro',
      pk_fields: ['cod_seq_perfil_loja'],
      itens: {
        insert: [],
        update: [],
        delete: [],
      },
    },
    {
      obj_name: 'cfop',
      pk_fields: ['cod_perfil'],
      itens: {
        insert: [],
        update: [],
        delete: [],
      },
    },
  ];

  const formDataBlank: FormPerfilDocumento = {
    cod_perfil: {
      value: undefined,
      isInvalid: false,
      isRequired: false,
    },

    tipo_operacao: {
      value: null,
      isInvalid: true,
      isRequired: true,
    },

    flg_inativo: {
      value: false,
      isInvalid: false,
      isRequired: false,
    },

    flg_gera_financeiro: {
      value: true,
      isInvalid: false,
      isRequired: false,
    },

    flg_gera_custo: {
      value: true,
      isInvalid: false,
      isRequired: false,
    },

    flg_destaca_st: {
      value: true,
      isInvalid: false,
      isRequired: false,
    },

    flg_gera_estoque: {
      value: true,
      isInvalid: false,
      isRequired: false,
    },
    flg_pedido: {
      value: false,
      isInvalid: false,
      isRequired: false,
    },
    flg_gera_livro_fiscal: {
      value: true,
      isInvalid: false,
      isRequired: false,
    },
    cfops: {
      value: [],
      isInvalid: false,
      isRequired: false,
    },
    lojas: {
      value: [],
      isInvalid: false,
      isRequired: true,
    },
  };

  const formFinanceiroBlank = {
    id: {
      isInvalid: false,
      isRequired: false,
    },

    cod_loja: {
      value: undefined,
      isInvalid: true,
      isRequired: true,
    },

    des_loja: {
      value: '',
      isInvalid: false,
      isRequired: false,
    },

    num_condicao: {
      value: 0,
      isInvalid: false,
      isRequired: false,
    },

    cod_condicao: {
      value: undefined,
      isInvalid: true,
      isRequired: true,
    },

    cod_finalizadora: {
      value: undefined,
      isInvalid: true,
      isRequired: true,
    },

    cod_cc: {
      value: null,
      isInvalid: true,
      isRequired: true,
    },

    cod_categoria: {
      value: undefined,
      isInvalid: true,
      isRequired: true,
    },

    per_desconto: {
      value: undefined,
      isInvalid: false,
      isRequired: false,
    },

    flg_boleto: {
      value: false,
      isInvalid: false,
      isRequired: false,
    },
  };

  const [loader, setLoader] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);

  const [currentTab, setCurrentTab] = useState<any>('cfop');
  const [iniInicializado, setInitInicializado] = useState(false);
  const [initOperacao, setInitOperacao] = useState(false);
  const [initUnidade, setInitUnidade] = useState(false);
  const [initEmissao, setInitEmissao] = useState(false);
  const [initNf, setInitNf] = useState(false);
  const [initCfop, setInitCfop] = useState<boolean>(false);

  const [manterDados, setManterDados] = useState(false);

  const [isRowEdit, setIsRowEdit] = useState(false);
  const [isCfopEdit, setIsCfopEdit] = useState(false);
  const [tipoCondicaoInit, setTipoCondicaoInit] = useState(false);

  // Listas para popular o select
  const [cfops, setCfops] = useState<ISelect[]>([]);
  const [idContabeis, setIdContabeis] = useState<ISelect[]>([]);
  const [condicoes, setCondicoes] = useState<ISelect[]>([]);
  const [finalizadoras, setFinalizadoras] = useState<ISelect[]>([]);
  const [categorias, setCategorias] = useState<ISelect[]>([]);
  const [categoriasAll, setCategoriasAll] = useState<ISelect[]>([]);
  const [tipoCategoria, setTipoCategoria] = useState<any>(undefined);
  const [codCategoria, setCodCategoria] = useState<any>(undefined);
  const [rows, setRows] = useState<PerfilDocumentoLoja[]>([]);
  const [rowsCFOP, setRowsCFOP] = useState<CfopRows[]>([]);
  const [formData, setFormData] = useState<FormPerfilDocumento>(formDataBlank);
  const [formFinanceiro, setFormFinanceiro] =
    useState<FormPerfilDocumentoLoja>(formFinanceiroBlank);
  const [masterDetail, setMasterDetail] =
    useState<MasterDetailProps[]>(masterDetailDefault);

  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    setError,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });
  const watch_tipo_operacao = watch('operacao');
  const tipo_nf_watch = watch('tipo_nf');
  const tipo_emissao_watch = watch('tipo_emissao');
  const {
    register: financeiroRegister,
    control: financeiroControl,
    handleSubmit: handleSubmitLimite,
    reset: resetFinanceiro,
    setValue: setValueFinanceiro,
    formState: formStateFinanceiro,
    clearErrors: clearErrorsFinanceiro,
  } = useForm({
    resolver: yupResolver(schemaFinanceiro),
    reValidateMode: 'onBlur',
  });

  const initialToggleValues = () => {
    setValue('flg_destaca_st', true);
    setValue('flg_gera_custo', true);
    setValue('flg_gera_estoque', true);
    setValue('flg_gera_financeiro', true);
    setValue('flg_gera_livro_fiscal', true);
  };

  useEffect(() => {
    initialToggleValues();
  }, []);

  useEffect(() => {
    const getFinalizadora = async () => {
      const res = await api.get('/finalizadora');
      const { data } = res.data;
      const options = data.map((finalizadora: Finalizadora) => {
        return {
          value: finalizadora.cod_finalizadora,
          label: finalizadora.des_finalizadora,
        };
      });
      setFinalizadoras(options);
    };
    getFinalizadora();
  }, []);

  useEffect(() => {
    const getCategoriasAll = async () => {
      const res = await api.get(`/categorias/`);
      const { data } = res.data;
      const options = data.map((categoria: ICategoria) => {
        return {
          value: categoria.cod_subcategoria,
          label: categoria.descricao,
        };
      });
      setCategoriasAll(options);
    };
    getCategoriasAll();
  }, []);

  const getCategorias = async (tipoCategoriaParam: any) => {
    const res = await api.get(`/categorias/${tipoCategoriaParam.value}`);
    const { data } = res.data;
    const options = data.map((categoria: ICategoria) => {
      return {
        value: categoria.cod_subcategoria,
        label: categoria.descricao,
      };
    });
    setTipoCategoria(tipoCategoriaParam.value);
    setCategorias(options);
  };

  const handleChangeDesconto = useCallback(
    (val: number, isInvalid: boolean) => {
      setFormFinanceiro({
        ...formFinanceiro,
        per_desconto: {
          ...formFinanceiro.per_desconto,
          value: val,
          isInvalid,
        },
      });
    },
    [formFinanceiro],
  );

  /**
   * Reseta form para criação de novo registro
   */
  const resetFormData = useCallback(() => {
    setMasterDetail(masterDetailDefault);
    setUpdate(false);
    setIsRowEdit(false);
    setTipoCondicaoInit(false);
    setFormData(formDataBlank);
    handleChangeDesconto(0, true);
    setInitCfop(false);
    reset();
    resetFinanceiro();
    setValue('des_perfil', '');
    setValue('tipo_emissao', '');
    setValue('tipo_unidade', '');
    setValue('tipo_nf', '');
    setValue('operacao', '');
    setValueFinanceiro('flg_boleto', false);
    setValue('flg_gera_financeiro', formDataBlank.flg_gera_financeiro.value);
    setValue('flg_gera_custo', formDataBlank.flg_gera_custo.value);
    setValue('flg_destaca_st', formDataBlank.flg_destaca_st.value);
    setValue('flg_gera_estoque', formDataBlank.flg_gera_estoque.value);
    setValue(
      'flg_gera_livro_fiscal',
      formDataBlank.flg_gera_livro_fiscal.value,
    );

    setFormFinanceiro(formFinanceiroBlank);
    setRows([]);
    setRowsCFOP([]);
    handleClearCfop();
    setCurrentTab('cfop');
    handleClearFinanceiro();
    setInitInicializado(false);
  }, [
    handleChangeDesconto,
    reset,
    resetFinanceiro,
    setValue,
    setValueFinanceiro,
    formDataBlank,
    masterDetailDefault,
    formFinanceiroBlank,
  ]);

  useEffect(() => {
    (async () => {
      if (watch_tipo_operacao) {
        const { data } = await api.get(
          `/cfop/operacao/${watch_tipo_operacao.value}`,
        );
        if (data.success) {
          const newCfop = data.data.map((c: any) => ({
            label: `${c.cfop} - ${c.des_cfop}`,
            value: c.cfop.trim(),
          }));
          setCfops(newCfop);
        }
      }
    })();
  }, [watch_tipo_operacao]);

  useEffect(() => {
    (async () => {
      const { data } = await api.get(`/perfil-documento-id-contabil`);
      if (data.success) {
        const newIdContabeis = data.data
          .map((c: any) => ({
            label: `${c.cod_id_ctb} - ${c.des_id_ctb}`,
            value: c.cod_id_ctb,
          }))
          .sort((a: any, b: any) => a.value - b.value);

        setIdContabeis(newIdContabeis);
      }
    })();
  }, []);

  /**
   *
   * FUNÇÕES RELACIONADAS AO CFOP
   */
  const handleVinculaCfopIdContabil = async () => {
    const dataForm = getValues();

    // Verifica se os campos ID Contábil está em branco
    if (!dataForm.cod_id_ctb || dataForm.cod_id_ctb === '') {
      setError('cod_id_ctb', { type: 'custom', message: 'Required' });
      toast.warning('Selecione um ID Contábil para vincular ao CFOP');
      return;
    }
    // Verifica se o campo CFOP Está em branco
    if (!dataForm.cfop || dataForm.cfop === '') {
      setError('cfop', { type: 'custom', message: 'Required' });
      toast.warning('Selecione um CFOP para ser vinculado ao ID Contábil');
      return;
    }

    // Verifica se CFOP já foi adicionado
    const exists = rowsCFOP.find(
      (cfop: any) => cfop.cfop === dataForm.cfop.value,
    );
    if (exists && isCfopEdit === false) {
      setError('cfop', { type: 'custom', message: 'Required' });
      toast.warning(
        'CFOP já utilizado, selecione outro CFOP para ser vinculado ao ID Contábil',
      );
      return;
    }

    const uuid = exists ? exists.uuid : nanoid();
    const newValue = {
      uuid,
      cod_id_ctb: dataForm.cod_id_ctb.value,
      cfop: dataForm.cfop.value,
      des_id_ctb: dataForm.cod_id_ctb.label.split(' - ')[1] || '',
      obj_cod_id_ctb: dataForm.cod_id_ctb,
      obj_cfop: dataForm.cfop,
      cod_perfil: dataForm.cod_perfil || undefined,
    };

    const cfopRows: any[] = await insertOrUpdate(
      'cfop',
      newValue,
      masterDetail,
      setMasterDetail,
    );
    setRowsCFOP(cfopRows);

    return handleClearCfop();
  };
  const handleClearCfop = () => {
    clearErrors('cfop');
    clearErrors('cod_id_ctb');
    setValue('cfop', '');
    setValue('cod_id_ctb', '');
    setValue('cod_perfil', undefined);
    setIsCfopEdit(false);
  };
  const columnsCFOP: GridColDef[] = [
    { field: 'cfop', headerName: 'CFOP', width: 150 },
    { field: 'cod_id_ctb', headerName: 'ID', width: 150 },
    {
      field: 'des_id_ctb',
      headerName: 'Descrição ID Contábil',
      flex: 1,
    },
    {
      field: 'acao',
      headerName: 'Ações',
      headerAlign: 'center',
      sortable: false,
      width: 230,
      disableColumnMenu: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        return (
          <>
            <ButtonRow type="button" onClick={() => handleEditCFOProw(row)}>
              <GoPencil
                size={20}
                style={{ color: '#72ab90', marginLeft: '30px' }}
              />{' '}
              Editar
            </ButtonRow>
            <ButtonRow
              type="button"
              onClick={() => handleDeleteCFOProw(row.uuid)}
            >
              <MdDeleteForever size={20} style={{ color: '#e63c3c' }} /> Excluir
            </ButtonRow>
          </>
        );
      },
    },
  ];
  const handleDeleteCFOProw = async (uuid: string) => {
    const cfopRows: any[] = await deleteItens(
      'cfop',
      uuid,
      masterDetail,
      setMasterDetail,
    );

    setRowsCFOP(cfopRows);
  };
  const handleEditCFOProw = (row: any) => {
    setValue('cod_perfil', row.cod_perfil);
    setValue('cfop', {
      value: row?.obj_cfop?.value || undefined,
      label: row?.obj_cfop?.label || '',
    });
    setValue('cod_id_ctb', {
      value: row?.obj_cod_id_ctb?.value || undefined,
      label: row?.obj_cod_id_ctb?.label || '',
    });
    setIsCfopEdit(true);
  };

  /**
   * Handle tooltip
   */
  useEffect(() => {
    const elementCfop = document.querySelectorAll(
      '.react-select__multi-value__label',
    );
    if (elementCfop.length > 0) {
      for (let i = 0; i < elementCfop.length; i++) {
        const filtered = cfops.filter((val: any) => {
          return val.value === String(elementCfop[i].innerHTML);
        });
        if (filtered[0]) {
          elementCfop[
            i
          ].innerHTML = `<div data-toggle="tooltip" data-toggle="tooltip" data-placement="top" className="DivALterada" title="${filtered[0]?.label}"> ${filtered[0]?.value} </div>`;
        }
      }
    }
  }, [formData.cfops.value, cfops]);

  useEffect(() => {
    if (
      (iniInicializado === true && formData.cfops.value === undefined) ||
      formData.cfops.value?.length === 0
    ) {
      setFormData({
        ...formData,
        cfops: { ...formData.cfops, isInvalid: true },
      });
      setError('cfopsData', { type: 'focus' });
    }
  }, [formData.cfops.value]);

  useEffect(() => {
    (async () => {
      const { data } = await api.get('/condicao');

      if (data.success) {
        const newcondicao = await data.data.map((condicaoPagamento: any) => ({
          label: `${condicaoPagamento.des_definicao} - ${condicaoPagamento.des_condicao}`,
          value: condicaoPagamento.cod_condicao,
        }));
        setCondicoes(newcondicao);
      }
    })();
  }, []);

  const handleChangeContaBanco = useCallback(
    (val: { label: string; value: number }, isInvalid: boolean) => {
      setFormFinanceiro({
        ...formFinanceiro,
        cod_cc: {
          ...formFinanceiro.cod_cc,
          value: val,
          isInvalid,
        },
      });
    },
    [formFinanceiro],
  );

  const handleChangeCategoria = useCallback(
    (val: { label: string; value: number }, isInvalid: boolean) => {
      setCodCategoria(val);
      setFormFinanceiro({
        ...formFinanceiro,
        cod_categoria: {
          ...formFinanceiro.cod_categoria,
          value: val,
          isInvalid,
        },
      });
    },
    [formFinanceiro],
  );

  const handleClearFinanceiro = () => {
    setFormFinanceiro(formFinanceiroBlank);
    setCodCategoria({});
    resetFinanceiro();
    setIsRowEdit(false);
  };

  const columns: GridColDef[] = [
    { field: 'cod_loja', headerName: 'Loja', width: 150, hide: true },
    { field: 'cod_loja', headerName: 'Loja', width: 150 },
    {
      field: 'cod_finalizadora',
      headerName: 'Finalizadora',
      width: 160,
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        return (
          <>
            {
              finalizadoras.find(
                (finalizadora) => row.cod_finalizadora === finalizadora.value,
              )?.label
            }
          </>
        );
      },
    },
    {
      field: 'num_condicao',
      headerName: 'Condição',
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        return (
          <>
            <Col style={{ display: 'flex', justifyContent: 'right' }}>
              {row.num_condicao} DIAS
            </Col>
          </>
        );
      },
    },
    {
      field: 'cod_condicao',
      headerName: 'Tipo de Condição',
      width: 175,
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        return (
          <>
            {
              condicoes.find((condicao) => row.cod_condicao === condicao.value)
                ?.label
            }
          </>
        );
      },
    },

    {
      field: 'cod_categoria',
      headerName: 'Categoria',
      width: 200,
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        return (
          <>
            {
              categoriasAll.find(
                (categoria) => row.cod_categoria === categoria.value,
              )?.label
            }
          </>
        );
      },
    },
    {
      field: 'flg_boleto_view',
      headerName: 'Imprimir Boleto?',
      headerAlign: 'center',
      cellClassName: 'centralized',
      width: 170,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'acao',
      headerName: 'Ações',
      headerAlign: 'center',
      sortable: false,
      width: 230,
      disableColumnMenu: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        return (
          <>
            <ButtonRow type="button" onClick={() => onEditLoja(row.cod_loja)}>
              <GoPencil
                size={20}
                style={{ color: '#72ab90', marginLeft: '30px' }}
              />{' '}
              Editar
            </ButtonRow>
            <ButtonRow type="button" onClick={() => onDeleteLoja(row.uuid)}>
              <MdDeleteForever size={20} style={{ color: '#e63c3c' }} /> Excluir
            </ButtonRow>
          </>
        );
      },
    },
  ];

  const onDeleteLoja = async (uuid: string) => {
    const limiteCredito: any[] = await deleteItens(
      'financeiro',
      uuid,
      masterDetail,
      setMasterDetail,
    );

    setRows(limiteCredito);
  };

  const onEditLoja = (id: number | number[] | undefined) => {
    const regLoja = rows.find((row) => {
      return row.id === id;
    });
    if (regLoja) {
      setValueFinanceiro('loja', {
        value: regLoja.cod_loja,
        label: regLoja.des_loja,
      });
      setValueFinanceiro(
        'cod_finalizadora',
        finalizadoras.find(
          (finalizadora) =>
            finalizadora.value === Number(regLoja?.cod_finalizadora),
        ),
      );
      setValueFinanceiro('num_condicao', regLoja.num_condicao);
      setValueFinanceiro(
        'cod_condicao',
        condicoes.find(
          (condicao) => condicao.value === Number(regLoja?.cod_condicao),
        ),
      );
      setValueFinanceiro('cod_cc', { value: regLoja.cod_cc, label: '' });
      setCodCategoria(
        categoriasAll.find(
          (categoria) => categoria.value === Number(regLoja?.cod_categoria),
        ),
      );
      setValueFinanceiro(
        'cod_categoria',
        categoriasAll.find(
          (categoria) => categoria.value === Number(regLoja?.cod_categoria),
        ),
      );

      setTipoCategoria(regLoja.cod_categoria?.value);
      setValueFinanceiro('cod_seq_perfil_loja', regLoja.cod_seq_perfil_loja);

      let formattedNumber = regLoja.per_desconto;
      if (typeof regLoja.per_desconto === 'number') {
        formattedNumber = regLoja.per_desconto.toFixed(2).toString();
      } else if (typeof regLoja.per_desconto === 'string') {
        formattedNumber = regLoja.per_desconto;
      } else {
        formattedNumber = '0.00';
      }

      formattedNumber = formattedNumber.replace('.', ',');
      setValueFinanceiro('per_desconto', formattedNumber);

      setValueFinanceiro('flg_boleto', regLoja.flg_boleto);

      setFormFinanceiro({
        ...formFinanceiro,
        cod_loja: {
          ...formFinanceiro.cod_loja,
          value: regLoja.cod_loja,
        },
        des_loja: {
          ...formFinanceiro.des_loja,
          value: regLoja.des_loja,
        },
        cod_finalizadora: {
          ...formFinanceiro.cod_finalizadora,
          value: finalizadoras.find(
            (finalizadora) =>
              finalizadora.value === Number(regLoja?.cod_finalizadora),
          ),
        },
        cod_condicao: {
          ...formFinanceiro.cod_condicao,
          value: condicoes.find(
            (condicao) => condicao.value === Number(regLoja?.cod_condicao),
          ),
        },
        num_condicao: {
          ...formFinanceiro.num_condicao,
          value: regLoja.num_condicao,
        },
        cod_cc: {
          ...formFinanceiro.cod_cc,
          value: { value: Number(regLoja.cod_cc), label: '' },
        },
        cod_categoria: {
          ...formFinanceiro.cod_categoria,
          value: categoriasAll.find(
            (categoria) => categoria.value === Number(regLoja?.cod_categoria),
          ),
        },
        flg_boleto: {
          ...formFinanceiro.flg_boleto,
          value: Boolean(regLoja.flg_boleto),
        },
      });

      setIsRowEdit(true);
    }
  };

  const handleAddLoja = handleSubmitLimite(async (data2) => {
    const arrayRows = rows.filter((row) => row.cod_loja === data2.loja.value);
    if (arrayRows.length > 0 && isRowEdit === false) {
      return toast.warning('Loja já cadastrada');
    }
    const uuid = arrayRows.length === 0 ? nanoid() : arrayRows[0].uuid;
    const rowFinanceiro: PerfilDocumentoLoja = {
      uuid,
      id: data2.loja.value,
      cod_loja: data2.loja.value,
      des_loja: data2.loja.label,
      num_condicao: data2.num_condicao,
      cod_condicao: data2.cod_condicao.value,
      cod_finalizadora: data2.cod_finalizadora.value,
      cod_cc: data2.cod_cc.value,
      cod_categoria: data2.cod_categoria.value,
      per_desconto: data2.per_desconto,
      flg_boleto_view: data2.flg_boleto ? 'Sim' : 'Não',
      flg_boleto: data2.flg_boleto,
      cod_seq_perfil_loja: data2.cod_seq_perfil_loja || undefined,
    };
    const limiteCredito: any[] = await insertOrUpdate(
      'financeiro',
      rowFinanceiro,
      masterDetail,
      setMasterDetail,
    );

    setRows(limiteCredito);

    return handleClearFinanceiro();
  });

  /**
   * Evento disparado ao clicar em uma linha da tabela
   * @param param
   */
  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;
    setUpdate(true);

    const { cod_perfil } = row;

    const res = await api.get(`/perfil-documento/${cod_perfil}`);
    const { data } = res.data;
    const {
      des_perfil,
      tipo_operacao,
      tipo_unidade,
      tipo_emissao,
      tipo_nf,
      flg_inativo,
      flg_gera_financeiro,
      flg_pedido,
      flg_gera_custo,
      flg_destaca_st,
      flg_gera_estoque,
      flg_gera_livro_fiscal,
    } = data;

    if (condicoes && finalizadoras && categorias) {
      setValue('des_perfil', des_perfil);
      setValue(
        'operacao',
        tiposOperacao.find((tipo) => tipo.value === tipo_operacao),
      );
      setValue(
        'tipo_unidade',
        tiposUnidade.find((tipo) => tipo.value === tipo_unidade),
      );
      setValue(
        'tipo_emissao',
        tiposEmissaoNf.find((tipo) => tipo.value === tipo_emissao),
      );
      setValue(
        'tipo_nf',
        tiposNf.find((tipo) => tipo.value === tipo_nf),
      );

      setValue('flg_inativo', flg_inativo);
      setValue('flg_gera_financeiro', flg_gera_financeiro);
      setValue('flg_gera_custo', flg_gera_custo);
      setValue('flg_destaca_st', flg_destaca_st);
      setValue('flg_gera_estoque', flg_gera_estoque);
      setValue('flg_pedido', flg_pedido);

      const rowLoja: any[] = [];
      data.lojas.forEach(async (loja: any) => {
        const rowFinanceiro = {
          uuid: nanoid(),
          id: loja.cod_loja,
          cod_loja: loja.cod_loja,
          des_loja: loja.loja?.descricao,
          num_condicao: loja.num_condicao,
          cod_condicao: loja.cod_condicao,
          cod_finalizadora: loja.cod_finalizadora,
          cod_cc: loja.cod_cc,
          cod_categoria: loja.cod_categoria,
          per_desconto: loja.per_desconto,
          flg_boleto_view: loja.flg_boleto ? 'Sim' : 'Não',
          flg_boleto: loja.flg_boleto,
          cod_seq_perfil_loja: loja.cod_seq_perfil_loja,
        };
        rowLoja.push(rowFinanceiro);
      });

      await addUpdate('financeiro', rowLoja, masterDetail, setMasterDetail);
      setRows(rowLoja);

      const rowCfop: any[] = [];
      data.cfops.forEach(async (cfop: any) => {
        const cfop_obj = cfops.find((i) => i.value === cfop.cfop);
        const ctb_obj = idContabeis.find((i) => i.value === cfop.cod_id_ctb);
        const rowCFOP = {
          uuid: nanoid(),
          cod_id_ctb: cfop.cod_id_ctb,
          cfop: cfop.cfop,
          des_id_ctb: ctb_obj?.label.split(' - ')[1] || '',
          obj_cod_id_ctb: ctb_obj,
          obj_cfop: cfop_obj,
          cod_perfil: cfop.cod_perfil,
        };
        rowCfop.push(rowCFOP);
      });
      await addUpdate('cfop', rowCfop, masterDetail, setMasterDetail);
      setRowsCFOP(rowCfop);
    }

    const perfilDocumentoLojas: FormPerfilDocumentoLoja[] = data.lojas.map(
      (loja: PerfilDocumentoLoja) => {
        return {
          cod_loja: {
            value: loja.cod_loja,
            isInvalid: false,
            isRequired: true,
          },
          des_loja: {
            value: loja.loja?.descricao,
            isInvalid: false,
            isRequired: false,
          },
          num_condicao: {
            value: loja.num_condicao,
            isInvalid: false,
            isRequired: true,
          },
          cod_condicao: {
            value: { value: loja.cod_condicao, label: '' },
            isInvalid: false,
            isRequired: true,
          },
          cod_finalizadora: {
            value: { value: loja.cod_finalizadora, label: '' },
            isInvalid: false,
            isRequired: true,
          },
          cod_cc: {
            value: { value: loja.cod_cc, label: '' },
            isInvalid: false,
            isRequired: true,
          },
          cod_categoria: {
            value: { value: loja.cod_categoria, label: '' },
            isInvalid: false,
            isRequired: true,
          },
          per_desconto: {
            value: loja.per_desconto,
            isInvalid: false,
            isRequired: true,
          },
          flg_boleto: {
            value: loja.flg_boleto,
            isInvalid: false,
            isRequired: true,
          },
        };
      },
    );

    const cfopsData: ISelect[] = [];
    const selectedCfops: string[] = [];

    data.cfops.map((cfop: Cfop) => {
      cfopsData.push({
        label: cfop.cfop,
        value: cfop.cfop,
      });
      selectedCfops.push(cfop.cfop);
      return cfop;
    });
    const filteredCfops = cfops.filter((cfop) => {
      return selectedCfops.indexOf(String(cfop.value)) === -1;
    });
    setCfops(filteredCfops);
    setFormData({
      cod_perfil: {
        value: cod_perfil,
        isInvalid: false,
        isRequired: true,
      },
      tipo_operacao: {
        value: { value: tipo_operacao, label: '' },
        isInvalid: false,
        isRequired: true,
      },
      flg_inativo: {
        value: flg_inativo,
        isInvalid: false,
        isRequired: true,
      },
      flg_gera_financeiro: {
        value: flg_gera_financeiro,
        isInvalid: false,
        isRequired: true,
      },
      flg_gera_custo: {
        value: flg_gera_custo,
        isInvalid: false,
        isRequired: true,
      },
      flg_destaca_st: {
        value: flg_destaca_st,
        isInvalid: false,
        isRequired: true,
      },
      flg_gera_estoque: {
        value: flg_gera_estoque,
        isInvalid: false,
        isRequired: true,
      },
      flg_pedido: {
        value: flg_pedido,
        isInvalid: false,
        isRequired: false,
      },
      flg_gera_livro_fiscal: {
        value: flg_gera_livro_fiscal,
        isInvalid: false,
        isRequired: true,
      },
      cfops: {
        value: cfopsData,
        isInvalid: false,
        isRequired: true,
      },
      lojas: {
        value: perfilDocumentoLojas,
        isInvalid: false,
        isRequired: true,
      },
    });
    setValue('cfopsData', cfopsData);
    setLoader(false);

    setShowSearch(false);
  };

  const verificaFlgGeraLivro = async () => {
    if (
      [0, 6].includes(tipo_nf_watch?.value) &&
      tipo_emissao_watch.value === 1 &&
      !formData.flg_gera_livro_fiscal.value
    ) {
      const confirm = await MySwal.fire({
        title: '',
        text: 'Perfil não esta marcado para Gerar Livro Fiscal. Deseja continuar assim mesmo?',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#dc3545',
        allowOutsideClick: true,
        icon: 'warning',
      }).then(async (result) => {
        return result.isConfirmed;
      });
      return confirm;
    }
    return true;
  };

  /**
   * Evento reponsavel por cadastrar ou editar um Operador
   * @return
   */
  const onSave = handleSubmit(async (dataForm) => {
    if (!(await verificaFlgGeraLivro())) return;
    setLoader(true);
    setInitCfop(true);
    if (rowsCFOP.length <= 0) {
      setLoader(false);
      return toast.warning('Ao menos um CFOP deve ser informado');
    }

    const financeiro = [];
    const lojas = rows;

    for (let i = 0; i < lojas.length; i++) {
      if (lojas[i].per_desconto !== null) {
        const obj = {
          cod_loja: lojas[i].cod_loja,
          cod_finalizadora: lojas[i].cod_finalizadora,
          num_condicao: lojas[i].num_condicao,
          cod_condicao: lojas[i].cod_condicao,
          cod_categoria: lojas[i].cod_categoria,
          cod_cc: lojas[i].cod_cc,
          per_desconto: String(lojas[i].per_desconto).replace('.', ','),
          flg_boleto: lojas[i].flg_boleto,
        };
        financeiro.push(obj);
      } else {
        const obj = {
          cod_loja: lojas[i].cod_loja,
          cod_finalizadora: lojas[i].cod_finalizadora,
          num_condicao: lojas[i].num_condicao,
          cod_condicao: lojas[i].cod_condicao,
          cod_categoria: lojas[i].cod_categoria,
          cod_cc: lojas[i].cod_cc,
          per_desconto: lojas[i].per_desconto,
          flg_boleto: lojas[i].flg_boleto,
        };
        financeiro.push(obj);
      }
    }

    try {
      /**
       * Reforça validação do financeiro para casos onde pode ter ocorrido
       * erro na migração dos dados
       */
      for (let i = 0; i < financeiro.length; i++) {
        await validationSchema.validate(financeiro[i], { abortEarly: false });
      }

      if (dataForm.flg_gera_financeiro && financeiro.length <= 0) {
        toast.warning(
          `A opção 'Gera Financeiro' está selecionada, porém, nenhum registro foi informado na aba Financeiro.`,
        );
        return;
      }

      if (isUpdate) {
        const codPerfil = formData.cod_perfil.value;
        const { data } = await api.put(`/perfil-documento/${codPerfil}`, {
          des_perfil: dataForm.des_perfil,
          tipo_operacao: dataForm.operacao.value,
          tipo_nf: dataForm.tipo_nf.value,
          tipo_unidade: dataForm.tipo_unidade.value,
          tipo_emissao: dataForm.tipo_emissao.value,
          flg_inativo: dataForm.flg_inativo,
          flg_destaca_st: dataForm.flg_destaca_st,
          flg_gera_financeiro: dataForm.flg_gera_financeiro,
          flg_gera_custo: dataForm.flg_gera_custo,
          flg_gera_estoque: dataForm.flg_gera_estoque,
          flg_pedido: dataForm.flg_pedido,
          flg_gera_livro_fiscal: dataForm.flg_gera_livro_fiscal,
          cfop: rowsCFOP,
          masterDetail,
        });
        if (data.success) {
          toast.success(data.message);
          resetFormData();
          setShowSearch(true);
          return;
        }
      }

      const { data } = await api.post('/perfil-documento', {
        des_perfil: dataForm.des_perfil,
        tipo_operacao: dataForm.operacao.value,
        tipo_nf: dataForm.tipo_nf.value,
        tipo_unidade: dataForm.tipo_unidade.value,
        tipo_emissao: dataForm.tipo_emissao.value,
        flg_inativo: dataForm.flg_inativo,
        flg_destaca_st: dataForm.flg_destaca_st,
        flg_gera_financeiro: dataForm.flg_gera_financeiro,
        flg_gera_custo: dataForm.flg_gera_custo,
        flg_gera_estoque: dataForm.flg_gera_estoque,
        flg_pedido: dataForm.flg_pedido,
        flg_gera_livro_fiscal: dataForm.flg_gera_livro_fiscal,
        cfop: rowsCFOP,
        masterDetail,
      });
      if (data.success) {
        toast.success(data.message);
        resetFormData();
        setShowSearch(false);
      }
    } catch (error: any) {
      if (error instanceof yup.ValidationError) {
        error.inner.forEach((err) => {
          toast.warning(err.message);
        });
      }
    } finally {
      setLoader(false);
    }
  });

  const onCancel = () => {
    resetFormData();
    setShowSearch(true);
    setUpdate(false);
  };

  const onDelete = async () => {
    const codPerfil = formData.cod_perfil.value;
    const res = await api.delete(`/perfil-documento/${codPerfil}`);
    const { message } = res.data;

    onCancel();

    return toast.success(message);
  };

  /**
   * Busca pessoa pelo cpf
   */

  const handleSwitch = useCallback(() => {
    setManterDados(!manterDados);
  }, [manterDados]);

  const handleCodLoja = (value: any) => {
    if (!isRowEdit) {
      setValueFinanceiro('loja', {
        value: value.value,
        label: value.label,
      });
      setValueFinanceiro('num_condicao', 30);
      setValueFinanceiro('cod_condicao', {
        value: 2,
        label: 'DD - DIAS DA DATA',
      });
      setFormFinanceiro((prevState) => {
        return {
          ...prevState,
          cod_loja: {
            ...formFinanceiro.cod_loja,
            value: value.value,
            isInvalid: !value.value,
          },
          cod_finalizadora: {
            value: undefined,
            isInvalid: true,
            isRequired: true,
          },
          num_condicao: {
            ...prevState.num_condicao,
            value: 30,
          },
          cod_condicao: {
            value: condicoes.find((condicao) => condicao.value === 2),
            isInvalid: true,
            isRequired: true,
          },
        };
      });
    }
  };

  const validaGeraEstoque = useCallback(() => {
    if (tipo_nf_watch?.value !== 4) {
      return true;
    }
    return false;
  }, [tipo_nf_watch]);

  const validaFlgLivro = useCallback(() => {
    return (
      [0, 6].includes(tipo_nf_watch?.value) && tipo_emissao_watch.value === 1
    );
  }, [tipo_nf_watch, tipo_emissao_watch]);

  if (loader) {
    return (
      <Container>
        <DefaultLoader />
      </Container>
    );
  }

  return (
    <Container>
      {showSearch && (
        <Search
          codTela={57}
          newData={() => setShowSearch(false)}
          onRowClick={onRowClick}
        />
      )}

      {!showSearch && (
        <FormDefault
          codTela={57}
          title="Perfil de Documentos"
          codigoRegistro={[
            { value: formData.cod_perfil.value, des_campo: 'Código' },
          ]}
          onSave={async () => {
            setInitOperacao(true);
            setInitUnidade(true);
            setInitEmissao(true);
            setInitNf(true);
            setInitCfop(true);
            await onSave();
          }}
          onCancel={() => {
            resetFormData();
            setShowSearch(true);
            setInitInicializado(false);
          }}
          isUpdate={isUpdate}
          onNew={resetFormData}
          onDelete={onDelete}
          onClearFields={resetFormData}
          onReturnSearch={() => {
            setShowSearch(true);
            setUpdate(false);
            resetFormData();
          }}
          onSwitch={handleSwitch}
        >
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <InputText
                label="Descrição"
                maxLength={50}
                placeholder="Informe a descrição"
                name="des_perfil"
                register={register}
                caseInput="upper"
                control={control}
                disabled={false}
                isError={!!errors.des_perfil}
              />
            </div>
            {/* ÍNICIO */}

            <div className="container-fluid col-sm-12  d-flex justify-content-between ">
              <div className="col-sm-12 col-md-2">
                <InputSelect
                  label="Operação"
                  placeholder="Informe a Operação"
                  name="operacao"
                  register={register}
                  isDisabled={isUpdate}
                  isError={initOperacao && !!errors.operacao}
                  control={control}
                  options={tiposOperacao}
                  changeSelected={(selected: SelectProps) => {
                    setValue('operacao', selected);
                    setInitOperacao(false);
                    getCategorias(selected);
                    console.log(selected);
                  }}
                />
              </div>

              <div className="col-sm-12 col-md-3">
                <InputSelect
                  label="Tipo de Unidade"
                  placeholder="Informe o tipo unidade"
                  name="tipo_unidade"
                  register={register}
                  isError={initUnidade && !!errors.tipo_unidade}
                  control={control}
                  options={tiposUnidade}
                  changeSelected={(selected: SelectProps) => {
                    setInitUnidade(false);
                    setValue('tipo_unidade', selected);
                  }}
                />
              </div>

              <div className="col-sm-12 col-md-3">
                <InputSelect
                  label="Emissão NF"
                  placeholder="Informe tipo de emissão"
                  name="tipo_emissao"
                  register={register}
                  isError={initEmissao && !!errors.tipo_emissao}
                  control={control}
                  options={tiposEmissaoNf}
                  changeSelected={(selected: SelectProps) => {
                    setInitEmissao(false);
                    setValue('tipo_emissao', selected);
                    if (selected.value !== 1) {
                      setFormData({
                        ...formData,
                        flg_gera_livro_fiscal: {
                          ...formData.flg_gera_livro_fiscal,
                          value: true,
                        },
                      });
                    }
                  }}
                />
              </div>

              <div className="col-sm-12 col-md-2">
                <InputSelect
                  label="Tipo NF"
                  placeholder="Informe a NF"
                  name="tipo_nf"
                  register={register}
                  isError={initNf && !!errors.tipo_nf}
                  control={control}
                  options={tiposNf}
                  changeSelected={(selected: SelectProps) => {
                    setInitNf(false);
                    if (selected.value !== 4) {
                      setFormData({
                        ...formData,
                        flg_gera_estoque: {
                          ...formData.flg_gera_estoque,
                          value: true,
                        },
                      });
                    }
                    if (![0, 6].includes(Number(selected.value))) {
                      setFormData({
                        ...formData,
                        flg_gera_livro_fiscal: {
                          ...formData.flg_gera_livro_fiscal,
                          value: true,
                        },
                      });
                    }
                    setValue('tipo_nf', selected);
                  }}
                />
              </div>

              <div className="col-sm-12 col-md-1">
                <ToggleDefault
                  labelText="Inativo?"
                  setChecked={formData.flg_inativo.value}
                  onSwitch={() => {
                    setValue('flg_inativo', !formData.flg_inativo.value);
                    setFormData({
                      ...formData,
                      flg_inativo: {
                        ...formData.flg_inativo,
                        value: !formData.flg_inativo.value,
                      },
                    });
                  }}
                />
              </div>
            </div>
            <div className="row mt-4">
              <Tabs
                id="controlled-perfil"
                activeKey={currentTab}
                onSelect={(tab) => setCurrentTab(tab)}
              >
                <Tab eventKey="cfop" title="CFOP">
                  <div className="row">
                    <h1>{iniInicializado}</h1>
                    <div className="col-sm-12 col-md-4">
                      <InputSelect
                        label="CFOP"
                        placeholder="Selecione um CFOP"
                        name="cfop"
                        register={register}
                        isError={!!errors.cfop}
                        control={control}
                        isDisabled={isCfopEdit}
                        changeSelected={function (selected: SelectType) {
                          clearErrors('cfop');
                          setValue('cfop', selected);
                        }}
                        options={cfops}
                      />
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <InputSelect
                        label="ID Contábil"
                        placeholder="Selecione um ID Contábil"
                        name="cod_id_ctb"
                        register={register}
                        isError={!!errors.cod_id_ctb}
                        control={control}
                        changeSelected={function (selected: SelectType) {
                          clearErrors('cod_id_ctb');
                          setValue('cod_id_ctb', selected);
                        }}
                        options={idContabeis}
                      />
                    </div>
                    <div className="col-sm-12 col-md-4 d-flex mt-4 containerButton">
                      <div className="containerButtonFlex">
                        <ConfirmButton
                          onClick={handleVinculaCfopIdContabil}
                          style={{ minWidth: '113px' }}
                        >
                          Confirmar
                        </ConfirmButton>
                        <CancelButton
                          onClick={handleClearCfop}
                          style={{ minWidth: '113px' }}
                        >
                          Cancelar
                        </CancelButton>
                      </div>
                    </div>
                    <div
                      className="col-sm-12 col-md-12 dataTable mt-4"
                      style={{ height: 300, width: '100%' }}
                    >
                      <DataGrid
                        rows={rowsCFOP}
                        columns={columnsCFOP}
                        pageSize={5}
                        getRowId={(row) => row.uuid}
                        sortModel={[{ field: 'cfop', sort: 'asc' }]}
                        hideFooterSelectedRowCount
                        disableColumnMenu
                        disableColumnFilter
                        disableColumnSelector
                        disableSelectionOnClick
                        localeText={{
                          noRowsLabel: '',
                        }}
                      />
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="nf" title="Características da NF">
                  <div className="row mt-3">
                    <div className="col-sm-4 col-md-2 col-lg-2">
                      <ToggleDefault
                        labelText="Gera Financeiro?"
                        setChecked={formData.flg_gera_financeiro.value}
                        onSwitch={() => {
                          setValue(
                            'flg_gera_financeiro',
                            !formData.flg_gera_financeiro.value,
                          );
                          setFormData({
                            ...formData,
                            flg_gera_financeiro: {
                              ...formData.flg_gera_financeiro,
                              value: !formData.flg_gera_financeiro.value,
                            },
                          });
                        }}
                      />
                    </div>
                    <div className="col-sm-4 col-md-2 col-lg-2">
                      <ToggleDefault
                        labelText="Atualiza Custo Reposição?"
                        setChecked={formData.flg_gera_custo.value}
                        onSwitch={() => {
                          setValue(
                            'flg_gera_custo',
                            !formData.flg_gera_custo.value,
                          );
                          setFormData({
                            ...formData,
                            flg_gera_custo: {
                              ...formData.flg_gera_custo,
                              value: !formData.flg_gera_custo.value,
                            },
                          });
                        }}
                      />
                    </div>
                    <div className="col-sm-4 col-md-2 col-lg-2">
                      <ToggleDefault
                        labelText="Calcula ST (NF Clientes)?"
                        setChecked={formData.flg_destaca_st.value}
                        onSwitch={() => {
                          setValue(
                            'flg_destaca_st',
                            !formData.flg_destaca_st.value,
                          );
                          setFormData({
                            ...formData,
                            flg_destaca_st: {
                              ...formData.flg_destaca_st,
                              value: !formData.flg_destaca_st.value,
                            },
                          });
                        }}
                      />
                    </div>
                    <div className="col-sm-4 col-md-2 col-lg-2">
                      <ToggleDefault
                        labelText="Gera Estoque?"
                        setChecked={formData.flg_gera_estoque.value}
                        onSwitch={() => {
                          setValue(
                            'flg_gera_estoque',
                            !formData.flg_gera_estoque.value,
                          );
                          setFormData({
                            ...formData,
                            flg_gera_estoque: {
                              ...formData.flg_gera_estoque,
                              value: !formData.flg_gera_estoque.value,
                            },
                          });
                        }}
                        disabled={validaGeraEstoque()}
                      />
                    </div>
                    <div className="col-sm-4 col-md-2 col-lg-2">
                      <ToggleDefault
                        labelText="Ativo para pedido?"
                        setChecked={formData.flg_pedido.value}
                        onSwitch={() => {
                          setValue('flg_pedido', !formData.flg_pedido.value);
                          setFormData({
                            ...formData,
                            flg_pedido: {
                              ...formData.flg_pedido,
                              value: !formData.flg_pedido.value,
                            },
                          });
                        }}
                        disabled={false}
                      />
                    </div>
                    <div className="col-sm-4 col-md-2 col-lg-2">
                      <ToggleDefault
                        labelText="Gera Livro Fiscal?"
                        setChecked={formData.flg_gera_livro_fiscal.value}
                        onSwitch={() => {
                          setValue(
                            'flg_gera_livro_fiscal',
                            !formData.flg_gera_livro_fiscal.value,
                          );
                          setFormData({
                            ...formData,
                            flg_gera_livro_fiscal: {
                              ...formData.flg_gera_livro_fiscal,
                              value: !formData.flg_gera_livro_fiscal.value,
                            },
                          });
                        }}
                        disabled={!validaFlgLivro()}
                      />
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="financeiro" title="Financeiro">
                  <div className="row mt-3">
                    <div className="col-sm-12 col-md-4 mt-2">
                      <Loja
                        selectedLoja={
                          formFinanceiro.cod_loja.value || undefined
                        }
                        IsInvalid={!!formStateFinanceiro.errors.loja}
                        onChangedObject={(value) => {
                          clearErrorsFinanceiro('loja');
                          handleCodLoja(value);
                        }}
                        onChange={() => null}
                        isDisabled={isRowEdit}
                      />
                    </div>

                    <div className="col-sm-12 col-md-2">
                      <InputNumberDias
                        label="Condição"
                        value={formFinanceiro.num_condicao.value}
                        placeholder=""
                        isNull
                        isUndefined
                        isDisabled={!formFinanceiro.cod_loja.value}
                        isRequired={!!formStateFinanceiro.errors.num_condicao}
                        setInvalid={!!formStateFinanceiro.errors.num_condicao}
                        iniInicializado={
                          !!formStateFinanceiro.errors.num_condicao
                        }
                        onBlur={(event: ChangeEvent<HTMLInputElement>) => {
                          if (event.target.value === '') {
                            setValueFinanceiro('num_condicao', 0);
                            setFormFinanceiro({
                              ...formFinanceiro,
                              num_condicao: {
                                ...formFinanceiro.num_condicao,
                                value: 0,
                              },
                            });
                          }
                        }}
                        onChange={(value: number) => {
                          if (value >= 0) {
                            setValueFinanceiro('num_condicao', value);
                            setFormFinanceiro({
                              ...formFinanceiro,
                              num_condicao: {
                                ...formFinanceiro.num_condicao,
                                value,
                                isInvalid: !value,
                              },
                            });
                          }
                        }}
                      />
                    </div>

                    <div className="col-sm-12 col-md-3">
                      <SelectInput
                        label="Tipo de Condição"
                        options={condicoes}
                        isDisabled={!formFinanceiro.cod_loja.value}
                        value={
                          formFinanceiro.cod_condicao.value === undefined
                            ? null
                            : formFinanceiro.cod_condicao.value
                        }
                        setInvalid={formFinanceiro.cod_condicao.isInvalid}
                        iniInicializado={tipoCondicaoInit}
                        isRequired
                        placeholder={
                          !formFinanceiro.cod_loja.value
                            ? 'Informe a Loja Primeiro'
                            : 'Informe o Tipo de Condição'
                        }
                        onChange={(newValue: any) => {
                          setValueFinanceiro('cod_condicao', newValue);
                          setFormFinanceiro({
                            ...formFinanceiro,
                            cod_condicao: {
                              ...formFinanceiro.num_condicao,
                              value: newValue,
                              isInvalid: !newValue,
                            },
                          });
                        }}
                      />
                    </div>
                    <div className="col-sm-12 col-md-3">
                      <Finalizadora
                        value={formFinanceiro.cod_finalizadora.value}
                        isRequired={
                          !!formStateFinanceiro.errors.cod_finalizadora
                        }
                        setInvalid={
                          !!formStateFinanceiro.errors.cod_finalizadora
                        }
                        initialized={
                          !!formStateFinanceiro.errors.cod_finalizadora
                        }
                        onChange={(value: any) => {
                          setValueFinanceiro('cod_finalizadora', {
                            value: value.value,
                            label: value.label,
                          });
                          setFormFinanceiro({
                            ...formFinanceiro,
                            cod_finalizadora: {
                              ...formFinanceiro.cod_finalizadora,
                              value,
                              isInvalid: !value.value,
                            },
                          });
                        }}
                        isDisabled={!formFinanceiro.cod_loja.value}
                        loja={formFinanceiro.cod_loja.value}
                      />
                    </div>

                    <div className="col-sm-12 col-md-4">
                      <ContaBanco
                        loja={formFinanceiro.cod_loja.value}
                        value={formFinanceiro.cod_cc.value}
                        isRequired={!!formStateFinanceiro.errors.cod_cc}
                        setInvalid={!!formStateFinanceiro.errors.cod_cc}
                        isDisabled={!formFinanceiro.cod_loja.value}
                        iniInicializado={!!formStateFinanceiro.errors.cod_cc}
                        onChange={(newValue: any, isInvalid = true) => {
                          setValueFinanceiro('cod_cc', newValue);
                          handleChangeContaBanco(newValue, isInvalid);
                        }}
                      />
                    </div>

                    <div className="col-sm-12 col-md-8">
                      <Categoria
                        tipoCategoria={
                          tipoCategoria === 0 ? 'Despesa' : 'Receita'
                        }
                        value={codCategoria}
                        isRequired={!!formStateFinanceiro.errors.cod_categoria}
                        setInvalid={!!formStateFinanceiro.errors.cod_categoria}
                        iniInicializado={
                          !!formStateFinanceiro.errors.cod_categoria
                        }
                        onChange={(newValue: any, isInvalid = true) => {
                          setValueFinanceiro('cod_categoria', {
                            value: newValue.value,
                            label: newValue.label,
                          });
                          handleChangeCategoria(newValue, isInvalid);
                        }}
                      />
                    </div>

                    <div className="col-sm-12 col-md-2 perDesconto">
                      <InputPercent
                        name="per_desconto"
                        label="Desconto Financeiro"
                        placeholder="0,00"
                        register={financeiroRegister}
                        control={financeiroControl}
                        isDisabled={isUpdate}
                        max={100}
                        min={0}
                        isError={!!errors.per_desconto}
                      />
                    </div>
                    <div className="col-sm-12 col-md-3 mt-3 perDesconto">
                      <ToggleDefault
                        labelText="Imprimir Boleto Após NF?"
                        setChecked={formFinanceiro.flg_boleto.value}
                        onSwitch={() => {
                          if (formFinanceiro.flg_boleto.value === false) {
                            setValueFinanceiro('flg_boleto', true);
                            setFormFinanceiro({
                              ...formFinanceiro,
                              flg_boleto: {
                                ...formFinanceiro.flg_boleto,
                                value: true,
                              },
                            });
                          } else {
                            setValueFinanceiro('flg_boleto', false);
                            setFormFinanceiro({
                              ...formFinanceiro,
                              flg_boleto: {
                                ...formFinanceiro.flg_boleto,
                                value: false,
                              },
                            });
                          }
                        }}
                      />
                    </div>
                    <div className="col-sm-12 col-md-7 d-flex mt-4 containerButton">
                      <div className="containerButtonFlex">
                        <ConfirmButton
                          onClick={handleAddLoja}
                          style={{ minWidth: '113px' }}
                        >
                          Confirmar
                        </ConfirmButton>
                        <CancelButton
                          onClick={handleClearFinanceiro}
                          style={{ minWidth: '113px' }}
                        >
                          Cancelar
                        </CancelButton>
                      </div>
                    </div>
                    <div
                      className="col-sm-12 col-md-12 dataTable mt-4"
                      style={{ height: 300, width: '100%' }}
                    >
                      <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={5}
                        sortModel={[{ field: 'cod_loja', sort: 'asc' }]}
                        hideFooterSelectedRowCount
                        disableColumnMenu
                        disableColumnFilter
                        disableColumnSelector
                        disableSelectionOnClick
                        localeText={{
                          noRowsLabel: '',
                        }}
                      />
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </FormDefault>
      )}
    </Container>
  );
};

export default PerfilDeDocumentos;
